import {FC} from 'react'
// import ChartsWidget3 from "./_metronic/partials/widgets/charts/ChartsWidget3";
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {PageTitle} from '../../_metronic/layout/core'
import {
    TablesWidget17,
  } from '../../_metronic/partials/widgets'
  

const RelevantPage: FC = () => {
    return (
        <div >
            <TablesWidget17 className=''/>
        </div>
    )
}

export {RelevantPage}