/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl, KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'



type Props = {
  className: string
}

const TablesWidget16: React.FC<Props> = ({className}) => {
  return (
    <div>
      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 border-bottom-0">
            <li className="nav-item ps-4">
              <a
                className="nav-link fw-bold active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
              >
                Ключевые фразы
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link fw-bold"
                data-bs-toggle="tab"
                href="#kt_tab_pane_2"
              >
                Минус-слова
              </a>
            </li>
      </ul>
      <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade active show"
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <div className='table-overflow'>
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-bordered  table-row-gray-300 table-col-bordered  table-col-gray-300  mb-0 gx-5 gy-5'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-semibold text-muted'>
                        <th className='min-w-315px text-nowrap'>
                          <div className='d-flex flex-row ps-10'>
                            <div className='ps-8 text-muted fw-semibold'>
                              Слова
                            </div>
                          </div>
                          
                        </th>
                        <th className='w-200px mw-200px text-nowrap text-muted fw-semibold'>Группа</th>
                        <th className='min-w-350px text-nowrap text-muted fw-semibold'>Кампания</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                            <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7 text-truncate mw-200px'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7 text-truncate mw-200px'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7 text-truncate mw-200px'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7 text-truncate mw-200px'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7 text-truncate mw-200px'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7 text-truncate mw-200px'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7 text-truncate mw-200px'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7 text-truncate mw-200px mw-200px'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
              </div>
              

            </div>
            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              {/* begin::Body */}

              <div className='table-overflow'>
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-bordered  table-row-gray-300 table-col-bordered  table-col-gray-300 align-middle  gx-3 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-315px text-nowrap'>
                          <div className='d-flex flex-row ps-10'>
                            <div className='ps-8 text-muted py-3 fw-semibold'>
                              Фразы
                            </div>
                          </div>
                          
                        </th>
                        <th className='min-w-175px text-nowrap text-muted fw-semibold'>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </th>
                        <th className='min-w-350px text-nowrap text-muted fw-semibold'>
                          <div className='py-3'>
                            Группа
                          </div>
                        </th>
                        <th className='min-w-350px text-nowrap text-muted fw-semibold'>
                          <div className='py-3'>
                            Кампания
                          </div>
                        </th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                             <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center ps-4'>
                            <button className='btn p-0'>
                              <i className="bi bi-trash fs-2 me-5"></i>
                            </button>
                              <div className='btn btn-outline btn-outline-default text-nowrap fs-6 py-1 px-4'>
                                Поздравление Деда Мороза — МСК
                              </div>
                          </div>
                        </td>
                        <td>
                          <select className="form-select form-select-solid py-1" aria-label="На кампанию">
                            <option>На кампанию</option>
                            <option value="1">Один</option>
                            <option value="2">Два</option>
                            <option value="3">Три</option>
                          </select>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            Поздравление Деда Мороза — МСК
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column'>
                            <span className='text-dark fw-bold text-muted fs-8'>
                            №3222123
                            </span>
                            <span className='text-dark fw-bold text-nowrap fs-7'>
                            moroz-dedushka.ru
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
              </div>
        {/* begin::Body */}
            </div>
        </div>
    </div>
  )
}

export {TablesWidget16}
