/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'


const HeaderUserMenu = ({ hideRoles, role }) => {
  const {currentUser, logout} = useAuth()

  const [hideRole, setHideRole] = useState(true)

  useEffect( () => {
    setHideRole(hideRoles)
  }, [hideRoles]);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-3.jpg')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.first_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
            <div className='fs-semibold fs-7'>
              {role == "owner" && "Владелец"}
              {role == "admin" && "Администратор"}
              {role == "manager" && "Менеджер"}
              {role == "viewer" && "Только просмотр"}
            </div>
          </div>
        </div>
      </div>

      {(() => {
        if (hideRole || role == "viewer") {
          return (
            <div></div>
          )
        } else {
          return (
            <div className='menu-item px-5'>
              <Link
                //to='/role-page'
                to={`/role-page/?login=${currentUser?.email}`}
                className='menu-link px-5'
              >
                Права доступа
              </Link>
            </div>
          )
        }
      })()}

      {/*{hideRole ?*/}
      {/*  <div></div> :*/}
      {/*  <div className='menu-item px-5'>*/}
      {/*    <Link to='/role-page' className='menu-link px-5'>*/}
      {/*      Права доступа*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*}*/}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Выйти
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
