/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='d-block px-10'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div className='text-dark '>
            <span className='text-muted fw-bold me-1'>
              {new Date().getFullYear().toString()}&copy;
            </span>
            <a
              href='https://next-level-agency.ru/ngram/'
              target='_blank'
              className='text-muted fw-bold text-hover-primary'
            >N-GRAM </a>
            /
            <a
              href='mailto:help@ngrambidder.ru'
              target='_blank'
              className='text-muted fw-bold text-hover-primary'
            > help@ngrambidder.ru</a>
          </div>

          <div className='d-flex flex-column'>
            <a
              href='/police-page'
              target='_blank'
              className='text-muted text-end fw-bold text-hover-primary'
            >
              Политика конфиденциальности
            </a>
            <a
              href='/license-page'
              target='_blank'
              className='text-muted text-end fw-bold text-hover-primary'
            >
              Лицензионное соглашение
            </a>

          </div>
        </div>
      </div>


    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
