import {FC} from 'react'
// import ChartsWidget3 from "./_metronic/partials/widgets/charts/ChartsWidget3";
import {useIntl} from 'react-intl'
import {KTIcon, toAbsoluteUrl, KTSVG} from '../../_metronic/helpers'
import {PageTitle} from '../../_metronic/layout/core'
import {
    TablesWidget15,
    TablesWidget16,
  } from '../../_metronic/partials/widgets'
  

const OptimizationPage: FC = () => {
    return (
        <div >
            <TablesWidget15 className=''/>

            <button type="button"
                className="btn btn-primary btn-open__modal rounded-top rounded-bottom-0"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_1"
                >
                Развернуть окно плагина
                <i className="bi bi-chevron-up ms-2"></i>
            </button>
            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog table">
                    <div className="modal-content">
                    <div className="modal-header bg-light p-0">
                        <h5 className="modal-title text-muted ms-4">Добавление фраз</h5>
                        <div
                        className="btn btn-bg-dark text-light rounded-0 rounded-top-end-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        >
                        Свернуть
                        </div>
                    </div>
                    <div className="modal-body p-0">
                        <TablesWidget16 className=''/>
                    </div>
                    <div className="modal-footer">
                        <div className='row w-100'>
                            <div className='col-6'>
                                <button className='btn btn-outline btn-outline-light bg-light fw-bold text-dark w-100'>
                                    Отменить
                                </button>
                            </div>
                            <div className='col-6'>
                                <button  className='btn btn-outline btn-outline-primary w-100'>
                                    Сохранить
                                </button>
                            </div>
                            <div className='col-12 mt-3'>
                            
                                <button type='button' className='btn btn-primary w-100'
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_2"
                                    >
                                    Добавить фразы в Яндекс.Директ
                                    <i className="bi bi-arrow-up-right ms-3"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" tabIndex={-1} id="kt_modal_2">
                <div className="modal-dialog alert p-0">
                    <div className="modal-content p-10">
                    <h3 className="modal-title text-center fw-bold text-middle mb-4">Фразы добавлены</h3>
                    <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
                        <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3"></i>
                        
                        <div className="d-flex flex-column align-items-center">
                            <h5 className="text-center fw-semibold">Не забудьте отправить изменения в Яндекс.Директ</h5>
                        </div>
                    </div>

                    <button
                        type="button"
                        className="btn btn-primary w-100"
                        data-bs-dismiss="modal"
                        >
                        Хорошо
                    </button>
                    </div>
                </div>
            </div>
        </div> 
    )
}



export {OptimizationPage}