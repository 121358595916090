/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import axios from 'axios'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Не правильный формат')
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Электронная почта обязательна'),
  password: Yup.string()
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Пароль обязателен'),
})

const initialValues = {
  email: '',
  password: '',
  //email: 'admin@demo.com',
  //password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

  let API_URL = process.env.REACT_APP_NGRAM_API_URL

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        // const {data: auth} = await login(values.email, values.password)
        // console.log("auth: " + auth)
        // saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // console.log("user: " + user)
        // setCurrentUser(user)

        axios.post(`${API_URL}/login/`, {
          email: values.email,
          password: values.password
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(function (response) {
          ///console.log(response)
          ///console.log(response.status)
          ///console.log(response.data)
          //console.log(response.data.status)
          //console.log(response.data.result.User)
          if (response.status == 200) {
            //saveAuth(auth)
            saveAuth(response.data.result.User)
            //const {data: user} = await getUserByToken(auth.api_token)
            //setCurrentUser(user)
            setCurrentUser(response.data.result.User)
            localStorage.setItem('profileId', "0")
            localStorage.setItem('profileName', "Выберите профиль")
            localStorage.setItem('profileRole', "")

            localStorage.setItem('profileDateFrom', "0000-00-00")
            localStorage.setItem('profileDateTo', "0000-00-00")
            localStorage.setItem('profileAttributionValue', "8")
            localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
            localStorage.setItem('profileGoalValue', "0")
            localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
            localStorage.setItem('profileAutotargetValue', "keywords")
            localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
            localStorage.setItem('profileUrlIsYandexValue', "null")
            localStorage.setItem('profileUrlIsYandexLabel', "---")
            localStorage.setItem('profileUrlIsGoogleValue', "null")
            localStorage.setItem('profileUrlIsGoogleLabel', "---")
            localStorage.setItem('checkDateValue', "0")
            localStorage.setItem('checkDateLabel', "Выберите дату")
          }
        })
        .catch(function (error) {
          console.log(error)
          if (error.response.data.message == "Username or password is incorrect") {
            setStatus('Неверный логин или пароль')
          }
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
        });
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Авторизация</h1>
      </div>
      {/* begin::Heading */}

      {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Адрес электронной почты</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Пароль</label>
        <input
          placeholder='Пароль'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
        Не помню пароль
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Войти</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Подождите пожалуйста...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-dark-500 text-center fw-semibold fs-6'>
      У вас нет учетной записи?{' '}
        <Link to='/auth/registration' className='link-primary'>
        Зарегистрироваться
        </Link>
      </div>
    </form>
  )
}
