/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {KTIcon, toAbsoluteUrl, KTSVG} from '../../../helpers'
// import {reInitMenu} from '../helpers'
import {Tooltip} from '../../../layout/components/tooltip/'

import { useState } from "react";
import DatePicker from "react-datepicker";
import Select from 'react-select'

import "react-datepicker/dist/react-datepicker.css";




type Props = {
  className: string
}


const ChartsWidget9: React.FC<Props> = ({className}) => {
  const options = [
    { value: '№3222123', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222124', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222125', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222126', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222127', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222128', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222129', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222134', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222145', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222153', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222164', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222175', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222183', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222194', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222115', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
  ]


  const options2 = [
    { value: 'Атрибуция1', label: 'Атрибуция1' },
    { value: 'Атрибуция2', label: 'Атрибуция2' },
    { value: 'Атрибуция3', label: 'Атрибуция3' },
    { value: 'Атрибуция4', label: 'Атрибуция4' },
    { value: 'Атрибуция5', label: 'Атрибуция5' },
    { value: 'Атрибуция6', label: 'Атрибуция6' },
    { value: 'Атрибуция7', label: 'Атрибуция7' },
    { value: 'Атрибуция8', label: 'Атрибуция8' },
    { value: 'Атрибуция9', label: 'Атрибуция9' },
    { value: 'Атрибуция10', label: 'Атрибуция10' },
    { value: 'Атрибуция11', label: 'Атрибуция11' },

  ]

  const options3 = [
    { value: 'Данные по цели1', label: 'Данные по цели1' },
    { value: 'Данные по цели2', label: 'Данные по цели2' },
    { value: 'Данные по цели3', label: 'Данные по цели3' },
    { value: 'Данные по цели4', label: 'Данные по цели4' },
    { value: 'Данные по цели5', label: 'Данные по цели5' },
    { value: 'Данные по цели6', label: 'Данные по цели6' },
    { value: 'Данные по цели7', label: 'Данные по цели7' },
    { value: 'Данные по цели8', label: 'Данные по цели8' },
    { value: 'Данные по цели9', label: 'Данные по цели9' },
    { value: 'Данные по цели10', label: 'Данные по цели10' },
    { value: 'Данные по цели11', label: 'Данные по цели11' },

  ]

  const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

    const locale = {
    localize: {
        day: n => days[n],
        month: n => months[n]
    },
    formatLong: {
        date: () => 'mm/dd/yyyy'
    }
  }
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate() + 6,startDate.getHours(),startDate.getMinutes(),startDate.getSeconds()));

    const handleChange = ([newStartDate, newEndDate]) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-6'>
        <h3 className='card-title align-items-start flex-column mb-0'>
          <span className='card-label fw-bold fs-3'>Аналитика рекламных кампаний</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}

      
      <div className='card-body'>
        <div className='row'>
          <div className='col-6 mb-md-5 mb-xl-6'>
            <div className="input-group mb-3">
              <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                <i className="bi bi-search fs-4 fw-bold"></i>
              </span>
              <Select options={options} className='react-select-container form-control border-start-0 p-0' classNamePrefix="react-select" placeholder="Поиск по названию или номеру РК"/>
            </div>
          </div>
          <div className='col-6 mb-md-5 mb-xl-6'>
            <div className="input-group mb-3">
              <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                <i className="bi bi-calendar4-event fs-6 fw-bold"></i>
              </span>
              <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                  locale={locale}
                  selected={startDate}
                  onChange={handleChange}
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="d MMMM yyyy"
              />
            </div>
          </div>
          {/* <div className='col-3 mb-md-5 mb-xl-6'>
            <a href="#" className="btn btn-light-primary w-100"><i className="bi bi-funnel fs-4 me-1 fw-bold"></i>Расширенный фильтр</a>
          </div> */}
        </div>
        <div className='row mb-5'>
          <div className='col-4 mb-md-5 mb-xl-6'>
            <h6>Атрибуция  
            <Tooltip text="По умолчанию используется Автоматическая атрибуция">
              <i className="las la-info-circle ms-2 fs-2"></i>
            </Tooltip>
            </h6>
           

            <Select options={options2} className='form-select react-select-container p-0' classNamePrefix="no-icon"  placeholder="Установите атрибуцию"/>
            
          </div>
          <div className='col-8 mb-md-5 mb-xl-6'>
            <h6>Данные по целям 
              <Tooltip text="По умолчанию выводятся данные по целям из настроек РК">
                <i className="las la-info-circle ms-2 fs-2"></i>
              </Tooltip>
              </h6>
              <Select options={options3} className='form-select react-select-container p-0' classNamePrefix="no-icon"  placeholder="Выберите данные по целям"/>
          </div>
        </div>
        
        <div className='row'>
          <div className='col-3 mb-md-5 mb-xl-6'>
            <div className="bg-primary rounded py-6 px-1">
              <div className="d-flex flex-column align-items-center">
                <div className="fs-6 fw-bold text-white">Показы</div>
                <div className="fw-semibold text-white fs-2" data-kt-countup="true" data-kt-countup-value="1 000 000" data-kt-countup-prefix="$">0</div>
              </div>
            </div>
          </div>
          <div className='col-3 mb-md-5 mb-xl-6'>
            <div className="bg-success rounded py-6 px-1">
              <div className="d-flex flex-column align-items-center">
                <div className="fs-6 fw-bold text-white">Клики</div>
                <div className="fw-semibold text-white fs-2" data-kt-countup="true" data-kt-countup-value="1 000 000" data-kt-countup-prefix="$">0</div>
              </div>
            </div>
          </div>
          <div className='col-3 mb-md-5 mb-xl-6'>
            <div className="bg-danger rounded py-6 px-1">
              <div className="d-flex flex-column align-items-center">
                <div className="fs-6 fw-bold text-white">Расходы</div>
                <div className="fw-semibold text-white fs-2" data-kt-countup="true" data-kt-countup-value="1 000 000" data-kt-countup-prefix="$">0</div>
              </div>
            </div>
          </div>
          <div className='col-3 mb-md-5 mb-xl-6'>
            <div className="bg-info rounded py-6 px-1">
              <div className="d-flex flex-column align-items-center">
                <div className="fs-6 fw-bold text-white">CTR</div>
                <div className="fw-semibold text-white fs-2" data-kt-countup="true" data-kt-countup-value="1 000 000" data-kt-countup-prefix="$">0</div>
              </div>
            </div>
          </div>
          <div className='col-3 mb-md-5 mb-xl-6'>
            <div className="bg-info rounded py-6 px-1">
              <div className="d-flex flex-column align-items-center">
                <div className="fs-6 fw-bold text-white">Конверсии</div>
                <div className="fw-semibold text-white fs-2" data-kt-countup="true" data-kt-countup-value="1 000 000" data-kt-countup-prefix="$">0</div>
              </div>
            </div>
          </div>
          
          <div className='col-3 mb-md-5 mb-xl-6'>
            <div className="bg-warning rounded py-6 px-1">
              <div className="d-flex flex-column align-items-center">
                <div className="fs-6 fw-bold text-white">Средняя цена за клик</div>
                <div className="fw-semibold text-white fs-2" data-kt-countup="true" data-kt-countup-value="1 000 000" data-kt-countup-prefix="$">0</div>
              </div>
            </div>
          </div>
          <div className='col-3 mb-md-5 mb-xl-6'>
            <div className="bg-secondary rounded py-6 px-1">
              <div className="d-flex flex-column align-items-center">
                <div className="fs-6 fw-bold text-white">Коэф. конверсионности кликов</div>
                <div className="fw-semibold text-white fs-2" data-kt-countup="true" data-kt-countup-value="1 000 000" data-kt-countup-prefix="$">0</div>
              </div>
            </div>
          </div>
          <div className='col-3 mb-md-5 mb-xl-6'>
            <div className="bg-dark rounded py-6 px-1">
              <div className="d-flex flex-column align-items-center">
                <div className="fs-6 fw-bold text-white">Средняя цена за конверсию</div>
                <div className="fw-semibold text-white fs-2" data-kt-countup="true" data-kt-countup-value="1 000 000" data-kt-countup-prefix="$">0</div>
              </div>
            </div>
          </div>
          
        </div>

        <h4 className='mt-2'>Расходы, конверсии, средняя цена конверсии (CPL) по дням</h4>
      
        {/* begin::Chart */}
        <div className='row'> 
          <div className='col-9'>
            <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '480px'}}></div>
          </div>
          <div className='col-3'>
            <div>
              <div className='bg-light-danger text-danger fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-danger h-8px w-8px me-3"></span>
                Показы
              </div>
              <div className='bg-light-primary text-primary fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-primary h-8px w-8px me-3"></span>
                Клики
              </div>
              <div className='bg-light-success text-success fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-success h-8px w-8px me-3"></span>
                wCTR
              </div>
              <div className='bg-light-warning text-warning fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-warning h-8px w-8px me-3"></span>
                CPC
              </div>
              <div className='bg-light-info text-info fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-info h-8px w-8px me-3"></span>
                Расход
              </div>
              <div className='bg-light-danger text-danger fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-danger h-8px w-8px me-3"></span>
                Ср. позиция показов 
              </div>
              <div className='bg-light-primary text-primary fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-primary h-8px w-8px me-3"></span>
                Ср. объем трафика 
              </div>
              <div className='bg-light-success text-success fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-success h-8px w-8px me-3"></span>
                Конверсии
              </div>
              <div className='bg-light-warning text-warning fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-warning h-8px w-8px me-3"></span>
                Доход
              </div>
              <div className='bg-light-info text-info fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-info h-8px w-8px me-3"></span>
                CPA
              </div>
              <div className='bg-light-warning text-warning fw-bold rounded px-6 py-2 mb-2 fs-5'>
                <span className="bullet bullet-dot bg-warning h-8px w-8px me-3"></span>
                ДРР
              </div>
            </div>
          </div>
          
        </div>

        {/* <button className='btn btn-primary col-12 btn-lg mt-4'>Подробнее</button> */}
        
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget9}




function getChartOptions(height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const strokeColor = getCSSVariableValue('--bs-gray-300')

  const color1 = getCSSVariableValue('--bs-danger')
  const color2 = getCSSVariableValue('--bs-primary')
  const color3 = getCSSVariableValue('--bs-primary')
  const color4 = getCSSVariableValue('--bs-danger')
  const color5 = getCSSVariableValue('--bs-success')
  const color6 = getCSSVariableValue('--bs-info')
  const color7 = getCSSVariableValue('--bs-success')
  const color8 = getCSSVariableValue('--bs-warning')
  const color9 = getCSSVariableValue('--bs-warning')
  const color10 = getCSSVariableValue('--bs-warning')
  const color11 = getCSSVariableValue('--bs-info')


  return {
    series: [
      {
        name: 'Красный',
        data: [100000, 102000, 100000, 102000, 105000, 102000, 100000],
      },
      {
        name: 'Синий',
        data: [100000, 90000, 80000, 95000, 105000, 110000, 80000],
      },
      {
        name: 'Голубой',
        data: [60000, 60000, 50000, 70000, 75000, 70000, 52000],
      },
      {
        name: 'Темнокрасный',
        data: [50000, 50000, 45000, 52000, 57000, 55000, 45000],
      },
      {
        name: 'Темнозеленый',
        data: [38000, 43000, 45000, 42000, 40000, 41000, 40000],
      },
      {
        name: 'Фиолетовый',
        data: [35000, 40000, 42000, 39000, 37000, 38000, 37000],
      },
      {
        name: 'Зеленый',
        data: [23000, 23000, 10000, 29000, 43000, 34000, 10000],
      },
      {
        name: 'Оранжевый',
        data: [23000, 15000, 10000, 15000, 25000, 20000, 19000],
      },
      {
        name: 'Желтый',
        data: [15000, 16000, 14000, 17000, 20000, 19000, 15000],
      },
      {
        name: 'Темножелтый',
        data: [18000, 9000, 5000, 11000, 19000, 15000, 12000],
      },
      {
        name: 'Темнофиолетовый',
        data: [9000, 8000, 2000, 11000, 18000, 15000, 3000],
      },

    ],
    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: 476,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },

    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [color1,color2,color3,color4,color5,color6,color7,color8,color9,color10,color11],
    },
    xaxis: {
      categories: ['4 Июн', '5 Июн', '6 Июн', '7 Июн', '8 Июн', '9 Июн', '10 Июн'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    // yaxis: {
    //   labels: {
    //     style: {
    //       colors: labelColor,
    //       fontSize: '12px',
    //     },
    //   },
    // },

    yaxis: [
      {
        title: {
          text: "Расходы, ₽",
        },
      },
      {
        opposite: true,
        title: {
          text: "Конверсии",
        }
      },
    ],

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
    },
    
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      // colors: [color1, color2, color3],
      strokeColors: [color1,color2,color3,color4,color5,color6,color7,color8,color9,color10,color11],
      strokeWidth: 3,
    },
  }
}
