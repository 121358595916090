import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
// import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
// import {ToolbarWrapper} from './components/toolbar'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>

                <Content>
                  <Outlet />
                </Content>
              </div>
              
            </div>
            <FooterWrapper />
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
      <div className="modal fade" tabIndex={-1} id="kt_modal_4">
            <div className="modal-dialog alert p-0">
                <div className="modal-content p-10">
                <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
                    <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3"></i>
                    
                    <div className="d-flex flex-column align-items-center">
                        <h5 className="text-center fw-semibold">Подождите, идет получение данных из Яндекс.Директ</h5>
                    </div>
                </div>
                </div>
            </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_5">
            <div className="modal-dialog alert p-0">
                <div className="modal-content p-10">
                <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
                    <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3"></i>
                    
                    <div className="d-flex flex-column align-items-center">
                        <h5 className="text-center fw-semibold">Данные отправлены в Яндекс.Директ</h5>
                    </div>
                </div>

                <button
                    type="button"
                    className="btn btn-primary w-100"
                    data-bs-dismiss="modal"
                    >
                    Закрыть
                </button>
                </div>
            </div>
      </div>
    </PageDataProvider>

  )
}

export {MasterLayout}
