import ReactDataGrid from '@inovua/reactdatagrid-community'

export const translateRDG = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
  sortAsc: 'По возрастанию',
  sortDesc: 'По убыванию',
  unsort: 'По умолчанию',
  group: 'По группе',
  ungroup: 'По умолчанию',
  lockStart: 'Закрепить от',
  lockEnd: 'Закрепить до',
  unlock: 'Открепить',
  columns: 'Колонки',
  showFilteringRow: 'Показать фильтр',
  hideFilteringRow: 'Скрыть фильтр',
  start: 'От',
  end: 'До'
})
