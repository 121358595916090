/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {Link} from 'react-router-dom'

import { useState } from "react";
import DatePicker  from "react-datepicker";
import Select from 'react-select'

import "react-datepicker/dist/react-datepicker.css";

type Props = {
  className: string
}

const ChartsWidget3: React.FC<Props> = ({className}) => {
  const options = [
    { value: '№3222123', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222124', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222125', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222126', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222127', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222128', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222129', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222134', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222145', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222153', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222164', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222175', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222183', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222194', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
    { value: '№3222115', label: `№3222123 \nПоздравление Деда Мороза — МСК `},
  ]


  const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

    const locale = {
    localize: {
        day: n => days[n],
        month: n => months[n]
    },
    formatLong: {
        date: () => 'mm/dd/yyyy'
    }
    }
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate() + 6,startDate.getHours(),startDate.getMinutes(),startDate.getSeconds()));

    const handleChange = ([newStartDate, newEndDate]) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column mb-0'>
          <span className='card-label fw-bold fs-3'>Динамика КПСС по аккаунту</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      
      <div className='card-body'>
        <div className='row'>
          <div className='col-6 mb-md-5 mb-xl-2'>
            <div className="input-group mb-3 dropdown">
              <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                <i className="bi bi-search fs-4 fw-bold"></i>
              </span>
              <Select options={options} className='react-select-container form-control border-start-0 p-0' classNamePrefix="react-select" placeholder="Поиск по названию или номеру РК"/>
            </div>
            
          </div>
          <div className='col-6 mb-md-5 mb-xl-2'>
            <div className="input-group mb-3">
              <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                <i className="bi bi-calendar4-event fs-6 fw-bold"></i>
              </span>
              {/* <select className="form-select border-start-0 ps-0 text-muted" aria-label="Select example">
                <option>9 янв 2023 - 12 апр 2023</option>
                <option value="1">9 янв 2023 - 12 апр 2023</option>
                <option value="2">9 янв 2023 - 12 апр 2023</option>
                <option value="3">9 янв 2023 - 12 апр 2023</option>
              </select> */}
              <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                  locale={locale}
                  selected={startDate}
                  onChange={handleChange}
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="d MMMM yyyy"
              />
            </div>
          </div>
        </div>
      
        
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '260px'}}></div>
        {/* end::Chart */}

        <Link to='/report-page' className='btn btn-primary col-12 btn-lg mt-4'>
          Смотреть отчёт КПСС по кампаниям
        </Link>
{/* 
        <button className='btn btn-primary col-12 btn-lg mt-4'>Смотреть отчёт КПСС по кампаниям</button> */}
      </div>
      {/* end::Body */}

    </div>
  )
}

export {ChartsWidget3}

function getChartOptions(height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const lightColor = getCSSVariableValue('--bs-primary-light')

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 40, 40, 90, 90, 70, 90],
      },

    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 260,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      
      categories: ['4 \n июн', '5 \n июн', '6 \n июн', '7 \n июн', '8 \n июн', '9 \n июн', '10 \n июн'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '13px',
        },
      },
      
    },

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
