/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'

const RoleUserMenu = ({ profileRole }) => {
  const {currentUser, logout} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <Link to='#' className='menu-link px-5' data-bs-toggle="modal" data-bs-target="#kt_modals_4">
          Изменить роль
        </Link>
      </div>

      {profileRole == "owner" ?
        <div className='menu-item px-5'>
          <Link to='#' className='menu-link px-5' data-bs-toggle="modal" data-bs-target="#kt_modals_3">
            Удалить пользователя
          </Link>
        </div> :
        <div></div>
      }

    </div>
  )
}

export {RoleUserMenu}
