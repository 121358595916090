import {FC} from 'react'
// import ChartsWidget3 from "./_metronic/partials/widgets/charts/ChartsWidget3";
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {PageTitle} from '../../_metronic/layout/core'
import {
    TablesWidget14,
  } from '../../_metronic/partials/widgets'
  

const BidderPage: FC = () => {
    return (
        <div >
            <TablesWidget14 className=''/>
        </div>
    )
}

export {BidderPage}