import React, {FC, useEffect, useState} from 'react'
// import ChartsWidget3 from "./_metronic/partials/widgets/charts/ChartsWidget3";
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {KTIcon, toAbsoluteUrl, KTSVG} from '../../_metronic/helpers'
import {HeaderNotificationsMenu, RoleUserMenu, Search, ThemeModeSwitcher} from '../../_metronic/partials'
import {PageTitle} from '../../_metronic/layout/core'
import {
    TablesWidget16,
  } from '../../_metronic/partials/widgets'
import Select from 'react-select'
import {useAuth} from '../modules/auth'
import { Modal } from 'react-bootstrap'

interface UserDataItem {
    account_id: number;
    login: string;
    role: string;
}

const RolePage: FC = () => {

    const API_URL = process.env.REACT_APP_NGRAM_API_URL

    const profileRole = localStorage.getItem('profileRole')

    const rolesOwnerandAdmin = [
        { value: 'admin', label: `Администратор` },
        { value: 'manager', label: `Менеджер` },
        { value: 'viewer', label: `Только просмотр` }
    ]

    const {currentUser, logout} = useAuth()

    const [showModalInvite, setShowModalInvite] = useState(false);
    const handleCloseModalInvite = () => setShowModalInvite(false);
    //const handleShowModalInvite = () => setShowModalInvite(true);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const handleCloseModalEdit = () => setShowModalEdit(false);
    //const handleShowModalEdit = () => setShowModalEdit(true);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const handleCloseModalDelete = () => setShowModalDelete(false);
    //const handleShowModalDelete = () => setShowModalDelete(true);
    const [showModalPermissionDenied, setShowModalPermissionDenied] = useState(false);
    const handleCloseModalPermissionDenied = () => setShowModalPermissionDenied(false);
    //const handleShowModalPermissionDenied = () => setShowModalPermissionDenied(true);

    const [users, setUsers] = useState<UserDataItem[]>([])
    const [emailInvite, setEmailInvite] = useState("")
    const [accountId, setAccountId] = useState(0)
    const [login, setLogin] = useState("")
    const [role, setRole] = useState("")
    const [roleTranslate, setRoleTranslate] = useState("")

    useEffect( () => {
        if (localStorage.getItem('profileId') != "0" || localStorage.getItem('profileId') != null ) {
            getUsersListOfProfile()
        }
    }, []);

    const getUsersListOfProfile = () => {
        const profileId = localStorage.getItem('profileId')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${currentUser?.api_token}`
        }

        fetch(`${API_URL}/get_users_list_of_profile/?profile_id=${profileId}`, {
            method: 'GET',
            headers: headers
        })
          .then(response => response.json())
          .then((data) => {
              //console.log(data.result);
              data.result.map(userItem => {
                  setUsers(users => [
                      ...users,
                      {
                          account_id: userItem.account_id,
                          login: userItem.login,
                          role: userItem.role
                      }
                  ])
              });
          });
    }

    const handleEmailInvite = event => {
        setEmailInvite(event.target.value);
    };

    const handleRole = (selectedOption) => {
        setRole(selectedOption.value)
    }

    function handleEditRole(event, account_id, login, role) {
        //console.log(account_id)
        //console.log(login)
        //console.log(role)
        setAccountId(account_id)
        setLogin(login)
        setRole(role)
        if (role == "owner") {
            setRoleTranslate("Владелец")
        } else if (role == "admin") {
            setRoleTranslate("Администратор")
        } else if (role == "manager") {
            setRoleTranslate("Менеджер")
        } else {
            setRoleTranslate("Только просмотр")
        }
    }

    const invite = () => {
        const query = {
            profile_id: localStorage.getItem('profileId'),
            email: emailInvite,
            role: role
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${currentUser?.api_token}`
        }

        fetch(`${API_URL}/send_invite_link_to_profile/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(query)
        })
          .then(response => response.json())
          .then((data) => {
              //console.log(data.status)
              if (data.status != "ERROR") {
                  setShowModalInvite(true)
              }
          });
    }

    const editUser = () => {
        const query = {
            profile_id: localStorage.getItem('profileId'),
            account_id: accountId,
            role: role
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${currentUser?.api_token}`
        }

        fetch(`${API_URL}/change_user_role_on_profile/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(query)
        })
          .then(response => response.json())
          .then((data) => {
              //console.log(data)
              if (data.status != "ERROR") {
                  setShowModalEdit(true)
              } else {
                  setShowModalPermissionDenied(true)
              }
          });
    }

    const deleteUser = () => {
        const query = {
            profile_id: localStorage.getItem('profileId'),
            account_id: accountId
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${currentUser?.api_token}`
        }

        fetch(`${API_URL}/delete_user_from_profile/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(query)
        })
          .then(response => response.json())
          .then((data) => {
              //console.log(data)
              if (data.status != "ERROR") {
                  setShowModalDelete(true)
              } else {
                  setShowModalPermissionDenied(true)
              }
          });
    }

    return (
        <div >
            <div className={`card mb-5`}>
                <div className='card-header border-0 pt-5 d-flex flex-column'>
                    <ol className="breadcrumb breadcrumb-dot text-muted fs-6 fw-bold mb-5">
                        <li className="breadcrumb-item pe-3">

                        <a href="#" className="pe-1">
                            <i className="fa-solid fa-house text-muted fs-6"></i>
                        </a>
                        </li>
                        <li className="breadcrumb-item px-0 text-muted">Права доступа</li>
                    </ol>
                    <h3 className='card-title align-items-start flex-column'>
                        {<span className='card-label fw-bold fs-3 mb-1'>Пользователи, управляющие аккаунтом</span>}
                    </h3>
                </div>

                <div className='card-body py-3'>
                    <div className='row'>
                        <div className='col-7'>
                            <div className='bg-light-primary py-6 px-5 rounded-3 mb-5'>
                                <div className='fs-4 fw-bold mb-3'>Управление ролями пользователей</div>
                                <div className='fw-bold text-muted'>Чтобы изменить роль или передать права управляющего, напротив нужного сотрудника нажмите «Изменить», затем выберите нужное действие.</div>
                            </div>


                            <table className='table table-bordered border align-middle mb-0 gx-5 gy-5 mb-4 overflow-visible'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th className='min-w-175px text-nowrap text-muted fw-semibold'>Логин</th>
                                        <th className='min-w-175px text-nowrap text-muted fw-semibold'>Роль</th>
                                        <th className='min-w-125px text-nowrap text-muted fw-semibold'>Действия</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>

                                {users && users.map((value, key) => {
                                    return (

                                    <tr key={key} data-key={key}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='text-muted'>{value.login}</div>
                                            </div>
                                        </td>
                                        <td className=''>
                                            <div className='d-flex align-items-center fw-semibold'>
                                                {value.role == "owner" && "Владелец"}
                                                {value.role == "admin" && "Администратор"}
                                                {value.role == "manager" && "Менеджер"}
                                                {value.role == "viewer" && "Только просмотр"}
                                            </div>
                                        </td>
                                        {(() => {
                                            if (profileRole == "manager" || profileRole == "viewer") {
                                                return (
                                                  <td>
                                                      <div className='app-navbar-item'>
                                                          <div
                                                            className='cursor-pointer symbol link-primary  fw-semibold'
                                                            //data-kt-menu-trigger="{default: 'click'}"
                                                            //data-kt-menu-attach='parent'
                                                            //data-kt-menu-placement='bottom-end'
                                                            //onClick={(event) => {handleEditRole(event, value.account_id, value.login, value.role)}}
                                                          >
                                                              Изменить
                                                          </div>
                                                          {/*<RoleUserMenu profileRole={profileRole} />*/}
                                                      </div>
                                                  </td>
                                                )
                                            } else {
                                                return (
                                                  <td>
                                                      <div className='app-navbar-item'>
                                                          <div
                                                            className='cursor-pointer symbol link-primary  fw-semibold'
                                                            data-kt-menu-trigger="{default: 'click'}"
                                                            data-kt-menu-attach='parent'
                                                            data-kt-menu-placement='bottom-end'
                                                            onClick={(event) => {handleEditRole(event, value.account_id, value.login, value.role)}}
                                                          >
                                                              Изменить
                                                          </div>
                                                          <RoleUserMenu profileRole={profileRole} />
                                                      </div>
                                                  </td>
                                                )
                                            }
                                        })()}
                                    </tr>)
                                })}
                                </tbody>
                            {/* end::Table body */}
                            </table>
                        </div>
                        <div className='col-5 d-flex justify-content-end'>
                            {(() => {
                                if (profileRole == "manager" || profileRole == "viewer") {
                                    return (
                                      <div className='d-block'>

                                      </div>
                                    )
                                } else {
                                    return (
                                      <div className='d-block'>
                                          <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#kt_modals_1">Пригласить пользователя</button>
                                      </div>
                                    )
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" tabIndex={-1} id="kt_modals_1">
                <div className="modal-dialog alert p-0">
                    <div className="modal-content p-10">
                        <h3 className="modal-title fw-bold mb-4 fs-2">Пригласить пользователя</h3>
                        <div className='fs-6 mb-7'>Пользователь получит письмо на указанную электронную почту с уведомлением и ссылкой для подтверждения аккаунта</div>

                        <form action="">
                            <input type="text" className="form-control mb-5" placeholder="Электронная почта" onChange={handleEmailInvite} />
                            <Select options={rolesOwnerandAdmin} className='form-select header-select-container text-dark p-0 mb-5' classNamePrefix="modal-select"  placeholder="Роль" defaultValue={{value: "0", label: "Роль"}} onChange={handleRole} />

                            <div className='text-dark fs-6 mb-5'>
                            Предоставляя персональные данные третьего лица для добавления пользователя, я заверяю о наличии согласия такого лица на передачу вам персональных данных, а также последующую обработку вами персональных данных.
                            </div>

                            <button
                                type="button"
                                className="btn btn-primary w-100"
                                //data-bs-toggle="modal"
                                //data-bs-target="#kt_modals_2"
                                data-bs-dismiss="modal"
                                onClick={invite}
                                >
                                Пригласить
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            {/*<div className="modal fade" tabIndex={-1} id="kt_modals_2">*/}
            {/*    <div className="modal-dialog alert p-0">*/}
            {/*        <div className="modal-content p-10">*/}
            {/*        <h3 className="modal-title text-center fw-bold text-middle mb-4 fs-2">Пользователь приглашен</h3>*/}

            {/*        <div className='text-center text-middle fs-6 mb-5'>*/}
            {/*        Как только человек пройдет авторизацию, он появится в таблице пользователей.*/}
            {/*        </div>*/}

            {/*        <button*/}
            {/*            type="button"*/}
            {/*            className="btn btn-primary w-100"*/}
            {/*            data-bs-dismiss="modal"*/}
            {/*            >*/}
            {/*            Хорошо*/}
            {/*        </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Modal dialogClassName="alert p-0" contentClassName="p-10" tabIndex={-1} id="kt_modals_2" show={showModalInvite} onHide={handleCloseModalInvite} animation={true}>
                <h3 className="modal-title text-center fw-bold text-middle mb-4 fs-2">Пользователь приглашен</h3>
                <div className='text-center text-middle fs-6 mb-5'>
                    Как только человек пройдет авторизацию, он появится в таблице пользователей.
                </div>
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                  onClick={handleCloseModalInvite}
                >
                    Хорошо
                </button>
            </Modal>

            <div className="modal fade" tabIndex={-1} id="kt_modals_3">
                <div className="modal-dialog alert p-0">
                    <div className="modal-content p-10">
                    <div className='fs-6 mb-5'>
                    Вы уверены, что хотите удалить
                    пользователя {login} ?
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <button
                            type="button"
                            className="btn btn-primary w-50 me-2"
                            //data-bs-toggle="modal"
                            //data-bs-target="#kt_modals_6"
                            data-bs-dismiss="modal"
                            onClick={deleteUser}
                            >
                            Да
                        </button>
                        <button
                            type="button"
                            className="btn btn-light w-50"
                            data-bs-dismiss="modal"
                            >
                            Нет
                        </button>
                    </div>

                    </div>
                </div>
            </div>

            {/*<div className="modal fade" tabIndex={-1} id="kt_modals_6">*/}
            {/*    <div className="modal-dialog alert p-0">*/}
            {/*        <div className="modal-content p-10">*/}
            {/*            <h3 className="modal-title text-center fw-bold text-middle mb-4 fs-5">Пользователь {login} удален</h3>*/}
            {/*            <button*/}
            {/*              type="button"*/}
            {/*              className="btn btn-primary w-100"*/}
            {/*              data-bs-dismiss="modal"*/}
            {/*            >*/}
            {/*                Хорошо*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Modal dialogClassName="alert p-0" contentClassName="p-10" tabIndex={-1} id="kt_modals_6" show={showModalDelete} onHide={handleCloseModalDelete} animation={true}>
                <h3 className="modal-title text-center fw-bold text-middle mb-4 fs-2">Пользователь {login} удален</h3>
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                  onClick={handleCloseModalDelete}
                >
                    Хорошо
                </button>
            </Modal>

            <div className="modal fade" tabIndex={-1} id="kt_modals_4">
                <div className="modal-dialog alert p-0">
                    <div className="modal-content p-10">
                        <h3 className="modal-title text-center fw-bold text-middle mb-4 fs-2">Настройка доступа</h3>

                        <form action="">
                            <input type="text" className="form-control mb-5" disabled placeholder={login} />
                            <Select options={rolesOwnerandAdmin} className='form-select header-select-container text-dark p-0 mb-5' classNamePrefix="modal-select"  placeholder={roleTranslate} onChange={handleRole} />
                            <button
                                type="button"
                                className="btn btn-primary w-100"
                                //data-bs-toggle="modal"
                                //data-bs-target="#kt_modals_5"
                                data-bs-dismiss="modal"
                                onClick={editUser}
                                >
                                Применить
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            {/*<div className="modal fade" tabIndex={-1} id="kt_modals_5">*/}
            {/*    <div className="modal-dialog alert p-0">*/}
            {/*        <div className="modal-content p-10">*/}
            {/*        <h3 className="modal-title text-center fw-bold text-middle mb-4">Доступ изменен</h3>*/}
            {/*        <button*/}
            {/*            type="button"*/}
            {/*            className="btn btn-primary w-100"*/}
            {/*            data-bs-dismiss="modal"*/}
            {/*            >*/}
            {/*            Хорошо*/}
            {/*        </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Modal dialogClassName="alert p-0" contentClassName="p-10" tabIndex={-1} id="kt_modals_5" show={showModalEdit} onHide={handleCloseModalEdit} animation={true}>
                <h3 className="modal-title text-center fw-bold text-middle mb-4 fs-2">Доступ изменен</h3>
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                  onClick={handleCloseModalEdit}
                >
                    Хорошо
                </button>
            </Modal>

            <Modal dialogClassName="alert p-0" contentClassName="p-10" tabIndex={-1} id="kt_modals_permission_denied" show={showModalPermissionDenied} onHide={handleCloseModalPermissionDenied} animation={true}>
                <h3 className="modal-title text-center fw-bold text-middle mb-4 fs-2">Доступ запрещен</h3>
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                  onClick={handleCloseModalPermissionDenied}
                >
                    Хорошо
                </button>
            </Modal>
        </div>
    )
}

export {RolePage}
