/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl, KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'



type Props = {
  className: string
}

const TablesWidget15: React.FC<Props> = ({className}) => {
  return (
    <div>
      <div className={`card ${className} mb-7`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex flex-column'>
          <ol className="breadcrumb breadcrumb-dot text-muted fs-6 fw-bold mb-5">
              <li className="breadcrumb-item pe-3">
                <a href="#" className="pe-1">
                  <i className="fa-solid fa-house text-muted fs-4"></i>
                </a>
              </li>
              <li className="breadcrumb-item text-grey-800 px-1">Отчет о КПСС </li>
              <li className="breadcrumb-item text-grey-800 px-1">Оптимизация КПСС</li>
              <li className="breadcrumb-item text-grey-800 px-1">Поисковые запросы</li>
              <li className="breadcrumb-item px-1 text-muted">Семантический анализ </li>
          </ol>

          <h3 className='card-title align-items-start flex-column mb-4'>
            <span className='card-label fw-bold fs-2 mb-1'>Семантический анализ</span>
            <span className='card-subtitle mt-4 fs-4'>Сервис нормализует поисковые запросы и ключевые фразы, производит очистку от стоп-слов и проверяет вхождение ключевой фразы в запрос. </span>
            <span className='card-subtitle mt-4 fs-4'>Используйте столбец «Недостающие слова» и добавляйте фразы с высоким потенциалом в ключевые слова для максимальной релевантности поисковых запросов. </span>
            <span className='card-subtitle mt-4 fs-4'>Чтобы добавить ключевую фразу нажмите «+», или кликните по недостающему слову чтобы добавить его к минус-словам.</span>
          </h3>

          
        </div>
        {/* end::Header */}

        <div className='card-body py-3 '>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-bordered mb-0 gx-5 gy-5'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-semibold text-muted'>
                  <th className='min-w-500px text-nowrap'>
                    <div className='d-flex flex-row align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='ms-3 text-muted fw-semibold'>
                        Поисковой запрос
                      </div>
                      
                    </div>
                    
                  </th>
                  <th className='min-w-400px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Рекламная кампания</span>
                        <i className="las la-angle-down fw-bold"></i>
                    </div>
                  </th>
                  <th className='min-w-400px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Группа</span>
                        <i className="las la-angle-down fw-bold"></i>
                    </div>
                  </th>
                  <th className='min-w-400px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Ключевая фраза</span>
                        <i className="las la-angle-down fw-bold"></i>
                    </div>
                  </th>
                  <th className='min-w-400px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Недостающие слова</span>
                        <i className="las la-angle-down fw-bold"></i>
                    </div>
                  </th>
                  <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Потенциал</span>
                        <i className="las la-arrow-up"></i>
                    </div>
                  </th>
                  <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Показы</span>
                        <i className="las la-arrow-up"></i>
                    </div>
                  </th>
                  <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Клики</span>
                        <i className="las la-arrow-up"></i>
                    </div>
                  </th>
                  <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Расход</span>
                        <i className="las la-arrow-up"></i>
                    </div>
                  </th>
                  <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Конверсии</span>
                        <i className="las la-arrow-up"></i>
                    </div>
                  </th>
                  <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='text-nowrap text-muted fw-semibold'>Доход</span>
                        <i className="las la-arrow-up"></i>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <KTSVG path="/media/icons/duotune/arrows/arr013.svg" className="svg-icon-primary  svg-icon-2hx" />
                      </div>
                      <div className='d-flex justify-content-start flex-column ms-3'>
                        <div className='text-dark fw-bold fs-6'>
                          Нанаять деда мороза в москве
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                        <div className='text-dark fw-bold fs-6'>
                          Поздравление Деда Мороза — МСК
                        </div>
                    </div>
                  </td>
                  <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        №3222123
                      </span>
                      <div className='text-dark fw-bold fs-6'>
                        Поздравление Деда Мороза — МСК
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                      </span>
                    </div>
                    
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                      ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-4'>
                        ???
                    </span>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          <div className='d-flex align-items-center justify-content-between mb-5 mt-7'>
            <div>
              <select className="form-select form-select-solid" aria-label="Select example">
                <option>10</option>
                <option value="1">9</option>
                <option value="2">9</option>
                <option value="3">9</option>
              </select>
            </div>
            <div>
            <ul className="pagination">
                <li className="page-item previous disabled">
                  <a href="#" className="page-link">
                    <i className="previous"></i>
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a href="#" className="page-link">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    4
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    5
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    6
                  </a>
                </li>
                <li className="page-item next">
                  <a href="#" className="page-link fw-bold text-muted">
                    <i className="next text-muted"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {TablesWidget15}
