/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid bg-light '>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='mb-20'>
            <img className='mw-200px' src="/media/logos/logo.svg" alt="" />
          </div>
          {/* begin::Wrapper */}
          <div className='w-lg-500px  p-12 py-15 bg-white rounded-3'>
            <Outlet />
          </div>
          {/* end::Wrapper */}

        </div>
        {/* end::Form */}
        <div className='d-flex justify-content-center px-10  mt-10'>
            <div className='w-lg-500px  d-flex align-items-center justify-content-between w-100'>
              <div className='text-dark '>
                <span className='text-muted fw-bold me-1'>
                  {new Date().getFullYear().toString()}&copy;
                </span>
                <a
                  href='https://next-level-agency.ru/ngram/'
                  target='_blank'
                  className='text-muted fw-bold text-hover-primary'
                >N-GRAM </a>
                /
                <a
                  href='mailto:help@ngrambidder.ru'
                  target='_blank'
                  className='text-muted fw-bold text-hover-primary'
                > help@ngrambidder.ru</a>
              </div>

              <div className='d-flex flex-column'>
                <a
                  href='/police-page'
                  target='_blank'
                  className='text-muted fw-bold text-hover-primary'
                >
                  Политика конфиденциальности
                </a>
                <a
                  href='/license-page'
                  target='_blank'
                  className='text-muted text-end fw-bold text-hover-primary'
                >
                  Лицензионное соглашение
                </a>

              </div>
            </div>
          </div>
      </div>
      {/* end::Body */}

    </div>
  )
}

export {AuthLayout}
