import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import Select from 'react-select'
import {useAuth} from '../../../../app/modules/auth'
import {useEffect, useRef, useState} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {addToDataLayer, sendMetricVisitParametr} from '../../../../utils/metrics'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

interface ProfileDataItem {
  id: number;
  name: string;
  role: string;
  value: string;
  label: string;
}

const Navbar = () => {

  const API_URL = process.env.REACT_APP_NGRAM_API_URL

  const {config} = useLayout()

  const {currentUser, logout} = useAuth()

  const [profiles, setProfiles] = useState<ProfileDataItem[]>([])

  const [hideRoles, setHideRoles] = useState(true)

  const [role, setRole] = useState("")

  const getProfiles = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    };

    fetch(`${API_URL}/get_profiles_of_user/`, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then((data) => {
        //console.log(data.result);
        setProfiles([])
        data.result.map(profileItem => {
          setProfiles(profiles => [
            ...profiles,
            {
              id: profileItem.profile.id,
              name: profileItem.profile.name,
              role: profileItem.role,
              value: `${profileItem.profile.id}`,
              label: profileItem.profile.name

            }
          ])
        });

        if (data.result.length != 0) {
          setHideRoles(false)
        }

        firstUpdate.current = false
      });
  }

  const firstUpdate = useRef(true)

  useEffect( () => {
    getProfiles()
  }, []);

  useEffect( () => {
    if (!firstUpdate.current) {
      const profileRole = localStorage.getItem('profileRole')
      if (profiles.length != 0 && localStorage.getItem('profileId') == "0") {
        setRole(profiles[0].role)
        localStorage.setItem('profileId', String(profiles[0].id))
        localStorage.setItem('profileName', profiles[0].name)
        localStorage.setItem('profileRole', profiles[0].role)

        window.dispatchEvent(new Event("localStorage"));
      } else {
        setRole(String(profileRole))
      }

      // sendMetricVisitParametr("params", {
      //   [`${currentUser?.email}`]: {
      //     [`${localStorage.getItem('profileName')}`]: {
      //       [`${window.location.pathname}`]: {}
      //     }
      //   }
      // })

      sendMetricVisitParametr("params", {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {}
        }
      })

      addToDataLayer({
        'login': currentUser?.email,
        'profile': localStorage.getItem('profileName'),
        'url': window.location.pathname,
      })
    }
  }, [profiles]);

  const handleProfile = (selectedOption) => {
    getProfiles()
    localStorage.setItem('profileId', selectedOption.id)
    localStorage.setItem('profileName', selectedOption.name)
    localStorage.setItem('profileRole', selectedOption.role)

    localStorage.setItem('profileDateFrom', "0000-00-00")
    localStorage.setItem('profileDateTo', "0000-00-00")
    localStorage.setItem('profileAttributionValue', "8")
    localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
    localStorage.setItem('profileGoalValue', "0")
    localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
    localStorage.setItem('profileAutotargetValue', "keywords")
    localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
    localStorage.setItem('profileUrlIsYandexValue', "null")
    localStorage.setItem('profileUrlIsYandexLabel', "---")
    localStorage.setItem('profileUrlIsGoogleValue', "null")
    localStorage.setItem('profileUrlIsGoogleLabel', "---")
    localStorage.setItem('checkDateValue', "0")
    localStorage.setItem('checkDateLabel', "Выберите дату")

    window.dispatchEvent(new Event("localStorage"));
    window.location.reload()
  }

  // @ts-ignore
  return (
    <div className='app-navbar flex-shrink-0 me-10'>

      {hideRoles ?
        <div></div> :
        <div className='d-flex align-items-center'>
          <Select options={profiles} value={{value: localStorage.getItem('profileId'), label: localStorage.getItem('profileName')}} className='form-select header-select-container border-0 text-dark p-0 min-w-200px' classNamePrefix="no-icon"  placeholder="Выберите профиль" defaultValue={{value: localStorage.getItem('profileId'), label: localStorage.getItem('profileName')}} onChange={handleProfile} />
        </div>
      }

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <a href='#' className='me-3'>
          <i className="bi bi-bell fs-2"></i>
        </a>
      </div>  */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-3.jpg')} alt='' />
        </div>
        <HeaderUserMenu hideRoles={hideRoles} role={role} />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
