/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Tooltip} from '../../../layout/components/tooltip/'
import {ScrollContainer} from 'react-indiana-drag-scroll'
import 'react-indiana-drag-scroll/dist/style.css'
import DatePicker, {registerLocale} from 'react-datepicker'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
import {useAuth} from '../../../../app/modules/auth'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import CheckBox from '@inovua/reactdatagrid-community/packages/CheckBox'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../helpers'
import * as XLSX from 'xlsx'
import {addDays} from 'date-fns'
import {addToDataLayer, sendMetricGoalVisitParametr, sendMetricVisitParametr} from '../../../../utils/metrics'

registerLocale("ru", ru); // register it with the name you want

type Props = {
  className: string
}

interface BidderCampaignDataItem {
  id: number;
  direct_id: string;
  name: string;
  default_goal: string;
  date_period: string;
  attribution_model: string;
  daily_budget: string;
  daily_budget_period: string;
  bidding_state: string;
  state: string;
  bidding_strategy: string;
  impressions: string;
  rate: string;
  revenue: string;
  clicks: string;
  conversions: string;
  cpc: string;
  ctr: string;
  cr: string;
  cpa: string;
  drr: string;
  profile: number;
  goal: number;
  value: string;
  label: string;
  title: string[];
  strategy: string[];
}

interface BidderAdgroupDataItem {
  id: number;
  group_name: string;
  group_direct_id: number;
  group_status: string;
  campaign_state: string;
  campaign_id: number;
  campaign_name: string;
  campaign_direct_id: number;
  keywords_count: number;
  clicks: number;
  impressions: number;
  conversions: number;
  cpc: number;
  ctr: number;
  cpa: number;
  drr: number;
  cr: number;
  rate: number;
  revenue: number;
  bidding_state: string;
  value: string;
  label: string;
  group: string[];
  campaign: string[];
}

interface BidderPhrasesDataItem {
  id: number,
  body: string;
  minus_words: number;
  campaign_id: number;
  campaign_name: string;
  campaign_direct_id: string;
  campaign_state: string;
  clicks: number;
  impressions: number;
  cpc: number;
  rate: number;
  current_bid: number;
  ngramm_bid: number;
  anahtar_ifadeler: string[];
  value: string;
  label: string;
}

interface GoalDataItem {
  name: string;
  direct_id: number;
  id: number;
  value: string;
  label: string;
}

const  TablesWidget14: React.FC<Props> = ({className}) => {

  const API_URL = process.env.REACT_APP_NGRAM_API_URL

  const [profileId, setProfileId] = useState(localStorage.getItem('profileId') || "")
  const [profileRole, setProfileRole] = useState(localStorage.getItem('profileRole') || "")

  const [profileDateFrom, setProfileDateFrom] = useState(localStorage.getItem('profileDateFrom') || "")
  const [profileDateTo, setProfileDateTo] = useState(localStorage.getItem('profileDateTo') || "")

  const {currentUser, logout} = useAuth()

  const [selectFocus, setSelectFocus] = useState(4)
  const onBlur = () => setSelectFocus(4)
  const gridStyle = { minHeight: 740, zIndex: selectFocus }
  const [activateRowOnFocus, setActivateRowOnFocus] = useState(true)
  const [checkboxTabIndex, setCheckboxTabIndex] = useState(false)
  const pleaseWait = <b>Загрузка данных, подождите ... </b>

  const options4 = [
    { value: '10', label: '10' }
  ]

  const columnsBidderCampaign = [
    { name: 'id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
    { name: 'title', defaultFlex: 1,minWidth: 300, header: 'Кампания',
      // onRender: (cellProps, {data}) => {
      //   cellProps.style.background = data.state != "ON" ? 'gray' : ''
      // },
    render: ({ value }) =>
      <div className='d-flex justify-content-start flex-column '>
        <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {/*№3222123*/}
          {value[0]}
        </span>
        <span className='text-dark fw-bold fs-8'>
          {/*Поздравление Деда Мороза — МСК*/}
          {value[1]}
        </span>
        {/*<div className='d-flex'>*/}
        {/*  {value[2] == "ON" ?*/}
        {/*    <div className='bg-light-success text-success fw-bold p-1 rounded-2 me-2 fs-8'>*/}
        {/*      Биддер подключен*/}
        {/*    </div> :*/}
        {/*    <div className='bg-light text-muted fw-bold p-1 rounded-2 me-2 fs-8'>*/}
        {/*      Биддер не подключен*/}
        {/*    </div>*/}
        {/*  }*/}

          {/*{value[2] == "ON" ?*/}
          {/*  <button className='btn btn-light text-muted fw-bold fs-8 px-2 py-1'><i className="bi bi-pause-fill fs-2"></i> Отключить</button> :*/}
          {/*  <button className='btn btn-light-success fw-bold fs-8 px-2 py-1'><i className="bi bi-play-fill fs-2"></i> Подключить</button>*/}
          {/*}*/}
        {/*</div>*/}
      </div>
    },
    { name: 'state', defaultFlex: 1, minWidth: 185, header: 'Статус',
    render: ({ value }) =>
    <div className='d-block'>
        {/*<span className='text-success fw-semibold d-block fs-7 mb-2'>*/}
        {/*Кампания активна*/}
        {/*</span>*/}
      {value == "ON" ?
        <span className='text-success fw-semibold d-block fs-7 mb-2'>
          Кампания активна
        </span> :
        <span className='text-muted fw-semibold d-block fs-7 mb-2'>
          Кампания приостановлена
        </span>
      }
      {/*<button className='btn btn-light text-muted fw-bold fs-5 px-4 py-2'><i className="bi bi-pause-fill fs-2"></i> Остановить</button>*/}
      {/* {value.state == "ON" ?
        <button className='btn btn-light-success fw-bold  w-auto px-3 py-2' onClick={(event) => {campaignState(event, value.id, "suspend")}}><i className="bi bi-pause-fill fs-2"></i> Остановить</button> :
        <button className='btn btn-light text-muted fw-bold w-auto px-3 py-2' onClick={(event) => {campaignState(event, value.id, "resume")}}><i className="bi bi-play-fill text-muted fs-2"></i> Запустить</button>
      } */}
    </div>
    },
    { name: 'strategy', defaultFlex: 1, minWidth: 210, header: 'Бюджет и стратегия',
    render: ({ value }) =>
    <div className='d-flex justify-content-start flex-column'>
        <span className='text-muted fw-semibold text-muted d-block lh-1 fs-7'>
        {/*Оптимизация конверсий*/}
          {value[0]}
        </span>
      <span className='text-dark fw-bold fs-7'>
        {/*8 000 000 ₽/нед*/}
        {value[1]} {value[2]}
        </span>
    </div>
    },
    { name: 'impressions', defaultFlex: 1, minWidth: 90, header: 'Показы', type: 'number',
    render: ({ value }) =>  value},
    { name: 'clicks', defaultFlex: 1, minWidth: 90, header: 'Клики', type: 'number',
    render: ({ value }) =>  value},
    { name: 'cpc', defaultFlex: 1, minWidth: 90, header: 'CPC, ₽', type: 'number',
    render: ({ value }) =>  value + ` ₽` },
    { name: 'ctr', defaultFlex: 1, minWidth: 90, header: 'CTR (%)', type: 'number',
    render: ({ value }) =>  `${value} %` },
    { name: 'rate', defaultFlex: 1, minWidth: 90, header: 'Расход c НДС', type: 'number',
    render: ({ value }) =>  value + ` ₽` },
    { name: 'conversions', defaultFlex: 1, minWidth: 90, header: 'Конверсии', type: 'number' },
    { name: 'cr', defaultFlex: 1, minWidth: 90, header: 'CR', type: 'number' },
    { name: 'revenue', defaultFlex: 1, minWidth: 90, header: 'Доход', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'cpa', defaultFlex: 1, minWidth: 90, header: 'CPA', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'drr', defaultFlex: 1, minWidth: 90, header: 'ДРР', type: 'number' },
  ]

  const columnsBidderAdgroup = [
    { name: 'id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
    { name: 'group', defaultFlex: 1,minWidth: 300, header: 'Группа',
      render: ({ value }) =>
        <div className='d-flex justify-content-start flex-column '>
        <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {/*№3222123*/}
          {value[0]}
        </span>
          <span className='text-dark fw-bold fs-8'>
          {/*Поздравление Деда Мороза — МСК*/}
            {value[1]}
        </span>
          {/*<div className='d-flex'>*/}
          {/*  {value[2] == "ON" ?*/}
          {/*    <div className='bg-light-success text-success fw-bold p-1 rounded-2 me-2 fs-8'>*/}
          {/*      Биддер подключен*/}
          {/*    </div> :*/}
          {/*    <div className='bg-light text-muted fw-bold p-1 rounded-2 me-2 fs-8'>*/}
          {/*      Биддер не подключен*/}
          {/*    </div>*/}
          {/*  }*/}

          {/*  {value[2] == "ON" ?*/}
          {/*    <button className='btn btn-light text-muted fw-bold fs-8 px-2 py-1'><i className="bi bi-pause-fill fs-2"></i> Отключить</button> :*/}
          {/*    <button className='btn btn-light-success fw-bold fs-8 px-2 py-1'><i className="bi bi-play-fill fs-2"></i> Подключить</button>*/}
          {/*  }*/}
          {/*</div>*/}
        </div>
    },
    { name: 'campaign_state', defaultFlex: 1, minWidth: 130, header: 'Статус кампании',
      render: ({ value }) =>
        <div className='d-block'>
          {value == "ON" ?
            <span className='text-success fw-semibold d-block fs-7 mb-2'>
              Кампания активна
            </span> :
            <span className='text-muted fw-semibold d-block fs-7 mb-2'>
              Кампания приостановлена
            </span>
          }
        {/*<span className='text-success fw-semibold d-block fs-7 mb-2'>*/}
        {/*/!*Идут показы*!/*/}
        {/*  {value}*/}
        {/*</span>*/}

          {/*<button className='btn btn-light text-muted fw-bold fs-5 px-4 py-2'><i className="bi bi-pause-fill fs-2"></i> Остановить</button>*/}
          {/* {value.state == "ON" ?
        <button className='btn btn-light-success fw-bold  w-auto px-3 py-2' onClick={(event) => {campaignState(event, value.id, "suspend")}}><i className="bi bi-pause-fill fs-2"></i> Остановить</button> :
        <button className='btn btn-light text-muted fw-bold w-auto px-3 py-2' onClick={(event) => {campaignState(event, value.id, "resume")}}><i className="bi bi-play-fill text-muted fs-2"></i> Запустить</button>
      } */}
        </div>
    },
    { name: 'campaign', defaultFlex: 1, minWidth: 180, header: 'Кампания',
      render: ({ value }) =>
        <div className='d-flex justify-content-start flex-column'>
        <span className='text-muted fw-semibold text-muted d-block lh-1 fs-7'>
        {/*Оптимизация конверсий*/}
          {value[0]}
        </span>
          <span className='text-dark fw-bold fs-7'>
        {/*8 000 000 ₽/нед*/}
            {value[1]}
        </span>
        </div>
    },
    { name: 'impressions', defaultFlex: 1, minWidth: 90, header: 'Показы', type: 'number',
      render: ({ value }) =>  value},
    { name: 'clicks', defaultFlex: 1, minWidth: 90, header: 'Клики', type: 'number',
      render: ({ value }) =>  value},
    { name: 'cpc', defaultFlex: 1, minWidth: 90, header: 'CPC, ₽', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'ctr', defaultFlex: 1, minWidth: 90, header: 'CTR (%)', type: 'number',
      render: ({ value }) =>  `${value} %` },
    { name: 'rate', defaultFlex: 1, minWidth: 90, header: 'Расход c НДС', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'conversions', defaultFlex: 1, minWidth: 90, header: 'Конверсии', type: 'number' },
    { name: 'cr', defaultFlex: 1, minWidth: 90, header: 'CR', type: 'number' },
    { name: 'revenue', defaultFlex: 1, minWidth: 90, header: 'Доход', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'cpa', defaultFlex: 1, minWidth: 90, header: 'CPA', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'drr', defaultFlex: 1, minWidth: 90, header: 'ДРР', type: 'number' },
  ]

  const columnsBidderPhrases = [
    { name: 'id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
    { name: 'anahtar_ifadeler', defaultFlex: 1,minWidth: 300, header: 'Ключевые фразы',
      render: ({ value }) =>
      <div className='d-flex justify-content-start flex-column '>
              <span className='text-muted fw-semibold text-muted d-block fs-8'>
                  {/*№3222123*/}
                №{value[0]}
              </span>
        <a href='#' className='text-dark fw-bold fs-8'>
          {/*!Аско стиралка официальный сайт*/}
          {value[1]}
        </a>
        <span className='text-muted fw-semibold text-muted d-block fs-8'>
                  {/*32 минус-слов - ???*/}
          {value[2]} минус-слов
        </span>
      </div>
    },
    { name: 'campaign_state', defaultFlex: 1, minWidth: 180, header: 'Статус',
    render: ({ value }) =>
    <div className='d-flex'>
      {value == "ON" ?
        <span className="text-success bg-light-success fw-semibold fs-8 p-1 rounded-2">Идут показы</span> :
        <span className='text-muted bg-light fw-semibold fs-8 p-1 rounded-2'>Кампания приостановлена</span>
      }
    </div>
    },
    { name: 'current_bid', defaultFlex: 1, minWidth: 150, header: 'Действующая ставка',
    render: ({ value }) =>
      <span className='text-success fw-bold fs-7'>
        {value} ₽
      </span>
    },
    { name: 'ngramm_bid', defaultFlex: 1, minWidth: 150, header: 'Ставки от N-GRAM Биддер',
    render: ({ value }) =>
      <span className='text-primary fw-bold fs-7'>
        {value} ₽
      </span>
    },
    { name: 'impressions', defaultFlex: 1, minWidth: 90, header: 'Показы',
    render: ({ value }) =>  value},
    { name: 'clicks', defaultFlex: 1, minWidth: 90, header: 'Клики',
    render: ({ value }) =>  value},
    { name: 'cpc', defaultFlex: 1, minWidth: 90, header: 'CPC, ₽',
    render: ({ value }) =>  `${value} ₽`},
    // { name: 'ctr', defaultFlex: 1, minWidth: 90, header: 'CTR (%)',
    // render: ({ value }) =>  `${value} ₽`},
    { name: 'rate', defaultFlex: 1, minWidth: 90, header: 'Расход c НДС',
    render: ({ value }) =>  `${value} ₽` },
  ]

  const nowDate = new Date()
  ///const [startDate, setStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
  ///const [endDate, setEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1)) //useState(nowDate)
  const [startDate, setStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
  const [endDate, setEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), 0)) //useState(nowDate)

  const [minDate, setMinDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
  const [maxDate, setMaxDate] = useState(nowDate)

  const [availableStartDate, setAvailableStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
  const [availableEndDate, setAvailableEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1)) //useState(nowDate)

  const [attribution, setAttribution] = useState(8)
  const [goal, setGoal] = useState(0)

  const [defaultValueAttribution, setDefaultValueAttribution] = useState({ value: localStorage.getItem('profileAttributionValue'), label: localStorage.getItem('profileAttributionLabel') })
  const [defaultValueGoal, setDefaultValueGoal] = useState({ value: localStorage.getItem('profileGoalValue'), label: localStorage.getItem('profileGoalLabel') })

  const [selectedOptionAttribution, setSelectedOptionAttribution] = useState(defaultValueAttribution)
  const [selectedOptionGoal, setSelectedOptionGoal] = useState(defaultValueGoal)

  const [dateForReport, setDateForReport] = useState(true)
  const [attributionForReport, setAttributionForReport] = useState(true) //
  const [goalForReport, setGoalForReport] = useState(false) //
  const [campaignForReport, setCampaignForReport] = useState(false)
  const [adgroupForReport, setAdgroupForReport] = useState(false)

  const [campaign, setCampaign] = useState(0) // old function
  const [bidderCampaigns, setBidderCampaigns] = useState<number[]>([])
  const [bidderAdgroups, setBidderAdgroups] = useState<number[]>([])
  const [bidderPhrases, setBidderPhrases] = useState<number[]>([])

  let tempBidderCampaigns: number[] = []
  const tempSelectedBidderCampaign = {}
  let tempBidderAdgroups: number[] = []
  const tempSelectedBidderAdgroup = {}
  let tempBidderPhrases = []
  const tempSelectedBidderPhrases = {}
  //let campaigns = [4712, 4710, 4655]
  //let arrattributions = [1, 2, 6, 5, 4]
  //let arrgoals = [2385, 2382, 2377, 2376, 2346, 2287, 2207, 2153, 2320]

  const [keywordId, setKeywordId] = useState(0)
  const [ngrammBid, setNgrammBid] = useState(0)
  // const [phrases, setPhrases] = useState([])

  const [selectedDG, setSelectedDG] = useState([])

  const [isActiveGroups, setIsActiveGroups] = useState(true)

  const [phraseForRate, setPhraseForRate] = useState(false)

  const [buttonSendReportCampaign, setButtonSendReportCampaign] = useState(true)
  const [buttonSendReportAdgroup, setButtonSendReportAdgroup] = useState(true)
  const [buttonSetRates, setButtonSetRates] = useState(true)

  const handleChangeDate = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)

    setMinDate(newStartDate)
    const futureDate = addDays(newStartDate, 59)
    setMaxDate(futureDate > nowDate ? nowDate : futureDate)
    if (newStartDate === null || newEndDate === null) {
      setDateForReport(false)
    } else {
      setDateForReport(true)
      localStorage.setItem('profileDateFrom', `${newStartDate.toLocaleDateString('fr-CA')}`)
      localStorage.setItem('profileDateTo', `${newEndDate.toLocaleDateString('fr-CA')}`)

      sendMetricGoalVisitParametr('reachGoal', 'filter_calendar_bidder-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_calendar_bidder-page": {}
          }
        }
      })
    }
  }

  const handleAttribution = (selectedOption) => {
    if (selectedOption === null || selectedOption.label == "Установите атрибуцию") {
      setAttribution(0)
      localStorage.setItem('profileAttributionValue', "8")
      localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
      setAttributionForReport(false)
    } else {
      setAttribution(selectedOption.id);
      setSelectedOptionAttribution(selectedOption)
      localStorage.setItem('profileAttributionValue', `${selectedOption.id}`)
      localStorage.setItem('profileAttributionLabel', `${selectedOption.full_name}`)
      setAttributionForReport(true)
      //checkButtonSendReport()

      sendMetricGoalVisitParametr('reachGoal', 'filter_attribution_bidder-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_attribution_bidder-page": {}
          }
        }
      })
    }
  }

  const handleGoal = (selectedOption) => {
    if (selectedOption === null || selectedOption.label == "Выберите данные по целям" || selectedOption.label == "---") {
      setGoal(0)
      setSelectedOptionGoal({value: '0', label: 'Выберите данные по целям'})
      localStorage.setItem('profileGoalValue', "0")
      localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
      setGoalForReport(false)
    } else {
      setGoal(selectedOption.id)
      setSelectedOptionGoal(selectedOption)
      localStorage.setItem('profileGoalValue', `${selectedOption.id}`)
      localStorage.setItem('profileGoalLabel', `${selectedOption.name}`)
      setGoalForReport(true)
      //checkButtonSendReport()

      sendMetricGoalVisitParametr('reachGoal', 'filter_goals_bidder-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_goals_bidder-page": {}
          }
        }
      })
    }
  }

  // const handleCampaign = (selectedOption) => {
  //   if (selectedOption === null || selectedOption.label == "Выберите данные по целям") {
  //     setGoal(0)
  //     setGoalForReport(false)
  //   } else {
  //     setGoal(selectedOption.id);
  //     setGoalForReport(true)
  //   }
  // }

  function handleCampaign(event, campaignId) { // old function
    //console.log(campaignId)
    if (campaignId === null) {
      ///setCampaign(0)
      setCampaignForReport(false)
    } else {
      setCampaign(campaignId);
      console.log(campaign)
      setCampaignForReport(true)
    }
  }

  // const onSelectionChange = useCallback(({ selected: selectedMap, data }) => {
  //   const newSelected = Object.keys(selectedMap).map(id => id * 1)
  //
  //   setSelected(newSelected);
  // }, [])

  const onSelectionBidderCampaign = ({ selected }) => {
    //console.log(Object.keys(selected).valueOf())
    //console.log(selected)
    tempBidderCampaigns = []
    if (selected == 1) {
      for (var key in currentBidderCampaignRow) {
        // @ts-ignore
        tempSelectedBidderCampaign[currentBidderCampaignRow[key].id] = {}
        //setCampaigns(campaigns => [...campaigns, currentBidderRow[key].id])
        tempBidderCampaigns.push(currentBidderCampaignRow[key].id)
      }
      setBidderCampaigns(tempBidderCampaigns)
      setSelectedBidderCampaign(tempSelectedBidderCampaign)
      setCampaignForReport(true)
      setIsActiveGroups(false)
      setBidderAdgroups([]) // Убираем выбранные группы при выборе кампаний
      setSelectedBidderAdgroup({}) // Убираем выбранные группы при выборе кампаний

      sendMetricGoalVisitParametr('reachGoal', 'choose_campaign_bidder-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "choose_campaign_bidder-page": {}
          }
        }
      })
    } else if (Object.keys(selected).length === 0) {
      setBidderCampaigns([])
      setSelectedBidderCampaign({})
      localStorage.removeItem("chooseCampaignId")
      setCampaignForReport(false)
      setIsActiveGroups(true)
      setBidderAdgroups([]) // Убираем выбранные группы при выборе кампаний
      setSelectedBidderAdgroup({}) // Убираем выбранные группы при выборе кампаний
    } else {
      for (var key in selected) {
        // @ts-ignore
        //tempCampaigns.push(Number(selected[key].id))
        tempBidderCampaigns.push(Number(key))
      }
      setBidderCampaigns(tempBidderCampaigns)
      setSelectedBidderCampaign(selected)
      setCampaignForReport(true)
      setIsActiveGroups(false)
      setBidderAdgroups([]) // Убираем выбранные группы при выборе кампаний
      setSelectedBidderAdgroup({}) // Убираем выбранные группы при выборе кампаний

      sendMetricGoalVisitParametr('reachGoal', 'choose_campaign_bidder-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "choose_campaign_bidder-page": {}
          }
        }
      })
    }
  }

  const onSelectionBidderAdgroup = ({ selected }) => {
    //console.log(Object.keys(selected).valueOf())
    //console.log(selected)
    tempBidderAdgroups = []
    if (selected == 1) {
      for (var key in currentBidderAdgroupRow) {
        // @ts-ignore
        tempSelectedBidderAdgroup[currentBidderAdgroupRow[key].id] = {}
        //setCampaigns(campaigns => [...campaigns, currentBidderRow[key].id])
        tempBidderAdgroups.push(currentBidderAdgroupRow[key].id)
      }
      setBidderAdgroups(tempBidderAdgroups)
      setSelectedBidderAdgroup(tempSelectedBidderAdgroup)
      setAdgroupForReport(true)
      ///setBidderPhrases([]) // Убираем выбранные фразы при выборе кампаний
      ///setSelectedBidderPhrases({}) // Убираем выбранные фразы при выборе кампаний
    } else if (Object.keys(selected).length === 0) {
      setBidderAdgroups([])
      setSelectedBidderAdgroup({})
      localStorage.removeItem("chooseAdgroupId")
      setAdgroupForReport(false)
      ///setBidderPhrases([]) // Убираем выбранные фразы при выборе кампаний
      ///setSelectedBidderPhrases({}) // Убираем выбранные фразы при выборе кампаний
    } else {
      for (var key in selected) {
        // @ts-ignore
        //tempCampaigns.push(Number(selected[key].id))
        tempBidderAdgroups.push(Number(key))
      }
      setBidderAdgroups(tempBidderAdgroups)
      setSelectedBidderAdgroup(selected)
      setAdgroupForReport(true)
      ///setBidderPhrases([]) // Убираем выбранные фразы при выборе кампаний
      ///setSelectedBidderPhrases({}) // Убираем выбранные фразы при выборе кампаний
    }
  }

  const onSelectionBidderPhrases = ({ selected }) => {
    //console.log(Object.keys(selected).valueOf())
    //console.log(selected)
    tempBidderPhrases = []
    if (selected == 1) {
      for (var key in currentBidderPhrasesRow) {
        // @ts-ignore
        tempSelectedBidderPhrases[currentBidderPhrasesRow[key].id] = {}
        setBidderPhrases(bidderPhrases => [...bidderPhrases, currentBidderPhrasesRow[key].id])
      }
      setSelectedBidderPhrases(tempSelectedBidderPhrases)
      setPhraseForRate(true)
    } else if (Object.keys(selected).length === 0) {
      setBidderPhrases([])
      setSelectedBidderPhrases({})
      setPhraseForRate(false)
    } else {
      for (var key in selected) {
        // @ts-ignore
        //tempPhrases.push(Number(selected[key].id))
        tempBidderPhrases.push(Number(key))
      }
      setBidderPhrases(tempBidderPhrases)
      setSelectedBidderPhrases(selected)
      setPhraseForRate(true)
    }
  }

  const checkButtonSendReport = () => { // !!!!!!!!!!!
    if (dateForReport && attributionForReport && goalForReport && campaignForReport) {
      setButtonSendReportCampaign(false)

      if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
        setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
        setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
      }

      getBidderReport()
      setMinDate(availableStartDate)
      setMaxDate(nowDate)
    } else {
      setButtonSendReportCampaign(true)
      if (startDate === null || endDate === null) {
        ///
      } else {
        getBidderReport()
        setMinDate(availableStartDate)
        setMaxDate(nowDate)
      }
      //getBidderReport()
    }

    if (dateForReport && attributionForReport && goalForReport && adgroupForReport) {
      setButtonSendReportAdgroup(false)

      if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
        setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
        setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
      }

      getBidderReport()
      setMinDate(availableStartDate)
      setMaxDate(nowDate)
    } else {
      setButtonSendReportAdgroup(true)
      if (startDate === null || endDate === null) {
        ///
      } else {
        getBidderReport()
        setMinDate(availableStartDate)
        setMaxDate(nowDate)
      }
      //getBidderReport()
    }
  }

  const checkButtonSetRate = () => {
    if (phraseForRate) {
      setButtonSetRates(false)
    } else {
      setButtonSetRates(true)
    }
  }

  const [toogle, setToggle] = useState(1)

  function updateToggle(id) {
    if (id == 1) {
      setIsSetRates(false)
    }

    if (id == 2) {
      sendMetricGoalVisitParametr('reachGoal', 'view_groups_bidder-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "view_groups_bidder-page": {}
          }
        }
      })
    } else if (id == 3) {
      sendMetricGoalVisitParametr('reachGoal', 'view_queries_bidder-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "view_queries_bidder-page": {}
          }
        }
      })
    }

    setToggle(id)

    setDefaultValueAttribution({ value: localStorage.getItem('profileAttributionValue'), label: localStorage.getItem('profileAttributionLabel') })
    setDefaultValueGoal({ value: localStorage.getItem('profileGoalValue'), label: localStorage.getItem('profileGoalLabel') })
    //setSelectedOptionAttribution(defaultValueAttribution)
    //setSelectedOptionGoal(defaultValueGoal)
    setSelectedOptionAttribution({ value: localStorage.getItem('profileAttributionValue'), label: localStorage.getItem('profileAttributionLabel') })
    setSelectedOptionGoal({ value: localStorage.getItem('profileGoalValue'), label: localStorage.getItem('profileGoalLabel') })
  }

  const firstUpdate = useRef(true)

  useEffect( () => {
    //console.log(dateForReport, attributionForReport, goalForReport)
    //checkButtonSendReport()

    if (!firstUpdate.current) {
      //console.log("firstUpdate: false")
      checkButtonSendReport()
    }
  }, [dateForReport, attributionForReport, goalForReport, campaignForReport, adgroupForReport, attribution, goal, profileId, toogle, availableEndDate])

  useEffect( () => {
    //console.log(phraseForRate)
    checkButtonSetRate()

    // if (!firstUpdate.current) {
    //   //console.log("firstUpdate: false")
    //   checkButtonSetRate()
    // }
  }, [phraseForRate])

  useEffect( () => {
    setLoadingData(true)

    sendMetricVisitParametr("params", {
      [`${localStorage.getItem('profileName')}`]: {
        [`${window.location.pathname}`]: {}
      }
    })

    addToDataLayer({
      'login': currentUser?.email,
      'profile': localStorage.getItem('profileName'),
      'url': window.location.pathname,
    })

    if (localStorage.getItem("chooseCampaignId") !== null) {
      // @ts-ignore
      tempSelectedBidderCampaign[localStorage.getItem("chooseCampaignId")] = {}
      setSelectedBidderCampaign(tempSelectedBidderCampaign)
      setBidderCampaigns([Number(localStorage.getItem("chooseCampaignId"))])
      setCampaignForReport(true)
      //setToggle(1)
      setIsActiveGroups(false)
      checkButtonSendReport()
    }

    if (localStorage.getItem("chooseAdgroupId") !== null) {
      // @ts-ignore
      tempSelectedBidderAdgroup[localStorage.getItem("chooseAdgroupId")] = {}
      setSelectedBidderAdgroup(tempSelectedBidderAdgroup)
      setBidderAdgroups([Number(localStorage.getItem("chooseAdgroupId"))])
      setAdgroupForReport(true)
      //setToggle(2)
      checkButtonSendReport()
    }

    if (localStorage.getItem("profileDateFrom") === null || localStorage.getItem("profileDateTo") === null) {
      localStorage.setItem('profileDateFrom', "0000-00-00")
      localStorage.setItem('profileDateTo', "0000-00-00")
      window.location.reload()
    }
    if (localStorage.getItem("profileAttributionValue") === null || localStorage.getItem("profileAttributionLabel") === null) {
      localStorage.setItem('profileAttributionValue', "8")
      localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
      window.location.reload()
    }
    if (localStorage.getItem("profileGoalValue") === null || localStorage.getItem("profileGoalLabel") === null) {
      localStorage.setItem('profileGoalValue', "0")
      localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
      window.location.reload()
    }
    if (localStorage.getItem("profileUrlIsYandexValue") === null || localStorage.getItem("profileUrlIsYandexLabel") === null) {
      localStorage.setItem('profileUrlIsYandexValue', "null")
      localStorage.setItem('profileUrlIsYandexLabel', "---")
      window.location.reload()
    }
    if (localStorage.getItem("profileUrlIsGoogleValue") === null || localStorage.getItem("profileUrlIsGoogleLabel") === null) {
      localStorage.setItem('profileUrlIsGoogleValue', "null")
      localStorage.setItem('profileUrlIsGoogleLabel', "---")
      window.location.reload()
    }
    if (localStorage.getItem("checkDateValue") === null || localStorage.getItem("checkDateLabel") === null) {
      localStorage.setItem('checkDateValue', "0")
      localStorage.setItem('checkDateLabel', "Выберите дату")
      window.location.reload()
    }

    window.addEventListener('localStorage', () => {
      setProfileId(String(localStorage.getItem('profileId')))
      //setProfileDateFrom(String(localStorage.getItem('profileDateFrom')))
      //setProfileDateTo(String(localStorage.getItem('profileDateTo')))
    })

    setSelectedOptionAttribution(defaultValueAttribution)
    setSelectedOptionGoal(defaultValueGoal)

    async function fetchDate() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${currentUser?.api_token}`
      }

      await fetch(`${API_URL}/get_dataset_period/?profile_id=${profileId}&section=bidding`, {
        method: 'GET',
        headers: headers
      })
        .then((response) => response.json())
        .then((json) => {
          //console.log(json);
          if (json.status == "ERROR") {
            ///
          } else if (json.result.date_from == null || json.result.date_to == null) {
            ///
          } else if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
            setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
            setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
            const dateFromResult = new Date(json.result.date_from)
            const dateToResult = new Date(json.result.date_to)
            setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
            setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
            setMinDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          } else {
            const dateFromResult = new Date(json.result.date_from)
            const dateToResult = new Date(json.result.date_to)
            const lastDateFromResult = new Date(json.result.last_date_from)
            const lastDateToResult = new Date(json.result.last_date_to)
            setStartDate(new Date(lastDateFromResult.getFullYear(), lastDateFromResult.getMonth(), lastDateFromResult.getDate()))
            setEndDate(new Date(lastDateToResult.getFullYear(), lastDateToResult.getMonth(), lastDateToResult.getDate()))
            //setStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
            //setEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
            setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
            setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
            setMinDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          }
          //setGoals(json)
          // json.map((object) => {
          //   setGoals((goals) => [
          //     ...goals,
          //     {
          //       value: object.id,
          //       label: object.name,
          //       isDisabled: false
          //     }
          //   ]);
          // });

          // Рабочий вариант
          if (defaultValueGoal.value == "0") {
            setGoalForReport(false)
          } else {
            setGoalForReport(true)
          }
        });
      firstUpdate.current = false

    }
    fetchDate()
  },[])

  //const [kpssReportRow, setKpssReportRow] = useState<ReportDataItem[]>([]);
  //const [kpssReportRowSelect, setKpssReportRowSelect] = useState<ReportDataItem[]>([]);
  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [bidderRowPerPage] = useState(10)
  const [calculatedNgrammRowPerPage] = useState(10)

  const [searchValueBidderCampaign, setSearchValueBidderCampaign] = useState<String[]>([])
  const [searchValueBidderAdgroup, setSearchValueBidderAdgroup] = useState<String[]>([])
  const [searchValueBidderPhrases, setSearchValueBidderPhrases] = useState<String[]>([])
  const [filteringBidderCampaign, setFilteringBidderCampaign] = useState(false)
  const [filteringBidderAdgroup, setFilteringBidderAdgroup] = useState(false)
  const [filteringBidderPhrases, setFilteringBidderPhrases] = useState(false)

  const [bidderCampaignRow, setBidderCampaignRow] = useState<BidderCampaignDataItem[]>([]);
  const [bidderCampaignRowSelect, setBidderCampaignRowSelect] = useState<BidderCampaignDataItem[]>([]);
  const [pageCountBidderCampaign, setPageCountBidderCampaign] = useState(0)
  const [currentPageBidderCampaign, setCurrentPageBidderCampaign] = useState(1)

  const [bidderAdgroupRow, setBidderAdgroupRow] = useState<BidderAdgroupDataItem[]>([]);
  const [bidderAdgroupRowSelect, setBidderAdgroupRowSelect] = useState<BidderAdgroupDataItem[]>([]);
  const [pageCountBidderAdgroup, setPageCountBidderAdgroup] = useState(0)

  const [bidderPhrasesRow, setBidderPhrasesRow] = useState<BidderPhrasesDataItem[]>([]);
  const [bidderPhrasesRowSelect, setBidderPhrasesRowSelect] = useState<BidderPhrasesDataItem[]>([]);
  const [pageCountBidderPhrases, setPageCountBidderPhrases] = useState(0)

  const [attributions, setAttributions] = useState([])
  const [goals, setGoals] = useState<GoalDataItem[]>([])

  const [showModalCalculationRates, setShowModalCalculationRates] = useState(false);
  const handleCloseModalCalculationRates = () => setShowModalCalculationRates(false);
  //const handleShowModalCalculationRates = () => setShowModalCalculationRates(true);
  const [showModalRatesSet, setShowModalRatesSet] = useState(false);
  const handleCloseModalRatesSet = () => setShowModalRatesSet(false);
  //const handleShowModalRatesSet = () => setShowModalRatesSet(true);

  const [isSetRates, setIsSetRates] = useState(false)

  const handleSearchBidderCampaign = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValueBidderCampaign([])
      setFilteringBidderCampaign(false)
    } else {
      setSearchValueBidderCampaign([])
      selectedOption.map(o =>
        setSearchValueBidderCampaign(searchValue => [
          ...searchValue,
          o.direct_id
        ])
      )
      setFilteringBidderCampaign(true)

      sendMetricGoalVisitParametr('reachGoal', 'filter_campaign_bidder-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_campaign_bidder-page": {}
          }
        }
      })
    }
  }

  const handleSearchBidderAdgroup = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValueBidderAdgroup([])
      setFilteringBidderAdgroup(false)
    } else {
      setSearchValueBidderAdgroup([])
      selectedOption.map(o =>
        setSearchValueBidderAdgroup(searchValue => [
          ...searchValue,
          o.group_direct_id.toString()
        ])
      )
      setFilteringBidderAdgroup(true)
    }
  }

  const handleSearchBidderPhrases = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValueBidderPhrases([])
      setFilteringBidderPhrases(false)
    } else {
      setSearchValueBidderPhrases([])
      selectedOption.map(o =>
        setSearchValueBidderPhrases(searchValueCalculatedNgramm => [
          ...searchValueCalculatedNgramm,
          o.body
        ])
      )
      setFilteringBidderPhrases(true)
    }
  }

  const fetchBidder = async (currentPage) => { //old function
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    const res = await fetch(
      `${API_URL}/get_campaigns/?page=${currentPage}`, {
        method: 'GET',
        headers: headers
      })
      .then(response => response.json())
      .then((data) => {
        setPageCountBidderCampaign(data.count)
        setBidderCampaignRow([])
        setBidderCampaignRowSelect([])
        data.results.map(bidderItem => {
          setBidderCampaignRow(bidderRow => [
            ...bidderRow,
            {
              id: bidderItem.id,
              direct_id: bidderItem.direct_id,
              name: bidderItem.name,
              default_goal: bidderItem.default_goal,
              date_period: bidderItem.date_period,
              attribution_model: bidderItem.attribution_model,
              daily_budget: bidderItem.daily_budget,
              daily_budget_period: bidderItem.daily_budget_period,
              bidding_state: bidderItem.bidding_state,
              state: bidderItem.state,
              bidding_strategy: bidderItem.bidding_strategy,
              impressions: bidderItem.impressions,
              rate: bidderItem.rate,
              revenue: bidderItem.revenue,
              clicks: bidderItem.clicks,
              conversions: bidderItem.conversions,
              cpc: bidderItem.cpc,
              ctr: bidderItem.ctr,
              cr: bidderItem.cr,
              cpa: bidderItem.cpa,
              drr: bidderItem.drr,
              profile: bidderItem.profile,
              goal: bidderItem.goal,
              value: `№${bidderItem.direct_id}`,
              label: `№${bidderItem.direct_id} \n${bidderItem.name}`,
              title: [`№${bidderItem.direct_id}`, `${bidderItem.name}`, `${bidderItem.bidding_state}`],
              strategy: [`${bidderItem.bidding_strategy}`, `${bidderItem.daily_budget}`, `${bidderItem.daily_budget_period}`]
            }
          ])

          setBidderCampaignRowSelect(bidderRowSelect => [
            ...bidderRowSelect,
            {
              id: bidderItem.id,
              direct_id: bidderItem.direct_id,
              name: bidderItem.name,
              default_goal: bidderItem.default_goal,
              date_period: bidderItem.date_period,
              attribution_model: bidderItem.attribution_model,
              daily_budget: bidderItem.daily_budget,
              daily_budget_period: bidderItem.daily_budget_period,
              bidding_state: bidderItem.bidding_state,
              state: bidderItem.state,
              bidding_strategy: bidderItem.bidding_strategy,
              impressions: bidderItem.impressions,
              rate: bidderItem.rate,
              revenue: bidderItem.revenue,
              clicks: bidderItem.clicks,
              conversions: bidderItem.conversions,
              cpc: bidderItem.cpc,
              ctr: bidderItem.ctr,
              cr: bidderItem.cr,
              cpa: bidderItem.cpa,
              drr: bidderItem.drr,
              profile: bidderItem.profile,
              goal: bidderItem.goal,
              value: `№${bidderItem.direct_id}`,
              label: `№${bidderItem.direct_id} \n${bidderItem.name}`,
              title: [`№${bidderItem.direct_id}`, `${bidderItem.name}`, `${bidderItem.bidding_state}`],
              strategy: [`${bidderItem.bidding_strategy}`, `${bidderItem.daily_budget}`, `${bidderItem.daily_budget_period}`]
            }
          ])
        });
      })
    //const data = await res.json()
    //return data.results
  }

  const handlePageBidderClick = async (pageNumber) => { //old function
    await fetchBidder(pageNumber)
    setCurrentPageBidderCampaign(pageNumber)
  }

  function campaignState(event, campaignId, campaignState) {
    console.log(event)
    console.log(campaignId)
    console.log(campaignState)
  }

  const getCalculatedNgramm = () => {
    const profileId = localStorage.getItem('profileId')
    setLoadingData(true)

    localStorage.removeItem("chooseCampaignId")
    localStorage.removeItem("chooseAdgroupId")

    setLoading(true)
    //console.log("getKpssReport")
    const query = {
      profile_id: profileId,
      goal_ids: [goal], //arrgoals,
      attribution_ids: [attribution], //arrattributions,
      campaign_ids: bidderCampaigns,
      adgroup_ids: bidderAdgroups,
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA')
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    fetch(`${API_URL}/bidding/get_calculated/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(query)
    })
      .then(response => response.json())
      //.then(data => setKpssReportRow(data.data))
      .then((data) => {
        setLoading(false)
        //console.log(data.result);

        //if (data.status != "ERROR") {
          //setShowModalCalculationRates(true)
        //}

        setBidderPhrasesRow([])
        setPageCountBidderPhrases(data.total)
        data.result.map(calculatedNgrammItem => {
          setBidderPhrasesRow(calculatedNgrammRow => [
            ...calculatedNgrammRow,
            {
              id: calculatedNgrammItem.id,
              body: calculatedNgrammItem.body,
              minus_words: calculatedNgrammItem.minus_words,
              campaign_id: calculatedNgrammItem.campaign_id,
              campaign_name: calculatedNgrammItem.campaign_name,
              campaign_direct_id: calculatedNgrammItem.campaign_direct_id,
              campaign_state: calculatedNgrammItem.campaign_state,
              clicks: calculatedNgrammItem.clicks,
              impressions: calculatedNgrammItem.impressions,
              cpc: calculatedNgrammItem.cpc,
              rate: calculatedNgrammItem.rate,
              current_bid: calculatedNgrammItem.current_bid,
              ngramm_bid: calculatedNgrammItem.ngramm_bid,
              anahtar_ifadeler: [`${calculatedNgrammItem.campaign_direct_id}`, `${calculatedNgrammItem.body}`, `${calculatedNgrammItem.minus_words}`],
              value: `№${calculatedNgrammItem.campaign_direct_id}`,
              label: `№${calculatedNgrammItem.campaign_direct_id} \n${calculatedNgrammItem.body}`
            }
          ])

          setBidderPhrasesRowSelect(calculatedNgrammRowSelect => [
            ...calculatedNgrammRowSelect,
            {
              id: calculatedNgrammItem.id,
              body: calculatedNgrammItem.body,
              minus_words: calculatedNgrammItem.minus_words,
              campaign_id: calculatedNgrammItem.campaign_id,
              campaign_name: calculatedNgrammItem.campaign_name,
              campaign_direct_id: calculatedNgrammItem.campaign_direct_id,
              campaign_state: calculatedNgrammItem.campaign_state,
              clicks: calculatedNgrammItem.clicks,
              impressions: calculatedNgrammItem.impressions,
              cpc: calculatedNgrammItem.cpc,
              rate: calculatedNgrammItem.rate,
              current_bid: calculatedNgrammItem.current_bid,
              ngramm_bid: calculatedNgrammItem.ngramm_bid,
              anahtar_ifadeler: [`${calculatedNgrammItem.campaign_direct_id}`, `${calculatedNgrammItem.body}`, `${calculatedNgrammItem.minus_words}`],
              value: `№${calculatedNgrammItem.campaign_direct_id}`,
              label: `№${calculatedNgrammItem.campaign_direct_id} \n${calculatedNgrammItem.body}`
            }
          ])
        });
        setLoadingData(false)
        setToggle(3)
      });
  }

  const setRates = () => {
    const profileId = localStorage.getItem('profileId')

    setLoading(true)
    const query = [{
      profile_id: profileId,
      keyword_id: keywordId,
      ngramm_bid: ngrammBid
    }]

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    fetch(`${API_URL}/bidding/set_bid_for_phrase/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(query)
    })
      .then(response => response.json())
      //.then(data => setKpssReportRow(data.data))
      .then((data) => {
        setLoading(false)
        //console.log(data.result);

        setShowModalRatesSet(true)
        setIsSetRates(true)
        setSelectedBidderPhrases({})
        setSelectedBidderCampaign({})
        setSelectedBidderAdgroup({})
        localStorage.removeItem("chooseCampaignId")
        setBidderCampaigns([])
        setBidderAdgroups([])
        setCampaignForReport(false)
        setAdgroupForReport(false)

        // if (data.status != "ERROR") {
        //   setShowModalCalculationRates(true)
        // }

        // setCalculatedNgrammRow([])
        // setPageCountCalculatedNgramm(data.total)
        // data.result.map(calculatedNgrammItem => {
        //   setCalculatedNgrammRow(calculatedNgrammRow => [
        //     ...calculatedNgrammRow,
        //     {
        //       id: calculatedNgrammItem.id,
        //       body: calculatedNgrammItem.body,
        //       campaign_id: calculatedNgrammItem.campaign_id,
        //       campaign_name: calculatedNgrammItem.campaign_name,
        //       campaign_direct_id: calculatedNgrammItem.campaign_direct_id,
        //       campaign_state: calculatedNgrammItem.campaign_state,
        //       clicks: calculatedNgrammItem.clicks,
        //       impressions: calculatedNgrammItem.impressions,
        //       cpc: calculatedNgrammItem.cpc,
        //       rate: calculatedNgrammItem.rate,
        //       current_bid: calculatedNgrammItem.current_bid,
        //       ngramm_bid: calculatedNgrammItem.ngramm_bid,
        //       anahtar_ifadeler: [`${calculatedNgrammItem.campaign_direct_id}`, `${calculatedNgrammItem.body}`]
        //     }
        //   ])
        // });
      });
  }

  const getBidderReport = () => {
    // let agg = "adgroups"
    // if (toogle == 2) {
    //   agg = "adgroups"
    // } else if (toogle == 3) {
    //   agg = "phrases"
    // } else {
    //   ///
    // }

    let agg = "campaigns"
    if (toogle == 1) {
      agg = "campaigns"
    } else if (toogle == 2) {
      agg = "adgroups"
    } else {
      agg = "phrases"
    }

    if (localStorage.getItem('profileAttributionValue') !== null || Number(localStorage.getItem('profileAttributionValue')) != 8 ) {
      setAttribution(Number(localStorage.getItem('profileAttributionValue')))
    }

    if (localStorage.getItem('profileGoalValue') !== null || Number(localStorage.getItem('profileGoalValue')) != 0) {
      setGoal(Number(localStorage.getItem('profileGoalValue')))
    }

    const profileId = localStorage.getItem('profileId')
    setProfileRole(localStorage.getItem('profileRole') || "")
    setLoading(true)
    setLoadingData(true)

    const queryCampaign = {
      profile_id: profileId,
      agg: agg,
      goal_ids: [goal],
      attribution_ids: [attribution],
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA'),
      //campaign_ids: bidderCampaigns,
      //adgroup_ids: bidderAdgroups
    }
    const queryAdgroup = {
      profile_id: profileId,
      agg: agg,
      goal_ids: [goal],
      attribution_ids: [attribution],
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA'),
      campaign_ids: bidderCampaigns,
      //adgroup_ids: bidderAdgroups
    }
    // const queryPhrase = {
    //   profile_id: profileId,
    //   agg: agg,
    //   goal_ids: [goal],
    //   attribution_ids: [attribution],
    //   date_from: startDate.toLocaleDateString('fr-CA'),
    //   date_to: endDate.toLocaleDateString('fr-CA'),
    //   campaign_ids: bidderCampaigns,
    //   adgroup_ids: kpssAdgroups
    // }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    if (toogle == 1) {
      fetch(`${API_URL}/bidding/get_report/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(queryCampaign)
      })
        .then(response => response.json())
        .then((data) => {
          setBidderCampaignRow([])
          setBidderCampaignRowSelect([])
          setPageCountBidderCampaign(data.count)
          data.result.map(bidderCampaignItem => {
            setBidderCampaignRow(bidderCampaignRow => [
              ...bidderCampaignRow,
              {
                id: bidderCampaignItem.id,
                direct_id: bidderCampaignItem.direct_id,
                name: bidderCampaignItem.name,
                default_goal: bidderCampaignItem.default_goal,
                date_period: bidderCampaignItem.date_period,
                attribution_model: bidderCampaignItem.attribution_model,
                daily_budget: bidderCampaignItem.daily_budget,
                daily_budget_period: bidderCampaignItem.daily_budget_period,
                bidding_state: bidderCampaignItem.bidding_state,
                state: bidderCampaignItem.state,
                bidding_strategy: bidderCampaignItem.bidding_strategy,
                impressions: bidderCampaignItem.impressions,
                rate: bidderCampaignItem.rate,
                revenue: bidderCampaignItem.revenue,
                clicks: bidderCampaignItem.clicks,
                conversions: bidderCampaignItem.conversions,
                cpc: bidderCampaignItem.cpc,
                ctr: bidderCampaignItem.ctr,
                cr: bidderCampaignItem.cr,
                cpa: bidderCampaignItem.cpa,
                drr: bidderCampaignItem.drr,
                profile: bidderCampaignItem.profile,
                goal: bidderCampaignItem.goal,
                value: `№${bidderCampaignItem.direct_id}`,
                label: `№${bidderCampaignItem.direct_id} \n${bidderCampaignItem.name}`,
                title: [`№${bidderCampaignItem.direct_id}`, `${bidderCampaignItem.name}`, `${bidderCampaignItem.bidding_state}`],
                strategy: [`${bidderCampaignItem.bidding_strategy}`, `${bidderCampaignItem.daily_budget}`, `${bidderCampaignItem.daily_budget_period}`]
              }
            ])

            setBidderCampaignRowSelect(bidderCampaignRowSelect => [
              ...bidderCampaignRowSelect,
              {
                id: bidderCampaignItem.id,
                direct_id: bidderCampaignItem.direct_id,
                name: bidderCampaignItem.name,
                default_goal: bidderCampaignItem.default_goal,
                date_period: bidderCampaignItem.date_period,
                attribution_model: bidderCampaignItem.attribution_model,
                daily_budget: bidderCampaignItem.daily_budget,
                daily_budget_period: bidderCampaignItem.daily_budget_period,
                bidding_state: bidderCampaignItem.bidding_state,
                state: bidderCampaignItem.state,
                bidding_strategy: bidderCampaignItem.bidding_strategy,
                impressions: bidderCampaignItem.impressions,
                rate: bidderCampaignItem.rate,
                revenue: bidderCampaignItem.revenue,
                clicks: bidderCampaignItem.clicks,
                conversions: bidderCampaignItem.conversions,
                cpc: bidderCampaignItem.cpc,
                ctr: bidderCampaignItem.ctr,
                cr: bidderCampaignItem.cr,
                cpa: bidderCampaignItem.cpa,
                drr: bidderCampaignItem.drr,
                profile: bidderCampaignItem.profile,
                goal: bidderCampaignItem.goal,
                value: `№${bidderCampaignItem.direct_id}`,
                label: `№${bidderCampaignItem.direct_id} \n${bidderCampaignItem.name}`,
                title: [`№${bidderCampaignItem.direct_id}`, `${bidderCampaignItem.name}`, `${bidderCampaignItem.bidding_state}`],
                strategy: [`${bidderCampaignItem.bidding_strategy}`, `${bidderCampaignItem.daily_budget}`, `${bidderCampaignItem.daily_budget_period}`]
              }
            ])
          });
          setLoadingData(false)
        });
    } else if (toogle == 2) {
      fetch(`${API_URL}/bidding/get_report/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(queryAdgroup)
      })
        .then(response => response.json())
        //.then(data => setKpssReportRow(data.data))
        .then((data) => {
          setBidderAdgroupRow([])
          setBidderAdgroupRowSelect([])
          setPageCountBidderAdgroup(data.total)
          data.result.map(bidderAdgroupItem => {
            setBidderAdgroupRow(bidderAdgroupRow => [
              ...bidderAdgroupRow,
              {
                id: bidderAdgroupItem.id,
                group_name: bidderAdgroupItem.group_name,
                group_direct_id: bidderAdgroupItem.group_direct_id,
                group_status: bidderAdgroupItem.group_status,
                campaign_state: bidderAdgroupItem.campaign_state,
                campaign_id: bidderAdgroupItem.campaign_id,
                campaign_name: bidderAdgroupItem.campaign_name,
                campaign_direct_id: bidderAdgroupItem.campaign_direct_id,
                keywords_count: bidderAdgroupItem.keywords_count,
                clicks: bidderAdgroupItem.clicks,
                impressions: bidderAdgroupItem.impressions,
                conversions: bidderAdgroupItem.conversions,
                cpc: bidderAdgroupItem.cpc,
                ctr: bidderAdgroupItem.ctr,
                cpa: bidderAdgroupItem.cpa,
                drr: bidderAdgroupItem.drr,
                cr: bidderAdgroupItem.cr,
                rate: bidderAdgroupItem.rate,
                revenue: bidderAdgroupItem.revenue,
                bidding_state: bidderAdgroupItem.bidding_state,
                value: `№${bidderAdgroupItem.group_direct_id}`,
                label: `№${bidderAdgroupItem.group_direct_id} \n${bidderAdgroupItem.group_name}`,
                group: [`№${bidderAdgroupItem.group_direct_id}`, `${bidderAdgroupItem.group_name}`, `${bidderAdgroupItem.bidding_state}`],
                campaign: [`№${bidderAdgroupItem.campaign_direct_id}`, `${bidderAdgroupItem.campaign_name}`]
              }
            ])

            setBidderAdgroupRowSelect(bidderAdgroupRowSelect => [
              ...bidderAdgroupRowSelect,
              {
                id: bidderAdgroupItem.id,
                group_name: bidderAdgroupItem.group_name,
                group_direct_id: bidderAdgroupItem.group_direct_id,
                group_status: bidderAdgroupItem.group_status,
                campaign_state: bidderAdgroupItem.campaign_state,
                campaign_id: bidderAdgroupItem.campaign_id,
                campaign_name: bidderAdgroupItem.campaign_name,
                campaign_direct_id: bidderAdgroupItem.campaign_direct_id,
                keywords_count: bidderAdgroupItem.keywords_count,
                clicks: bidderAdgroupItem.clicks,
                impressions: bidderAdgroupItem.impressions,
                conversions: bidderAdgroupItem.conversions,
                cpc: bidderAdgroupItem.cpc,
                ctr: bidderAdgroupItem.ctr,
                cpa: bidderAdgroupItem.cpa,
                drr: bidderAdgroupItem.drr,
                cr: bidderAdgroupItem.cr,
                rate: bidderAdgroupItem.rate,
                revenue: bidderAdgroupItem.revenue,
                bidding_state: bidderAdgroupItem.bidding_state,
                value: `№${bidderAdgroupItem.group_direct_id}`,
                label: `№${bidderAdgroupItem.group_direct_id} \n${bidderAdgroupItem.group_name}`,
                group: [`№${bidderAdgroupItem.group_direct_id}`, `${bidderAdgroupItem.group_name}`, `${bidderAdgroupItem.bidding_state}`],
                campaign: [`№${bidderAdgroupItem.campaign_direct_id}`, `${bidderAdgroupItem.campaign_name}`]
              }
            ])
          });
          setLoadingData(false)
        });
    } else {
      ///
    }

    fetch(`${API_URL}/get_attribution_models/`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => {
        setAttributions(json)
      });

    fetch(`${API_URL}/get_goals/?profile_id=${profileId}`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => {
        //setGoals(json)
        setGoals([])
        json.map(goalItem => {
          setGoals(goals => [
            ...goals,
            {
              name: goalItem.name,
              direct_id: goalItem.direct_id,
              id: goalItem.id,
              value: `${goalItem.id}`,
              label: `${goalItem.name}`
            }
          ])
        });
      });

    if (toogle === 3) {
      setLoadingData(false)
    }
  }

  // useEffect( () => {
  //   // let agg = "adgroups"
  //   // if (toogle == 2) {
  //   //   agg = "adgroups"
  //   // } else if (toogle == 3) {
  //   //   agg = "phrases"
  //   // } else {
  //   //   ///
  //   // }
  //
  //   let agg = "campaigns"
  //   if (toogle == 1) {
  //     agg = "campaigns"
  //   } else if (toogle == 2) {
  //     agg = "adgroups"
  //   } else {
  //     agg = "phrases"
  //   }
  //
  //   const profileId = localStorage.getItem('profileId')
  //   setProfileRole(localStorage.getItem('profileRole') || "")
  //   setLoadingData(true)
  //
  //   if (localStorage.getItem("chooseCampaignId") !== null) {
  //     // @ts-ignore
  //     tempSelectedBidderCampaign[localStorage.getItem("chooseCampaignId")] = {}
  //     setSelectedBidderCampaign(tempSelectedBidderCampaign)
  //     setBidderCampaigns([Number(localStorage.getItem("chooseCampaignId"))])
  //     setCampaignForReport(true)
  //     //setToggle(1)
  //     setIsActiveGroups(false)
  //     checkButtonSendReport()
  //   }
  //
  //   if (localStorage.getItem("chooseAdgroupId") !== null) {
  //     // @ts-ignore
  //     tempSelectedBidderAdgroup[localStorage.getItem("chooseAdgroupId")] = {}
  //     setSelectedBidderAdgroup(tempSelectedBidderAdgroup)
  //     setBidderAdgroups([Number(localStorage.getItem("chooseAdgroupId"))])
  //     setAdgroupForReport(true)
  //     //setToggle(2)
  //     checkButtonSendReport()
  //   }
  //
  //   if (localStorage.getItem("profileDateFrom") === null || localStorage.getItem("profileDateTo") === null) {
  //     localStorage.setItem('profileDateFrom', "0000-00-00")
  //     localStorage.setItem('profileDateTo', "0000-00-00")
  //     window.location.reload()
  //   }
  //   if (localStorage.getItem("profileAttributionValue") === null || localStorage.getItem("profileAttributionLabel") === null) {
  //     localStorage.setItem('profileAttributionValue', "8")
  //     localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
  //     window.location.reload()
  //   }
  //   if (localStorage.getItem("profileGoalValue") === null || localStorage.getItem("profileGoalLabel") === null) {
  //     localStorage.setItem('profileGoalValue', "0")
  //     localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
  //     window.location.reload()
  //   }
  //
  //   window.addEventListener('localStorage', () => {
  //     setProfileId(String(localStorage.getItem('profileId')))
  //     //setProfileDateFrom(String(localStorage.getItem('profileDateFrom')))
  //     //setProfileDateTo(String(localStorage.getItem('profileDateTo')))
  //   })
  //
  //   setSelectedOptionAttribution(defaultValueAttribution)
  //   setSelectedOptionGoal(defaultValueGoal)
  //
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Token ${currentUser?.api_token}`
  //   }
  //
  //   if (defaultValueGoal.value == "0") {
  //     if (toogle == 1) {
  //       //fetch(`${API_URL}/get_campaigns/?profile_id=${profileId}`, {
  //       fetch(`${API_URL}/bidding/get_report/?profile_id=${profileId}&agg=${agg}&goal_ids=${[goal]}&attribution_ids=${[attribution]}`, {
  //         method: 'GET',
  //         headers: headers
  //       })
  //         .then(response => response.json())
  //         .then((data) => {
  //           setBidderCampaignRow([])
  //           setBidderCampaignRowSelect([])
  //           setPageCountBidderCampaign(data.count)
  //           data.result.map(bidderCampaignItem => {
  //             setBidderCampaignRow(bidderCampaignRow => [
  //               ...bidderCampaignRow,
  //               {
  //                 id: bidderCampaignItem.id,
  //                 direct_id: bidderCampaignItem.direct_id,
  //                 name: bidderCampaignItem.name,
  //                 default_goal: bidderCampaignItem.default_goal,
  //                 date_period: bidderCampaignItem.date_period,
  //                 attribution_model: bidderCampaignItem.attribution_model,
  //                 daily_budget: bidderCampaignItem.daily_budget,
  //                 daily_budget_period: bidderCampaignItem.daily_budget_period,
  //                 bidding_state: bidderCampaignItem.bidding_state,
  //                 state: bidderCampaignItem.state,
  //                 bidding_strategy: bidderCampaignItem.bidding_strategy,
  //                 impressions: bidderCampaignItem.impressions,
  //                 rate: bidderCampaignItem.rate,
  //                 revenue: bidderCampaignItem.revenue,
  //                 clicks: bidderCampaignItem.clicks,
  //                 conversions: bidderCampaignItem.conversions,
  //                 cpc: bidderCampaignItem.cpc,
  //                 ctr: bidderCampaignItem.ctr,
  //                 cr: bidderCampaignItem.cr,
  //                 cpa: bidderCampaignItem.cpa,
  //                 drr: bidderCampaignItem.drr,
  //                 profile: bidderCampaignItem.profile,
  //                 goal: bidderCampaignItem.goal,
  //                 value: `№${bidderCampaignItem.direct_id}`,
  //                 label: `№${bidderCampaignItem.direct_id} \n${bidderCampaignItem.name}`,
  //                 title: [`№${bidderCampaignItem.direct_id}`, `${bidderCampaignItem.name}`, `${bidderCampaignItem.bidding_state}`],
  //                 strategy: [`${bidderCampaignItem.bidding_strategy}`, `${bidderCampaignItem.daily_budget}`, `${bidderCampaignItem.daily_budget_period}`]
  //               }
  //             ])
  //
  //             setBidderCampaignRowSelect(bidderCampaignRowSelect => [
  //               ...bidderCampaignRowSelect,
  //               {
  //                 id: bidderCampaignItem.id,
  //                 direct_id: bidderCampaignItem.direct_id,
  //                 name: bidderCampaignItem.name,
  //                 default_goal: bidderCampaignItem.default_goal,
  //                 date_period: bidderCampaignItem.date_period,
  //                 attribution_model: bidderCampaignItem.attribution_model,
  //                 daily_budget: bidderCampaignItem.daily_budget,
  //                 daily_budget_period: bidderCampaignItem.daily_budget_period,
  //                 bidding_state: bidderCampaignItem.bidding_state,
  //                 state: bidderCampaignItem.state,
  //                 bidding_strategy: bidderCampaignItem.bidding_strategy,
  //                 impressions: bidderCampaignItem.impressions,
  //                 rate: bidderCampaignItem.rate,
  //                 revenue: bidderCampaignItem.revenue,
  //                 clicks: bidderCampaignItem.clicks,
  //                 conversions: bidderCampaignItem.conversions,
  //                 cpc: bidderCampaignItem.cpc,
  //                 ctr: bidderCampaignItem.ctr,
  //                 cr: bidderCampaignItem.cr,
  //                 cpa: bidderCampaignItem.cpa,
  //                 drr: bidderCampaignItem.drr,
  //                 profile: bidderCampaignItem.profile,
  //                 goal: bidderCampaignItem.goal,
  //                 value: `№${bidderCampaignItem.direct_id}`,
  //                 label: `№${bidderCampaignItem.direct_id} \n${bidderCampaignItem.name}`,
  //                 title: [`№${bidderCampaignItem.direct_id}`, `${bidderCampaignItem.name}`, `${bidderCampaignItem.bidding_state}`],
  //                 strategy: [`${bidderCampaignItem.bidding_strategy}`, `${bidderCampaignItem.daily_budget}`, `${bidderCampaignItem.daily_budget_period}`]
  //               }
  //             ])
  //           });
  //           setLoadingData(false)
  //         });
  //     } else if (toogle == 2) {
  //       fetch(`${API_URL}/bidding/get_report/?profile_id=${profileId}&agg=${agg}&goal_ids=${[goal]}&attribution_ids=${[attribution]}&date_from=${startDate.toLocaleDateString('fr-CA')}&date_to=${endDate.toLocaleDateString('fr-CA')}&campaign_ids=${bidderCampaigns}`, {
  //         method: 'GET',
  //         headers: headers
  //       })
  //         .then(response => response.json())
  //         //.then(data => setKpssReportRow(data.data))
  //         .then((data) => {
  //           setBidderAdgroupRow([])
  //           setBidderAdgroupRowSelect([])
  //           setPageCountBidderAdgroup(data.total)
  //           data.result.map(bidderAdgroupItem => {
  //             setBidderAdgroupRow(bidderAdgroupRow => [
  //               ...bidderAdgroupRow,
  //               {
  //                 id: bidderAdgroupItem.id,
  //                 group_name: bidderAdgroupItem.group_name,
  //                 group_direct_id: bidderAdgroupItem.group_direct_id,
  //                 group_status: bidderAdgroupItem.group_status,
  //                 campaign_state: bidderAdgroupItem.campaign_state,
  //                 campaign_id: bidderAdgroupItem.campaign_id,
  //                 campaign_name: bidderAdgroupItem.campaign_name,
  //                 campaign_direct_id: bidderAdgroupItem.campaign_direct_id,
  //                 keywords_count: bidderAdgroupItem.keywords_count,
  //                 clicks: bidderAdgroupItem.clicks,
  //                 impressions: bidderAdgroupItem.impressions,
  //                 conversions: bidderAdgroupItem.conversions,
  //                 cpc: bidderAdgroupItem.cpc,
  //                 ctr: bidderAdgroupItem.ctr,
  //                 cpa: bidderAdgroupItem.cpa,
  //                 drr: bidderAdgroupItem.drr,
  //                 cr: bidderAdgroupItem.cr,
  //                 rate: bidderAdgroupItem.rate,
  //                 revenue: bidderAdgroupItem.revenue,
  //                 bidding_state: bidderAdgroupItem.bidding_state,
  //                 value: `№${bidderAdgroupItem.group_direct_id}`,
  //                 label: `№${bidderAdgroupItem.group_direct_id} \n${bidderAdgroupItem.group_name}`,
  //                 group: [`№${bidderAdgroupItem.group_direct_id}`, `${bidderAdgroupItem.group_name}`, `${bidderAdgroupItem.bidding_state}`],
  //                 campaign: [`№${bidderAdgroupItem.campaign_direct_id}`, `${bidderAdgroupItem.campaign_name}`]
  //               }
  //             ])
  //
  //             setBidderAdgroupRowSelect(bidderAdgroupRowSelect => [
  //               ...bidderAdgroupRowSelect,
  //               {
  //                 id: bidderAdgroupItem.id,
  //                 group_name: bidderAdgroupItem.group_name,
  //                 group_direct_id: bidderAdgroupItem.group_direct_id,
  //                 group_status: bidderAdgroupItem.group_status,
  //                 campaign_state: bidderAdgroupItem.campaign_state,
  //                 campaign_id: bidderAdgroupItem.campaign_id,
  //                 campaign_name: bidderAdgroupItem.campaign_name,
  //                 campaign_direct_id: bidderAdgroupItem.campaign_direct_id,
  //                 keywords_count: bidderAdgroupItem.keywords_count,
  //                 clicks: bidderAdgroupItem.clicks,
  //                 impressions: bidderAdgroupItem.impressions,
  //                 conversions: bidderAdgroupItem.conversions,
  //                 cpc: bidderAdgroupItem.cpc,
  //                 ctr: bidderAdgroupItem.ctr,
  //                 cpa: bidderAdgroupItem.cpa,
  //                 drr: bidderAdgroupItem.drr,
  //                 cr: bidderAdgroupItem.cr,
  //                 rate: bidderAdgroupItem.rate,
  //                 revenue: bidderAdgroupItem.revenue,
  //                 bidding_state: bidderAdgroupItem.bidding_state,
  //                 value: `№${bidderAdgroupItem.group_direct_id}`,
  //                 label: `№${bidderAdgroupItem.group_direct_id} \n${bidderAdgroupItem.group_name}`,
  //                 group: [`№${bidderAdgroupItem.group_direct_id}`, `${bidderAdgroupItem.group_name}`, `${bidderAdgroupItem.bidding_state}`],
  //                 campaign: [`№${bidderAdgroupItem.campaign_direct_id}`, `${bidderAdgroupItem.campaign_name}`]
  //               }
  //             ])
  //           });
  //           setLoadingData(false)
  //         });
  //     } else {
  //       ///
  //     }
  //   }
  //
  //   fetch(`${API_URL}/get_attribution_models/`, {
  //     method: 'GET',
  //     headers: headers
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setAttributions(json)
  //     });
  //
  //   fetch(`${API_URL}/get_goals/?profile_id=${profileId}`, {
  //     method: 'GET',
  //     headers: headers
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setGoals(json)
  //     });
  //
  //   fetch(`${API_URL}/get_dataset_period/?profile_id=${profileId}&section=bidding`, {
  //     method: 'GET',
  //     headers: headers
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       if (json.status == "ERROR") {
  //         ///
  //       } else if (json.result.date_from == null || json.result.date_to == null) {
  //         ///
  //       } else if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
  //         setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
  //         setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
  //         const dateFromResult = new Date(json.result.date_from)
  //         const dateToResult = new Date(json.result.date_to)
  //         setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
  //         setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
  //       } else {
  //         const dateFromResult = new Date(json.result.date_from)
  //         const dateToResult = new Date(json.result.date_to)
  //         const lastDateFromResult = new Date(json.result.last_date_from)
  //         const lastDateToResult = new Date(json.result.last_date_to)
  //         setStartDate(new Date(lastDateFromResult.getFullYear(), lastDateFromResult.getMonth(), lastDateFromResult.getDate()))
  //         setEndDate(new Date(lastDateToResult.getFullYear(), lastDateToResult.getMonth(), lastDateToResult.getDate()))
  //         //setStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
  //         //setEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
  //         setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
  //         setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
  //       }
  //
  //       // Рабочий вариант
  //       if (defaultValueGoal.value == "0") {
  //         setGoalForReport(false)
  //       } else {
  //         setGoalForReport(true)
  //       }
  //     });
  //
  //   if (toogle === 3) {
  //     setLoadingData(false)
  //   }
  // }, [profileId, toogle])

  ///const lastKpssRowIndex = currentPage * bidderRowPerPage
  ///const firstKpssRowIndex = lastKpssRowIndex - bidderRowPerPage
  //const currentKpssRow = kpssReportRow.slice(firstKpssRowIndex, lastKpssRowIndex)
  //const filter = kpssReportRow.filter(item => item.campaign_id.includes(searchValue))

  const currentBidderCampaignRow = filteringBidderCampaign ? bidderCampaignRow.filter((item) => searchValueBidderCampaign.includes(item.direct_id)) : bidderCampaignRow //.slice(firstKpssRowIndex, lastKpssRowIndex)
  const currentBidderAdgroupRow = filteringBidderAdgroup ? bidderAdgroupRow.filter((item) => searchValueBidderAdgroup.includes(String(item.group_direct_id))) : bidderAdgroupRow
  const currentBidderPhrasesRow = filteringBidderPhrases ? bidderPhrasesRow.filter((item) => searchValueBidderPhrases.includes(item.body)) : bidderPhrasesRow

  const lastCalculatedNgrammRowIndex = currentPage *  calculatedNgrammRowPerPage
  const firstCalculatedNgrammRowIndex = lastCalculatedNgrammRowIndex - calculatedNgrammRowPerPage
  const currentCalculatedNgrammRow = bidderPhrasesRow.slice(firstCalculatedNgrammRowIndex, lastCalculatedNgrammRowIndex)

  const paginate = pageNumber => setCurrentPage(pageNumber)
  // const nextPage = () => setCurrentPage( prev => prev + 1 )
  // const prevPage = () => setCurrentPage( prev => prev - 1 )

  const pageNumbersBidder: number[] = []
  const pageNumbersCalculatedNgramm: number[] = []

  for (let i = 1; i <= Math.ceil(pageCountBidderCampaign / bidderRowPerPage); i++) {
    pageNumbersBidder.push(i)
  }

  for (let i = 1; i <= Math.ceil(pageCountBidderPhrases / calculatedNgrammRowPerPage); i++) {
    pageNumbersCalculatedNgramm.push(i)
  }

  // useEffect( () => {
  //   document.addEventListener('scroll', scrollHandler)
  //   return function() {
  //     document.removeEventListener('scroll', scrollHandler)
  //   };
  // }, [])

  // const [skip, setSkip] = useState(50)

  const removeAll = () => {
    setBidderCampaigns([])
    setBidderAdgroups([])
    setSelectedBidderCampaign({})
    setSelectedBidderAdgroup({})
    setSelectedBidderPhrases({})
    setCampaignForReport(false)
    setAdgroupForReport(false)
    localStorage.removeItem("chooseCampaignId")
    localStorage.removeItem("chooseAdgroupId")
  }

  const [selectedBidderCampaign, setSelectedBidderCampaign] = useState({})
  const [selectedBidderAdgroup, setSelectedBidderAdgroup] = useState({})
  const [selectedBidderPrases, setSelectedBidderPhrases] = useState({})

  const handleOnExport = () => {
    var wb = XLSX.utils.book_new()
    if (toogle == 1) {
      //console.log(campaignReportRow)
      const header = ['Кампания',
        'Среднев. через показы рел. заголовка',
        'Среднев. через клики рел. заголовка',
        'Среднев. через показы рел. текста',
        'Среднев. через клики рел. текста',
        'Показы',
        'Клики',
        'Расход',
        'Конверсии',
        'Доход'];
      // const campaignData = (campaignReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.campaigns
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(bidderCampaignRow) //, {header}
      XLSX.utils.book_append_sheet(wb, ws, "BidderCampaign")
      XLSX.writeFile(wb, "BidderCampaign.xlsx")
    } else if (toogle == 2) {
      //console.log(adgroupReportRow)
      const header = ['Группы',
        'Среднев. через показы рел. заголовка',
        'Среднев. через клики рел. заголовка',
        'Среднев. через показы рел. текста',
        'Среднев. через клики рел. текста',
        'Показы',
        'Клики',
        'Расход',
        'Конверсии',
        'Доход'];
      // const adgroupData = (adgroupReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.groups
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(bidderAdgroupRow)
      XLSX.utils.book_append_sheet(wb, ws, "BidderAdgroup")
      XLSX.writeFile(wb, "BidderAdgroup.xlsx")
    } else {
      //console.log(advertReportRow)
      const header = ['Заголовок объявления',
        'Текст объявления',
        'Поисковый запрос',
        'Релевантность заголовка запросу',
        'Релевантность текста запросу',
        'Показы',
        'Клики',
        'Расход',
        'Конверсии',
        'Доход'];
      // const advertData = (advertReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.title_announcement
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(bidderPhrasesRow)
      XLSX.utils.book_append_sheet(wb, ws, "BidderPhrases")
      XLSX.writeFile(wb, "BidderPhrases.xlsx")
    }

    sendMetricGoalVisitParametr('reachGoal', 'table_download_bidder-page', {
      [`${localStorage.getItem('profileName')}`]: {
        [`${window.location.pathname}`]: {
          "table_download_bidder-page": {}
        }
      }
    })
  }

  const onRenderRow = (rowProps) => {
    if (rowProps.data.age > 30) {
      rowProps.style.color = '#7986cb'
    }
  }

  const rowClassCampaign = ({data})=> {
    if (data.state != "ON") {
      return "global-custom-row-gray"
    }
    return 'global-custom-row'
  }

  const rowClassAdgroup = ({data})=> {
    if (data.campaign_state != "ON") {
      return "global-custom-row-gray"
    }
    return 'global-custom-row'
  }

  const formatOptionLabelGoal = ({ value, label }) => (
    <div style={{ display: "flex" }}>
      <div>{label}</div>
      <div style={{ marginLeft: "10px", color: "#ccc" }}>
        {value != 0 ? value : ''}
      </div>
    </div>
  )

  // const [first, setFirst] = useState<BidderCampaignDataItem[]>([]);
  // const [firstLocked, setFirstLocked] = useState(false);
  // const lockedRows = []
  // const update = useCallback((newState, fn) => {
  //   console.log(newState)
  //   //let data = [].concat(contactsDataSource)
  //
  //   // we don't want to show locked rows in the dataSource
  //   if (newState.firstLocked) {
  //     //setFirst(currentBidderCampaignRow.splice(0, 1)[0]);
  //     setFirst(currentBidderCampaignRow.splice(0, 1));
  //   }
  //
  //   const newStateValue = Object.values(newState)[0];
  //
  //   fn(newStateValue);
  // }, [firstLocked])
  //
  // if (firstLocked) {
  //   console.log(first)
  //   // lockedRows.push({
  //   //   position: 'start',
  //   //   render: ({column}, computedProps) => {
  //   //     return first ? first[column.id] : null
  //   //   }
  //   // })
  // }

  return (
    <div>
      <div className='card-container'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex flex-column'>
          <ol className="breadcrumb breadcrumb-dot text-muted fs-7 fw-bold mb-5">
            <li className="breadcrumb-item pe-3">

              <a href="#" className="pe-1">
                <i className="fa-solid fa-house text-muted fs-4" />
              </a>
            </li>
            <li className="breadcrumb-item px-0 text-muted">Биддер</li>
          </ol>

          {/*<h2 className='card-title align-items-start flex-column'>*/}
          {/*  <span className='card-label fw-bold fs-3 mb-1'>Биддер</span>*/}
          {/*</h2>*/}

          <div className='row d-flex justify-content-between'>
            <div className='col'>
              <h2 className='card-title align-items-start flex-column mb-3'>
                <span className='card-label fw-bold fs-3 mb-1'>Биддер</span>
              </h2>
              <h5 className='mb-2 text-muted'>&nbsp;</h5>
            </div>
            <div className='col d-flex justify-content-end'>
              <button className='btn btn-light-primary fw-semibold' onClick={handleOnExport}>Выгрузить в XLSX
                <KTIcon iconName='arrow-down-right' className='fs-4  ms-1' />
              </button>
            </div>
          </div>

           <ul className="nav nav-tabs nav-line-tabs mb-8 mt-5 fs-7 border-bottom-0">
            <li className="nav-item">
              <a
                className={toogle === 1 ? "nav-link fw-bold active" : "nav-link fw-bold"}
                //data-bs-toggle="tab"
                //href="#kt_tab_pane_1"
                href="#"
                onClick={()=>updateToggle(1)}
              >
                Кампании
              </a>
            </li>
            <li className="nav-item">
              <a
                className={toogle === 2 ? `nav-link fw-bold active` : `nav-link fw-bold ${isActiveGroups ? "disabled" : ""}`}
                //data-bs-toggle="tab"
                //href="#kt_tab_pane_2"
                href="#"
                onClick={()=>updateToggle(2)}
              >
                Группы
              </a>
            </li>
            <li className="nav-item">
              <a
                className={toogle === 3 ? "nav-link fw-bold active" : "nav-link fw-bold disabled"}
                //data-bs-toggle="tab"
                //href="#kt_tab_pane_3"
                href="#"
                onClick={()=>updateToggle(3)}
              >
                Ставки и фразы
              </a>
            </li>
          </ul>

        </div>
        {/* end::Header */}


        <div className="tab-content  mb-7" id="myTabContent">
          {/*active show*/}
          <div className={toogle === 1 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_1" role="tabpanel">
            {/* begin::Body */}
            <div className='card-body py-3'>
              <h4 className='mb-7'>Выберите рекламные кампании для биддинга</h4>
              <div className='row'>
                <div className='col-6 mb-md-5 mb-xl-6'>
                </div>
                <div className='col-6 mb-md-5 mb-xl-6'>
                  Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""}
                </div>
              </div>
              <div className='row'>
                <div className='col-6 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                                    <i className="bi bi-search fs-4 fw-bold" />
                                </span>
                    {/*<Select options={options} className='react-select-container form-control border-start-0 p-0' classNamePrefix="react-select" placeholder="Поиск по названию или номеру РК"/>*/}
                    <Select options={bidderCampaignRowSelect} className='react-select-container form-control border-start-0 p-0 my-search' classNamePrefix="react-select" placeholder="Поиск по названию или номеру РК" onChange={handleSearchBidderCampaign} isMulti onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                  </div>
                </div>
                <div className='col-6 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                                    <i className="bi bi-calendar4-event fs-7 fw-bold" />
                                </span>

                    <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                      //locale={locale}
                                locale="ru"
                                selected={startDate}
                                onChange={handleChangeDate}
                                minDate={minDate}
                                maxDate={maxDate}
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                      //dateFormat="yyyy-MM-dd"
                                dateFormat="d MMMM yyyy"
                    />

                  </div>
                </div>
                {/* <div className='col-3 mb-md-5 mb-xl-6'>
                        <a href="#" className="btn btn-light-primary w-100"><i className="bi bi-funnel fs-4 me-1 fw-bold"></i>Расширенный фильтр</a>
                        </div> */}
              </div>
              <div className='row mb-5'>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Атрибуция
                    <Tooltip text="По умолчанию используется Автоматическая атрибуция">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-8 mb-md-5 mb-xl-6'>
                  <h6>Данные по целям
                    <Tooltip text="По умолчанию выводятся данные по целям из настроек РК">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon"  placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-3 mb-md-3 mb-xl-6'>
                  {/* <a href="#" className='btn btn-primary text-light fw-bold w-auto px-3 py-2' onClick={getKpssReport}>
                            Сформировать отчет
                          </a> */}

                  {/*<button*/}
                  {/*  onClick={getCalculatedNgramm}*/}
                  {/*  className='btn btn-lg btn-primary w-100 mb-5 rounded-2'*/}
                  {/*  disabled={buttonSendReport}*/}
                  {/*>*/}
                  {/*  {!loading && <span className='indicator-label'>Сформировать отчет</span>}*/}
                  {/*  {loading && (*/}
                  {/*    <span className='indicator-progress' style={{display: 'block'}}>*/}
                  {/*              Пожалуйста подождите...{' '}*/}
                  {/*      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>*/}
                  {/*            </span>*/}
                  {/*  )}*/}
                  {/*</button>*/}
                </div>
              </div>

              {/*<div style={{ marginBottom: 20}}>*/}
              {/*  <CheckBox checked={firstLocked} onChange={firstLocked => update({ firstLocked }, setFirstLocked)}>*/}
              {/*    Lock row 1*/}
              {/*  </CheckBox>*/}
              {/*</div>*/}

              {/* begin::Table container */}
              <div className=''>
                {/* begin::Table */}
                <ReactDataGrid
                  checkboxColumn={true}
                  checkboxOnlyRowSelect={true}
                  className='table__data-grid'
                  idProperty="id"
                  showZebraRows={false}
                  style={gridStyle}
                  activateRowOnFocus={activateRowOnFocus}
                  columns={columnsBidderCampaign}
                  dataSource={currentBidderCampaignRow}
                  pagination={true}
                  //limit={10}
                  defaultLimit={10}
                  rowHeight={null}
                  minRowHeight={63}
                  onSelectionChange={onSelectionBidderCampaign}
                  selected={selectedBidderCampaign}
                  //onSelectionChange={onSelectionChange}
                  //defaultLimit={15}
                  //defaultSkip={45}
                  //skip={skip}
                  //onSkipChange={()=>fetchBidder(2)}
                  editable={true}
                  loading={loadingData}
                  loadingText={pleaseWait}
                  //renderRow={onRenderRow}
                  rowClassName={rowClassCampaign}
                  //lockedRows={lockedRows}
                  //useRowHeightForLockedRows={true}
                />
                <ScrollContainer className="scroll-container">
                  <table className='table table-bordered border align-middle mb-0 gx-3 gy-3' style={{ display: 'none' }}>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-350px text-nowrap'>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-check-ms form-check-custom form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-9-check'
                            />
                          </div>
                          <div className='ms-3 text-muted fw-semibold fs-7'>
                            Кампания
                          </div>
                        </div>
                      </th>
                      <th className='min-w-150px'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Статус</span>
                          <i className="las la-angle-down fw-bold" />
                        </div>
                      </th>
                      <th className='min-w-200px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Бюджет и стратегия</span>
                          <i className="las la-angle-down fw-bold" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Показы</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Клики</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>CPC, ₽</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>CTR (%)</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Расход с НДС</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Конверсии</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>CR</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Доход</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>CPA</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>ДРР</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>

                    {currentBidderCampaignRow.map((value, key) => {
                      return (
                        <tr key={key} data-key={key}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='form-check form-check-ms form-check-custom form-check'>
                                <input className='form-check-input widget-9-check' type='checkbox' value={value.id} onChange={(event) => {handleCampaign(event, value.id)}} />
                              </div>
                              <div className='d-flex justify-content-start flex-column ms-3'>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {/*№3222123*/}
                                  №{value.direct_id}
                                </span>
                                <a href='#' className='text-dark fw-bold fs-7'>
                                  {/*Поздравление Деда Мороза — МСК*/}
                                  {value.name}
                                </a>
                                <div className='d-flex'>
                                  <div className='bg-light-success text-success fw-bold p-2 rounded-2 me-2 fs-7'>
                                    Биддер подключен
                                  </div>
                                  <button className='btn btn-light text-muted fw-bold fs-7 px-4 py-2'><i className="bi bi-pause-fill fs-2" /> Отключить</button>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-block'>
                                <span className='text-success fw-semibold d-block fs-7 mb-2'>
                                Идут показы
                                </span>
                              {/*<button className='btn btn-light text-muted fw-bold fs-5 px-4 py-2'><i className="bi bi-pause-fill fs-2"></i> Остановить</button>*/}
                              {/* {value.state == "ON" ?
                                <button className='btn btn-light-success fw-bold  w-auto px-3 py-2' onClick={(event) => {campaignState(event, value.id, "suspend")}}><i className="bi bi-pause-fill fs-2"></i> Остановить</button> :
                                <button className='btn btn-light text-muted fw-bold w-auto px-3 py-2' onClick={(event) => {campaignState(event, value.id, "resume")}}><i className="bi bi-play-fill text-muted fs-2"></i> Запустить</button>
                              } */}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-muted fw-semibold text-muted d-block lh-1 fs-7'>
                                {/*Оптимизация конверсий*/}
                                  {value.bidding_strategy}
                                </span>
                              <span className='text-dark fw-bold fs-7'>
                                {/*8 000 000 ₽/нед*/}
                                {value.daily_budget} {value.daily_budget_period}
                                </span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {/*90 123 ₽*/}
                                  {value.impressions} ₽
                                </span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {value.clicks} ₽
                                </span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {value.cpc} ₽
                                </span>
                            </div>
                          </td>
                          <td >
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {value.ctr} %
                                </span>
                            </div>
                          </td>
                          <td >
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {/*90 123 ₽*/}
                                  {value.rate} ₽
                                </span>
                            </div>
                          </td>
                          <td >
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {value.conversions}
                                </span>
                            </div>
                          </td>
                          <td >
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {value.cr}
                                </span>
                            </div>
                          </td>
                          <td >
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {/*11 111*/}
                                  {value.revenue}
                                </span>
                            </div>
                          </td>
                          <td >
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {value.cpa}
                                </span>
                            </div>
                          </td>
                          <td >
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {value.drr}
                                </span>
                            </div>
                          </td>
                        </tr>)
                    })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </ScrollContainer>

                {/* end::Table */}
              </div>
              {/* end::Table container */}
              <div className='d-flex align-items-center justify-content-center mt-5'>
                  <button className='btn btn-primary' style={{ display: 'none' }}>Показать еще</button>
              </div>

              <div className='d-flex align-items-center justify-content-between mb-5 mt-7 '>
                <div style={{ display: 'none' }}>
                  <Select options={options4} className='form-select form-select-solid react-select-container p-0' classNamePrefix="no-icon"  placeholder="10"/>

                </div>
                <div style={{ display: 'none' }}>
                  <ul className="pagination">
                    {/* <li className="page-item previous disabled">
                            <a href="#" className="page-link">
                                <i className="previous"></i>
                            </a>
                            </li> */}

                    {
                      pageNumbersBidder.map(number => (
                        filteringBidderCampaign ? <li></li> :
                          <li className="page-item" key={number}>
                            <a href="#" className={`page-link fw-bold text-muted ${currentPageBidderCampaign === number ? "active" : ""}`} onClick={() => handlePageBidderClick(number)}>
                              {number}
                            </a>
                          </li>
                      ))
                    }

                    {/* <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                1
                            </a>
                            </li>
                            <li className="page-item active">
                            <a href="#" className="page-link">
                                2
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                3
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                4
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                5
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                6
                            </a>
                            </li> */}

                    {/* <li className="page-item next">
                            <a href="#" className="page-link fw-bold text-muted">
                                <i className="next text-muted"></i>
                            </a>
                            </li> */}
                  </ul>
                </div>
              </div>
            </div>
            {/* begin::Body */}

            <div className='card mt-7 mb-7 card-fixed'>
                    <div className='card-header border-0 pt-5 d-flex flex-column rounded-4'>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h3 className='card-title fw-bold fs-3 mb-3'>
                                Рекламных кампаний выбрано <span className='text-muted ms-2'>({bidderCampaigns.length})</span>
                            </h3>
                            <button className='fw-bold fs-4 btn btn-white text-danger p-0' onClick={removeAll}>Удалить все</button>
                        </div>

                         <button type='button' className={`btn mb-5 ${buttonSendReportCampaign ? "btn-light" : "btn-primary"}`} //заменять btn-ligth на btn-primary когда выбраны ключевые фразы
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_3"
                            onClick={getCalculatedNgramm}
                            disabled={buttonSendReportCampaign}
                            >
                            Рассчитать ставки от N-Gram Bidder
                        </button>
                    </div>
            </div>
          </div>
          <div className={toogle === 2 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_2" role="tabpanel">
            {/* begin::Body */}
            <div className='card-body py-3'>
              <h4 className='mb-7'>Выберете рекламные кампании для биддинга</h4>
              <div className='row'>
                <div className='col-6 mb-md-5 mb-xl-6'>
                </div>
                <div className='col-6 mb-md-5 mb-xl-6'>
                  Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""}
                </div>
              </div>
              <div className='row'>
                <div className='col-6 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                            <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                                <i className="bi bi-search fs-4 fw-bold" />
                            </span>
                    <Select options={bidderAdgroupRowSelect} className='react-select-container form-control border-start-0 p-0 my-search' classNamePrefix="react-select" placeholder="Поиск по группам" onChange={handleSearchBidderAdgroup} isMulti onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                  </div>
                </div>
                <div className='col-6 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                            <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                                <i className="bi bi-calendar4-event fs-7 fw-bold" />
                            </span>
                    {/* <select className="form-select border-start-0 ps-0 text-muted" aria-label="Select example">
                                <option>9 янв 2023 - 12 апр 2023</option>
                                <option value="1">9 янв 2023 - 12 апр 2023</option>
                                <option value="2">9 янв 2023 - 12 апр 2023</option>
                                <option value="3">9 янв 2023 - 12 апр 2023</option>
                            </select> */}
                    <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                      //locale={locale}
                                locale="ru"
                                selected={startDate}
                                onChange={handleChangeDate}
                                minDate={minDate}
                                maxDate={maxDate}
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                      //dateFormat="yyyy-MM-dd"
                                dateFormat="d MMMM yyyy"
                    />
                  </div>
                </div>
                {/* <div className='col-3 mb-md-5 mb-xl-6'>
                    <a href="#" className="btn btn-light-primary w-100"><i className="bi bi-funnel fs-4 me-1 fw-bold"></i>Расширенный фильтр</a>
                    </div> */}
              </div>
              <div className='row mb-5'>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Атрибуция
                    <Tooltip text="По умолчанию используется Автоматическая атрибуция">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-8 mb-md-5 mb-xl-6'>
                  <h6>Данные по целям
                    <Tooltip text="По умолчанию выводятся данные по целям из настроек РК">
                      <i className="las la-info-circle ms-2 fs-2"></i>
                    </Tooltip>
                  </h6>
                  <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon" placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
              </div>
              {/* begin::Table container */}
              <div className=''>
                {/* begin::Table */}
                <ReactDataGrid
                  checkboxColumn={true}
                  checkboxOnlyRowSelect={true}
                  className='table__data-grid'
                  idProperty="id"
                  showZebraRows={false}
                  style={gridStyle}
                  activateRowOnFocus={activateRowOnFocus}
                  columns={columnsBidderAdgroup}
                  dataSource={currentBidderAdgroupRow}
                  pagination={true}
                  //limit={10}
                  defaultLimit={10}
                  rowHeight={null}
                  minRowHeight={63}
                  onSelectionChange={onSelectionBidderAdgroup}
                  selected={selectedBidderAdgroup}
                  editable={true}
                  loading={loadingData}
                  loadingText={pleaseWait}
                  rowClassName={rowClassAdgroup}
                />
                <ScrollContainer>
                  <table className='table table-bordered border align-middle mb-0 gx-3 gy-3' style={{ display: 'none' }}>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-400px text-nowrap'>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-check-ms form-check-custom form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-9-check'
                            />
                          </div>
                          <div className='ms-3 text-muted fs-7'>
                            Кампания
                          </div>
                        </div>
                      </th>
                      <th className='min-w-225px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Статус</span>
                          <i className="las la-angle-down fw-bold" />
                        </div>
                      </th>
                      <th className='min-w-200px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Ключевые фразы</span>
                          <i className="las la-angle-down fw-bold" />
                        </div>
                      </th>
                      <th className='min-w-400px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Кампания</span>
                          <i className="las la-angle-down fw-bold" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Показы</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Клики</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>CPC, ₽</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>CTR (%)</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-200px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Расход с НДС</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Конверсии</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>CR</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Доход</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>CPA</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap text-muted'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>ДРР</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                    <tr>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-check-ms form-check-custom form-check'>
                            <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                          </div>
                          <div className='d-flex justify-content-start flex-column ms-3'>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    №3222123
                                </span>
                            <a href='#' className='text-dark fw-bold fs-7'>
                              Поздравление Деда Мороза — МСК
                            </a>
                            <div className='d-flex'>
                              <div className='bg-light-success text-success fw-bold p-2 rounded-2 me-2'>
                                Биддер подключен
                              </div>
                              <button className='btn btn-light text-muted fw-bold fs-5 px-4 py-2'><i className="bi bi-pause-fill fs-2" /> Отключить</button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex'>
                          <div className='text-success bg-light-success fw-bold fs-7 p-2 rounded-2'>
                            Идут показы
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='fw-bold text-dark d-block fs-7'>
                                8 888 ключевых фраз
                                </span>
                          <a href='#' className='text-muted fw-semibold fs-7'>
                            Добавить
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        №3222123
                                    </span>
                          <a href='#' className='text-dark fw-bold fs-7'>
                            Поздравление Деда Мороза — МСК
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                90 123 ₽
                                </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                90 123 ₽
                                </span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                90 123 ₽
                                </span>
                        </div>
                      </td>
                      <td >
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                11 %
                                </span>
                        </div>
                      </td>
                      <td >
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                90 123 ₽
                                </span>
                        </div>
                      </td>
                      <td >
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                11 111
                                </span>
                        </div>
                      </td>
                      <td >
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                11 111
                                </span>
                        </div>
                      </td>
                      <td >
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                11 111
                                </span>
                        </div>
                      </td>
                      <td >
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                11 111
                                </span>
                        </div>
                      </td>
                      <td >
                        <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                11 111
                                </span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </ScrollContainer>
                {/* end::Table */}
              </div>
              {/* end::Table container */}

              <div className='d-flex align-items-center justify-content-center mt-5'>
                  <button className='btn btn-primary' style={{ display: 'none' }}>Показать еще</button>
              </div>
              {/* <div className='d-flex align-items-center justify-content-between mb-5 mt-7'>
                        <div>
                        <Select options={options4} className='form-select form-select-solid react-select-container p-0' classNamePrefix="no-icon"  placeholder="10"/>
                        </div>
                        <div>
                        <ul className="pagination">
                            <li className="page-item previous disabled">
                            <a href="#" className="page-link">
                                <i className="previous"></i>
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                1
                            </a>
                            </li>
                            <li className="page-item active">
                            <a href="#" className="page-link">
                                2
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                3
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                4
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                5
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                6
                            </a>
                            </li>
                            <li className="page-item next">
                            <a href="#" className="page-link fw-bold text-muted">
                                <i className="next text-muted"></i>
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div> */}
            </div>

            <div className='card mt-7 mb-7 card-fixed'>
              <div className='card-header border-0 pt-5 d-flex flex-column'>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                  <h3 className='card-title fw-bold fs-3 mb-3'>
                    Групп объявлений выбрано <span className='text-muted ms-2'>({bidderAdgroups.length})</span>
                  </h3>
                  <button className='fw-bold fs-4 btn btn-white text-danger p-0' onClick={removeAll}>Удалить все</button>
                </div>
                <button type='button' className={`btn mb-5 ${buttonSendReportAdgroup ? "btn-light" : "btn-primary"}`} //заменять btn-ligth на btn-primary когда выбраны ключевые фразы
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_3"
                        onClick={getCalculatedNgramm}
                        disabled={buttonSendReportAdgroup}
                >
                  Рассчитать ставки от N-Gram Bidder
                </button>
              </div>
            </div>


          </div>
          <div className={toogle === 3 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_3" role="tabpanel">
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* <h4 className='mb-7'>Нужен текст</h4> */}
              <div className='row'>
              <div className='col-5 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                                  <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                                      <i className="bi bi-search fs-4 fw-bold" />
                                  </span>
                      {/*<Select options={options} className='react-select-container form-control border-start-0 p-0' classNamePrefix="react-select" placeholder="Поиск по названию или номеру РК"/>*/}
                      <Select options={bidderPhrasesRowSelect} className='react-select-container form-control border-start-0 p-0 my-search' classNamePrefix="react-select" placeholder="Поиск по ключевым фразам" onChange={handleSearchBidderPhrases} isMulti onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                    </div>
                  </div>
                        {/* <div className='col-4 mb-md-5 mb-xl-6'>
                            <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                                    <i className="bi bi-calendar4-event fs-7 fw-bold"></i>
                                </span>
                                <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                                    locale={locale}
                                    selected={startDate}
                                    onChange={handleChange}
                                    selectsRange
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="d MMMM yyyy"
                                />
                            </div>
                        </div>
                        <div className='col-3 mb-md-5 mb-xl-6'>
                            <select className="form-select  text-muted" aria-label="Select example">
                                    <option>Сортировка</option>
                                    <option value="1">Сортировка</option>
                                    <option value="2">Сортировка</option>
                                    <option value="3">Сортировка</option>
                                </select>
                        </div> */}
                    </div>
              {/* begin::Table container */}
              <div className=''>
                {/* begin::Table */}
                <ReactDataGrid
                  checkboxColumn={true}
                  checkboxOnlyRowSelect={true}
                  className='table__data-grid'
                  idProperty="id"
                  showZebraRows={false}
                  style={gridStyle}
                  activateRowOnFocus={activateRowOnFocus}
                  columns={columnsBidderPhrases}
                  dataSource={currentBidderPhrasesRow}
                  pagination={true}
                  //limit={10}
                  defaultLimit={10}
                  rowHeight={null}
                  minRowHeight={63}
                  onSelectionChange={onSelectionBidderPhrases}
                  selected={selectedBidderPrases}
                  editable={true}
                  loading={loadingData}
                  loadingText={pleaseWait}
              />
                <ScrollContainer>
                  <table className='table table-bordered border align-middle mb-0 gx-3 gy-3' style={{ display: 'none' }}>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-350px text-nowrap'>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-check-ms form-check-custom form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-9-check'
                            />
                          </div>
                          <div className='ms-3 text-muted fw-semibold fs-7'>
                          Ключевые фразы
                          </div>
                        </div>
                      </th>
                      {/* <th className='min-w-350px text-nowrap'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Кампания/группа</span>
                          <i className="las la-angle-down fw-bold"></i>
                        </div>
                      </th> */}
                      <th className='min-w-200px text-nowrap'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Статус</span>
                          <i className="las la-angle-down fw-bold" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Действующая ставка</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-150px text-nowrap'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Ставки от N-GRAM Биддер</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Показы</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Клики</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>CPC, ₽</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>
                      <th className='min-w-125px text-nowrap'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <span className='text-nowrap text-muted fw-semibold fs-7'>Расход с НДС</span>
                          <i className="las la-arrow-up" />
                        </div>
                      </th>

                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>

                    {currentCalculatedNgrammRow.map((value, key) => {
                      return (
                        <tr key={key} data-key={key}>
                          <td>
                          <div className='d-flex align-items-center'>
                            <div className='form-check form-check-ms form-check-custom form-check'>
                              <input className='form-check-input widget-9-check' type='checkbox' value={value.id} onChange={(event) => {handleCampaign(event, value.id)}} />
                            </div>
                            <div className='d-flex justify-content-start flex-column ms-3'>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {/*№3222123*/}
                                      № direct_id
                                    </span>
                              <a href='#' className='text-dark fw-bold fs-7'>
                                {/*!Аско стиралка официальный сайт*/}
                                {value.body}
                              </a>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        32 минус-слов - ???
                                    </span>
                            </div>
                          </div>

                          </td>
                           {/*<td>
                             <div >
                              <div className='d-flex justify-content-start flex-column ms-3'>
                                <a href='#' className='text-dark fw-bold fs-7'>
                                  //Название группы РК, <span className='text-muted'>№3222123</span>
                                  Название группы РК - ???, <span className='text-muted'>№ - ???</span>
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        //№3222123 Поздравление Деда Мороза — МСК//
                                  //№{value.campaign_direct_id} {value.campaign_name}
                                    </span>
                              </div>
                            </div>
                          </td>  */}

                          <td>
                            <div className='d-flex'>
                              {value.campaign_state == "ON" ?
                                <span className="text-success bg-light-success fw-bold fs-7 p-2 rounded-2">Идут показы</span> :
                                <span className='text-muted bg-light fw-bold fs-7 p-2 rounded-2'>Кампания приостановлена</span>
                              }
                            </div>
                          </td>
                          <td >
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-success fw-bold fs-7'>
                                {/*90 123 ₽*/}
                                  {value.current_bid} ₽
                                </span>
                            </div>
                          </td>
                          <td >
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-primary fw-bold fs-7'>
                                {/*90 123 ₽*/}
                                  {value.ngramm_bid} ₽
                                </span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {/*90 123 ₽*/}
                                  {value.impressions} ₽
                                </span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {/*90 123 ₽*/}
                                  {value.clicks} ₽
                                </span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {/*90 123 ₽*/}
                                  {value.cpc} ₽
                                </span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold fs-7'>
                                {/*90 123 ₽*/}
                                  {value.rate} ₽
                                </span>
                            </div>
                          </td>


                        </tr>)
                    })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </ScrollContainer>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
              <div className='d-flex align-items-center justify-content-center mt-5'>
                  <button className='btn btn-primary' style={{ display: 'none' }}>Показать еще</button>
              </div>

              <div className='d-flex align-items-center justify-content-between mb-5 mt-7'>
                <div style={{ display: 'none' }}>
                  <Select options={options4} className='form-select form-select-solid react-select-container p-0' classNamePrefix="no-icon"  placeholder="10"/>
                </div>
                <div style={{ display: 'none' }}>
                  <ul className="pagination">
                    {/* <li className="page-item previous disabled">
                            <a href="#" className="page-link">
                                <i className="previous"></i>
                            </a>
                            </li> */}

                    {
                      pageNumbersCalculatedNgramm.map(number => (
                        // filtering ? <li></li> :
                        <li className="page-item" key={number}>
                          <a href="#" className={`page-link fw-bold text-muted ${currentPage === number ? "active" : ""}`} onClick={() => paginate(number)}>
                            {number}
                          </a>
                        </li>
                      ))
                    }

                    {/* <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                1
                            </a>
                            </li>
                            <li className="page-item active">
                            <a href="#" className="page-link">
                                2
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                3
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                4
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                5
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link fw-bold text-muted">
                                6
                            </a>
                            </li> */}

                    {/* <li className="page-item next">
                            <a href="#" className="page-link fw-bold text-muted">
                                <i className="next text-muted"></i>
                            </a>
                            </li> */}
                  </ul>
                </div>
              </div>
            </div>


            {profileRole != "viewer" ?
              <div className='card mt-7 mb-7 card-fixed'>
                <div className='card-header border-0 pt-5 d-flex flex-column rounded-4'>

                  {isSetRates ?
                    <button type='button'
                            className="btn mb-5 btn-primary" //заменять btn-ligth на btn-primary когда выбраны ключевые фразы
                      //data-bs-toggle="modal"
                      //data-bs-target="#kt_modal_6"
                            onClick={() => updateToggle(1)}
                    >
                      На главную
                    </button> :
                    <button type='button'
                            className={`btn mb-5 ${buttonSetRates ? "btn-light" : "btn-primary"}`} //заменять btn-ligth на btn-primary когда выбраны ключевые фразы
                      //data-bs-toggle="modal"
                      //data-bs-target="#kt_modal_6"
                            onClick={setRates}
                            disabled={buttonSetRates}
                    >
                      Установить ставки
                    </button>
                  }

                </div>
              </div> :
              <div></div>
            }


          </div>
        </div>




      </div>






      <div className="modal fade" tabIndex={-1} id="kt_modal_3">
        <div className="modal-dialog alert p-0">
          <div className="modal-content p-10">
            <h3 className="modal-title text-center fw-bold text-middle mb-4">Расчет ставок может занять до 5 минут</h3>
            <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
              <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3" />

              <div className="d-flex flex-column align-items-center">
                <h5 className="text-center fw-semibold">Пожалуйста, не закрывайте окно N-Gram Bidder</h5>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-primary w-100"
              data-bs-dismiss="modal"
            >
              Ок
            </button>
          </div>
        </div>
      </div>

      <Modal dialogClassName="alert p-0" contentClassName="p-10" tabIndex={-1} id="kt_modal_3" show={showModalCalculationRates} onHide={handleCloseModalCalculationRates} animation={true}>
        <h3 className="modal-title text-center fw-bold text-middle mb-4">Расчет ставок может занять до 5 минут</h3>
        <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
          <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3" />

          <div className="d-flex flex-column align-items-center">
            <h5 className="text-center fw-semibold">Пожалуйста, не закрывайте окно N-Gram Bidder</h5>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary w-100"
          data-bs-dismiss="modal"
          onClick={handleCloseModalCalculationRates}
        >
          Хорошо
        </button>
      </Modal>


      {/*<div className="modal fade" tabIndex={-1} id="kt_modal_6">*/}
      {/*  <div className="modal-dialog alert p-0">*/}
      {/*    <div className="modal-content p-10">*/}
      {/*      <h3 className="modal-title text-center fw-bold text-middle mb-4">Ставки установлены</h3>*/}
      {/*      /!* <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">*/}
      {/*        <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3"></i>*/}

      {/*        <div className="d-flex flex-column align-items-center">*/}
      {/*          <h5 className="text-center fw-semibold">Не забудьте отправить изменения в Яндекс.Директ</h5>*/}
      {/*        </div>*/}
      {/*      </div> *!/*/}

      {/*      <button*/}
      {/*        type="button"*/}
      {/*        className="btn btn-primary w-100"*/}
      {/*        data-bs-dismiss="modal"*/}
      {/*      >*/}
      {/*        Хорошо*/}
      {/*      </button>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Modal dialogClassName="alert p-0" contentClassName="p-10" tabIndex={-1} id="kt_modal_6" show={showModalRatesSet} onHide={handleCloseModalRatesSet} animation={true}>
        <h3 className="modal-title text-center fw-bold text-middle mb-4">Ставки установлены</h3>

        <button
          type="button"
          className="btn btn-primary w-100"
          data-bs-dismiss="modal"
          onClick={handleCloseModalRatesSet}
        >
          Хорошо
        </button>
      </Modal>

    </div>
  )
}

export {TablesWidget14}
