/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import axios from 'axios'

const initialValues = {
  username: '',
  phone_number: '',
  email: '',
  password: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  username: Yup.string()
  .min(3, 'Минимум 3 символа')
  .max(50, 'Максимум 50 символов')
  .required('Имя пользователя является обязательным'),
  password: Yup.string()
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Пароль является обязательным'),
  phone_number: Yup.string()
    .min(3, 'Минимум 11 символов')
    .required('Номер телефона является обязательным'),
  email: Yup.string()
    .email('Неверный формат электронной почты')
    .min(3, 'Минимум 3 символа')
    .max(50, 'Максимум 50 символов')
    .required('Электронная почта является обязательным'),
  acceptTerms: Yup.bool().required('Вы должны принять правила и условия'),
})

export function Registration() {

  const API_URL = process.env.REACT_APP_NGRAM_API_URL

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [successReg, setSuccessReg] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        // const {data: auth} = await register(
        //   values.email,
        //   values.username,
        //   values.lastname,
        //   values.password,
        // )
        // saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // setCurrentUser(user)

        axios.post(`${API_URL}/registration/`, {
          username: values.username,
          password: values.password,
          phone_number: values.phone_number,
          email: values.email
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(function (response) {
          //console.log(response)
          //console.log(response.data.status)
          if (response.status == 200) {
            setSuccessReg(true)
            setSubmitting(false)
            setLoading(false)
          }
        })
        .catch(function (error) {
          console.log(error)
          if (error.response.data.message == "User with same email already exist") {
            setStatus('Пользователь с таким адресом электронной почты уже существует')
          }
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
        });
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div>
      {!successReg &&
      <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Регистрация</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group username */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Имя пользователя </label>
        <input
          placeholder='Ваше имя'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.username && formik.errors.username,
            },
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Адрес электронной почты</label>
        <input
          placeholder='Ваша почта'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Номер телефона</label>
        <input
          placeholder='Ваш номер'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('phone_number')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.phone_number && formik.errors.phone_number,
            },
            {
              'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
            }
          )}
        />
        {formik.touched.phone_number && formik.errors.phone_number && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone_number}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Создать пароль</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Пароль'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>



      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            Даю согласие на обработку персональных данных
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Зарегистрироваться</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Подождите пожалуйста...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>



        <div className='text-dark-500 text-center fw-semibold fs-6'>
          Уже зарегистрированны?{' '}
          <Link to='/auth/login' className='link-primary'>
            Войдите
          </Link>
        </div>
      </div>
      {/* end::Form group */}
      </form>
      }
      {successReg && (
      <form
        className='form w-100'
        // onSubmit=""
        noValidate
        id='kt_login_signin_form1'
        style={{display: 'block'}}
      >
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Благодарим за регистрацию!</h1>
        </div>

        <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
            <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3"></i>

            <div className="d-flex flex-column align-items-center">
                <h4 className="text-center fw-semibold">Мы отправили письмо на указанный адрес электронной почты, для завершения регистрации перейдите по ссылке из письма</h4>
            </div>
        </div>


        <Link to='/auth/login' className='btn btn-primary w-100 mt-5'>
          Хорошо
        </Link>
      </form>
       )}
    </div>

  )
}
