import {SidebarMenuMain} from './SidebarMenuMain'
import clsx from 'clsx'
import {KTIcon} from '../../../../helpers'
import {useLayout} from '../../../core'
import React, {MutableRefObject, useEffect, useRef, useState} from 'react'
import {ToggleComponent} from '../../../../assets/ts/components'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarMenu = (props: PropsType) => {
  const {config} = useLayout()
  const toggleRef = useRef<HTMLDivElement>(null)
  const [titleButton, setTitleButton] = useState(true)

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
      ? 'minimize'
      : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  const updateTitleButton = () => {
    setTitleButton(!titleButton)
  }

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])

  return (
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper hover-scroll-overlay-y my-5'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
        >
          <SidebarMenuMain />
        </div>
      </div>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <>
        {titleButton
          ?
        <div
          ref={toggleRef}
          id='kt_app_sidebar_toggle'
          // className={clsx(
          //   'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
          //   {active: appSidebarDefaultMinimizeDefault}
          // )}
          className={clsx(
            'btn btn-light-primary fw-semibold h-40px position-absolute translate-middle rotate slide-button-menu',
            {active: appSidebarDefaultMinimizeDefault}
          )}

          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
          onClick={updateTitleButton}
        >
          <KTIcon iconName='black-left-line' className='fs-2 rotate-180 ms-1' />Свернуть
        </div>
          :
        <div
          ref={toggleRef}
          id='kt_app_sidebar_toggle'
          // className={clsx(
          //   'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
          //   {active: appSidebarDefaultMinimizeDefault}
          // )}
          className={clsx(
            'btn btn-light-primary fw-semibold h-40px position-absolute translate-middle rotate slide-button-menu-hide',
            {active: appSidebarDefaultMinimizeDefault}
          )}

          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
          onClick={updateTitleButton}
        >
          <KTIcon iconName='black-right-line' className='fs-2 rotate-180 ms-1' />
        </div>
        }
        </>
      )}
    </div>
  )
}

export {SidebarMenu}
