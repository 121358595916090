/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl, KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'
import {Tooltip} from '../../../layout/components/tooltip'
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css'
import  DatePicker, { registerLocale } from "react-datepicker"
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css"
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import CheckBox from '@inovua/reactdatagrid-community/packages/CheckBox'

import axios from 'axios'
import * as XLSX from 'xlsx'
import ru from "date-fns/locale/ru";
import {useAuth} from '../../../../app/modules/auth'
import {addDays} from 'date-fns'
import {addToDataLayer, sendMetricGoalVisitParametr, sendMetricVisitParametr} from '../../../../utils/metrics'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import {translateRDG} from '../../../../translations/ru/translation'
registerLocale("ru", ru); // register it with the name you want

type Props = {
  className: string
}

interface RACampaignDataItem {
  campaign_id: number;
  rel_title_from_impressions: number;
  rel_title_from_clicks: number;
  rel_text_from_impressions: number;
  rel_text_from_clicks: number;
  campaign_name: string;
  impressions: number;
  rate: number;
  clicks: number;
  conversions: number;
  revenue: number;
  // rel_title_from_impressions_autotarget: number;
  // rel_title_from_clicks_autotarget: number;
  // rel_text_from_impressions_autotarget: number;
  // rel_text_from_clicks_autotarget: number;
  // impressions_autotarget: number;
  // rate_autotarget: number;
  // clicks_autotarget: number;
  // conversions_autotarget: number;
  // revenue_autotarget: number;
  autotarget_rate_part: number;
  value?: string;
  label?: string;
  campaigns?: string[];
}

interface RAAdgroupDataItem {
  adgroup_id: number;
  rel_title_from_impressions: number;
  rel_title_from_clicks: number;
  rel_text_from_impressions: number;
  rel_text_from_clicks: number;
  group_name: string;
  impressions: number;
  rate: number;
  clicks: number;
  conversions: number;
  revenue: number;
  // rel_title_from_impressions_autotarget: number;
  // rel_title_from_clicks_autotarget: number;
  // rel_text_from_impressions_autotarget: number;
  // rel_text_from_clicks_autotarget: number;
  // impressions_autotarget: number;
  // rate_autotarget: number;
  // clicks_autotarget: number;
  // conversions_autotarget: number;
  // revenue_autotarget: number;
  autotarget_rate_part: number;
  value?: string;
  label?: string;
  groups?: string[];
}

interface RAAdvertDataItem {
  advert_id: number;
  rel_title: number;
  rel_text: number;
  title: string;
  title2: string;
  text: string;
  query: string;
  impressions: number;
  rate: number;
  clicks: number;
  conversions: number;
  revenue: number;
  // rel_title_from_impressions_autotarget: number;
  // rel_title_from_clicks_autotarget: number;
  // rel_text_from_impressions_autotarget: number;
  // rel_text_from_clicks_autotarget: number;
  // impressions_autotarget: number;
  // rate_autotarget: number;
  // clicks_autotarget: number;
  // conversions_autotarget: number;
  // revenue_autotarget: number;
  is_autotarget: boolean;
  value?: string;
  label?: string;
  title_announcement?: string[];
}

interface GoalDataItem {
  name: string;
  direct_id: number;
  id: number;
  value: string;
  label: string;
}

interface DOEDataItem {
  account_allocation: string;
  clicks: number;
  impressions: number;
  rate: number;
  clicks_percent: number;
  impressions_percent: number;
  rate_percent: number;
}

const TablesWidget18: React.FC<Props> = ({className}) => {

  const API_URL = process.env.REACT_APP_NGRAM_API_URL

  const [profileId, setProfileId] = useState(localStorage.getItem('profileId') || "")

  const {currentUser, logout} = useAuth()

  const [selectFocus, setSelectFocus] = useState(4)
  const onBlur = () => setSelectFocus(4)
  const gridStyle = { minHeight: 740, zIndex: selectFocus }
  const [activateRowOnFocus, setActivateRowOnFocus] = useState(true)
  const pleaseWait = <b>Загрузка данных, подождите ... </b>

    const columnsCampaign = [
        { name: 'campaign_id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
        { name: 'campaigns', defaultFlex: 1, minWidth: 300, header: 'Кампания',
          render: ({ value }) =>
            <div className='d-flex justify-content-start flex-column '>
          <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {value[0]}
          </span>
              <span className='text-dark fw-bold fs-8'>
            {value[1]}
          </span>
            </div>
        },
        { name: 'rel_title_from_impressions', defaultFlex: 1,  minWidth: 190, header: 'Рел. заголовков через показы', type: 'number', filterEditor: NumberFilter,
            render: ({ value }) =>
              <div className='d-flex flex-column w-100 me-2'>
                <div className='d-flex flex-stack mb-2'>
                  <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
                </div>
                <div className='progress h-6px mw-150px'>
                  {Number(value) <= 75 ? <div
                    className='progress-bar bg-danger'
                    role='progressbar'
                    style={{width: `${value}%`}}
                  /> : <div
                    className='progress-bar bg-success'
                    role='progressbar'
                    style={{width: `${value}%`}}
                  /> }
                </div>
              </div>
        },
        { name: 'rel_title_from_clicks',defaultFlex: 1,  minWidth: 180, header: 'Рел. заголовков через клики', type: 'number', filterEditor: NumberFilter,
          render: ({ value }) =>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
              </div>
              <div className='progress h-6px mw-150px'>
                {Number(value) <= 75 ? <div
                  className='progress-bar bg-danger'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> : <div
                  className='progress-bar bg-success'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> }
              </div>
            </div>
        },
        { name: 'rel_text_from_impressions', defaultFlex: 1,  minWidth: 130, header: 'Среднев. через показы рел. текста', type: 'number',
          render: ({ value }) =>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
              </div>
              <div className='progress h-6px mw-150px'>
                {Number(value) <= 75 ? <div
                  className='progress-bar bg-danger'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> : <div
                  className='progress-bar bg-success'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> }
              </div>
            </div>
        },
        { name: 'rel_text_from_clicks', defaultFlex: 1,  minWidth: 130, header: 'Среднев. через клики рел. текста', type: 'number',
          render: ({ value }) =>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
              </div>
              <div className='progress h-6px mw-150px'>
                {Number(value) <= 75 ? <div
                  className='progress-bar bg-danger'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> : <div
                  className='progress-bar bg-success'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> }
              </div>
            </div>
        },
        { name: 'impressions',defaultFlex: 1,  minWidth: 90, header: 'Показы', type: 'number' },
        { name: 'clicks', defaultFlex: 1, minWidth: 90, header: 'Клики', type: 'number' },
        { name: 'rate',defaultFlex: 1,  minWidth: 90, header: 'Расход', type: 'number',
          render: ({ value }) =>  value + ` ₽` },
        { name: 'autotarget_rate_part', defaultFlex: 1, minWidth: 120, header: 'Доля расхода с автотаргетинга', type: 'number',
          render: ({ value }) =>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
              </div>
              {(() => {
                if (Number(value) <= 25 && String(value) != "null") {
                  return (
                    <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
                  )
                } else if (Number(value) > 25) {
                  return (
                    <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
                  )
                } else {
                  return (
                    <div className='h-6px mw-150px'><div /></div>
                  )
                }
              })()}
            </div>
        },
        { name: 'conversions',defaultFlex: 1,  minWidth: 90, header: 'Конверсии', type: 'number' },
        { name: 'revenue', defaultFlex: 1, minWidth: 90, header: 'Доход', type: 'number',
          render: ({ value }) =>  value + ` ₽` },
    ]

    const columnsAdgroup = [
        { name: 'adgroup_id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
        { name: 'groups', defaultFlex: 1, minWidth: 300, header: 'Группы',
          render: ({ value }) =>
            <div className='d-flex justify-content-start flex-column '>
          <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {value[0]}
          </span>
              <span className='text-dark fw-bold fs-8'>
            {value[1]}
          </span>
            </div>
        },
        { name: 'rel_title_from_impressions', defaultFlex: 1,  minWidth: 180, header: 'Рел. заголовков через показы', type: 'number', filterEditor: NumberFilter,
            render: ({ value }) =>
              <div className='d-flex flex-column w-100 me-2'>
                <div className='d-flex flex-stack mb-2'>
                  <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
                </div>
                <div className='progress h-6px mw-150px'>
                  {Number(value) <= 75 ? <div
                    className='progress-bar bg-danger'
                    role='progressbar'
                    style={{width: `${value}%`}}
                  /> : <div
                    className='progress-bar bg-success'
                    role='progressbar'
                    style={{width: `${value}%`}}
                  /> }
                </div>
              </div>
        },
        { name: 'rel_title_from_clicks',defaultFlex: 1,  minWidth: 180, header: 'Рел. заголовков через клики', type: 'number', filterEditor: NumberFilter,
            render: ({ value }) =>
              <div className='d-flex flex-column w-100 me-2'>
                <div className='d-flex flex-stack mb-2'>
                  <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
                </div>
                <div className='progress h-6px mw-150px'>
                  {Number(value) <= 75 ? <div
                    className='progress-bar bg-danger'
                    role='progressbar'
                    style={{width: `${value}%`}}
                  /> : <div
                    className='progress-bar bg-success'
                    role='progressbar'
                    style={{width: `${value}%`}}
                  /> }
                </div>
              </div>
        },
        { name: 'rel_text_from_impressions', defaultFlex: 1,  minWidth: 130, header: 'Среднев. через показы рел. текста', type: 'number',
          render: ({ value }) =>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
              </div>
              <div className='progress h-6px mw-150px'>
                {Number(value) <= 75 ? <div
                  className='progress-bar bg-danger'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> : <div
                  className='progress-bar bg-success'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> }
              </div>
            </div>
        },
        { name: 'rel_text_from_clicks', defaultFlex: 1,  minWidth: 130, header: 'Среднев. через клики рел. текста', type: 'number',
          render: ({ value }) =>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
              </div>
              <div className='progress h-6px mw-150px'>
                {Number(value) <= 75 ? <div
                  className='progress-bar bg-danger'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> : <div
                  className='progress-bar bg-success'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> }
              </div>
            </div>
        },
        { name: 'impressions',defaultFlex: 1,  minWidth: 90, header: 'Показы', type: 'number' },
        { name: 'clicks', defaultFlex: 1, minWidth: 90, header: 'Клики', type: 'number' },
        { name: 'rate',defaultFlex: 1,  minWidth: 90, header: 'Расход', type: 'number',
          render: ({ value }) =>  value + ` ₽` },
        { name: 'autotarget_rate_part', defaultFlex: 1, minWidth: 120, header: 'Доля расхода с автотаргетинга', type: 'number',
          render: ({ value }) =>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
              </div>
              {(() => {
                if (Number(value) <= 25 && String(value) != "null") {
                  return (
                    <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
                  )
                } else if (Number(value) > 25) {
                  return (
                    <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
                  )
                } else {
                  return (
                    <div className='h-6px mw-150px'><div /></div>
                  )
                }
              })()}
            </div>
        },
        { name: 'conversions',defaultFlex: 1,  minWidth: 90, header: 'Конверсии', type: 'number' },
        { name: 'revenue', defaultFlex: 1, minWidth: 90, header: 'Доход', type: 'number',
          render: ({ value }) =>  value + ` ₽` },
    ]

    const columnsAdvert = [
        { name: 'id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
        { name: 'title_announcement', defaultFlex: 1, minWidth: 200, header: 'Заголовок объявления',
          render: ({ value }) =>
            <div className='d-flex justify-content-start flex-column '>
          <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {value[0]}
          </span>
              <span className='text-dark fw-bold fs-8'>
            {value[1]} {value[2]}
          </span>
            </div>
        },
        { name: 'text', defaultFlex: 1,  minWidth: 200, header: 'Текст объявления' },
        { name: 'query',defaultFlex: 1,  minWidth: 130, header: 'Поисковый запрос' },
        { name: 'rel_title', defaultFlex: 1,  minWidth: 180, header: 'Рел. заголовка по запросу', type: 'number', filterEditor: NumberFilter,
          render: ({ value }) =>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
              </div>
              <div className='progress h-6px mw-150px'>
                {Number(value) <= 75 ? <div
                  className='progress-bar bg-danger'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> : <div
                  className='progress-bar bg-success'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> }
              </div>
            </div>
        },
        { name: 'rel_text', defaultFlex: 1,  minWidth: 130, header: 'Рел. текста запросу', type: 'number',
          render: ({ value }) =>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
              </div>
              <div className='progress h-6px mw-150px'>
                {Number(value) <= 75 ? <div
                  className='progress-bar bg-danger'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> : <div
                  className='progress-bar bg-success'
                  role='progressbar'
                  style={{width: `${value}%`}}
                /> }
              </div>
            </div>
        },
        { name: 'impressions',defaultFlex: 1,  minWidth: 90, header: 'Показы', type: 'number' },
        { name: 'clicks', defaultFlex: 1, minWidth: 90, header: 'Клики', type: 'number' },
        { name: 'rate',defaultFlex: 1,  minWidth: 90, header: 'Расход', type: 'number',
          render: ({ value }) =>  value + ` ₽` },
        { name: 'is_autotarget', defaultFlex: 1, minWidth: 120, header: 'Автотаргетинг', type: 'number',
          render: ({ value }) =>
            <div>
              {(() => {
                if (value === true) {
                  return (
                    <div className="d-flex align-items-center justify-content-center text-center text-middle fw-bold fs-7  text-white rounded-2 p-2 bg-success">Да</div>
                  )
                } else if (value === false) {
                  return (
                    <div className="d-flex align-items-center justify-content-center text-center text-middle fw-bold fs-7  text-white rounded-2 p-2 bg-danger">Нет</div>
                  )
                } else {
                  return (
                    <div className="d-flex align-items-center justify-content-center text-center text-middle fw-bold fs-7  text-white rounded-2 p-2"> </div>
                  )
                }
              })()}
            </div>
        },
        { name: 'conversions',defaultFlex: 1,  minWidth: 90, header: 'Конверсии', type: 'number' },
        { name: 'revenue', defaultFlex: 1, minWidth: 90, header: 'Доход', type: 'number',
          render: ({ value }) =>  value + ` ₽` },
    ]

    const nowDate = new Date()
    ///const [startDate, setStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
    ///const [endDate, setEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1)) //useState(nowDate)
    const [startDate, setStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
    const [endDate, setEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), 0)) //useState(nowDate)

    const [minDate, setMinDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
    const [maxDate, setMaxDate] = useState(nowDate)

    const [availableStartDate, setAvailableStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
    const [availableEndDate, setAvailableEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1)) //useState(nowDate)

    const [autotargetDateFrom, setAutotargetDateFrom] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
    const [autotargetDateTo, setAutotargetDateTo] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1))

    const [attribution, setAttribution] = useState(8)
    const [goal, setGoal] = useState(0)
    const [autotarget, setAutotarget] = useState("keywords")

    const [defaultValueSearchCampaign, setDefaultValueSearchCampaign] = useState<RACampaignDataItem[]>([])
    const [defaultValueSearchAdgroup, setDefaultValueSearchAdgroup] = useState<RAAdgroupDataItem[]>([])
    const [defaultValueSearchAdvert, setDefaultValueSearchAdvert] = useState<RAAdvertDataItem[]>([])
    const [defaultValueSearchAdvertText, setDefaultValueSearchAdvertText] = useState<RAAdvertDataItem[]>([])

    const [defaultValueAttribution, setDefaultValueAttribution] = useState({ value: localStorage.getItem('profileAttributionValue'), label: localStorage.getItem('profileAttributionLabel') })
    const [defaultValueGoal, setDefaultValueGoal] = useState({ value: localStorage.getItem('profileGoalValue'), label: localStorage.getItem('profileGoalLabel') })
    const [defaultValueAutotarget, setDefaultValueAutotarget] = useState({ value: localStorage.getItem('profileAutotargetValue'), label: localStorage.getItem('profileAutotargetLabel') })

    const [selectedOptionSearchCampaign, setSelectedOptionSearchCampaign] = useState(defaultValueSearchCampaign)
    const [selectedOptionSearchAdgroup, setSelectedOptionSearchAdgroup] = useState(defaultValueSearchAdgroup)
    const [selectedOptionSearchAdvert, setSelectedOptionSearchAdvert] = useState(defaultValueSearchAdvert)
    const [selectedOptionSearchAdvertText, setSelectedOptionSearchAdvertText] = useState(defaultValueSearchAdvertText)

    const [selectedOptionAttribution, setSelectedOptionAttribution] = useState(defaultValueAttribution)
    const [selectedOptionGoal, setSelectedOptionGoal] = useState(defaultValueGoal)
    const [selectedOptionAutotarget, setSelectedOptionAutotarget] = useState(defaultValueAutotarget)

    const [dateForReport, setDateForReport] = useState(true)
    const [attributionForReport, setAttributionForReport] = useState(true)
    const [goalForReport, setGoalForReport] = useState(false)

    const [campaigns, setCampaigns] = useState<number[]>([])
    const [adgroups, setAdgroups] = useState<number[]>([])

    let tempCampaigns: number[] = []
    const tempSelectedCampaign = {}
    let tempAdgroups: number[] = []
    const tempSelectedAdgroup = {}

    const [isActiveGroups, setIsActiveGroups] = useState(true)
    const [isActiveAdvert, setIsActiveAdvert] = useState(true)

    const [buttonSendReport, setButtonSendReport] = useState(true)

    const [buttonResetFilters, setButtonResetFilters] = useState(true)

    const handleChangeDate = ([newStartDate, newEndDate]) => {
      setStartDate(newStartDate)
      setEndDate(newEndDate)

      setMinDate(newStartDate)
      const futureDate = addDays(newStartDate, 59)
      setMaxDate(futureDate > nowDate ? nowDate : futureDate)
      if (newStartDate === null || newEndDate === null) {
        setDateForReport(false)
      } else {
        setDateForReport(true)
        localStorage.setItem('profileDateFrom', `${newStartDate.toLocaleDateString('fr-CA')}`)
        localStorage.setItem('profileDateTo', `${newEndDate.toLocaleDateString('fr-CA')}`)
        setButtonResetFilters(false)

        sendMetricGoalVisitParametr('reachGoal', 'filter_calendar_relevant-ads-page', {
          [`${localStorage.getItem('profileName')}`]: {
            [`${window.location.pathname}`]: {
              "filter_calendar_relevant-ads-page": {}
            }
          }
        })
      }
      //console.log(newStartDate.toLocaleDateString('fr-CA'))
      //console.log(newEndDate.toLocaleDateString('fr-CA'))
    }

    const handleAttribution = (selectedOption) => {
      if (selectedOption === null || selectedOption.label == "Установите атрибуцию") {
        setAttribution(0)
        localStorage.setItem('profileAttributionValue', "8")
        localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
        setAttributionForReport(false)
      } else {
        setAttribution(selectedOption.id);
        setSelectedOptionAttribution(selectedOption)
        localStorage.setItem('profileAttributionValue', `${selectedOption.id}`)
        localStorage.setItem('profileAttributionLabel', `${selectedOption.full_name}`)
        setAttributionForReport(true)
        setButtonResetFilters(false)
        //checkSendReport()

        sendMetricGoalVisitParametr('reachGoal', 'filter_attribution_relevant-ads-page', {
          [`${localStorage.getItem('profileName')}`]: {
            [`${window.location.pathname}`]: {
              "filter_attribution_relevant-ads-page": {}
            }
          }
        })
      }
    }

    const handleGoal = (selectedOption) => {
      if (selectedOption === null || selectedOption.label == "Выберите данные по целям" || selectedOption.label == "---") {
        setGoal(0)
        setSelectedOptionGoal({value: '0', label: 'Выберите данные по целям'})
        localStorage.setItem('profileGoalValue', "0")
        localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
        setGoalForReport(false)
      } else {
        setGoal(selectedOption.id);
        setSelectedOptionGoal(selectedOption)
        localStorage.setItem('profileGoalValue', `${selectedOption.id}`)
        localStorage.setItem('profileGoalLabel', `${selectedOption.name}`)
        setGoalForReport(true)
        setButtonResetFilters(false)
        //checkSendReport()

        sendMetricGoalVisitParametr('reachGoal', 'filter_goals_relevant-ads-page', {
          [`${localStorage.getItem('profileName')}`]: {
            [`${window.location.pathname}`]: {
              "filter_goals_relevant-ads-page": {}
            }
          }
        })
      }
    }

  const handleAutotarget = (selectedOption) => {
    if (selectedOption === null || selectedOption.label == "Выберите условие показа" || selectedOption.label == "---") {
      setAutotarget("keywords")
      setSelectedOptionAutotarget({value: 'keywords', label: 'Выберите условие показа'})
      localStorage.setItem('profileAutotargetValue', "keywords")
      localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
    } else {
      setAutotarget(selectedOption.value)
      setSelectedOptionAutotarget(selectedOption)
      localStorage.setItem('profileAutotargetValue', `${selectedOption.value}`)
      localStorage.setItem('profileAutotargetLabel', `${selectedOption.label}`)
      setButtonResetFilters(false)
      //checkSendReport()

      // sendMetricGoalVisitParametr('reachGoal', 'filter_goals_report-page', {
      //   [`${localStorage.getItem('profileName')}`]: {
      //     [`${window.location.pathname}`]: {
      //       "filter_goals_report-page": {}
      //     }
      //   }
      // })
    }
  }

    const onSelectionCampaign = ({ selected }) => {
      //console.log(Object.keys(selected).valueOf())
      //console.log(selected)
      tempCampaigns = []
      if (selected == 1) {
        for (var key in currentCampaignReportRow) {
          // @ts-ignore
          tempSelectedCampaign[currentCampaignReportRow[key].campaign_id] = {}
          tempCampaigns.push(currentCampaignReportRow[key].campaign_id)
        }
        setCampaigns(tempCampaigns)
        setSelectedCampaign(tempSelectedCampaign)
        ///setCampaignForReport(true)
        setIsActiveGroups(false)
        ///setIsActiveAdvert(false)
        setAdgroups([]) // Убираем выбранные группы при выборе кампаний
        setSelectedAdgroup({}) // Убираем выбранные группы при выборе кампаний

        sendMetricGoalVisitParametr('reachGoal', 'choose_campaign_relevant-ads-page', {
          [`${localStorage.getItem('profileName')}`]: {
            [`${window.location.pathname}`]: {
              "choose_campaign_relevant-ads-page": {}
            }
          }
        })
      } else if (Object.keys(selected).length === 0) {
        setCampaigns([])
        setSelectedCampaign({})
        ///localStorage.removeItem("chooseCampaignId")
        ///setCampaignForReport(false)
        setIsActiveGroups(true)
        setIsActiveAdvert(true)
        setAdgroups([]) // Убираем выбранные группы при выборе кампаний
        setSelectedAdgroup({}) // Убираем выбранные группы при выборе кампаний
      } else {
        for (var key in selected) {
          // @ts-ignore
          //tempCampaigns.push(Number(selected[key].campaign_id))
          tempCampaigns.push(Number(key))
        }
        setCampaigns(tempCampaigns)
        setSelectedCampaign(selected)
        ///setCampaignForReport(true)
        setIsActiveGroups(false)
        ///setIsActiveAdvert(false)
        setAdgroups([]) // Убираем выбранные группы при выборе кампаний
        setSelectedAdgroup({}) // Убираем выбранные группы при выборе кампаний

        sendMetricGoalVisitParametr('reachGoal', 'choose_campaign_relevant-ads-page', {
          [`${localStorage.getItem('profileName')}`]: {
            [`${window.location.pathname}`]: {
              "choose_campaign_relevant-ads-page": {}
            }
          }
        })
      }
    }

    const onSelectionAdgroup = ({ selected }) => {
      //console.log(Object.keys(selected).valueOf())
      //console.log(selected)
      tempAdgroups = []
      if (selected == 1) {
        for (var key in currentAdgroupReportRow) {
          // @ts-ignore
          tempSelectedAdgroup[currentAdgroupReportRow[key].adgroup_id] = {}
          tempAdgroups.push(currentAdgroupReportRow[key].adgroup_id)
        }
        setAdgroups(tempAdgroups)
        setSelectedAdgroup(tempSelectedAdgroup)
        ///setCampaignForReport(true)
        setIsActiveAdvert(false)
      } else if (Object.keys(selected).length === 0) {
        setAdgroups([])
        setSelectedAdgroup({})
        ///localStorage.removeItem("chooseCampaignId")
        ///setCampaignForReport(false)
        setIsActiveAdvert(true)
      } else {
        for (var key in selected) {
          // @ts-ignore
          //tempCampaigns.push(Number(selected[key].campaign_id))
          tempAdgroups.push(Number(key))
        }
        setAdgroups(tempAdgroups)
        setSelectedAdgroup(selected)
        ///setCampaignForReport(true)
        setIsActiveAdvert(false)
      }
    }

    // const checkButtonSendReport = () => {
    //   if (dateForReport && attributionForReport && goalForReport) {
    //     setButtonSendReport(false)
    //   } else {
    //     setButtonSendReport(true)
    //   }
    // }

  const checkSendReport = () => {
    if (dateForReport && attributionForReport && goalForReport) {
      setButtonSendReport(false)

      if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
        setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
        setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
      }

      getRelevantAnnouncements()
      setMinDate(availableStartDate)
      setMaxDate(nowDate)
    } else {
      setButtonSendReport(true)
      if (startDate === null || endDate === null) {
        ///
      } else {
        getRelevantAnnouncements()
        setMinDate(availableStartDate)
        setMaxDate(nowDate)
      }
    }
  }

  const [toogle, setToggle] = useState(1)

  function updateToggle(id) {
    if (id == 1) {
      //setIsSetRates(false)
    }

    if (id == 2) {
      sendMetricGoalVisitParametr('reachGoal', 'view_groups_relevant-ads-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "view_groups_relevant-ads-page": {}
          }
        }
      })
    } else if (id == 3) {
      sendMetricGoalVisitParametr('reachGoal', 'view_queries_relevant-ads-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "view_queries_relevant-ads-page": {}
          }
        }
      })
    }

    setToggle(id)

    setDefaultValueAttribution({ value: localStorage.getItem('profileAttributionValue'), label: localStorage.getItem('profileAttributionLabel') })
    setDefaultValueGoal({ value: localStorage.getItem('profileGoalValue'), label: localStorage.getItem('profileGoalLabel') })
    setDefaultValueAutotarget({ value: localStorage.getItem('profileAutotargetValue'), label: localStorage.getItem('profileAutotargetLabel') })
    //setSelectedOptionAttribution(defaultValueAttribution)
    //setSelectedOptionGoal(defaultValueGoal)
    setSelectedOptionAttribution({ value: localStorage.getItem('profileAttributionValue'), label: localStorage.getItem('profileAttributionLabel') })
    setSelectedOptionGoal({ value: localStorage.getItem('profileGoalValue'), label: localStorage.getItem('profileGoalLabel') })
    setSelectedOptionAutotarget({ value: localStorage.getItem('profileAutotargetValue'), label: localStorage.getItem('profileAutotargetLabel') })
  }

  const firstUpdate = useRef(true)

  async function fetchDate() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    await fetch(`${API_URL}/get_dataset_period/?profile_id=${profileId}&section=adverts`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        if (json.status == "ERROR") {
          ///
        } else if (json.result.date_from == null || json.result.date_to == null) {
          ///
        } else if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
          setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
          setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
          const dateFromResult = new Date(json.result.date_from)
          const dateToResult = new Date(json.result.date_to)
          setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
          setMinDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAutotargetDateFrom(new Date(json.result.autotarget_date_from))
          setAutotargetDateTo(new Date(json.result.autotarget_date_to))
        } else {
          const dateFromResult = new Date(json.result.date_from)
          const dateToResult = new Date(json.result.date_to)
          const lastDateFromResult = new Date(json.result.last_date_from)
          const lastDateToResult = new Date(json.result.last_date_to)
          setStartDate(new Date(lastDateFromResult.getFullYear(), lastDateFromResult.getMonth(), lastDateFromResult.getDate()))
          setEndDate(new Date(lastDateToResult.getFullYear(), lastDateToResult.getMonth(), lastDateToResult.getDate()))
          //setStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          //setEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
          setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
          setMinDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAutotargetDateFrom(new Date(json.result.autotarget_date_from))
          setAutotargetDateTo(new Date(json.result.autotarget_date_to))
        }
        //setGoals(json)
        // json.map((object) => {
        //   setGoals((goals) => [
        //     ...goals,
        //     {
        //       value: object.id,
        //       label: object.name,
        //       isDisabled: false
        //     }
        //   ]);
        // });

        // Рабочий вариант
        if (defaultValueGoal.value == "0") {
          setGoalForReport(false)
        } else {
          setGoalForReport(true)
        }
      });
    firstUpdate.current = false
  }

  useEffect( () => {
    //console.log(dateForReport, attributionForReport, goalForReport)
    //checkButtonSendReport()

    if (!firstUpdate.current) {
      //console.log("firstUpdate: false")
      checkSendReport()
    }
  }, [dateForReport, attributionForReport, goalForReport, attribution, goal, autotarget, profileId, toogle, availableEndDate])

  useEffect( () => {
    setLoadingData(true)

    sendMetricVisitParametr("params", {
      [`${localStorage.getItem('profileName')}`]: {
        [`${window.location.pathname}`]: {}
      }
    })

    addToDataLayer({
      'login': currentUser?.email,
      'profile': localStorage.getItem('profileName'),
      'url': window.location.pathname,
    })

    if (localStorage.getItem("profileDateFrom") === null || localStorage.getItem("profileDateTo") === null) {
      localStorage.setItem('profileDateFrom', "0000-00-00")
      localStorage.setItem('profileDateTo', "0000-00-00")
      window.location.reload()
    }
    if (localStorage.getItem("profileAttributionValue") === null || localStorage.getItem("profileAttributionLabel") === null) {
      localStorage.setItem('profileAttributionValue', "8")
      localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
      window.location.reload()
    }
    if (localStorage.getItem("profileGoalValue") === null || localStorage.getItem("profileGoalLabel") === null) {
      localStorage.setItem('profileGoalValue', "0")
      localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
      window.location.reload()
    }
    if (localStorage.getItem("profileAutotargetValue") === null || localStorage.getItem("profileAutotargetLabel") === null) {
      localStorage.setItem('profileAutotargetValue', "keywords")
      localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
      window.location.reload()
    }
    if (localStorage.getItem("profileUrlIsYandexValue") === null || localStorage.getItem("profileUrlIsYandexLabel") === null) {
      localStorage.setItem('profileUrlIsYandexValue', "null")
      localStorage.setItem('profileUrlIsYandexLabel', "---")
      window.location.reload()
    }
    if (localStorage.getItem("profileUrlIsGoogleValue") === null || localStorage.getItem("profileUrlIsGoogleLabel") === null) {
      localStorage.setItem('profileUrlIsGoogleValue', "null")
      localStorage.setItem('profileUrlIsGoogleLabel', "---")
      window.location.reload()
    }
    if (localStorage.getItem("checkDateValue") === null || localStorage.getItem("checkDateLabel") === null) {
      localStorage.setItem('checkDateValue', "0")
      localStorage.setItem('checkDateLabel', "Выберите дату")
      window.location.reload()
    }

    window.addEventListener('localStorage', () => {
      setProfileId(String(localStorage.getItem('profileId')))
      //setProfileDateFrom(String(localStorage.getItem('profileDateFrom')))
      //setProfileDateTo(String(localStorage.getItem('profileDateTo')))
    })

    setSelectedOptionAttribution(defaultValueAttribution)
    setSelectedOptionGoal(defaultValueGoal)
    setSelectedOptionAutotarget(defaultValueAutotarget)

    fetchDate()
  },[])

    //const [kpssReportRow, setKpssReportRow] = useState([])
    const [campaignReportRow, setCampaignReportRow] = useState<RACampaignDataItem[]>([]);
    const [campaignReportRowSelect, setCampaignReportRowSelect] = useState<RACampaignDataItem[]>([]);
    const [pageCountCampaign, setPageCountCampaign] = useState(0)
    const [adgroupReportRow, setAdgroupReportRow] = useState<RAAdgroupDataItem[]>([]);
    const [adgroupReportRowSelect, setAdgroupReportRowSelect] = useState<RAAdgroupDataItem[]>([]);
    const [pageCountAdgroup, setPageCountAdgroup] = useState(0)
    const [advertReportRow, setAdvertReportRow] = useState<RAAdvertDataItem[]>([]);
    const [advertReportRowSelect, setAdvertReportRowSelect] = useState<RAAdvertDataItem[]>([]);
    const [advertTextReportRowSelect, setAdvertTextReportRowSelect] = useState<RAAdvertDataItem[]>([]);
    const [pageCountAdvert, setPageCountAdvert] = useState(0)

    const [doeReportRow, setDoeReportRow] = useState<DOEDataItem[]>([])

    const [summaryRelTitleFromImpressions, setSummaryRelTitleFromImpressions] = useState(0)
    const [summaryRelTitleFromClicks, setSummaryRelTitleFromClicks] = useState(0)
    const [summaryRelTextFromImpressions, setSummaryRelTextFromImpressions] = useState(0)
    const [summaryRelTextFromClicks, setSummaryRelTextFromClicks] = useState(0)

    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [kpssRowPerPage] = useState(10)

    const [searchValueCampaign, setSearchValueCampaign] = useState<String[]>([])
    const [filteringCampaign, setFilteringCampaign] = useState(false)
    const [searchValueAdgroup, setSearchValueAdgroup] = useState<String[]>([])
    const [filteringAdgroup, setFilteringAdgroup] = useState(false)
    const [searchValueAdvert, setSearchValueAdvert] = useState<String[]>([])
    const [filteringAdvert, setFilteringAdvert] = useState(false)
    const [searchValueAdvertText, setSearchValueAdvertText] = useState<String[]>([])
    const [filteringAdvertText, setFilteringAdvertText] = useState(false)

    const [attributions, setAttributions] = useState([])
    const [goals, setGoals] = useState<GoalDataItem[]>([])
    const autotargets = [
      {value: "autotarget", label: "Автотаргетинг"},
      {value: "keywords", label: "Ключевые фразы"},
      {value: "summary", label: "Все вместе"}
    ]

  const handleSearchCampaign = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValueCampaign([])
      setSelectedOptionSearchCampaign([])
      setFilteringCampaign(false)
    } else {
      setSearchValueCampaign([])
      setSelectedOptionSearchCampaign([])
      selectedOption.map(o =>
        setSearchValueCampaign(searchValueCampaign => [
          ...searchValueCampaign,
          o.campaign_id.toString()
        ])
      )
      selectedOption.map(campaignItem =>
        setSelectedOptionSearchCampaign(searchValueCampaing => [
          ...searchValueCampaing,
          {
            campaign_id: campaignItem.campaign_id,
            rel_title_from_impressions: campaignItem.rel_title_from_impressions,
            rel_title_from_clicks: campaignItem.rel_title_from_clicks,
            rel_text_from_impressions: campaignItem.rel_text_from_impressions,
            rel_text_from_clicks: campaignItem.rel_text_from_clicks,
            campaign_name: campaignItem.campaign_name,
            impressions: campaignItem.impressions,
            rate: campaignItem.rate,
            clicks: campaignItem.clicks,
            conversions: campaignItem.conversions,
            revenue: campaignItem.revenue,
            autotarget_rate_part: campaignItem.autotarget_rate_part,
            value: `№${campaignItem.campaign_id}`,
            label: `№${campaignItem.campaign_id} \n${campaignItem.campaign_name}`,
            campaigns: [`№${campaignItem.campaign_id}`, `${campaignItem.campaign_name}`]
          }
        ])
      )

      setFilteringCampaign(true)
      setButtonResetFilters(false)

      sendMetricGoalVisitParametr('reachGoal', 'filter_campaign_relevant-ads-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_campaign_relevant-ads-page": {}
          }
        }
      })
    }
  }

  const handleSearchAdgroup = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValueAdgroup([])
      setSelectedOptionSearchAdgroup([])
      setFilteringAdgroup(false)
    } else {
      setSearchValueAdgroup([])
      setSelectedOptionSearchAdgroup([])
      selectedOption.map(o =>
        setSearchValueAdgroup(searchValueAdgroup => [
          ...searchValueAdgroup,
          o.adgroup_id.toString()
        ])
      )
      selectedOption.map(adgroupItem =>
        setSelectedOptionSearchAdgroup(searchValueAdgroup => [
          ...searchValueAdgroup,
          {
            adgroup_id: adgroupItem.adgroup_id,
            rel_title_from_impressions: adgroupItem.rel_title_from_impressions,
            rel_title_from_clicks: adgroupItem.rel_title_from_clicks,
            rel_text_from_impressions: adgroupItem.rel_text_from_impressions,
            rel_text_from_clicks: adgroupItem.rel_text_from_clicks,
            group_name: adgroupItem.group_name,
            impressions: adgroupItem.impressions,
            rate: adgroupItem.rate,
            clicks: adgroupItem.clicks,
            conversions: adgroupItem.conversions,
            revenue: adgroupItem.revenue,
            autotarget_rate_part: adgroupItem.autotarget_rate_part,
            value: `№${adgroupItem.adgroup_id}`,
            label: `№${adgroupItem.adgroup_id} \n${adgroupItem.group_name}`,
            groups: [`№${adgroupItem.adgroup_id}`, `${adgroupItem.group_name}`]
          }
        ])
      )

      setFilteringAdgroup(true)
      setButtonResetFilters(false)
    }
  }

  const handleSearchAdvert = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValueAdvert([])
      setSelectedOptionSearchAdvert([])
      setFilteringAdvert(false)
    } else {
      setSearchValueAdvert([])
      setSelectedOptionSearchAdvert([])
      selectedOption.map(o =>
        setSearchValueAdvert(searchValueAdvert => [
          ...searchValueAdvert,
          o.advert_id.toString()
        ])
      )
      selectedOption.map(advertItem =>
        setSelectedOptionSearchAdvert(searchValueAdvert => [
          ...searchValueAdvert,
          {
            advert_id: advertItem.advert_id,
            rel_title: advertItem.rel_title,
            rel_text: advertItem.rel_text,
            title: advertItem.title,
            title2: advertItem.title2,
            text: advertItem.text,
            query: advertItem.query,
            impressions: advertItem.impressions,
            rate: advertItem.rate,
            clicks: advertItem.clicks,
            conversions: advertItem.conversions,
            revenue: advertItem.revenue,
            is_autotarget: advertItem.is_autotarget,
            value: `№${advertItem.advert_id}`,
            label: `№${advertItem.advert_id} \n${advertItem.title}`,
            title_announcement: [`№${advertItem.advert_id}`, `${advertItem.title}`, `${advertItem.title2}`]
          }
        ])
      )

      setFilteringAdvert(true)
      setButtonResetFilters(false)
    }
  }

  const handleSearchAdvertText = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValueAdvertText([])
      setSelectedOptionSearchAdvertText([])
      setFilteringAdvertText(false)
    } else {
      setSearchValueAdvertText([])
      setSelectedOptionSearchAdvertText([])
      selectedOption.map(o =>
        setSearchValueAdvertText(searchValueAdvertText => [
          ...searchValueAdvertText,
          o.text
        ])
      )
      selectedOption.map(advertItem =>
        setSelectedOptionSearchAdvertText(searchValueAdvertText => [
          ...searchValueAdvertText,
          {
            advert_id: advertItem.advert_id,
            rel_title: advertItem.rel_title,
            rel_text: advertItem.rel_text,
            title: advertItem.title,
            title2: advertItem.title2,
            text: advertItem.text,
            query: advertItem.query,
            impressions: advertItem.impressions,
            rate: advertItem.rate,
            clicks: advertItem.clicks,
            conversions: advertItem.conversions,
            revenue: advertItem.revenue,
            is_autotarget: advertItem.is_autotarget,
            value: `№${advertItem.advert_id}`,
            label: `№${advertItem.advert_id} \n${advertItem.title}`,
            title_announcement: [`№${advertItem.advert_id}`, `${advertItem.title}`, `${advertItem.title2}`]
          }
        ])
      )

      setFilteringAdvertText(true)
      setButtonResetFilters(false)
    }
  }

  const getRelevantAnnouncements = () => {
    let agg = "campaigns"
    if (toogle == 1) {
      agg = "campaigns"
    } else if (toogle == 2) {
      agg = "adgroups"
    } else {
      agg = "adverts"
    }

    if (localStorage.getItem('profileAttributionValue') !== null || Number(localStorage.getItem('profileAttributionValue')) != 8 ) {
      setAttribution(Number(localStorage.getItem('profileAttributionValue')))
    }

    if (localStorage.getItem('profileGoalValue') !== null || Number(localStorage.getItem('profileGoalValue')) != 0) {
      setGoal(Number(localStorage.getItem('profileGoalValue')))
    }

    if (localStorage.getItem('profileAutotargetValue') !== null || String(localStorage.getItem('profileGoalValue')) != "") {
      setAutotarget(String(localStorage.getItem('profileAutotargetValue')))
    }

    const profileId = localStorage.getItem('profileId')
    setLoading(true)
    setLoadingData(true)

    const queryCampaign = {
      profile_id: profileId,
      agg: agg,
      goal_ids: [goal],
      attribution_ids: [attribution],
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA'),
      //campaign_ids: campaigns,
      //adgroup_ids: adgroups
      display_condition: autotarget
    }
    const queryAdgroup = {
      profile_id: profileId,
      agg: agg,
      goal_ids: [goal],
      attribution_ids: [attribution],
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA'),
      campaign_ids: campaigns,
      //adgroup_ids: adgroups
      display_condition: autotarget
    }
    const queryAdvert = {
      profile_id: profileId,
      agg: agg,
      goal_ids: [goal],
      attribution_ids: [attribution],
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA'),
      campaign_ids: campaigns,
      adgroup_ids: adgroups,
      display_condition: autotarget
    }
    const queryDoe = {
      profile_id: profileId,
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA')
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    if (toogle == 1) {
      fetch(`${API_URL}/relevance/get_adverts_report/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(queryCampaign)
      })
        .then(response => response.json())
        //.then(data => setKpssReportRow(data.data))
        .then((data) => {
          setLoading(false)
          setCampaignReportRow([])
          setCampaignReportRowSelect([])
          setPageCountCampaign(data.total)
          data.results.map(campaignItem => {
            setCampaignReportRow(campaignReportRow => [
              ...campaignReportRow,
              {
                campaign_id: campaignItem.campaign_id,
                rel_title_from_impressions: campaignItem.rel_title_from_impressions,
                rel_title_from_clicks: campaignItem.rel_title_from_clicks,
                rel_text_from_impressions: campaignItem.rel_text_from_impressions,
                rel_text_from_clicks: campaignItem.rel_text_from_clicks,
                campaign_name: campaignItem.campaign_name,
                impressions: campaignItem.impressions,
                rate: campaignItem.rate,
                clicks: campaignItem.clicks,
                conversions: campaignItem.conversions,
                revenue: campaignItem.revenue,
                // rel_title_from_impressions_autotarget: campaignItem.rel_title_from_impressions_autotarget,
                // rel_title_from_clicks_autotarget: campaignItem.rel_title_from_clicks_autotarget,
                // rel_text_from_impressions_autotarget: campaignItem.rel_text_from_impressions_autotarget,
                // rel_text_from_clicks_autotarget: campaignItem.rel_text_from_clicks_autotarget,
                // impressions_autotarget: campaignItem.impressions_autotarget,
                // rate_autotarget: campaignItem.rate_autotarget,
                // clicks_autotarget: campaignItem.clicks_autotarget,
                // conversions_autotarget: campaignItem.conversions_autotarget,
                // revenue_autotarget: campaignItem.revenue_autotarget,
                autotarget_rate_part: campaignItem.autotarget_rate_part,
                value: `№${campaignItem.campaign_id}`,
                label: `№${campaignItem.campaign_id} \n${campaignItem.campaign_name}`,
                campaigns: [`№${campaignItem.campaign_id}`, `${campaignItem.campaign_name}`]
              }
            ])

            setCampaignReportRowSelect(campaignReportRowSelect => [
              ...campaignReportRowSelect,
              {
                campaign_id: campaignItem.campaign_id,
                rel_title_from_impressions: campaignItem.rel_title_from_impressions,
                rel_title_from_clicks: campaignItem.rel_title_from_clicks,
                rel_text_from_impressions: campaignItem.rel_text_from_impressions,
                rel_text_from_clicks: campaignItem.rel_text_from_clicks,
                campaign_name: campaignItem.campaign_name,
                impressions: campaignItem.impressions,
                rate: campaignItem.rate,
                clicks: campaignItem.clicks,
                conversions: campaignItem.conversions,
                revenue: campaignItem.revenue,
                // rel_title_from_impressions_autotarget: campaignItem.rel_title_from_impressions_autotarget,
                // rel_title_from_clicks_autotarget: campaignItem.rel_title_from_clicks_autotarget,
                // rel_text_from_impressions_autotarget: campaignItem.rel_text_from_impressions_autotarget,
                // rel_text_from_clicks_autotarget: campaignItem.rel_text_from_clicks_autotarget,
                // impressions_autotarget: campaignItem.impressions_autotarget,
                // rate_autotarget: campaignItem.rate_autotarget,
                // clicks_autotarget: campaignItem.clicks_autotarget,
                // conversions_autotarget: campaignItem.conversions_autotarget,
                // revenue_autotarget: campaignItem.revenue_autotarget,
                autotarget_rate_part: campaignItem.autotarget_rate_part,
                value: `№${campaignItem.campaign_id}`,
                label: `№${campaignItem.campaign_id} \n${campaignItem.campaign_name}`,
                campaigns: [`№${campaignItem.campaign_id}`, `${campaignItem.campaign_name}`]
              }
            ])
          });

          setSummaryRelTitleFromImpressions(data.summary.rel_title_from_impressions)
          setSummaryRelTitleFromClicks(data.summary.rel_title_from_clicks)
          setSummaryRelTextFromImpressions(data.summary.rel_text_from_impressions)
          setSummaryRelTextFromClicks(data.summary.rel_text_from_clicks)

          setLoadingData(false)
        });
    } else if (toogle == 2) {
      fetch(`${API_URL}/relevance/get_adverts_report/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(queryAdgroup)
      })
        .then(response => response.json())
        //.then(data => setKpssReportRow(data.data))
        .then((data) => {
          setLoading(false)
          setAdgroupReportRow([])
          setAdgroupReportRowSelect([])
          setPageCountCampaign(data.total)
          data.results.map(adgroupItem => {
            setAdgroupReportRow(adgroupReportRow => [
              ...adgroupReportRow,
              {
                adgroup_id: adgroupItem.adgroup_id,
                rel_title_from_impressions: adgroupItem.rel_title_from_impressions,
                rel_title_from_clicks: adgroupItem.rel_title_from_clicks,
                rel_text_from_impressions: adgroupItem.rel_text_from_impressions,
                rel_text_from_clicks: adgroupItem.rel_text_from_clicks,
                group_name: adgroupItem.group_name,
                impressions: adgroupItem.impressions,
                rate: adgroupItem.rate,
                clicks: adgroupItem.clicks,
                conversions: adgroupItem.conversions,
                revenue: adgroupItem.revenue,
                // rel_title_from_impressions_autotarget: adgroupItem.rel_title_from_impressions_autotarget,
                // rel_title_from_clicks_autotarget: adgroupItem.rel_title_from_clicks_autotarget,
                // rel_text_from_impressions_autotarget: adgroupItem.rel_text_from_impressions_autotarget,
                // rel_text_from_clicks_autotarget: adgroupItem.rel_text_from_clicks_autotarget,
                // impressions_autotarget: adgroupItem.impressions_autotarget,
                // rate_autotarget: adgroupItem.rate_autotarget,
                // clicks_autotarget: adgroupItem.clicks_autotarget,
                // conversions_autotarget: adgroupItem.conversions_autotarget,
                // revenue_autotarget: adgroupItem.revenue_autotarget,
                autotarget_rate_part: adgroupItem.autotarget_rate_part,
                value: `№${adgroupItem.adgroup_id}`,
                label: `№${adgroupItem.adgroup_id} \n${adgroupItem.group_name}`,
                groups: [`№${adgroupItem.adgroup_id}`, `${adgroupItem.group_name}`]
              }
            ])

            setAdgroupReportRowSelect(adgroupReportRowSelect => [
              ...adgroupReportRowSelect,
              {
                adgroup_id: adgroupItem.adgroup_id,
                rel_title_from_impressions: adgroupItem.rel_title_from_impressions,
                rel_title_from_clicks: adgroupItem.rel_title_from_clicks,
                rel_text_from_impressions: adgroupItem.rel_text_from_impressions,
                rel_text_from_clicks: adgroupItem.rel_text_from_clicks,
                group_name: adgroupItem.group_name,
                impressions: adgroupItem.impressions,
                rate: adgroupItem.rate,
                clicks: adgroupItem.clicks,
                conversions: adgroupItem.conversions,
                revenue: adgroupItem.revenue,
                // rel_title_from_impressions_autotarget: adgroupItem.rel_title_from_impressions_autotarget,
                // rel_title_from_clicks_autotarget: adgroupItem.rel_title_from_clicks_autotarget,
                // rel_text_from_impressions_autotarget: adgroupItem.rel_text_from_impressions_autotarget,
                // rel_text_from_clicks_autotarget: adgroupItem.rel_text_from_clicks_autotarget,
                // impressions_autotarget: adgroupItem.impressions_autotarget,
                // rate_autotarget: adgroupItem.rate_autotarget,
                // clicks_autotarget: adgroupItem.clicks_autotarget,
                // conversions_autotarget: adgroupItem.conversions_autotarget,
                // revenue_autotarget: adgroupItem.revenue_autotarget,
                autotarget_rate_part: adgroupItem.autotarget_rate_part,
                value: `№${adgroupItem.adgroup_id}`,
                label: `№${adgroupItem.adgroup_id} \n${adgroupItem.group_name}`,
                groups: [`№${adgroupItem.adgroup_id}`, `${adgroupItem.group_name}`]
              }
            ])
          });

          setSummaryRelTitleFromImpressions(data.summary.rel_title_from_impressions)
          setSummaryRelTitleFromClicks(data.summary.rel_title_from_clicks)
          setSummaryRelTextFromImpressions(data.summary.rel_text_from_impressions)
          setSummaryRelTextFromClicks(data.summary.rel_text_from_clicks)

          setLoadingData(false)
        });
    } else {
      fetch(`${API_URL}/relevance/get_adverts_report/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(queryAdvert)
      })
        .then(response => response.json())
        //.then(data => setKpssReportRow(data.data))
        .then((data) => {
          setLoading(false)
          setAdvertReportRow([])
          setAdvertReportRowSelect([])
          setAdvertTextReportRowSelect([])
          setPageCountCampaign(data.total)
          data.results.map(advertItem => {
            setAdvertReportRow(advertReportRow => [
              ...advertReportRow,
              {
                advert_id: advertItem.advert_id,
                rel_title: advertItem.rel_title,
                rel_text: advertItem.rel_text,
                title: advertItem.title,
                title2: advertItem.title2,
                text: advertItem.text,
                query: advertItem.query,
                impressions: advertItem.impressions,
                rate: advertItem.rate,
                clicks: advertItem.clicks,
                conversions: advertItem.conversions,
                revenue: advertItem.revenue,
                // rel_title_from_impressions_autotarget: advertItem.rel_title_from_impressions_autotarget,
                // rel_title_from_clicks_autotarget: advertItem.rel_title_from_clicks_autotarget,
                // rel_text_from_impressions_autotarget: advertItem.rel_text_from_impressions_autotarget,
                // rel_text_from_clicks_autotarget: advertItem.rel_text_from_clicks_autotarget,
                // impressions_autotarget: advertItem.impressions_autotarget,
                // rate_autotarget: advertItem.rate_autotarget,
                // clicks_autotarget: advertItem.clicks_autotarget,
                // conversions_autotarget: advertItem.conversions_autotarget,
                // revenue_autotarget: advertItem.revenue_autotarget,
                is_autotarget: advertItem.is_autotarget,
                value: `№${advertItem.advert_id}`,
                label: `№${advertItem.advert_id} \n${advertItem.title}`,
                title_announcement: [`№${advertItem.advert_id}`, `${advertItem.title}`, `${advertItem.title2}`]
              }
            ])

            setAdvertReportRowSelect(advertReportRowSelect => [
              ...advertReportRowSelect,
              {
                advert_id: advertItem.advert_id,
                rel_title: advertItem.rel_title,
                rel_text: advertItem.rel_text,
                title: advertItem.title,
                title2: advertItem.title2,
                text: advertItem.text,
                query: advertItem.query,
                impressions: advertItem.impressions,
                rate: advertItem.rate,
                clicks: advertItem.clicks,
                conversions: advertItem.conversions,
                revenue: advertItem.revenue,
                // rel_title_from_impressions_autotarget: advertItem.rel_title_from_impressions_autotarget,
                // rel_title_from_clicks_autotarget: advertItem.rel_title_from_clicks_autotarget,
                // rel_text_from_impressions_autotarget: advertItem.rel_text_from_impressions_autotarget,
                // rel_text_from_clicks_autotarget: advertItem.rel_text_from_clicks_autotarget,
                // impressions_autotarget: advertItem.impressions_autotarget,
                // rate_autotarget: advertItem.rate_autotarget,
                // clicks_autotarget: advertItem.clicks_autotarget,
                // conversions_autotarget: advertItem.conversions_autotarget,
                // revenue_autotarget: advertItem.revenue_autotarget,
                is_autotarget: advertItem.is_autotarget,
                value: `№${advertItem.advert_id}`,
                label: `№${advertItem.advert_id} \n${advertItem.title}`,
                title_announcement: [`№${advertItem.advert_id}`, `${advertItem.title}`, `${advertItem.title2}`]
              }
            ])

            setAdvertTextReportRowSelect(advertTextReportRowSelect => [
              ...advertTextReportRowSelect,
              {
                advert_id: advertItem.advert_id,
                rel_title: advertItem.rel_title,
                rel_text: advertItem.rel_text,
                title: advertItem.title,
                title2: advertItem.title2,
                text: advertItem.text,
                query: advertItem.query,
                impressions: advertItem.impressions,
                rate: advertItem.rate,
                clicks: advertItem.clicks,
                conversions: advertItem.conversions,
                revenue: advertItem.revenue,
                // rel_title_from_impressions_autotarget: advertItem.rel_title_from_impressions_autotarget,
                // rel_title_from_clicks_autotarget: advertItem.rel_title_from_clicks_autotarget,
                // rel_text_from_impressions_autotarget: advertItem.rel_text_from_impressions_autotarget,
                // rel_text_from_clicks_autotarget: advertItem.rel_text_from_clicks_autotarget,
                // impressions_autotarget: advertItem.impressions_autotarget,
                // rate_autotarget: advertItem.rate_autotarget,
                // clicks_autotarget: advertItem.clicks_autotarget,
                // conversions_autotarget: advertItem.conversions_autotarget,
                // revenue_autotarget: advertItem.revenue_autotarget,
                is_autotarget: advertItem.is_autotarget,
                value: `№${advertItem.advert_id}`,
                label: `${advertItem.text}`,
                title_announcement: [`№${advertItem.advert_id}`, `${advertItem.title}`, `${advertItem.title2}`]
              }
            ])
          });

          setSummaryRelTitleFromImpressions(data.summary.rel_title_from_impressions)
          setSummaryRelTitleFromClicks(data.summary.rel_title_from_clicks)
          setSummaryRelTextFromImpressions(data.summary.rel_text_from_impressions)
          setSummaryRelTextFromClicks(data.summary.rel_text_from_clicks)

          setLoadingData(false)
        });
    }

    fetch(`${API_URL}/get_attribution_models/`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => {
        setAttributions(json)
      });

    fetch(`${API_URL}/get_goals/?profile_id=${profileId}`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => {
        //setGoals(json)
        setGoals([])
        json.map(goalItem => {
          setGoals(goals => [
            ...goals,
            {
              name: goalItem.name,
              direct_id: goalItem.direct_id,
              id: goalItem.id,
              value: `${goalItem.id}`,
              label: `${goalItem.name}`
            }
          ])
        });
      });

    fetch(`${API_URL}/get_distribution_of_expenses/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(queryDoe)
    })
      .then(response => response.json())
      //.then(data => setKpssReportRow(data.data))
      .then((data) => {
        setLoading(false)
        setDoeReportRow([])
        if (data.status === "ERROR") {
          ///
        } else {
          data.results.map(doeItem => {
            setDoeReportRow(doeReportRow => [
              ...doeReportRow,
              {
                account_allocation: doeItem.account_allocation, //== "AUTOTARGETING" ? "Поиск: Автотаргетинг" : "KEYWORD" ? "Поиск: Ключевые фразы" : "AD_NETWORK" ? "Сеть" : "",
                clicks: doeItem.clicks,
                impressions: doeItem.impressions,
                rate: doeItem.rate,
                clicks_percent: doeItem.clicks_percent,
                impressions_percent: doeItem.impressions_percent,
                rate_percent: doeItem.rate_percent
              }
            ])
          });
        }

        setLoadingData(false)
      });
  }

    // useEffect( () => {
    //   // if (localStorage.getItem('profileGoalValue') != "0") {
    //   //   setGoalForReport(true)
    //   // }
    //
    //   let agg = "campaigns"
    //   if (toogle == 1) {
    //     agg = "campaigns"
    //   } else if (toogle == 2) {
    //     agg = "adgroups"
    //   } else {
    //     agg = "adverts"
    //   }
    //
    //   const profileId = localStorage.getItem('profileId')
    //   setLoadingData(true)
    //
    //   if (localStorage.getItem("profileDateFrom") === null || localStorage.getItem("profileDateTo") === null) {
    //     localStorage.setItem('profileDateFrom', "0000-00-00")
    //     localStorage.setItem('profileDateTo', "0000-00-00")
    //     window.location.reload()
    //   }
    //   if (localStorage.getItem("profileAttributionValue") === null || localStorage.getItem("profileAttributionLabel") === null) {
    //     localStorage.setItem('profileAttributionValue', "8")
    //     localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
    //     window.location.reload()
    //   }
    //   if (localStorage.getItem("profileGoalValue") === null || localStorage.getItem("profileGoalLabel") === null) {
    //     localStorage.setItem('profileGoalValue', "0")
    //     localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
    //     window.location.reload()
    //   }
    //
    //   window.addEventListener('localStorage', () => {
    //     setProfileId(String(localStorage.getItem('profileId')))
    //     //setProfileDateFrom(String(localStorage.getItem('profileDateFrom')))
    //     //setProfileDateTo(String(localStorage.getItem('profileDateTo')))
    //   })
    //
    //   setSelectedOptionAttribution(defaultValueAttribution)
    //   setSelectedOptionGoal(defaultValueGoal)
    //
    //   const headers = {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Token ${currentUser?.api_token}`
    //   }
    //
    //   if (defaultValueGoal.value == "0") {
    //     if (toogle == 1) {
    //       fetch(`${API_URL}/relevance/get_adverts_report/?profile_id=${profileId}&agg=${agg}&goal_ids=${[goal]}&attribution_ids=${[attribution]}`, {
    //         method: 'GET',
    //         headers: headers
    //       })
    //         .then(response => response.json())
    //         .then((data) => {
    //           setCampaignReportRow([])
    //           setCampaignReportRowSelect([])
    //           setPageCountCampaign(data.total)
    //           data.results.map(campaignItem => {
    //             setCampaignReportRow(campaignReportRow => [
    //               ...campaignReportRow,
    //               {
    //                 campaign_id: campaignItem.campaign_id,
    //                 rel_title_from_impressions: campaignItem.rel_title_from_impressions,
    //                 rel_title_from_clicks: campaignItem.rel_title_from_clicks,
    //                 rel_text_from_impressions: campaignItem.rel_text_from_impressions,
    //                 rel_text_from_clicks: campaignItem.rel_text_from_clicks,
    //                 campaign_name: campaignItem.campaign_name,
    //                 impressions: campaignItem.impressions,
    //                 rate: campaignItem.rate,
    //                 clicks: campaignItem.clicks,
    //                 conversions: campaignItem.conversions,
    //                 revenue: campaignItem.revenue,
    //                 value: `№${campaignItem.campaign_id}`,
    //                 label: `№${campaignItem.campaign_id} \n${campaignItem.campaign_name}`,
    //                 campaigns: [`№${campaignItem.campaign_id}`, `${campaignItem.campaign_name}`]
    //               }
    //             ])
    //
    //             setCampaignReportRowSelect(campaignReportRowSelect => [
    //               ...campaignReportRowSelect,
    //               {
    //                 campaign_id: campaignItem.campaign_id,
    //                 rel_title_from_impressions: campaignItem.rel_title_from_impressions,
    //                 rel_title_from_clicks: campaignItem.rel_title_from_clicks,
    //                 rel_text_from_impressions: campaignItem.rel_text_from_impressions,
    //                 rel_text_from_clicks: campaignItem.rel_text_from_clicks,
    //                 campaign_name: campaignItem.campaign_name,
    //                 impressions: campaignItem.impressions,
    //                 rate: campaignItem.rate,
    //                 clicks: campaignItem.clicks,
    //                 conversions: campaignItem.conversions,
    //                 revenue: campaignItem.revenue,
    //                 value: `№${campaignItem.campaign_id}`,
    //                 label: `№${campaignItem.campaign_id} \n${campaignItem.campaign_name}`,
    //                 campaigns: [`№${campaignItem.campaign_id}`, `${campaignItem.campaign_name}`]
    //               }
    //             ])
    //           });
    //
    //           setSummaryRelTitleFromImpressions(data.summary.rel_title_from_impressions)
    //           setSummaryRelTitleFromClicks(data.summary.rel_title_from_clicks)
    //           setSummaryRelTextFromImpressions(data.summary.rel_text_from_impressions)
    //           setSummaryRelTextFromClicks(data.summary.rel_text_from_clicks)
    //
    //           setLoadingData(false)
    //         });
    //     } else if (toogle == 2) {
    //       //fetch(`${API_URL}/relevance/get_adverts_report/?profile_id=${profileId}&agg=${agg}`, {
    //       fetch(`${API_URL}/relevance/get_adverts_report/?profile_id=${profileId}&agg=${agg}&date_from=${startDate.toLocaleDateString('fr-CA')}&date_to=${endDate.toLocaleDateString('fr-CA')}&goal_ids=${[goal]}&attribution_ids=${[attribution]}&campaign_ids=${campaigns}`, {
    //         method: 'GET',
    //         headers: headers
    //       })
    //         .then(response => response.json())
    //         .then((data) => {
    //           setAdgroupReportRow([])
    //           setAdgroupReportRowSelect([])
    //           setPageCountCampaign(data.total)
    //           data.results.map(adgroupItem => {
    //             setAdgroupReportRow(adgroupReportRow => [
    //               ...adgroupReportRow,
    //               {
    //                 adgroup_id: adgroupItem.adgroup_id,
    //                 rel_title_from_impressions: adgroupItem.rel_title_from_impressions,
    //                 rel_title_from_clicks: adgroupItem.rel_title_from_clicks,
    //                 rel_text_from_impressions: adgroupItem.rel_text_from_impressions,
    //                 rel_text_from_clicks: adgroupItem.rel_text_from_clicks,
    //                 group_name: adgroupItem.group_name,
    //                 impressions: adgroupItem.impressions,
    //                 rate: adgroupItem.rate,
    //                 clicks: adgroupItem.clicks,
    //                 conversions: adgroupItem.conversions,
    //                 revenue: adgroupItem.revenue,
    //                 value: `№${adgroupItem.adgroup_id}`,
    //                 label: `№${adgroupItem.adgroup_id} \n${adgroupItem.group_name}`,
    //                 groups: [`№${adgroupItem.adgroup_id}`, `${adgroupItem.group_name}`]
    //               }
    //             ])
    //
    //             setAdgroupReportRowSelect(adgroupReportRowSelect => [
    //               ...adgroupReportRowSelect,
    //               {
    //                 adgroup_id: adgroupItem.adgroup_id,
    //                 rel_title_from_impressions: adgroupItem.rel_title_from_impressions,
    //                 rel_title_from_clicks: adgroupItem.rel_title_from_clicks,
    //                 rel_text_from_impressions: adgroupItem.rel_text_from_impressions,
    //                 rel_text_from_clicks: adgroupItem.rel_text_from_clicks,
    //                 group_name: adgroupItem.group_name,
    //                 impressions: adgroupItem.impressions,
    //                 rate: adgroupItem.rate,
    //                 clicks: adgroupItem.clicks,
    //                 conversions: adgroupItem.conversions,
    //                 revenue: adgroupItem.revenue,
    //                 value: `№${adgroupItem.adgroup_id}`,
    //                 label: `№${adgroupItem.adgroup_id} \n${adgroupItem.group_name}`,
    //                 groups: [`№${adgroupItem.adgroup_id}`, `${adgroupItem.group_name}`]
    //               }
    //             ])
    //           });
    //
    //           setSummaryRelTitleFromImpressions(data.summary.rel_title_from_impressions)
    //           setSummaryRelTitleFromClicks(data.summary.rel_title_from_clicks)
    //           setSummaryRelTextFromImpressions(data.summary.rel_text_from_impressions)
    //           setSummaryRelTextFromClicks(data.summary.rel_text_from_clicks)
    //
    //           setLoadingData(false)
    //         });
    //     } else {
    //       //fetch(`${API_URL}/relevance/get_adverts_report/?profile_id=${profileId}&agg=${agg}`, {
    //       fetch(`${API_URL}/relevance/get_adverts_report/?profile_id=${profileId}&agg=${agg}&date_from=${startDate.toLocaleDateString('fr-CA')}&date_to=${endDate.toLocaleDateString('fr-CA')}&goal_ids=${[goal]}&attribution_ids=${[attribution]}&campaign_ids=${campaigns}&adgroup_ids=${adgroups}`, {
    //         method: 'GET',
    //         headers: headers
    //       })
    //         .then(response => response.json())
    //         .then((data) => {
    //           setAdvertReportRow([])
    //           setAdvertReportRowSelect([])
    //           setAdvertTextReportRowSelect([])
    //           setPageCountCampaign(data.total)
    //           data.results.map(advertItem => {
    //             setAdvertReportRow(advertReportRow => [
    //               ...advertReportRow,
    //               {
    //                 advert_id: advertItem.advert_id,
    //                 rel_title: advertItem.rel_title,
    //                 rel_text: advertItem.rel_text,
    //                 title: advertItem.title,
    //                 title2: advertItem.title2,
    //                 text: advertItem.text,
    //                 query: advertItem.query,
    //                 impressions: advertItem.impressions,
    //                 rate: advertItem.rate,
    //                 clicks: advertItem.clicks,
    //                 conversions: advertItem.conversions,
    //                 revenue: advertItem.revenue,
    //                 value: `№${advertItem.advert_id}`,
    //                 label: `№${advertItem.advert_id} \n${advertItem.title}`,
    //                 title_announcement: [`№${advertItem.advert_id}`, `${advertItem.title}`, `${advertItem.title2}`]
    //               }
    //             ])
    //
    //             setAdvertReportRowSelect(advertReportRowSelect => [
    //               ...advertReportRowSelect,
    //               {
    //                 advert_id: advertItem.advert_id,
    //                 rel_title: advertItem.rel_title,
    //                 rel_text: advertItem.rel_text,
    //                 title: advertItem.title,
    //                 title2: advertItem.title2,
    //                 text: advertItem.text,
    //                 query: advertItem.query,
    //                 impressions: advertItem.impressions,
    //                 rate: advertItem.rate,
    //                 clicks: advertItem.clicks,
    //                 conversions: advertItem.conversions,
    //                 revenue: advertItem.revenue,
    //                 value: `№${advertItem.advert_id}`,
    //                 label: `№${advertItem.advert_id} \n${advertItem.title}`,
    //                 title_announcement: [`№${advertItem.advert_id}`, `${advertItem.title}`, `${advertItem.title2}`]
    //               }
    //             ])
    //
    //             setAdvertTextReportRowSelect(advertTextReportRowSelect => [
    //               ...advertTextReportRowSelect,
    //               {
    //                 advert_id: advertItem.advert_id,
    //                 rel_title: advertItem.rel_title,
    //                 rel_text: advertItem.rel_text,
    //                 title: advertItem.title,
    //                 title2: advertItem.title2,
    //                 text: advertItem.text,
    //                 query: advertItem.query,
    //                 impressions: advertItem.impressions,
    //                 rate: advertItem.rate,
    //                 clicks: advertItem.clicks,
    //                 conversions: advertItem.conversions,
    //                 revenue: advertItem.revenue,
    //                 value: `№${advertItem.advert_id}`,
    //                 label: `${advertItem.text}`,
    //                 title_announcement: [`№${advertItem.advert_id}`, `${advertItem.title}`, `${advertItem.title2}`]
    //               }
    //             ])
    //           });
    //
    //           setSummaryRelTitleFromImpressions(data.summary.rel_title_from_impressions)
    //           setSummaryRelTitleFromClicks(data.summary.rel_title_from_clicks)
    //           setSummaryRelTextFromImpressions(data.summary.rel_text_from_impressions)
    //           setSummaryRelTextFromClicks(data.summary.rel_text_from_clicks)
    //
    //           setLoadingData(false)
    //         });
    //     }
    //   }
    //
    //   fetch(`${API_URL}/get_attribution_models/`, {
    //     method: 'GET',
    //     headers: headers
    //   })
    //     .then((response) => response.json())
    //     .then((json) => {
    //       setAttributions(json)
    //     });
    //
    //   fetch(`${API_URL}/get_goals/?profile_id=${profileId}`, {
    //     method: 'GET',
    //     headers: headers
    //   })
    //     .then((response) => response.json())
    //     .then((json) => {
    //       setGoals(json)
    //     });
    //
    //   fetch(`${API_URL}/get_dataset_period/?profile_id=${profileId}&section=adverts`, {
    //     method: 'GET',
    //     headers: headers
    //   })
    //     .then((response) => response.json())
    //     .then((json) => {
    //       if (json.status == "ERROR") {
    //         ///
    //       } else if (json.result.date_from == null || json.result.date_to == null) {
    //         ///
    //       } else if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
    //         setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
    //         setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
    //         const dateFromResult = new Date(json.result.date_from)
    //         const dateToResult = new Date(json.result.date_to)
    //         setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
    //         setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
    //       } else {
    //         const dateFromResult = new Date(json.result.date_from)
    //         const dateToResult = new Date(json.result.date_to)
    //         const lastDateFromResult = new Date(json.result.last_date_from)
    //         const lastDateToResult = new Date(json.result.last_date_to)
    //         setStartDate(new Date(lastDateFromResult.getFullYear(), lastDateFromResult.getMonth(), lastDateFromResult.getDate()))
    //         setEndDate(new Date(lastDateToResult.getFullYear(), lastDateToResult.getMonth(), lastDateToResult.getDate()))
    //         //setStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
    //         //setEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
    //         setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
    //         setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
    //       }
    //
    //       // Рабочий вариант
    //       if (defaultValueGoal.value == "0") {
    //         setGoalForReport(false)
    //       } else {
    //         setGoalForReport(true)
    //       }
    //     });
    // }, [profileId, toogle])

  const currentCampaignReportRow = filteringCampaign ? campaignReportRow.filter((item) => searchValueCampaign.includes(String(item.campaign_id))) : campaignReportRow
  const currentAdgroupReportRow = filteringAdgroup ? adgroupReportRow.filter((item) => searchValueAdgroup.includes(String(item.adgroup_id))) : adgroupReportRow
  const currentAdvertReportRow1 = filteringAdvert ? advertReportRow.filter((item) => searchValueAdvert.includes(String(item.advert_id))) : advertReportRow

  const currentAdvertReportRow = () => {
      if (filteringAdvert) {
        return advertReportRow.filter((item) => searchValueAdvert.includes(String(item.advert_id)))
      } else if (filteringAdvertText) {
        return advertReportRow.filter((item) => searchValueAdvertText.includes(item.text))
      } else {
        return advertReportRow
      }
  }

  const [selectedCampaign, setSelectedCampaign] = useState({})
  const [selectedAdgroup, setSelectedAdgroup] = useState({})

  const handleOnExport = () => {
    var wb = XLSX.utils.book_new()
    if (toogle == 1) {
      //console.log(campaignReportRow)
      const header = ['Кампания',
        'Среднев. через показы рел. заголовка',
        'Среднев. через клики рел. заголовка',
        'Среднев. через показы рел. текста',
        'Среднев. через клики рел. текста',
        'Показы',
        'Клики',
        'Расход',
        'Конверсии',
        'Доход'];
      // const campaignData = (campaignReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.campaigns
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(campaignReportRow) //, {header}
      XLSX.utils.book_append_sheet(wb, ws, "RACampaign")
      XLSX.writeFile(wb, "RACampaign.xlsx")
    } else if (toogle == 2) {
      //console.log(adgroupReportRow)
      const header = ['Группы',
        'Среднев. через показы рел. заголовка',
        'Среднев. через клики рел. заголовка',
        'Среднев. через показы рел. текста',
        'Среднев. через клики рел. текста',
        'Показы',
        'Клики',
        'Расход',
        'Конверсии',
        'Доход'];
      // const adgroupData = (adgroupReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.groups
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(adgroupReportRow)
      XLSX.utils.book_append_sheet(wb, ws, "RAAdgroup")
      XLSX.writeFile(wb, "RAAdgroup.xlsx")
    } else {
      //console.log(advertReportRow)
      const header = ['Заголовок объявления',
        'Текст объявления',
        'Поисковый запрос',
        'Релевантность заголовка запросу',
        'Релевантность текста запросу',
        'Показы',
        'Клики',
        'Расход',
        'Конверсии',
        'Доход'];
      // const advertData = (advertReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.title_announcement
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(advertReportRow)
      XLSX.utils.book_append_sheet(wb, ws, "RAAdvert")
      XLSX.writeFile(wb, "RAAdvert.xlsx")
    }

    sendMetricGoalVisitParametr('reachGoal', 'table_download_relevant-ads-page', {
      [`${localStorage.getItem('profileName')}`]: {
        [`${window.location.pathname}`]: {
          "table_download_relevant-ads-page": {}
        }
      }
    })
  }

  const handleResetFilters = () => {
    // setToggle(1)
    //
    // setCampaigns([])
    // setSelectedCampaign({})
    // setIsActiveGroups(true)
    // setIsActiveAdvert(true)
    // setAdgroups([]) // Убираем выбранные группы при выборе кампаний
    // setSelectedAdgroup({}) // Убираем выбранные группы при выборе кампаний

    setSelectedOptionSearchCampaign([])
    setSelectedOptionSearchAdgroup([])
    setSelectedOptionSearchAdvert([])
    setSelectedOptionSearchAdvertText([])

    setFilteringCampaign(false)
    setFilteringAdgroup(false)
    setFilteringAdvert(false)
    setFilteringAdvertText(false)

    //setStartDate(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
    //setEndDate(new Date(nowDate.getFullYear(), nowDate.getMonth(), 0))
    //setMinDate(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
    //setMaxDate(nowDate)
    //setAvailableStartDate(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
    //setAvailableEndDate(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1))
    localStorage.setItem('profileDateFrom', "0000-00-00")
    localStorage.setItem('profileDateTo', "0000-00-00")
    setAttribution(8)
    localStorage.setItem('profileAttributionValue', "8")
    localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
    setSelectedOptionAttribution(defaultValueAttribution)
    setGoal(0)
    localStorage.setItem('profileGoalValue', "0")
    localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
    setSelectedOptionGoal(defaultValueGoal)
    setAutotarget("keywords")
    localStorage.setItem('profileAutotargetValue', "keywords")
    localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
    setSelectedOptionAutotarget(defaultValueAutotarget)

    setButtonResetFilters(true)

    fetchDate()
  }

  const checkboxColumn = {
    renderCheckbox: (checkboxProps, cellProps) => {
      const { onChange, checked } = checkboxProps
      //console.log(cellProps.data)
      //console.log(checkboxProps)

      if (checkboxProps.checked == true) {
        return (
          <CheckBox
            checked={true}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }

      if (checkboxProps.checked == false && campaigns.length < 1) { // || kpssCampaigns.length > 1
        return (
          <CheckBox
            checked={false}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }

      if (checkboxProps.checked == false && campaigns.length > 1) {
        return (
          <CheckBox
            checked={false}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }

      if (checkboxProps.checked == null && campaigns.length > 1) {
        return (
          <CheckBox
            checked={true}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }

      if (checkboxProps.checked == true && campaigns.length < 1) {
        return (
          <CheckBox
            //checked={false}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      } else {
        return (
          <CheckBox
            disabled={true}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }
    }
  }

  const formatOptionLabelGoal = ({ value, label }) => (
    <div style={{ display: "flex" }}>
      <div>{label}</div>
      <div style={{ marginLeft: "10px", color: "#ccc" }}>
        {value != 0 ? value : ''}
      </div>
    </div>
  )

  const editedAdvertReportRowSelect = Array.from(new Map(advertReportRowSelect.map(item => [item.advert_id, item])).values())
  const editedAdvertTextReportRowSelect = Array.from(new Map(advertTextReportRowSelect.map(item => [item.advert_id, item])).values())

  const [defaultFilterValueCampaign, setDefaultFilterValueCampaign] = useState([
    { name: 'rel_title_from_impressions', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'rel_title_from_clicks', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null }
  ])

  const onFilterValueChangeCampaign = useCallback((filterValue) => {
    setDefaultFilterValueCampaign(filterValue)
  }, [])

  const [defaultFilterValueAdgroup, setDefaultFilterValueAdgroup] = useState([
    { name: 'rel_title_from_impressions', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'rel_title_from_clicks', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null }
  ])

  const onFilterValueChangeAdgroup = useCallback((filterValue) => {
    setDefaultFilterValueAdgroup(filterValue)
  }, [])

  const [defaultFilterValueAdvert, setDefaultFilterValueAdvert] = useState([
    { name: 'rel_title', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null }
  ])

  const onFilterValueChangeAdvert = useCallback((filterValue) => {
    setDefaultFilterValueAdvert(filterValue)
  }, [])

  return (
    <div>
      {/* <div>{JSON.stringify(kpssReportRow)}</div> */}
        <div className='card-container'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex flex-column'>
            <ol className="breadcrumb breadcrumb-dot text-muted fs-7 fw-bold mb-5">
                <li className="breadcrumb-item pe-3">

                <a href="#" className="pe-1">
                    <i className="fa-solid fa-house text-muted fs-4" />
                </a>
                </li>
                <li className="breadcrumb-item px-0 text-muted">Релевантность объявлений</li>
            </ol>
            <div className='row d-flex justify-content-between'>
                <div className='col'>
                    <h2 className='card-title align-items-start flex-column mb-3'>
                        <span className='card-label fw-bold fs-3'>Отчет о релевантности объявлений <span className='text-muted'>({pageCountCampaign})</span></span>
                    </h2>
                    <h5 className='mb-2 text-muted'>Процент релевантных объявлений</h5>
                </div>
                <div className='col d-flex justify-content-end'>
                  <button className='btn btn-light-primary fw-semibold' onClick={handleOnExport}>Выгрузить в XLSX
                    <KTIcon iconName='arrow-down-right' className='fs-4  ms-1' />
                  </button>

                  <button
                    className={`btn fw-semibold btn-light-primary ${buttonResetFilters ? "btn-reset-disabled" : "btn-reset"}`}
                    onClick={handleResetFilters}
                    disabled={buttonResetFilters}
                  >
                    Сбросить фильтры
                  </button>
                </div>
            </div>

            <ul className="nav nav-tabs nav-line-tabs mb-2 mt-5 fs-7 border-bottom-0">
                <li className="nav-item">
                    <a
                      className={toogle === 1 ? "nav-link fw-bold active" : "nav-link fw-bold"}
                      //data-bs-toggle="tab"
                      //href="#kt_tab_pane_1"
                      href="#"
                      onClick={()=>updateToggle(1)}
                    >
                      Кампании
                    </a>
                </li>
                <li className="nav-item">
                    <a
                      className={toogle === 2 ? `nav-link fw-bold active` : `nav-link fw-bold ${isActiveGroups ? "disabled" : ""}`}
                      //data-bs-toggle="tab"
                      //href="#kt_tab_pane_2"
                      href="#"
                      onClick={()=>updateToggle(2)}
                    >
                      Группы
                    </a>
                </li>
                <li className="nav-item">
                    <a
                      className={toogle === 3 ? `nav-link fw-bold active` : `nav-link fw-bold ${isActiveAdvert ? "disabled" : ""}`}
                      //data-bs-toggle="tab"
                      //href="#kt_tab_pane_3"
                      href="#"
                      onClick={()=>updateToggle(3)}
                    >
                      Объявления
                    </a>
                </li>
            </ul>
        </div>
        {/* end::Header */}


        <div className="tab-content" id="myTabContent">
          <div className={toogle === 1 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_1" role="tabpanel">
                {/* begin::Body */}
                <div className='card-body py-3'>
                  <div className='row'>
                    <div className='col-7 mb-md-5 mb-xl-6'>
                    </div>
                    <div className='col-5 mb-md-5 mb-xl-6 text-period'>
                      Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""} | {autotargetDateFrom.toLocaleDateString() == new Date(0).toLocaleDateString() ? "Автотаргетинг в НГРАМ отсутствует" : `Автотаргетинг с ${autotargetDateFrom.toLocaleDateString()}`}
                    </div>
                  </div>
                    <div className='row'>
                        <div className='col-7 mb-md-5 mb-xl-6'>
                            <div className="input-group mb-3">
                                            <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                                                <i className="bi bi-search fs-4 fw-bold" />
                                            </span>
                                <Select
                                  className='react-select-container form-control border-start-0 p-0 my-search'
                                  classNamePrefix="react-select"
                                  options={campaignReportRowSelect}
                                  placeholder="Поиск по названию кампании"
                                  defaultValue={selectedOptionSearchCampaign}
                                  value={selectedOptionSearchCampaign}
                                  onChange={handleSearchCampaign}
                                  isMulti
                                  onFocus={() => setSelectFocus(2)}
                                  onBlur={onBlur}
                                />
                            </div>
                        </div>
                        <div className='col-5 mb-md-5 mb-xl-6'>
                            <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                                    <i className="bi bi-calendar4-event fs-7 fw-bold" />
                                </span>

                                <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                                //locale={locale}
                                            locale="ru"
                                            selected={startDate}
                                            onChange={handleChangeDate}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            selectsRange
                                            startDate={startDate}
                                            endDate={endDate}
                                //dateFormat="yyyy-MM-dd"
                                            dateFormat="d MMMM yyyy"
                                />

                            </div>
                        </div>



                    </div>
                    <div className='row mb-5'>
                        <div className='col-4 mb-md-5 mb-xl-6'>
                            <h6>Атрибуция
                              <Tooltip text="По умолчанию используется Автоматическая атрибуция">
                                <i className="las la-info-circle ms-2 fs-2" />
                              </Tooltip>
                            </h6>
                            <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                        </div>
                        <div className='col-4 mb-md-5 mb-xl-6'>
                            <h6>Данные по целям
                              <Tooltip text="По умолчанию для выбора доступны цели из настроек РК">
                                <i className="las la-info-circle ms-2 fs-2" />
                              </Tooltip>
                            </h6>
                            <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon"  placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                        </div>
                        <div className='col-4 mb-md-5 mb-xl-6'>
                          <h6>Условия показа
                            <Tooltip text="По умолчанию используется 'Ключевые фразы'">
                              <i className="las la-info-circle ms-2 fs-2" />
                            </Tooltip>
                          </h6>
                          <Select options={autotargets} className="form-select react-select-container p-0 my-select-autotarget" classNamePrefix="no-icon" placeholder="Выберите условие показа" value={selectedOptionAutotarget} defaultValue={defaultValueAutotarget} onChange={handleAutotarget} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                        </div>
                    </div>


                    <div className='row mb-10'>
                      {/*{checkUser && (*/}
                      <div className='col-6'>
                        <div className='bg-light'>
                          <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>Доля по типу трафика</span>
                                </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>%Показов</span>
                                </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>%Кликов</span>
                                </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>%Расходов</span>
                                </div>
                              </th>
                            </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                            {doeReportRow.map((value, key) => {
                              return (
                                <tr key={key} data-key={key}>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-between'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*Поиск: Автотаргетинг*/}
                                        {/*{value.account_allocation}*/}
                                        {(() => {
                                          if (value.account_allocation == 'AD_NETWORK') {
                                            return (
                                              "Сеть"
                                            )
                                          } else if (value.account_allocation == 'AUTOTARGETING') {
                                            return (
                                              "Поиск: Автотаргетинг"
                                            )
                                          } else if (value.account_allocation == 'FEED_FILTER') {
                                            return (
                                              "Поиск: Фид"
                                            )
                                          } else if (value.account_allocation == 'RETARGETING') {
                                            return (
                                              "Поиск: Аудитория"
                                            )
                                          } else if (value.account_allocation == 'WEBPAGE_FILTER') {
                                            return (
                                              "Поиск: Содержание URL"
                                            )
                                          } else if (value.account_allocation == 'OFFER_RETARGETING') {
                                            return (
                                              "Поиск: Офферный ретаргетинг"
                                            )
                                          } else if (value.account_allocation == 'KEYWORD') {
                                            return (
                                              "Поиск: Ключевые фразы"
                                            )
                                          } else {
                                            return (
                                              ""
                                            )
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*1,16%*/}
                                        {value.impressions_percent}%
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*1,16%*/}
                                        {value.clicks_percent}%
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*1,16%*/}
                                        {value.rate_percent}%
                                      </div>
                                    </div>
                                  </td>
                                </tr>)
                            })}
                            </tbody>
                            {/* end::Table body */}
                          </table>
                        </div>
                      </div>
                      {/*)}*/}
                      <div className='col-6'>
                      <div className='bg-light'>
                      <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                          {/* begin::Table head */}
                          <thead>
                          <tr className='fw-bold text-muted'>
                              <th className='min-w-160px text-nowrap'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                      <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. заголовка</span>
                                  </div>
                              </th>
                              <th className='min-w-160px'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                      <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. заголовка</span>
                                  </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                      <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. текста</span>
                                  </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                      <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. текста</span>
                                  </div>
                              </th>
                          </tr>
                          </thead>
                          {/* end::Table head */}
                          {/* begin::Table body */}
                          <tbody>
                          <tr>
                              <td>
                                  <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                      {/*55,94%*/}
                                    {summaryRelTitleFromImpressions}%
                                  </div>
                              </td>
                              <td>
                                  <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                      {/*60,97%*/}
                                    {summaryRelTitleFromClicks}%
                                  </div>
                              </td>
                              <td>
                                  <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                      {/*26,97%*/}
                                    {summaryRelTextFromImpressions}%
                                  </div>
                              </td>
                              <td>
                                  <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                      {/*32,08%*/}
                                    {summaryRelTextFromClicks}%
                                  </div>
                              </td>
                          </tr>


                          </tbody>
                          {/* end::Table body */}
                      </table>

                      </div>

                      </div>
                    </div>
                    {/* begin::Table container */}
                    <div className=''>
                    {/* begin::Table */}
                    <ReactDataGrid
                        ///checkboxColumn={true}
                        checkboxColumn={checkboxColumn} // Object for disabled checkbox
                        checkboxOnlyRowSelect={true}
                        className='table__data-grid'
                        idProperty="campaign_id"
                        showZebraRows={false}
                        style={gridStyle}
                        activateRowOnFocus={activateRowOnFocus}
                        columns={columnsCampaign}
                        dataSource={currentCampaignReportRow}
                        pagination={true}
                        //limit={10}
                        defaultLimit={10}
                        rowHeight={null}
                        minRowHeight={63}
                        onSelectionChange={onSelectionCampaign}
                        selected={selectedCampaign}
                        editable={true}
                        loading={loadingData}
                        loadingText={pleaseWait}
                        enableFiltering={true}
                        defaultFilterValue={defaultFilterValueCampaign}
                        onFilterValueChange={onFilterValueChangeCampaign}
                        i18n={translateRDG}
                        enableColumnAutosize={false}
                    />
                    {/* end::Table */}
                    </div>
                    {/* end::Table container */}

                </div>
                {/* begin::Body */}

            </div>
            <div className={toogle === 2 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_2" role="tabpanel">
                {/* begin::Body */}
                <div className='card-body py-3'>
                  <div className='row'>
                    <div className='col-7 mb-md-5 mb-xl-6'>
                    </div>
                    <div className='col-5 mb-md-5 mb-xl-6 text-period'>
                      Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""} | {autotargetDateFrom.toLocaleDateString() == new Date(0).toLocaleDateString() ? "Автотаргетинг в НГРАМ отсутствует" : `Автотаргетинг с ${autotargetDateFrom.toLocaleDateString()}`}
                    </div>
                  </div>
                    <div className='row'>
                        <div className='col-7 mb-md-5 mb-xl-6'>
                            <div className="input-group mb-3">
                                            <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                                                <i className="bi bi-search fs-4 fw-bold" />
                                            </span>
                                <Select
                                  className='react-select-container form-control border-start-0 p-0 my-search'
                                  classNamePrefix="react-select"
                                  options={adgroupReportRowSelect}
                                  placeholder="Поиск по группам"
                                  defaultValue={selectedOptionSearchAdgroup}
                                  value={selectedOptionSearchAdgroup}
                                  onChange={handleSearchAdgroup}
                                  isMulti
                                  onFocus={() => setSelectFocus(2)}
                                  onBlur={onBlur}
                                />
                            </div>
                        </div>
                        <div className='col-5 mb-md-5 mb-xl-6'>
                            <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                                    <i className="bi bi-calendar4-event fs-7 fw-bold" />
                                </span>

                                <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                                //locale={locale}
                                            locale="ru"
                                            selected={startDate}
                                            onChange={handleChangeDate}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            selectsRange
                                            startDate={startDate}
                                            endDate={endDate}
                                //dateFormat="yyyy-MM-dd"
                                            dateFormat="d MMMM yyyy"
                                />

                            </div>
                        </div>



                    </div>
                    <div className='row mb-5'>
                        <div className='col-4 mb-md-5 mb-xl-6'>
                            <h6>Атрибуция
                              <Tooltip text="По умолчанию используется Автоматическая атрибуция">
                                <i className="las la-info-circle ms-2 fs-2" />
                              </Tooltip>
                            </h6>
                          <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                        </div>
                        <div className='col-4 mb-md-5 mb-xl-6'>
                            <h6>Данные по целям
                              <Tooltip text="По умолчанию для выбора доступны цели из настроек РК">
                                <i className="las la-info-circle ms-2 fs-2" />
                              </Tooltip>
                            </h6>
                          <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon" placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                        </div>
                        <div className='col-4 mb-md-5 mb-xl-6'>
                          <h6>Условия показа
                            <Tooltip text="По умолчанию используется 'Ключевые фразы'">
                              <i className="las la-info-circle ms-2 fs-2" />
                            </Tooltip>
                          </h6>
                          <Select options={autotargets} className="form-select react-select-container p-0 my-select-autotarget" classNamePrefix="no-icon" placeholder="Выберите условие показа" value={selectedOptionAutotarget} defaultValue={defaultValueAutotarget} onChange={handleAutotarget} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                        </div>
                    </div>


                    <div className='row mb-10'>
                      {/*{checkUser && (*/}
                      <div className='col-6'>
                        <div className='bg-light'>
                          <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>Доля по типу трафика</span>
                                </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>%Показов</span>
                                </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>%Кликов</span>
                                </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>%Расходов</span>
                                </div>
                              </th>
                            </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                            {doeReportRow.map((value, key) => {
                              return (
                                <tr key={key} data-key={key}>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-between'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*Поиск: Автотаргетинг*/}
                                        {/*{value.account_allocation}*/}
                                        {(() => {
                                          if (value.account_allocation == 'AD_NETWORK') {
                                            return (
                                              "Сеть"
                                            )
                                          } else if (value.account_allocation == 'AUTOTARGETING') {
                                            return (
                                              "Поиск: Автотаргетинг"
                                            )
                                          } else if (value.account_allocation == 'FEED_FILTER') {
                                            return (
                                              "Поиск: Фид"
                                            )
                                          } else if (value.account_allocation == 'RETARGETING') {
                                            return (
                                              "Поиск: Аудитория"
                                            )
                                          } else if (value.account_allocation == 'WEBPAGE_FILTER') {
                                            return (
                                              "Поиск: Содержание URL"
                                            )
                                          } else if (value.account_allocation == 'OFFER_RETARGETING') {
                                            return (
                                              "Поиск: Офферный ретаргетинг"
                                            )
                                          } else if (value.account_allocation == 'KEYWORD') {
                                            return (
                                              "Поиск: Ключевые фразы"
                                            )
                                          } else {
                                            return (
                                              ""
                                            )
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*1,16%*/}
                                        {value.impressions_percent}%
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*1,16%*/}
                                        {value.clicks_percent}%
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*1,16%*/}
                                        {value.rate_percent}%
                                      </div>
                                    </div>
                                  </td>
                                </tr>)
                            })}
                            </tbody>
                            {/* end::Table body */}
                          </table>
                        </div>
                      </div>
                      {/*)}*/}
                      <div className='col-6'>
                      <div className='bg-light'>
                      <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                          {/* begin::Table head */}
                          <thead>
                          <tr className='fw-bold text-muted'>
                              <th className='min-w-160px text-nowrap'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                      <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. заголовка</span>
                                  </div>
                              </th>
                              <th className='min-w-160px'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                      <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. заголовка</span>
                                  </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                      <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. текста</span>
                                  </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                      <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. текста</span>
                                  </div>
                              </th>
                          </tr>
                          </thead>
                          {/* end::Table head */}
                          {/* begin::Table body */}
                          <tbody>
                          <tr>
                              <td>
                                  <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                      {/*55,94%*/}
                                    {summaryRelTitleFromImpressions}%
                                  </div>
                              </td>
                              <td>
                                  <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                      {/*60,97%*/}
                                    {summaryRelTitleFromClicks}%
                                  </div>
                              </td>
                              <td>
                                  <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                      {/*26,97%*/}
                                    {summaryRelTextFromImpressions}%
                                  </div>
                              </td>
                              <td>
                                  <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                      {/*32,08%*/}
                                    {summaryRelTextFromClicks}%
                                  </div>
                              </td>
                          </tr>


                          </tbody>
                          {/* end::Table body */}
                      </table>

                      </div>

                      </div>
                    </div>
                    {/* begin::Table container */}
                    <div className=''>
                    {/* begin::Table */}
                      <ReactDataGrid
                        checkboxColumn={true}
                        checkboxOnlyRowSelect={true}
                        className='table__data-grid'
                        idProperty="adgroup_id"
                        showZebraRows={false}
                        style={gridStyle}
                        activateRowOnFocus={activateRowOnFocus}
                        columns={columnsAdgroup}
                        dataSource={currentAdgroupReportRow}
                        pagination={true}
                        //limit={10}
                        defaultLimit={10}
                        rowHeight={null}
                        minRowHeight={63}
                        onSelectionChange={onSelectionAdgroup}
                        selected={selectedAdgroup}
                        editable={true}
                        loading={loadingData}
                        loadingText={pleaseWait}
                        enableFiltering={true}
                        defaultFilterValue={defaultFilterValueAdgroup}
                        onFilterValueChange={onFilterValueChangeAdgroup}
                        i18n={translateRDG}
                        enableColumnAutosize={false}
                      />
                    {/* end::Table */}
                    </div>
                    {/* end::Table container */}

                </div>
                {/* begin::Body */}

            </div>
            <div className={toogle === 3 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_3" role="tabpanel">
                {/* begin::Body */}
                <div className='card-body py-3'>
                <div className='row'>
                  <div className='col-4 mb-md-5 mb-xl-6'>

                  </div>
                  <div className='col-3 mb-md-5 mb-xl-6'>

                  </div>
                  <div className='col-5 mb-md-5 mb-xl-6 text-period'>
                    Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""} | {autotargetDateFrom.toLocaleDateString() == new Date(0).toLocaleDateString() ? "Автотаргетинг в НГРАМ отсутствует" : `Автотаргетинг с ${autotargetDateFrom.toLocaleDateString()}`}
                  </div>
                </div>
                <div className='row'>
                        <div className='col-4 mb-md-5 mb-xl-6'>
                            <div className="input-group">
                                <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                                    <i className="bi bi-search fs-4 fw-bold" />
                                </span>
                                <Select
                                  className='react-select-container form-control border-start-0 p-0 my-search'
                                  classNamePrefix="react-select"
                                  options={editedAdvertReportRowSelect}
                                  placeholder="Поиск по заголовкам"
                                  defaultValue={selectedOptionSearchAdvert}
                                  value={selectedOptionSearchAdvert}
                                  onChange={handleSearchAdvert}
                                  isMulti
                                  onFocus={() => setSelectFocus(2)}
                                  onBlur={onBlur}
                                />
                            </div>
                        </div>
                        <div className='col-3 mb-md-5 mb-xl-6'>
                            <div className="input-group">
                                <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                                    <i className="bi bi-search fs-4 fw-bold" />
                                </span>
                                <Select
                                  className='react-select-container form-control border-start-0 p-0 my-search'
                                  classNamePrefix="react-select"
                                  options={editedAdvertTextReportRowSelect}
                                  placeholder="Поиск по тексту"
                                  defaultValue={selectedOptionSearchAdvertText}
                                  value={selectedOptionSearchAdvertText}
                                  onChange={handleSearchAdvertText}
                                  isMulti
                                  onFocus={() => setSelectFocus(2)}
                                  onBlur={onBlur}
                                />
                            </div>
                        </div>
                        <div className='col-5 mb-md-5 mb-xl-6'>
                          <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                                  <i className="bi bi-calendar4-event fs-7 fw-bold" />
                                </span>

                            <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                              //locale={locale}
                                        locale="ru"
                                        selected={startDate}
                                        onChange={handleChangeDate}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        selectsRange
                                        startDate={startDate}
                                        endDate={endDate}
                              //dateFormat="yyyy-MM-dd"
                                        dateFormat="d MMMM yyyy"
                            />

                          </div>
                        </div>
                    </div>
                    <div className='row'>
                        {/*<div className='col-4 mb-md-5 mb-xl-6'>*/}
                        {/*    <h6>Период*/}
                        {/*    </h6>*/}
                        {/*    <div className="input-group">*/}
                        {/*    <span className="input-group-text bg-white border-end-0" id="basic-addon2">*/}
                        {/*            <i className="bi bi-calendar4-event fs-7 fw-bold" />*/}
                        {/*        </span>*/}
                        {/*        <Select options={options} className='react-select-container form-control border-start-0 p-0 my-select' classNamePrefix="react-select" placeholder="16 декабря 2023г ???" onChange={handleSearchCampaign}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                      <div className='col-4 mb-md-5 mb-xl-6'>
                        <h6>Атрибуция
                          <Tooltip text="По умолчанию используется Автоматическая атрибуция">
                            <i className="las la-info-circle ms-2 fs-2" />
                          </Tooltip>
                        </h6>
                        <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                      </div>
                      <div className='col-4 mb-md-5 mb-xl-6'>
                        <h6>Данные по целям
                          <Tooltip text="По умолчанию для выбора доступны цели из настроек РК">
                            <i className="las la-info-circle ms-2 fs-2" />
                          </Tooltip>
                        </h6>
                        <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon" placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                      </div>
                      <div className='col-4 mb-md-5 mb-xl-6'>
                        <h6>Условия показа
                          <Tooltip text="По умолчанию используется 'Ключевые фразы'">
                            <i className="las la-info-circle ms-2 fs-2" />
                          </Tooltip>
                        </h6>
                        <Select options={autotargets} className="form-select react-select-container p-0 my-select-autotarget" classNamePrefix="no-icon" placeholder="Выберите условие показа" value={selectedOptionAutotarget} defaultValue={defaultValueAutotarget} onChange={handleAutotarget} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                      </div>
                    </div>

                    <div className='row mb-10'>
                      {/*{checkUser && (*/}
                      <div className='col-6'>
                        <div className='bg-light'>
                          <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>Доля по типу трафика</span>
                                </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>%Показов</span>
                                </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>%Кликов</span>
                                </div>
                              </th>
                              <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                <div className='d-flex align-items-center justify-content-end'>
                                  <span className='text-nowrap text-muted fw-semibold fs-7'>%Расходов</span>
                                </div>
                              </th>
                            </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                            {doeReportRow.map((value, key) => {
                              return (
                                <tr key={key} data-key={key}>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-between'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*Поиск: Автотаргетинг*/}
                                        {/*{value.account_allocation}*/}
                                        {(() => {
                                          if (value.account_allocation == 'AD_NETWORK') {
                                            return (
                                              "Сеть"
                                            )
                                          } else if (value.account_allocation == 'AUTOTARGETING') {
                                            return (
                                              "Поиск: Автотаргетинг"
                                            )
                                          } else if (value.account_allocation == 'FEED_FILTER') {
                                            return (
                                              "Поиск: Фид"
                                            )
                                          } else if (value.account_allocation == 'RETARGETING') {
                                            return (
                                              "Поиск: Аудитория"
                                            )
                                          } else if (value.account_allocation == 'WEBPAGE_FILTER') {
                                            return (
                                              "Поиск: Содержание URL"
                                            )
                                          } else if (value.account_allocation == 'OFFER_RETARGETING') {
                                            return (
                                              "Поиск: Офферный ретаргетинг"
                                            )
                                          } else if (value.account_allocation == 'KEYWORD') {
                                            return (
                                              "Поиск: Ключевые фразы"
                                            )
                                          } else {
                                            return (
                                              ""
                                            )
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*1,16%*/}
                                        {value.impressions_percent}%
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*1,16%*/}
                                        {value.clicks_percent}%
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*1,16%*/}
                                        {value.rate_percent}%
                                      </div>
                                    </div>
                                  </td>
                                </tr>)
                            })}
                            </tbody>
                            {/* end::Table body */}
                          </table>
                        </div>
                      </div>
                      {/*)}*/}
                      <div className='col-6'>
                        <div className='bg-light'>
                        <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-160px text-nowrap'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. заголовка</span>
                                    </div>
                                </th>
                                <th className='min-w-160px'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. заголовка</span>
                                    </div>
                                </th>
                                <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через показы<br /> рел. текста</span>
                                    </div>
                                </th>
                                <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='text-nowrap text-muted fw-semibold fs-7'>Среднев. через клики<br /> рел. текста</span>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                            <tr>
                                <td>
                                    <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*55,94%*/}
                                      {summaryRelTitleFromImpressions}%
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*60,97%*/}
                                      {summaryRelTitleFromClicks}%
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*26,97%*/}
                                      {summaryRelTextFromImpressions}%
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                        {/*32,08%*/}
                                      {summaryRelTextFromClicks}%
                                    </div>
                                </td>
                            </tr>


                            </tbody>
                            {/* end::Table body */}
                        </table>

                        </div>

                        </div>
                    </div>
                    {/* begin::Table container */}
                    <div className=''>
                    {/* begin::Table */}
                      <ReactDataGrid
                        className='table__data-grid'
                        idProperty="id"
                        showZebraRows={false}
                        style={gridStyle}
                        activateRowOnFocus={activateRowOnFocus}
                        columns={columnsAdvert}
                        dataSource={currentAdvertReportRow()}
                        pagination={true}
                        //limit={10}
                        defaultLimit={10}
                        rowHeight={null}
                        minRowHeight={63}
                        editable={true}
                        loading={loadingData}
                        loadingText={pleaseWait}
                        enableFiltering={true}
                        defaultFilterValue={defaultFilterValueAdvert}
                        onFilterValueChange={onFilterValueChangeAdvert}
                        i18n={translateRDG}
                        enableColumnAutosize={false}
                      />
                    {/* end::Table */}
                    </div>
                    {/* end::Table container */}

                </div>
                {/* begin::Body */}

            </div>
        </div>




        </div>






        <div className="modal fade" tabIndex={-1} id="kt_modal_3">
            <div className="modal-dialog alert p-0">
                <div className="modal-content p-10">
                <h3 className="modal-title text-center fw-bold text-middle mb-4">Расчет ставок может занять до 5 минут</h3>
                <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
                    <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3" />

                    <div className="d-flex flex-column align-items-center">
                        <h5 className="text-center fw-semibold">Пожалуйста, не закрывайте окно N-Gram Bidder</h5>
                    </div>
                </div>

                <button
                    type="button"
                    className="btn btn-primary w-100"
                    data-bs-dismiss="modal"
                    >
                    Ок
                </button>
                </div>
            </div>
        </div>


        <div className="modal fade" tabIndex={-1} id="kt_modal_6">
            <div className="modal-dialog alert p-0">
                <div className="modal-content p-10">
                <h3 className="modal-title text-center fw-bold text-middle mb-4">Ставки установлены</h3>
                <div className="alert alert-warning d-flex flex-column align-items-center p-5 mb-5">
                    <i className="bi bi-exclamation-octagon text-warning fs-3x mb-3" />

                    <div className="d-flex flex-column align-items-center">
                        <h5 className="text-center fw-semibold">Не забудьте отправить изменения в Яндекс.Директ</h5>
                    </div>
                </div>

                <button
                    type="button"
                    className="btn btn-primary w-100"
                    data-bs-dismiss="modal"
                    >
                    Хорошо
                </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export {TablesWidget18}
