import {FC} from 'react'
// import ChartsWidget3 from "./_metronic/partials/widgets/charts/ChartsWidget3";
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {PageTitle} from '../../_metronic/layout/core'
import {
    ChartsWidget3,
    MixedWidget7,
    ChartsWidget9,
  } from '../../_metronic/partials/widgets'
  

const MyPage: FC = () => {
    return (
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
            <div className='col-9'>
            <ChartsWidget3  className='h-100'/>
            <div className='row'>
                <div className='col-12'>
                    
                </div>
            </div>
            </div>
            <div className='col-3 '>
                <MixedWidget7
                chartColor='primary'
                chartHeight=''
                className='h-100' 
                />
            </div>
           
            <div>
            <ChartsWidget9  className='h-100'/>
            </div>
        </div>
    )
}

export {MyPage}
