/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {KTIcon, toAbsoluteUrl, KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'
import {Tooltip} from '../../../layout/components/tooltip/'
import { ScrollContainer } from 'react-indiana-drag-scroll'
import 'react-indiana-drag-scroll/dist/style.css'
import  DatePicker, { registerLocale } from 'react-datepicker'
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css"
import axios from 'axios'
import ru from "date-fns/locale/ru"; // the locale you want
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import CheckBox from '@inovua/reactdatagrid-community/packages/CheckBox'
import {useAuth} from '../../../../app/modules/auth'
import * as XLSX from 'xlsx'
import {addDays} from 'date-fns'
import {addToDataLayer, sendMetricGoalVisitParametr, sendMetricVisitParametr} from '../../../../utils/metrics'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import {translateRDG} from '../../../../translations/ru/translation'
import filter from '@inovua/reactdatagrid-community/filter'

registerLocale("ru", ru); // register it with the name you want

type Props = {
  className: string
}

interface KpssCampaignDataItem {
  campaign_id: number;
  daily_budget: number;
  daily_budget_period: string;
  campaign_name: string;
  kpss_from_impressions: number;
  kpss_from_clicks: number;
  bidding_strategy: string;
  ineffective_rate: number;
  state: string;
  impressions: number;
  rate: number;
  clicks: number;
  conversions: number;
  revenue: number;
  autotarget_rate_part: number;
  // impressions__autotarget: number;
  // rate__autotarget: number;
  // clicks__autotarget: number;
  // conversions__autotarget: number;
  // revenue__autotarget: number;
  recommendation: string;
  value: string;
  label: string;
  title: string[];
  strategy: string[];
  bidding: string;
  biddingId: string[];
}

interface KpssAdgroupDataItem {
  adgroup_id: number;
  campaign_name: string;
  campaign_id: number;
  group_name: string;
  kpss_from_impressions: number;
  kpss_from_clicks: number;
  ineffective_rate: number;
  impressions: number;
  rate: number;
  clicks: number;
  conversions: number;
  revenue: number;
  autotarget_rate_part: number;
  // impressions__autotarget: number;
  // rate__autotarget: number;
  // clicks__autotarget: number;
  // conversions__autotarget: number;
  // revenue__autotarget: number;
  recommendation: string;
  value: string;
  label: string;
  campaign_group: string[];
  campaign: string[];
  biddingId: string[];
}

interface KpssPhrasesDataItem {
  key: string;
  campaign_name: string;
  campaign_id: number;
  group_name: string;
  adgroup_id: number;
  kpss: number;
  key_id: number;
  query: string;
  impressions: number;
  rate: number;
  clicks: number;
  conversions: number;
  revenue: number;
  is_autotarget: boolean;
  // impressions__autotarget: number;
  // rate__autotarget: number;
  // clicks__autotarget: number;
  // conversions__autotarget: number;
  // revenue__autotarget: number;
  value: string;
  label: string;
  key_phrase: string[];
  ad_set: string[];
  campaign: string[];
}

interface GoalDataItem {
  name: string;
  direct_id: number;
  id: number;
  value: string;
  label: string;
}

interface DOEDataItem {
  account_allocation: string;
  clicks: number;
  impressions: number;
  rate: number;
  clicks_percent: number;
  impressions_percent: number;
  rate_percent: number;
}

const TablesWidget9: React.FC<Props> = ({className}) => {

  const API_URL = process.env.REACT_APP_NGRAM_API_URL

  const [profileId, setProfileId] = useState(localStorage.getItem('profileId') || "")

  const [profileDateFrom, setProfileDateFrom] = useState(localStorage.getItem('profileDateFrom') || "")
  const [profileDateTo, setProfileDateTo] = useState(localStorage.getItem('profileDateTo') || "")
  // const [profileAttributionValue, setProfileAttributionValue] = useState(localStorage.getItem('profileAttributionValue'))
  // const [profileAttributionLabel, setProfileAttributionLabel] = useState(localStorage.getItem('profileAttributionLabel'))
  // const [profileGoalValue, setProfileGoalValue] = useState(localStorage.getItem('profileGoalValue'))
  // const [profileGoalLabel, setProfileGoalLabel] = useState(localStorage.getItem('profileGoalLabel'))

  const {currentUser, logout} = useAuth()
  const checkUser = currentUser?.email == "dev@next-level-agency.ru" ? true : false

  const [selectFocus, setSelectFocus] = useState(4)
  const onBlur = () => setSelectFocus(4)
  const gridStyle = { minHeight: 740, zIndex: selectFocus }
  const [activateRowOnFocus, setActivateRowOnFocus] = useState(true)
  const pleaseWait = <b>Загрузка данных, подождите ... </b>

  const options4 = [
    { value: '10', label: '10' }
  ]

  const columnsKpssCampaingn = [
    { name: 'campaign_id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
    { name: 'title', defaultFlex: 1, minWidth: 300, header: 'Название',
      render: ({ value }) =>
        // <div className='d-flex align-items-center'>
        //   <div className='form-check form-check-ms form-check-custom form-check '>
        //     <input className='form-check-input widget-9-check' type='hidden' value='1' />
        //   </div>
        //   <div className='d-flex justify-content-start flex-column ms-3'>
        //     <span className='text-muted fw-semibold text-muted d-block fs-7'>
        //       {value[0]}
        //     </span>
        //     <div className='text-dark fw-bold fs-6'>
        //       {value[1]}
        //     </div>
        //   </div>
        // </div>
        <div className='d-flex justify-content-start flex-column '>
          <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {/*№3222123*/}
            {value[0]}
          </span>
          <span className='text-dark fw-bold fs-8'>
            {/*Поздравление Деда Мороза — МСК*/}
            {value[1]}
          </span>
        </div>
    },
    { name: 'kpss_from_clicks', defaultFlex: 1, minWidth: 180, header: 'КПСС через клики', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
          </div>
          {(() => {
            if (Number(value) <= 75 && String(value) != "null") {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else if (Number(value) > 75) {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else {
              return (
                <div className='h-6px mw-150px'><div /></div>
              )
            }
          })()}
        </div>
    },
    { name: 'kpss_from_impressions', defaultFlex: 1, minWidth: 180, header: 'КПСС через показы', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'strategy', defaultFlex: 1, minWidth: 150, header: 'Бюджет и стратегия',
      render: ({value}) =>
        <div className='d-flex flex-column'>
        <span className='text-dark fw-bold text-muted lh-1 fs-7'>
          {/* Оптимизация конверсий */}
          {value[0]}
        </span>
          {/*<span className='text-dark fw-bold  fs-6'>*/}
          {/*  8 000 000 ₽/нед*/}
          {/*</span>*/}
          <span className='text-dark fw-bold  fs-6'>
          {value[1]} {value[2]}
        </span>
        </div>
    },
    { name: 'impressions', defaultFlex: 1, minWidth: 90, header: 'Показы', type: 'number' },
    { name: 'clicks', defaultFlex: 1, minWidth: 90, header: 'Клики', type: 'number' },
    { name: 'rate', defaultFlex: 1, minWidth: 90, header: 'Расход c НДС', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'autotarget_rate_part', defaultFlex: 1, minWidth: 120, header: 'Доля расхода с автотаргетинга', type: 'number',
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
          </div>
          {(() => {
            if (Number(value) <= 25 && String(value) != "null") {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else if (Number(value) > 25) {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else {
              return (
                <div className='h-6px mw-150px'><div /></div>
              )
            }
          })()}
        </div>
    },
    { name: 'conversions', defaultFlex: 1, minWidth: 90, header: 'Конверсии', type: 'number' },
    { name: 'revenue', defaultFlex: 1, minWidth: 90, header: 'Доход', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'ineffective_rate', defaultFlex: 1, minWidth: 120, header: 'Неэффективный расход', type: 'number',
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
          </div>
          {(() => {
            if (Number(value) <= 25 && String(value) != "null") {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else if (Number(value) > 25) {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else {
              return (
                <div className='h-6px mw-150px'><div /></div>
              )
            }
          })()}
        </div>
    },
    { name: 'recommendation', defaultFlex: 1, minWidth: 200, header: 'Рекомендации' },
    { name: 'biddingId', defaultFlex: 1, minWidth: 160, header: 'Биддинг',
      render: ({ value }) =>
        <div className='d-flex'>
          {value[1] == "Хороший показатель КПСС, подключите биддер для управления ставками" ?
            <span><Link to='/bidder-page' onClick={(event) => {connectBidderCampaign(event, value[0])}} className='btn btn-light text-dark fw-bold w-auto px-3 py-2'><i className="bi bi-play-fill text-dark fs-2" /> Подключить</Link></span> :
            <span className='bg-light-danger text-danger fw-bold d-flex align-items-center fw-bold w-auto px-3 py-2 rounded-1 fs-6'>
            {/*<KTSVG path="/media/icons/duotune/general/gen045.svg" className="text-danger svg-icon-muted svg-icon-2 me-3" />*/}
              <Tooltip text="Биддинг ставок недоступен для рекламных кампаний с показателем КПСС ниже 75%. Оптимизируйте РК!">
              {/*<i className="las la-info-circle ms-0 fs-2 text-danger" />*/}
                <i className="bi bi-info-circle-fill text-danger fs-2" />
            </Tooltip>
              &nbsp;&nbsp;&nbsp;Недоступен
          </span>
          }
        </div>
    },
  ]

  const columnsKpssAdgroup = [
    { name: 'adgroup_id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
    { name: 'campaign_group', defaultFlex: 1,minWidth: 300, header: 'Группа объявлений',
      render: ({ value }) =>
        <div className='d-flex justify-content-start flex-column '>
          <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {value[0]}
          </span>
          <span className='text-dark fw-bold fs-8'>
            {value[1]}
          </span>
        </div>
    },
    { name: 'campaign', defaultFlex: 1,minWidth: 300, header: 'Кампания',
      render: ({ value }) =>
        <div className='d-flex justify-content-start flex-column '>
          <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {value[0]}
          </span>
          <span className='text-dark fw-bold fs-8'>
            {value[1]}
          </span>
        </div>
    },
    { name: 'kpss_from_clicks', defaultFlex: 1, minWidth: 180, header: 'КПСС через клики', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
          </div>
          {(() => {
            if (Number(value) <= 75 && String(value) != "null") {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else if (Number(value) > 75) {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else {
              return (
                <div className='h-6px mw-150px'><div /></div>
              )
            }
          })()}
        </div>
    },
    { name: 'kpss_from_impressions', defaultFlex: 1, minWidth: 180, header: 'КПСС через показы', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'impressions', defaultFlex: 1, minWidth: 90, header: 'Показы', type: 'number' },
    { name: 'clicks', defaultFlex: 1, minWidth: 90, header: 'Клики', type: 'number' },
    { name: 'rate', defaultFlex: 1, minWidth: 90, header: 'Расход c НДС', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'autotarget_rate_part', defaultFlex: 1, minWidth: 120, header: 'Доля расхода с автотаргетинга', type: 'number',
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{String(value) != "null" ? `${value}%` : ""}</span>
          </div>
          {(() => {
            if (Number(value) <= 25 && String(value) != "null") {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-success' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else if (Number(value) > 25) {
              return (
                <div className='progress h-6px mw-150px'><div className='progress-bar bg-danger' role='progressbar' style={{width: `${value}%`}} /></div>
              )
            } else {
              return (
                <div className='h-6px mw-150px'><div /></div>
              )
            }
          })()}
        </div>
    },
    { name: 'conversions', defaultFlex: 1, minWidth: 90, header: 'Конверсии', type: 'number' },
    { name: 'revenue', defaultFlex: 1, minWidth: 90, header: 'Доход', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'recommendation', defaultFlex: 1, minWidth: 200, header: 'Рекомендации' },
    // { name: 'biddingId', defaultFlex: 1, minWidth: 140, header: 'Биддинг',
    //   render: ({ value }) =>
    //     <div className='d-flex'>
    //       {value[1] == "Хороший показатель КПСС, подключите биддер для управления ставками" ?
    //         <span><Link to='/bidder-page' onClick={(event) => {connectBidderAdgroup(event, value[0])}} className='btn btn-light text-dark fw-bold w-auto px-3 py-2'><i className="bi bi-play-fill text-dark fs-2"></i> Подключить</Link></span> :
    //         <span className='bg-light-danger text-danger fw-bold d-flex align-items-center fw-bold w-auto px-3 py-2 rounded-1 fs-7'>
    //         <KTSVG path="/media/icons/duotune/general/gen045.svg" className="text-danger svg-icon-muted svg-icon-2 me-3" />
    //         Недоступен
    //       </span>
    //       }
    //     </div>
    // },
  ]

  const columnsKpssPhrases = [
    { name: 'key_id', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40 },
    { name: 'key_phrase', defaultFlex: 1,minWidth: 300, header: 'Ключевая фраза',
      render: ({ value }) =>
        <div className='d-flex justify-content-start flex-column '>
          <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {value[0]}
          </span>
          <span className='text-dark fw-bold fs-8'>
            {value[1]}
          </span>
        </div>
    },
    { name: 'query', defaultFlex: 1, minWidth: 90, header: 'Поисковый запрос', type: 'number' },
    { name: 'ad_set', defaultFlex: 1,minWidth: 300, header: 'Группа объявлений',
      render: ({ value }) =>
        <div className='d-flex justify-content-start flex-column '>
          <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {value[0]}
          </span>
          <span className='text-dark fw-bold fs-8'>
            {value[1]}
          </span>
        </div>
    },
    { name: 'campaign', defaultFlex: 1,minWidth: 300, header: 'Кампания',
      render: ({ value }) =>
        <div className='d-flex justify-content-start flex-column '>
          <span className='text-muted fw-semibold text-muted d-block fs-8'>
            {value[0]}
          </span>
          <span className='text-dark fw-bold fs-8'>
            {value[1]}
          </span>
        </div>
    },
    { name: 'kpss', defaultFlex: 1, minWidth: 180, header: 'КПСС', type: 'number', filterEditor: NumberFilter,
      render: ({ value }) =>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>{value}%</span>
          </div>
          <div className='progress h-6px mw-150px'>
            {Number(value) <= 75 ? <div
              className='progress-bar bg-danger'
              role='progressbar'
              style={{width: `${value}%`}}
            /> : <div
              className='progress-bar bg-success'
              role='progressbar'
              style={{width: `${value}%`}}
            /> }
          </div>
        </div>
    },
    { name: 'impressions', defaultFlex: 1, minWidth: 90, header: 'Показы', type: 'number' },
    { name: 'clicks', defaultFlex: 1, minWidth: 90, header: 'Клики', type: 'number' },
    { name: 'rate', defaultFlex: 1, minWidth: 90, header: 'Расход c НДС', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
    { name: 'is_autotarget', defaultFlex: 1, minWidth: 120, header: 'Автотаргетинг', type: 'number',
      render: ({ value }) =>
        <div>
          {(() => {
            if (value === true) {
              return (
                <div className="d-flex align-items-center justify-content-center text-center text-middle fw-bold fs-7  text-white rounded-2 p-2 bg-success">Да</div>
              )
            } else if (value === false) {
              return (
                <div className="d-flex align-items-center justify-content-center text-center text-middle fw-bold fs-7  text-white rounded-2 p-2 bg-danger">Нет</div>
              )
            } else {
              return (
                <div className="d-flex align-items-center justify-content-center text-center text-middle fw-bold fs-7  text-white rounded-2 p-2"> </div>
              )
            }
          })()}
        </div>
    },
    { name: 'conversions', defaultFlex: 1, minWidth: 90, header: 'Конверсии', type: 'number' },
    { name: 'revenue', defaultFlex: 1, minWidth: 90, header: 'Доход', type: 'number',
      render: ({ value }) =>  value + ` ₽` },
  ]

  // const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
  // const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

  //   const locale = {
  //   localize: {
  //       day: n => days[n],
  //       month: n => months[n]
  //   },
  //   formatLong: {
  //       date: () => 'yyyy-mm-dd'
  //   }
  // }

  const nowDate = new Date()
  ///const [startDate, setStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
  ///const [endDate, setEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1)) //useState(nowDate)
  const [startDate, setStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
  const [endDate, setEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), 0)) //useState(nowDate)

  const [minDate, setMinDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
  const [maxDate, setMaxDate] = useState(nowDate)

  const [availableStartDate, setAvailableStartDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
  const [availableEndDate, setAvailableEndDate] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1)) //useState(nowDate)

  const [autotargetDateFrom, setAutotargetDateFrom] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
  const [autotargetDateTo, setAutotargetDateTo] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1))

  const [attribution, setAttribution] = useState(8)
  const [goal, setGoal] = useState(0)
  const [autotarget, setAutotarget] = useState("keywords")

  const [defaultValueSearchCampaign, setDefaultValueSearchCampaign] = useState<KpssCampaignDataItem[]>([])
  const [defaultValueSearchAdgroup, setDefaultValueSearchAdgroup] = useState<KpssAdgroupDataItem[]>([])
  const [defaultValueSearchPhrases, setDefaultValueSearchPhrases] = useState<KpssPhrasesDataItem[]>([])

  const [defaultValueAttribution, setDefaultValueAttribution] = useState({ value: localStorage.getItem('profileAttributionValue'), label: localStorage.getItem('profileAttributionLabel') })
  const [defaultValueGoal, setDefaultValueGoal] = useState({ value: localStorage.getItem('profileGoalValue'), label: localStorage.getItem('profileGoalLabel') })
  const [defaultValueAutotarget, setDefaultValueAutotarget] = useState({ value: localStorage.getItem('profileAutotargetValue'), label: localStorage.getItem('profileAutotargetLabel') })

  const [selectedOptionSearchCampaign, setSelectedOptionSearchCampaign] = useState(defaultValueSearchCampaign)
  const [selectedOptionSearchAdgroup, setSelectedOptionSearchAdgroup] = useState(defaultValueSearchAdgroup)
  const [selectedOptionSearchPhrases, setSelectedOptionSearchPhrases] = useState(defaultValueSearchPhrases)

  const [selectedOptionAttribution, setSelectedOptionAttribution] = useState(defaultValueAttribution)
  const [selectedOptionGoal, setSelectedOptionGoal] = useState(defaultValueGoal)
  const [selectedOptionAutotarget, setSelectedOptionAutotarget] = useState(defaultValueAutotarget)

  const [dateForReport, setDateForReport] = useState(true)
  const [attributionForReport, setAttributionForReport] = useState(true)
  const [goalForReport, setGoalForReport] = useState(false)

  const [kpssCampaigns, setKpssCampaigns] = useState<number[]>([])
  const [kpssAdgroups, setKpssAdgroups] = useState<number[]>([])

  let tempKpssCampaigns: number[] = []
  const tempSelectedKpssCampaign = {}
  let tempKpssAdgroups: number[] = []
  const tempSelectedKpssAdgroup = {}

  const [isActiveGroups, setIsActiveGroups] = useState(true)
  const [isActivePhrases, setIsActivePhrases] = useState(true)

  const [buttonSendReport, setButtonSendReport] = useState(true)

  const [buttonResetFilters, setButtonResetFilters] = useState(true)

  const handleChangeDate = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)

    setMinDate(newStartDate)
    const futureDate = addDays(newStartDate, 59)
    setMaxDate(futureDate > nowDate ? nowDate : futureDate)
    if (newStartDate === null || newEndDate === null) {
      setDateForReport(false)
    } else {
      localStorage.setItem('profileDateFrom', `${newStartDate.toLocaleDateString('fr-CA')}`)
      localStorage.setItem('profileDateTo', `${newEndDate.toLocaleDateString('fr-CA')}`)
      setDateForReport(true)
      setButtonResetFilters(false)

      sendMetricGoalVisitParametr('reachGoal', 'filter_calendar_report-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_calendar_report-page": {}
          }
        }
      })
    }
    //console.log(newStartDate.toLocaleDateString('fr-CA'))
    //console.log(newEndDate.toLocaleDateString('fr-CA'))
  }

  const handleAttribution = (selectedOption) => {
    //console.log(selectedOption.id)
    if (selectedOption === null || selectedOption.label == "Установите атрибуцию") {
      setAttribution(0)
      localStorage.setItem('profileAttributionValue', "8")
      localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
      setAttributionForReport(false)
    } else {
      setAttribution(selectedOption.id)
      setSelectedOptionAttribution(selectedOption)
      localStorage.setItem('profileAttributionValue', `${selectedOption.id}`)
      localStorage.setItem('profileAttributionLabel', `${selectedOption.full_name}`)
      setAttributionForReport(true)
      setButtonResetFilters(false)
      //checkSendReport()

      sendMetricGoalVisitParametr('reachGoal', 'filter_attribution_report-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_attribution_report-page": {}
          }
        }
      })
    }
  }

  const handleGoal = (selectedOption) => {
    //console.log(selectedOption.id)
    if (selectedOption === null || selectedOption.label == "Выберите данные по целям" || selectedOption.label == "---") {
      setGoal(0)
      setSelectedOptionGoal({value: '0', label: 'Выберите данные по целям'})
      localStorage.setItem('profileGoalValue', "0")
      localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
      setGoalForReport(false)
    } else {
      setGoal(selectedOption.id)
      setSelectedOptionGoal(selectedOption)
      localStorage.setItem('profileGoalValue', `${selectedOption.id}`)
      localStorage.setItem('profileGoalLabel', `${selectedOption.name}`)
      setGoalForReport(true)
      setButtonResetFilters(false)
      //checkSendReport()

      sendMetricGoalVisitParametr('reachGoal', 'filter_goals_report-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_goals_report-page": {}
          }
        }
      })
    }
  }

  const handleAutotarget = (selectedOption) => {
    //console.log(selectedOption.id)
    if (selectedOption === null || selectedOption.label == "Выберите условие показа" || selectedOption.label == "---") {
      setAutotarget("keywords")
      setSelectedOptionAutotarget({value: 'keywords', label: 'Выберите условие показа'})
      localStorage.setItem('profileAutotargetValue', "keywords")
      localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
      ///setGoalForReport(false)
    } else {
      setAutotarget(selectedOption.value)
      setSelectedOptionAutotarget(selectedOption)
      localStorage.setItem('profileAutotargetValue', `${selectedOption.value}`)
      localStorage.setItem('profileAutotargetLabel', `${selectedOption.label}`)
      setButtonResetFilters(false)

      ///setGoalForReport(true)
      //checkSendReport()

      // sendMetricGoalVisitParametr('reachGoal', 'filter_goals_report-page', {
      //   [`${localStorage.getItem('profileName')}`]: {
      //     [`${window.location.pathname}`]: {
      //       "filter_goals_report-page": {}
      //     }
      //   }
      // })
    }
  }

  const onSelectionKpssCampaign = ({ selected }) => {
    //console.log(Object.keys(selected).valueOf())
    //console.log(selected)
    tempKpssCampaigns = []
    if (selected == 1) { // select all
      for (var key in currentKpssCampaingRow) {
        // @ts-ignore
        tempSelectedKpssCampaign[currentKpssCampaingRow[key].campaign_id] = {}
        tempKpssCampaigns.push(currentKpssCampaingRow[key].campaign_id)
      }
      setKpssCampaigns(tempKpssCampaigns)
      setSelectedKpssCampaign(tempSelectedKpssCampaign)
      ///setCampaignForReport(true)
      setIsActiveGroups(false)
      ///setIsActivePhrases(false)
      setKpssAdgroups([]) // Убираем выбранные группы при выборе кампаний
      setSelectedKpssAdgroup({}) // Убираем выбранные группы при выборе кампаний

      sendMetricGoalVisitParametr('reachGoal', 'choose_campaign_report-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "choose_campaign_report-page": {}
          }
        }
      })
    } else if (Object.keys(selected).length === 0) { // select none
      setKpssCampaigns([])
      setSelectedKpssCampaign({})
      localStorage.removeItem("chooseKpssCampaignId")
      ///setCampaignForReport(false)
      setIsActiveGroups(true)
      setIsActivePhrases(true)
      setKpssAdgroups([]) // Убираем выбранные группы при выборе кампаний
      setSelectedKpssAdgroup({}) // Убираем выбранные группы при выборе кампаний
    } else { // select some
      for (var key in selected) {
        // @ts-ignore
        //tempCampaigns.push(Number(selected[key].id))
        tempKpssCampaigns.push(Number(key))
      }
      setKpssCampaigns(tempKpssCampaigns)
      setSelectedKpssCampaign(selected)
      ///setCampaignForReport(true)
      setIsActiveGroups(false)
      ///setIsActivePhrases(false)
      setKpssAdgroups([]) // Убираем выбранные группы при выборе кампаний
      setSelectedKpssAdgroup({}) // Убираем выбранные группы при выборе кампаний

      sendMetricGoalVisitParametr('reachGoal', 'choose_campaign_report-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "choose_campaign_report-page": {}
          }
        }
      })
    }
  }

  const onSelectionKpssAdgroup = ({ selected }) => {
    //console.log(Object.keys(selected).valueOf())
    //console.log(selected)
    tempKpssAdgroups = []
    if (selected == 1) {
      for (var key in currentKpssAdgroupRow) {
        // @ts-ignore
        tempSelectedKpssAdgroup[currentKpssAdgroupRow[key].adgroup_id] = {}
        tempKpssAdgroups.push(currentKpssAdgroupRow[key].adgroup_id)
      }
      setKpssAdgroups(tempKpssAdgroups)
      setSelectedKpssAdgroup(tempSelectedKpssAdgroup)
      ///setCampaignForReport(true)
      setIsActivePhrases(false)
    } else if (Object.keys(selected).length === 0) {
      setKpssAdgroups([])
      setSelectedKpssAdgroup({})
      localStorage.removeItem("chooseKpssAdgroupId")
      ///setCampaignForReport(false)
      setIsActivePhrases(true)
    } else {
      for (var key in selected) {
        // @ts-ignore
        //tempCampaigns.push(Number(selected[key].id))
        tempKpssAdgroups.push(Number(key))
      }
      setKpssAdgroups(tempKpssAdgroups)
      setSelectedKpssAdgroup(selected)
      ///setCampaignForReport(true)
      setIsActivePhrases(false)
    }
  }

  // const checkButtonSendReport = () => {
  //   if (dateForReport && attributionForReport && goalForReport) {
  //     setButtonSendReport(false)
  //   } else {
  //     setButtonSendReport(true)
  //   }
  // }

  const checkSendReport = () => {
    if (dateForReport && attributionForReport && goalForReport) {
      setButtonSendReport(false)

      if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
        setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
        setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
      }

      getKpssReport()
      setMinDate(availableStartDate)
      setMaxDate(nowDate)
    } else {
      setButtonSendReport(true)
      if (startDate === null || endDate === null) {
        ///
      } else {
        getKpssReport()
        setMinDate(availableStartDate)
        setMaxDate(nowDate)
      }
    }
  }

  const [toogle, setToggle] = useState(1)

  function updateToggle(id) {
    if (id == 1) {
      //setIsSetRates(false)
    }

    if (id == 2) {
      sendMetricGoalVisitParametr('reachGoal', 'view_groups_report-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "view_groups_report-page": {}
          }
        }
      })
    } else if (id == 3) {
      sendMetricGoalVisitParametr('reachGoal', 'view_queries_report-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "view_queries_report-page": {}
          }
        }
      })
    }

    setToggle(id)

    setDefaultValueAttribution({ value: localStorage.getItem('profileAttributionValue'), label: localStorage.getItem('profileAttributionLabel') })
    setDefaultValueGoal({ value: localStorage.getItem('profileGoalValue'), label: localStorage.getItem('profileGoalLabel') })
    setDefaultValueAutotarget({ value: localStorage.getItem('profileAutotargetValue'), label: localStorage.getItem('profileAutotargetLabel') })
    //setSelectedOptionAttribution(defaultValueAttribution)
    //setSelectedOptionGoal(defaultValueGoal)
    setSelectedOptionAttribution({ value: localStorage.getItem('profileAttributionValue'), label: localStorage.getItem('profileAttributionLabel') })
    setSelectedOptionGoal({ value: localStorage.getItem('profileGoalValue'), label: localStorage.getItem('profileGoalLabel') })
    setSelectedOptionAutotarget({ value: localStorage.getItem('profileAutotargetValue'), label: localStorage.getItem('profileAutotargetLabel') })
  }

  const firstUpdate = useRef(true)

  async function fetchDate() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    await fetch(`${API_URL}/get_dataset_period/?profile_id=${profileId}&section=kpss`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        if (json.status == "ERROR") {
          ///
        } else if (json.result.date_from == null || json.result.date_to == null) {
          ///
        } else if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
          setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
          setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
          const dateFromResult = new Date(json.result.date_from)
          const dateToResult = new Date(json.result.date_to)
          setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
          setMinDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAutotargetDateFrom(new Date(json.result.autotarget_date_from))
          setAutotargetDateTo(new Date(json.result.autotarget_date_to))
        } else {
          const dateFromResult = new Date(json.result.date_from)
          const dateToResult = new Date(json.result.date_to)
          const lastDateFromResult = new Date(json.result.last_date_from)
          const lastDateToResult = new Date(json.result.last_date_to)
          setStartDate(new Date(lastDateFromResult.getFullYear(), lastDateFromResult.getMonth(), lastDateFromResult.getDate()))
          setEndDate(new Date(lastDateToResult.getFullYear(), lastDateToResult.getMonth(), lastDateToResult.getDate()))
          //setStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          //setEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
          setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
          setMinDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
          setAutotargetDateFrom(new Date(json.result.autotarget_date_from))
          setAutotargetDateTo(new Date(json.result.autotarget_date_to))
        }
        //setGoals(json)
        // json.map((object) => {
        //   setGoals((goals) => [
        //     ...goals,
        //     {
        //       value: object.id,
        //       label: object.name,
        //       isDisabled: false
        //     }
        //   ]);
        // });

        // Рабочий вариант
        if (defaultValueGoal.value == "0") {
          setGoalForReport(false)
        } else {
          setGoalForReport(true)
        }
      });
    firstUpdate.current = false
  }

  useEffect( () => {
    if (profileId != "0") {
      fetchDate()
    }
  }, [profileId])

  useEffect( () => {
    //console.log(dateForReport, attributionForReport, goalForReport)
    //checkButtonSendReport()

    if (!firstUpdate.current) {
      //console.log("firstUpdate: false")
      checkSendReport()
    }
  }, [dateForReport, attributionForReport, goalForReport, attribution, goal, autotarget, profileId, toogle, availableEndDate])

  useEffect( () => {
    setLoadingData(true)

    sendMetricVisitParametr("params", {
      [`${localStorage.getItem('profileName')}`]: {
        [`${window.location.pathname}`]: {}
      }
    })

    addToDataLayer({
      'login': currentUser?.email,
      'profile': localStorage.getItem('profileName'),
      'url': window.location.pathname,
    })

    if (localStorage.getItem("profileDateFrom") === null || localStorage.getItem("profileDateTo") === null) {
      localStorage.setItem('profileDateFrom', "0000-00-00")
      localStorage.setItem('profileDateTo', "0000-00-00")
      window.location.reload()
    }
    if (localStorage.getItem("profileAttributionValue") === null || localStorage.getItem("profileAttributionLabel") === null) {
      localStorage.setItem('profileAttributionValue', "8")
      localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
      window.location.reload()
    }
    if (localStorage.getItem("profileGoalValue") === null || localStorage.getItem("profileGoalLabel") === null) {
      localStorage.setItem('profileGoalValue', "0")
      localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
      window.location.reload()
    }
    if (localStorage.getItem("profileAutotargetValue") === null || localStorage.getItem("profileAutotargetLabel") === null) {
      localStorage.setItem('profileAutotargetValue', "keywords")
      localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
      window.location.reload()
    }
    if (localStorage.getItem("profileUrlIsYandexValue") === null || localStorage.getItem("profileUrlIsYandexLabel") === null) {
      localStorage.setItem('profileUrlIsYandexValue', "null")
      localStorage.setItem('profileUrlIsYandexLabel', "---")
      window.location.reload()
    }
    if (localStorage.getItem("profileUrlIsGoogleValue") === null || localStorage.getItem("profileUrlIsGoogleLabel") === null) {
      localStorage.setItem('profileUrlIsGoogleValue', "null")
      localStorage.setItem('profileUrlIsGoogleLabel', "---")
      window.location.reload()
    }
    if (localStorage.getItem("checkDateValue") === null || localStorage.getItem("checkDateLabel") === null) {
      localStorage.setItem('checkDateValue', "0")
      localStorage.setItem('checkDateLabel', "Выберите дату")
      window.location.reload()
    }

    window.addEventListener('localStorage', () => {
      setProfileId(String(localStorage.getItem('profileId')))
      //setProfileDateFrom(String(localStorage.getItem('profileDateFrom')))
      //setProfileDateTo(String(localStorage.getItem('profileDateTo')))
    })

    setSelectedOptionAttribution(defaultValueAttribution)
    setSelectedOptionGoal(defaultValueGoal)
    setSelectedOptionAutotarget(defaultValueAutotarget)
  },[])

  //const [kpssReportRow, setKpssReportRow] = useState([])
  const [kpssCampaignReportRow, setKpssCampaignReportRow] = useState<KpssCampaignDataItem[]>([])
  const [kpssCampaignReportRowSelect, setKpssCampaignReportRowSelect] = useState<KpssCampaignDataItem[]>([])
  const [pageCountKpssCampaign, setPageCountKpssCampaign] = useState(0)
  const [kpssAdgroupReportRow, setKpssAdgroupReportRow] = useState<KpssAdgroupDataItem[]>([])
  const [kpssAdgroupReportRowSelect, setKpssAdgroupReportRowSelect] = useState<KpssAdgroupDataItem[]>([])
  const [pageCountKpssAdgroup, setPageCountKpssAdgroup] = useState(0)
  const [kpssPhrasesReportRow, setKpssPhrasesReportRow] = useState<KpssPhrasesDataItem[]>([])
  const [kpssPhrasesReportRowSelect, setKpssPhrasesReportRowSelect] = useState<KpssPhrasesDataItem[]>([])
  const [pageCountKpssPhrases, setPageCountKpssPhrases] = useState(0)

  const [doeReportRow, setDoeReportRow] = useState<DOEDataItem[]>([])

  const [summaryKpssFromClicks, setSummaryKpssFromClicks] = useState(0)
  const [summaryKpssFromImpressions, setSummaryKpssFromImpressions] = useState(0)

  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [kpssRowPerPage] = useState(10)
  // const [searchValue, setSearchValue] = useState("")
  // const [searchValue, setSearchValue] = useState<ReportDataItem[]>([])

  const [searchValueCampaing, setSearchValueCampaing] = useState<String[]>([])
  const [filteringCampaign, setFilteringCampaign] = useState(false)
  const [searchValueAdgroup, setSearchValueAdgroup] = useState<String[]>([])
  const [filteringAdgroup, setFilteringAdgroup] = useState(false)
  const [searchValuePhrases, setSearchValuePhrases] = useState<String[]>([])
  const [filteringPhrases, setFilteringPhrases] = useState(false)

  const [kpssReportRowSearch, setKpssReportRowSearch] = useState([
    {
      value: "",
      label: ""
    }
  ])
  const [attributions, setAttributions] = useState([])
  // const [attributions, setAttributions] = useState([
  //   {
  //     value: 0,
  //     label: "Установите атрибуцию",
  //     isDisabled: true
  //   }
  // ])
  const [goals, setGoals] = useState<GoalDataItem[]>([])
  // const [goals, setGoals] = useState([
  //   {
  //     value: 0,
  //     label: "Выберите данные по целям",
  //     isDisabled: true
  //   }
  // ])
  const autotargets = [
    {value: "autotarget", label: "Автотаргетинг"},
    {value: "keywords", label: "Ключевые фразы"},
    {value: "summary", label: "Все вместе"}
  ]

  // const [fetching, setFetching] = useState(true)

  // const scrollHandler = (event) => {
  //   console.log('scroll')
  // }

  const handleSearchCampaign = (selectedOption) => {
    //console.log(selectedOption)
    //console.log(selectedOption.campaign_id)
    //if (selectedOption === null) {
    if (selectedOption.length === 0) {
      // setSearchValue("")
      setSearchValueCampaing([])
      setSelectedOptionSearchCampaign([])
      setFilteringCampaign(false)
    } else {
      //setSearchValue(selectedOption.campaign_id);
      //setSearchValue(selectedOption);
      setSearchValueCampaing([])
      setSelectedOptionSearchCampaign([])
      selectedOption.map(o =>
        setSearchValueCampaing(searchValueCampaing => [
          ...searchValueCampaing,
          o.campaign_id.toString()
        ])
      )
      selectedOption.map(kpssCampaignItem =>
       setSelectedOptionSearchCampaign(searchValueCampaing => [
        ...searchValueCampaing,
         {
           campaign_id: kpssCampaignItem.campaign_id,
           daily_budget: kpssCampaignItem.daily_budget,
           daily_budget_period: kpssCampaignItem.daily_budget_period,
           campaign_name: kpssCampaignItem.campaign_name,
           kpss_from_impressions: kpssCampaignItem.kpss_from_impressions,
           kpss_from_clicks: kpssCampaignItem.kpss_from_clicks,
           bidding_strategy: kpssCampaignItem.bidding_strategy,
           ineffective_rate: kpssCampaignItem.ineffective_rate,
           state: kpssCampaignItem.state,
           impressions: kpssCampaignItem.impressions,
           rate: kpssCampaignItem.rate,
           clicks: kpssCampaignItem.clicks,
           conversions: kpssCampaignItem.conversions,
           revenue: kpssCampaignItem.revenue,
           autotarget_rate_part: kpssCampaignItem.autotarget_rate_part,
           recommendation: kpssCampaignItem.recommendation,
           value: `№${kpssCampaignItem.campaign_id}`,
           label: `№${kpssCampaignItem.campaign_id} \n${kpssCampaignItem.campaign_name}`,
           title: [`№${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.campaign_name}`],
           strategy: [`${kpssCampaignItem.bidding_strategy}`, `${kpssCampaignItem.daily_budget}`, `${kpssCampaignItem.daily_budget_period}`],
           bidding: kpssCampaignItem.recommendation,
           biddingId: [`${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.recommendation}`]
         }
      ])
      )

      setFilteringCampaign(true)
      setButtonResetFilters(false)

      sendMetricGoalVisitParametr('reachGoal', 'filter_campaign_report-page', {
        [`${localStorage.getItem('profileName')}`]: {
          [`${window.location.pathname}`]: {
            "filter_campaign_report-page": {}
          }
        }
      })
    }
  }

  const handleSearchAdgroup = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValueAdgroup([])
      setSelectedOptionSearchAdgroup([])
      setFilteringAdgroup(false)
    } else {
      setSearchValueAdgroup([])
      setSelectedOptionSearchAdgroup([])
      selectedOption.map(o =>
        setSearchValueAdgroup(searchValueAdgroup=> [
          ...searchValueAdgroup,
          o.adgroup_id.toString()
        ])
      )
      selectedOption.map(kpssAdgroupItem =>
        setSelectedOptionSearchAdgroup(searchValueAdgroup => [
          ...searchValueAdgroup,
          {
            adgroup_id: kpssAdgroupItem.adgroup_id,
            campaign_name: kpssAdgroupItem.campaign_name,
            campaign_id: kpssAdgroupItem.campaign_id,
            group_name: kpssAdgroupItem.group_name,
            kpss_from_impressions: kpssAdgroupItem.kpss_from_impressions,
            kpss_from_clicks: kpssAdgroupItem.kpss_from_clicks,
            ineffective_rate: kpssAdgroupItem.ineffective_rate,
            impressions: kpssAdgroupItem.impressions,
            rate: kpssAdgroupItem.rate,
            clicks: kpssAdgroupItem.clicks,
            conversions: kpssAdgroupItem.conversions,
            revenue: kpssAdgroupItem.revenue,
            autotarget_rate_part: kpssAdgroupItem.autotarget_rate_part,
            recommendation: kpssAdgroupItem.recommendation,
            value: `№${kpssAdgroupItem.adgroup_id}`,
            label: `№${kpssAdgroupItem.adgroup_id} \n${kpssAdgroupItem.group_name}`,
            campaign_group: [`№${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.group_name}`],
            campaign: [`№${kpssAdgroupItem.campaign_id}`, `${kpssAdgroupItem.campaign_name}`],
            biddingId: [`${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.recommendation}`]
          }
        ])
      )

      setFilteringAdgroup(true)
      setButtonResetFilters(false)
    }
  }

  const handleSearchPhrases = (selectedOption) => {
    if (selectedOption.length === 0) {
      setSearchValuePhrases([])
      setSelectedOptionSearchPhrases([])
      setFilteringPhrases(false)
    } else {
      setSearchValuePhrases([])
      setSelectedOptionSearchPhrases([])
      selectedOption.map(o =>
        setSearchValuePhrases(searchValuePhrases => [
          ...searchValuePhrases,
          o.key_id.toString()
        ])
      )
      selectedOption.map(kpssPhrasesItem =>
        setSelectedOptionSearchPhrases(searchValuePhrases => [
          ...searchValuePhrases,
          {
            key: kpssPhrasesItem.key,
            campaign_name: kpssPhrasesItem.campaign_name,
            campaign_id: kpssPhrasesItem.campaign_id,
            group_name: kpssPhrasesItem.group_name,
            adgroup_id: kpssPhrasesItem.adgroup_id,
            kpss: kpssPhrasesItem.kpss,
            key_id: kpssPhrasesItem.key_id,
            query: kpssPhrasesItem.query,
            impressions: kpssPhrasesItem.impressions,
            rate: kpssPhrasesItem.rate,
            clicks: kpssPhrasesItem.clicks,
            conversions: kpssPhrasesItem.conversions,
            revenue: kpssPhrasesItem.revenue,
            is_autotarget: kpssPhrasesItem.is_autotarget,
            value: `№${kpssPhrasesItem.key_id}`,
            label: `№${kpssPhrasesItem.key_id} \n${kpssPhrasesItem.key}`,
            key_phrase: [`№${kpssPhrasesItem.key_id}`, `${kpssPhrasesItem.key}`],
            ad_set: [`№${kpssPhrasesItem.adgroup_id}`, `${kpssPhrasesItem.group_name}`],
            campaign: [`№${kpssPhrasesItem.campaign_id}`, `${kpssPhrasesItem.campaign_name}`]
          }
        ])
      )

      setFilteringPhrases(true)
      setButtonResetFilters(false)
    }
  }

  function campaignState(event, campaignId, campaignState) {
    console.log(event)
    console.log(campaignId)
    console.log(campaignState)
  }

  const getKpssReport = () => {
    let agg = "campaigns"
    if (toogle == 1) {
      agg = "campaigns"
    } else if (toogle == 2) {
      agg = "adgroups"
    } else {
      agg = "phrases"
    }

    if (localStorage.getItem('profileAttributionValue') !== null || Number(localStorage.getItem('profileAttributionValue')) != 8 ) {
      setAttribution(Number(localStorage.getItem('profileAttributionValue')))
    }

    if (localStorage.getItem('profileGoalValue') !== null || Number(localStorage.getItem('profileGoalValue')) != 0) {
      setGoal(Number(localStorage.getItem('profileGoalValue')))
    }

    if (localStorage.getItem('profileAutotargetValue') !== null || String(localStorage.getItem('profileGoalValue')) != "") {
      setAutotarget(String(localStorage.getItem('profileAutotargetValue')))
    }

    const profileId = localStorage.getItem('profileId')
    setLoadingData(true)

    const queryCampaign = {
      profile_id: profileId,
      agg: agg,
      goal_ids: [goal],
      attribution_ids: [attribution],
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA'),
      //campaign_ids: kpssCampaigns,
      //adgroup_ids: kpssAdgroups
      display_condition: autotarget
    }
    const queryAdgroup = {
      profile_id: profileId,
      agg: agg,
      goal_ids: [goal],
      attribution_ids: [attribution],
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA'),
      campaign_ids: kpssCampaigns,
      //adgroup_ids: kpssAdgroups
      display_condition: autotarget
    }
    const queryPhrase = {
      profile_id: profileId,
      agg: agg,
      goal_ids: [goal],
      attribution_ids: [attribution],
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA'),
      campaign_ids: kpssCampaigns,
      adgroup_ids: kpssAdgroups,
      display_condition: autotarget
    }
    const queryDoe = {
      profile_id: profileId,
      date_from: startDate.toLocaleDateString('fr-CA'),
      date_to: endDate.toLocaleDateString('fr-CA')
    }

    // const headers = {
    //   'Content-Type': 'application/json'
    //   //'Authorization': 'Bearer ab5b'
    // }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${currentUser?.api_token}`
    }

    // const getKpssReportRow = async () => {
    //   setLoading(true)
    //   await axios.post(`${API_URL}/cubejs-api/v1/load`, query, { headers })
    //   .then(function (response) {
    //     //console.log(response.data.data)
    //     setKpssReportRow(response.data.data)
    //     setLoading(false)
    //   })
    //   .catch(error => {
    //       console.error('There was an error!', error)
    //   });
    // }
    //
    // getKpssReportRow()

    // fetch(`${API_URL}/get_kpss_report/`, {
    //   method: 'POST',
    //   headers: headers,
    //   body: JSON.stringify(query)
    if (toogle == 1) {
      fetch(`${API_URL}/kpss/get_report/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(queryCampaign)
      })
        .then(response => response.json())
        //.then(data => setKpssReportRow(data.data))
        .then((data) => {
          //setLoading(false)
          //console.log(data.result);
          // setKpssReportRow(data.data)
          // data.data.map((object) => {
          //   //console.log(object)
          //   setKpssReportRowSearch((kpssReportRowSearch) => [
          //     ...kpssReportRowSearch,
          //     {
          //       value: object["kpss_kpssreportrow.campaign_id"],
          //       label: object["kpss_kpssreportrow.campaign_name"]
          //     }
          //   ]);
          // });

          setKpssCampaignReportRow([])
          setKpssCampaignReportRowSelect([])
          // data.result.map(reportItem => {
          //   setKpssReportRow(kpssReportRow => [
          //     ...kpssReportRow,
          //     {
          //       campaign_name: reportItem["kpss_kpssreportrow.campaign_name"],
          //       clicks: reportItem["kpss_kpssreportrow.clicks"],
          //       impressions: reportItem["kpss_kpssreportrow.impressions"],
          //       kpss_from_clicks: reportItem["kpss_kpssreportrow.kpss_from_clicks"],
          //       revenue: reportItem["kpss_kpssreportrow.revenue"],
          //       conversions: reportItem["kpss_kpssreportrow.conversions"],
          //       kpss_from_impressions: reportItem["kpss_kpssreportrow.kpss_from_impressions"],
          //       recommendation: reportItem["kpss_kpssreportrow.recommendation"],
          //       campaign_id: reportItem["kpss_kpssreportrow.campaign_id"],
          //       rate: reportItem["kpss_kpssreportrow.rate"],
          //       ineffective_rate: reportItem["kpss_kpssreportrow.ineffective_rate"],
          //       daily_budget: reportItem["kpss_kpssreportrow.daily_budget"],
          //       bidding_strategy: reportItem["kpss_kpssreportrow.bidding_strategy"],
          //       daily_budget_period: reportItem["kpss_kpssreportrow.daily_budget_period"],
          //       campaign_state: reportItem["kpss_kpssreportrow.campaign_state"],
          //       value: `№${reportItem["kpss_kpssreportrow.value"]}`,
          //       label: `№${reportItem["kpss_kpssreportrow.label"]}`
          //       //value: reportItem["kpss_kpssreportrow.value"],
          //       //label: reportItem["kpss_kpssreportrow.label"]
          //     }
          //   ])
          // });
          setPageCountKpssCampaign(data.total)
          data.results.map(kpssCampaignItem => {
            setKpssCampaignReportRow(kpssCampaignReportRow => [
              ...kpssCampaignReportRow,
              {
                campaign_id: kpssCampaignItem.campaign_id,
                daily_budget: kpssCampaignItem.daily_budget,
                daily_budget_period: kpssCampaignItem.daily_budget_period,
                campaign_name: kpssCampaignItem.campaign_name,
                kpss_from_impressions: kpssCampaignItem.kpss_from_impressions,
                kpss_from_clicks: kpssCampaignItem.kpss_from_clicks,
                bidding_strategy: kpssCampaignItem.bidding_strategy,
                ineffective_rate: kpssCampaignItem.ineffective_rate,
                state: kpssCampaignItem.state,
                impressions: kpssCampaignItem.impressions,
                rate: kpssCampaignItem.rate,
                clicks: kpssCampaignItem.clicks,
                conversions: kpssCampaignItem.conversions,
                revenue: kpssCampaignItem.revenue,
                autotarget_rate_part: kpssCampaignItem.autotarget_rate_part,
                // impressions__autotarget: kpssCampaignItem.impressions__autotarget,
                // rate__autotarget: kpssCampaignItem.rate__autotarget,
                // clicks__autotarget: kpssCampaignItem.clicks__autotarget,
                // conversions__autotarget: kpssCampaignItem.conversions__autotarget,
                // revenue__autotarget: kpssCampaignItem.revenue__autotarget,
                recommendation: kpssCampaignItem.recommendation,
                value: `№${kpssCampaignItem.campaign_id}`,
                label: `№${kpssCampaignItem.campaign_id} \n${kpssCampaignItem.campaign_name}`,
                title: [`№${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.campaign_name}`],
                strategy: [`${kpssCampaignItem.bidding_strategy}`, `${kpssCampaignItem.daily_budget}`, `${kpssCampaignItem.daily_budget_period}`],
                bidding: kpssCampaignItem.recommendation,
                biddingId: [`${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.recommendation}`]
              }
            ])

            setKpssCampaignReportRowSelect(kpssCampaignReportRowSelect => [
              ...kpssCampaignReportRowSelect,
              {
                campaign_id: kpssCampaignItem.campaign_id,
                daily_budget: kpssCampaignItem.daily_budget,
                daily_budget_period: kpssCampaignItem.daily_budget_period,
                campaign_name: kpssCampaignItem.campaign_name,
                kpss_from_impressions: kpssCampaignItem.kpss_from_impressions,
                kpss_from_clicks: kpssCampaignItem.kpss_from_clicks,
                bidding_strategy: kpssCampaignItem.bidding_strategy,
                ineffective_rate: kpssCampaignItem.ineffective_rate,
                state: kpssCampaignItem.state,
                impressions: kpssCampaignItem.impressions,
                rate: kpssCampaignItem.rate,
                clicks: kpssCampaignItem.clicks,
                conversions: kpssCampaignItem.conversions,
                revenue: kpssCampaignItem.revenue,
                autotarget_rate_part: kpssCampaignItem.autotarget_rate_part,
                // impressions__autotarget: kpssCampaignItem.impressions__autotarget,
                // rate__autotarget: kpssCampaignItem.rate__autotarget,
                // clicks__autotarget: kpssCampaignItem.clicks__autotarget,
                // conversions__autotarget: kpssCampaignItem.conversions__autotarget,
                // revenue__autotarget: kpssCampaignItem.revenue__autotarget,
                recommendation: kpssCampaignItem.recommendation,
                value: `№${kpssCampaignItem.campaign_id}`,
                label: `№${kpssCampaignItem.campaign_id} \n${kpssCampaignItem.campaign_name}`,
                title: [`№${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.campaign_name}`],
                strategy: [`${kpssCampaignItem.bidding_strategy}`, `${kpssCampaignItem.daily_budget}`, `${kpssCampaignItem.daily_budget_period}`],
                bidding: kpssCampaignItem.recommendation,
                biddingId: [`${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.recommendation}`]
              }
            ])
          });

          setSummaryKpssFromClicks(data.summary.kpss_from_clicks)
          setSummaryKpssFromImpressions(data.summary.kpss_from_impressions)

          setLoadingData(false)
        })
    } else if (toogle == 2) {
      fetch(`${API_URL}/kpss/get_report/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(queryAdgroup)
      })
        .then(response => response.json())
        //.then(data => setKpssReportRow(data.data))
        .then((data) => {
          setLoading(false)
          setKpssAdgroupReportRow([])
          setKpssAdgroupReportRowSelect([])
          setPageCountKpssCampaign(data.total)
          data.results.map(kpssAdgroupItem => {
            setKpssAdgroupReportRow(kpssAdgroupReportRow => [
              ...kpssAdgroupReportRow,
              {
                adgroup_id: kpssAdgroupItem.adgroup_id,
                campaign_name: kpssAdgroupItem.campaign_name,
                campaign_id: kpssAdgroupItem.campaign_id,
                group_name: kpssAdgroupItem.group_name,
                kpss_from_impressions: kpssAdgroupItem.kpss_from_impressions,
                kpss_from_clicks: kpssAdgroupItem.kpss_from_clicks,
                ineffective_rate: kpssAdgroupItem.ineffective_rate,
                impressions: kpssAdgroupItem.impressions,
                rate: kpssAdgroupItem.rate,
                clicks: kpssAdgroupItem.clicks,
                conversions: kpssAdgroupItem.conversions,
                revenue: kpssAdgroupItem.revenue,
                autotarget_rate_part: kpssAdgroupItem.autotarget_rate_part,
                // impressions__autotarget: kpssAdgroupItem.impressions__autotarget,
                // rate__autotarget: kpssAdgroupItem.rate__autotarget,
                // clicks__autotarget: kpssAdgroupItem.clicks__autotarget,
                // conversions__autotarget: kpssAdgroupItem.conversions__autotarget,
                // revenue__autotarget: kpssAdgroupItem.revenue__autotarget,
                recommendation: kpssAdgroupItem.recommendation,
                value: `№${kpssAdgroupItem.adgroup_id}`,
                label: `№${kpssAdgroupItem.adgroup_id} \n${kpssAdgroupItem.group_name}`,
                campaign_group: [`№${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.group_name}`],
                campaign: [`№${kpssAdgroupItem.campaign_id}`, `${kpssAdgroupItem.campaign_name}`],
                biddingId: [`${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.recommendation}`]
              }
            ])

            setKpssAdgroupReportRowSelect(kpssAdgroupReportRowSelect => [
              ...kpssAdgroupReportRowSelect,
              {
                adgroup_id: kpssAdgroupItem.adgroup_id,
                campaign_name: kpssAdgroupItem.campaign_name,
                campaign_id: kpssAdgroupItem.campaign_id,
                group_name: kpssAdgroupItem.group_name,
                kpss_from_impressions: kpssAdgroupItem.kpss_from_impressions,
                kpss_from_clicks: kpssAdgroupItem.kpss_from_clicks,
                ineffective_rate: kpssAdgroupItem.ineffective_rate,
                impressions: kpssAdgroupItem.impressions,
                rate: kpssAdgroupItem.rate,
                clicks: kpssAdgroupItem.clicks,
                conversions: kpssAdgroupItem.conversions,
                revenue: kpssAdgroupItem.revenue,
                autotarget_rate_part: kpssAdgroupItem.autotarget_rate_part,
                // impressions__autotarget: kpssAdgroupItem.impressions__autotarget,
                // rate__autotarget: kpssAdgroupItem.rate__autotarget,
                // clicks__autotarget: kpssAdgroupItem.clicks__autotarget,
                // conversions__autotarget: kpssAdgroupItem.conversions__autotarget,
                // revenue__autotarget: kpssAdgroupItem.revenue__autotarget,
                recommendation: kpssAdgroupItem.recommendation,
                value: `№${kpssAdgroupItem.adgroup_id}`,
                label: `№${kpssAdgroupItem.adgroup_id} \n${kpssAdgroupItem.group_name}`,
                campaign_group: [`№${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.group_name}`],
                campaign: [`№${kpssAdgroupItem.campaign_id}`, `${kpssAdgroupItem.campaign_name}`],
                biddingId: [`${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.recommendation}`]
              }
            ])
          });

          setSummaryKpssFromClicks(data.summary.kpss_from_clicks)
          setSummaryKpssFromImpressions(data.summary.kpss_from_impressions)

          setLoadingData(false)
        });
    } else {
      fetch(`${API_URL}/kpss/get_report/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(queryPhrase)
      })
        .then(response => response.json())
        //.then(data => setKpssReportRow(data.data))
        .then((data) => {
          setLoading(false)
          setKpssPhrasesReportRow([])
          setKpssPhrasesReportRowSelect([])
          setPageCountKpssCampaign(data.total)
          data.results.map(kpssPhrasesItem => {
            setKpssPhrasesReportRow(kpssPhrasesReportRow => [
              ...kpssPhrasesReportRow,
              {
                key: kpssPhrasesItem.key,
                campaign_name: kpssPhrasesItem.campaign_name,
                campaign_id: kpssPhrasesItem.campaign_id,
                group_name: kpssPhrasesItem.group_name,
                adgroup_id: kpssPhrasesItem.adgroup_id,
                kpss: kpssPhrasesItem.kpss,
                key_id: kpssPhrasesItem.key_id,
                query: kpssPhrasesItem.query,
                impressions: kpssPhrasesItem.impressions,
                rate: kpssPhrasesItem.rate,
                clicks: kpssPhrasesItem.clicks,
                conversions: kpssPhrasesItem.conversions,
                revenue: kpssPhrasesItem.revenue,
                is_autotarget: kpssPhrasesItem.is_autotarget,
                // impressions__autotarget: kpssPhrasesItem.impressions__autotarget,
                // rate__autotarget: kpssPhrasesItem.rate__autotarget,
                // clicks__autotarget: kpssPhrasesItem.clicks__autotarget,
                // conversions__autotarget: kpssPhrasesItem.conversions__autotarget,
                // revenue__autotarget: kpssPhrasesItem.revenue__autotarget,
                value: `№${kpssPhrasesItem.key_id}`,
                label: `№${kpssPhrasesItem.key_id} \n${kpssPhrasesItem.key}`,
                key_phrase: [`№${kpssPhrasesItem.key_id}`, `${kpssPhrasesItem.key}`],
                ad_set: [`№${kpssPhrasesItem.adgroup_id}`, `${kpssPhrasesItem.group_name}`],
                campaign: [`№${kpssPhrasesItem.campaign_id}`, `${kpssPhrasesItem.campaign_name}`]
              }
            ])

            setKpssPhrasesReportRowSelect(kpssPhrasesReportRowSelect => [
              ...kpssPhrasesReportRowSelect,
              {
                key: kpssPhrasesItem.key,
                campaign_name: kpssPhrasesItem.campaign_name,
                campaign_id: kpssPhrasesItem.campaign_id,
                group_name: kpssPhrasesItem.group_name,
                adgroup_id: kpssPhrasesItem.adgroup_id,
                kpss: kpssPhrasesItem.kpss,
                key_id: kpssPhrasesItem.key_id,
                query: kpssPhrasesItem.query,
                impressions: kpssPhrasesItem.impressions,
                rate: kpssPhrasesItem.rate,
                clicks: kpssPhrasesItem.clicks,
                conversions: kpssPhrasesItem.conversions,
                revenue: kpssPhrasesItem.revenue,
                is_autotarget: kpssPhrasesItem.is_autotarget,
                // impressions__autotarget: kpssPhrasesItem.impressions__autotarget,
                // rate__autotarget: kpssPhrasesItem.rate__autotarget,
                // clicks__autotarget: kpssPhrasesItem.clicks__autotarget,
                // conversions__autotarget: kpssPhrasesItem.conversions__autotarget,
                // revenue__autotarget: kpssPhrasesItem.revenue__autotarget,
                value: `№${kpssPhrasesItem.key_id}`,
                label: `№${kpssPhrasesItem.key_id} \n${kpssPhrasesItem.key}`,
                key_phrase: [`№${kpssPhrasesItem.key_id}`, `${kpssPhrasesItem.key}`],
                ad_set: [`№${kpssPhrasesItem.adgroup_id}`, `${kpssPhrasesItem.group_name}`],
                campaign: [`№${kpssPhrasesItem.campaign_id}`, `${kpssPhrasesItem.campaign_name}`]
              }
            ])
          });

          setSummaryKpssFromClicks(data.summary.kpss_from_clicks)
          setSummaryKpssFromImpressions(data.summary.kpss_from_impressions)

          setLoadingData(false)
        });
    }

    fetch(`${API_URL}/get_attribution_models/`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        setAttributions(json)
        // json.map((object) => {
        //   setAttributions((attributions) => [
        //     ...attributions,
        //     {
        //       value: object.id,
        //       label: object.short_name,
        //       isDisabled: false
        //     }
        //   ]);
        // });
      });

    fetch(`${API_URL}/get_goals/?profile_id=${profileId}`, {
      method: 'GET',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        //setGoals(json)
        setGoals([])
        json.map(goalItem => {
          setGoals(goals => [
            ...goals,
            {
              name: goalItem.name,
              direct_id: goalItem.direct_id,
              id: goalItem.id,
              value: `${goalItem.id}`,
              label: `${goalItem.name}`
            }
          ])
        });
        // json.map((object) => {
        //   setGoals((goals) => [
        //     ...goals,
        //     {
        //       value: object.id,
        //       label: object.name,
        //       isDisabled: false
        //     }
        //   ]);
        // });
      });

    fetch(`${API_URL}/get_distribution_of_expenses/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(queryDoe)
    })
      .then(response => response.json())
      //.then(data => setKpssReportRow(data.data))
      .then((data) => {
        setLoading(false)
        setDoeReportRow([])
        if (data.status === "ERROR") {
          ///
        } else {
          data.results.map(doeItem => {
            setDoeReportRow(doeReportRow => [
              ...doeReportRow,
              {
                account_allocation: doeItem.account_allocation,
                clicks: doeItem.clicks,
                impressions: doeItem.impressions,
                rate: doeItem.rate,
                clicks_percent: doeItem.clicks_percent,
                impressions_percent: doeItem.impressions_percent,
                rate_percent: doeItem.rate_percent
              }
            ])
          });
        }

        setLoadingData(false)
      });
  }

  // const filterArticles = (searchValue) => {
  //   if (searchValue === "") {
  //     //console.log("пусто" + searchValue)
  //     return kpssReportRow;
  //   }
  //   //console.log("не пусто " + searchValue)
  //   return kpssReportRow.filter((item) =>
  //     item.campaign_id.includes(searchValue)
  //   );
  // };
  //
  // useEffect(() => {
  //   const filteredArticles = filterArticles(searchValue);
  //   //setKpssReportRow(filteredArticles);
  //
  // }, [searchValue]);

  // useEffect( () => {
  //   let agg = "campaigns"
  //   if (toogle == 1) {
  //     agg = "campaigns"
  //   } else if (toogle == 2) {
  //     agg = "adgroups"
  //   } else {
  //     agg = "phrases"
  //   }
  //
  //   const profileId = localStorage.getItem('profileId')
  //   setLoadingData(true)
  //
  //   if (localStorage.getItem("profileDateFrom") === null || localStorage.getItem("profileDateTo") === null) {
  //     localStorage.setItem('profileDateFrom', "0000-00-00")
  //     localStorage.setItem('profileDateTo', "0000-00-00")
  //     window.location.reload()
  //   }
  //   if (localStorage.getItem("profileAttributionValue") === null || localStorage.getItem("profileAttributionLabel") === null) {
  //     localStorage.setItem('profileAttributionValue', "8")
  //     localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
  //     window.location.reload()
  //   }
  //   if (localStorage.getItem("profileGoalValue") === null || localStorage.getItem("profileGoalLabel") === null) {
  //     localStorage.setItem('profileGoalValue', "0")
  //     localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
  //     window.location.reload()
  //   }
  //
  //   window.addEventListener('localStorage', () => {
  //     setProfileId(String(localStorage.getItem('profileId')))
  //     //setProfileDateFrom(String(localStorage.getItem('profileDateFrom')))
  //     //setProfileDateTo(String(localStorage.getItem('profileDateTo')))
  //   })
  //
  //   setSelectedOptionAttribution(defaultValueAttribution)
  //   setSelectedOptionGoal(defaultValueGoal)
  //
  //   // if (goal == 0) {
  //   //   setGoalForReport(false)
  //   // } else {
  //   //   setGoalForReport(true)
  //   // }
  //
  //   // if (profileGoalValue == "0") {
  //   //   setGoalForReport(false)
  //   // } else {
  //   //   setGoalForReport(true)
  //   // }
  //
  //   // const query = {
  //   //   "query": {
  //   //     "dimensions": [
  //   //       "kpss_kpssreportrow.campaign_name",
  //   //       "kpss_kpssreportrow.clicks",
  //   //       "kpss_kpssreportrow.impressions",
  //   //       "kpss_kpssreportrow.kpss_from_clicks",
  //   //       "kpss_kpssreportrow.revenue",
  //   //       "kpss_kpssreportrow.conversions",
  //   //       "kpss_kpssreportrow.kpss_from_impressions",
  //   //       "kpss_kpssreportrow.recommendation",
  //   //       "kpss_kpssreportrow.campaign_id",
  //   //       "kpss_kpssreportrow.rate",
  //   //       "kpss_kpssreportrow.ineffective_rate",
  //   //       "kpss_kpssreportrow.daily_budget",
  //   //       "kpss_kpssreportrow.bidding_strategy",
  //   //       "kpss_kpssreportrow.daily_budget_period",
  //   //       "kpss_kpssreportrow.campaign_state",
  //   //       "kpss_kpssreportrow.value",
  //   //       "kpss_kpssreportrow.label"
  //   //     ],
  //   //     "order": {
  //   //       "kpss_kpssreport.date_to": "asc"
  //   //     },
  //   //     "filters": [
  //   //       {
  //   //         "member": "kpss_kpssreportrow.campaign_name",
  //   //         "operator": "notEquals",
  //   //         "values": [
  //   //           "Общий_итог"
  //   //         ]
  //   //       }
  //   //     ],
  //   //     "limit": 5000
  //   //   }
  //   // };
  //
  //   // const headers = {
  //   //   'Content-Type': 'application/json',
  //   //   'Authorization': 'Bearer ab5b'
  //   // };
  //
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Token ${currentUser?.api_token}`
  //   }
  //
  //   // const getKpssReportRow = async () => {
  //   //   setLoading(true)
  //   //   await axios.post(`${API_URL}/cubejs-api/v1/load`, query, { headers })
  //   //   .then(function (response) {
  //   //     //console.log(response.data.data)
  //   //     setKpssReportRow(response.data.data)
  //   //     setLoading(false)
  //   //   })
  //   //   .catch(error => {
  //   //       console.error('There was an error!', error)
  //   //   });
  //   // }
  //   //
  //   // getKpssReportRow()
  //
  //   // fetch(`${API_URL}/cubejs-api/v1/load`, {
  //   //   method: 'POST',
  //   //   headers: headers,
  //   //   body: JSON.stringify(query)
  //   if (defaultValueGoal.value == "0") {
  //     if (toogle == 1) {
  //       //fetch(`${API_URL}/kpss/get_report/?profile_id=${profileId}&agg=${agg}&goal_ids=${[goal]}&attribution_ids=${[attribution]}&date_from=${startDate.toLocaleDateString('fr-CA')}&date_to=${endDate.toLocaleDateString('fr-CA')}&campaign_ids=${kpssCampaigns}&adgroup_ids=${kpssAdgroups}`, {
  //       fetch(`${API_URL}/kpss/get_report/?profile_id=${profileId}&agg=${agg}&goal_ids=${[goal]}&attribution_ids=${[attribution]}&date_from=${startDate.toLocaleDateString('fr-CA')}&date_to=${endDate.toLocaleDateString('fr-CA')}`, {
  //         method: 'GET',
  //         headers: headers
  //       })
  //         .then(response => response.json())
  //         //.then(data => setKpssReportRow(data.data))
  //         .then((data) => {
  //           //console.log(data)
  //           //console.log(data.results)
  //           // setKpssReportRow(data.data)
  //           // data.data.map((object) => {
  //           //   //console.log(object)
  //           //   setKpssReportRowSearch((kpssReportRowSearch) => [
  //           //     ...kpssReportRowSearch,
  //           //     {
  //           //       value: object["kpss_kpssreportrow.campaign_id"],
  //           //       label: object["kpss_kpssreportrow.campaign_name"]
  //           //     }
  //           //   ]);
  //           // });
  //
  //           // data.data.map(reportItem => {
  //           //   setKpssReportRow(kpssReportRow => [
  //           //     ...kpssReportRow,
  //           //     {
  //           //       campaign_name: reportItem["kpss_kpssreportrow.campaign_name"],
  //           //       clicks: reportItem["kpss_kpssreportrow.clicks"],
  //           //       impressions: reportItem["kpss_kpssreportrow.impressions"],
  //           //       kpss_from_clicks: reportItem["kpss_kpssreportrow.kpss_from_clicks"],
  //           //       revenue: reportItem["kpss_kpssreportrow.revenue"],
  //           //       conversions: reportItem["kpss_kpssreportrow.conversions"],
  //           //       kpss_from_impressions: reportItem["kpss_kpssreportrow.kpss_from_impressions"],
  //           //       recommendation: reportItem["kpss_kpssreportrow.recommendation"],
  //           //       campaign_id: reportItem["kpss_kpssreportrow.campaign_id"],
  //           //       rate: reportItem["kpss_kpssreportrow.rate"],
  //           //       ineffective_rate: reportItem["kpss_kpssreportrow.ineffective_rate"],
  //           //       daily_budget: reportItem["kpss_kpssreportrow.daily_budget"],
  //           //       bidding_strategy: reportItem["kpss_kpssreportrow.bidding_strategy"],
  //           //       daily_budget_period: reportItem["kpss_kpssreportrow.daily_budget_period"],
  //           //       campaign_state: reportItem["kpss_kpssreportrow.campaign_state"],
  //           //       value: `№${reportItem["kpss_kpssreportrow.value"]}`,
  //           //       label: `№${reportItem["kpss_kpssreportrow.label"]}`
  //           //       //value: reportItem["kpss_kpssreportrow.value"],
  //           //       //label: reportItem["kpss_kpssreportrow.label"]
  //           //     }
  //           //   ])
  //           //
  //           //   setKpssReportRowSelect(kpssReportRowSelect => [
  //           //     ...kpssReportRowSelect,
  //           //     {
  //           //       campaign_name: reportItem["kpss_kpssreportrow.campaign_name"],
  //           //       clicks: reportItem["kpss_kpssreportrow.clicks"],
  //           //       impressions: reportItem["kpss_kpssreportrow.impressions"],
  //           //       kpss_from_clicks: reportItem["kpss_kpssreportrow.kpss_from_clicks"],
  //           //       revenue: reportItem["kpss_kpssreportrow.revenue"],
  //           //       conversions: reportItem["kpss_kpssreportrow.conversions"],
  //           //       kpss_from_impressions: reportItem["kpss_kpssreportrow.kpss_from_impressions"],
  //           //       recommendation: reportItem["kpss_kpssreportrow.recommendation"],
  //           //       campaign_id: reportItem["kpss_kpssreportrow.campaign_id"],
  //           //       rate: reportItem["kpss_kpssreportrow.rate"],
  //           //       ineffective_rate: reportItem["kpss_kpssreportrow.ineffective_rate"],
  //           //       daily_budget: reportItem["kpss_kpssreportrow.daily_budget"],
  //           //       bidding_strategy: reportItem["kpss_kpssreportrow.bidding_strategy"],
  //           //       daily_budget_period: reportItem["kpss_kpssreportrow.daily_budget_period"],
  //           //       campaign_state: reportItem["kpss_kpssreportrow.campaign_state"],
  //           //       value: `№${reportItem["kpss_kpssreportrow.value"]}`,
  //           //       label: `№${reportItem["kpss_kpssreportrow.label"]}`
  //           //       //value: reportItem["kpss_kpssreportrow.value"],
  //           //       //label: reportItem["kpss_kpssreportrow.label"]
  //           //     }
  //           //   ])
  //           // });
  //           setKpssCampaignReportRow([])
  //           setKpssCampaignReportRowSelect([])
  //           setPageCountKpssCampaign(data.total)
  //           data.results.map(kpssCampaignItem => {
  //             setKpssCampaignReportRow(kpssCampaignReportRow => [
  //               ...kpssCampaignReportRow,
  //               {
  //                 campaign_id: kpssCampaignItem.campaign_id,
  //                 daily_budget: kpssCampaignItem.daily_budget,
  //                 daily_budget_period: kpssCampaignItem.daily_budget_period,
  //                 campaign_name: kpssCampaignItem.campaign_name,
  //                 kpss_from_impressions: kpssCampaignItem.kpss_from_impressions,
  //                 kpss_from_clicks: kpssCampaignItem.kpss_from_clicks,
  //                 bidding_strategy: kpssCampaignItem.bidding_strategy,
  //                 ineffective_rate: kpssCampaignItem.ineffective_rate,
  //                 state: kpssCampaignItem.state,
  //                 impressions: kpssCampaignItem.impressions,
  //                 rate: kpssCampaignItem.rate,
  //                 clicks: kpssCampaignItem.clicks,
  //                 conversions: kpssCampaignItem.conversions,
  //                 revenue: kpssCampaignItem.revenue,
  //                 recommendation: kpssCampaignItem.recommendation,
  //                 value: `№${kpssCampaignItem.campaign_id}`,
  //                 label: `№${kpssCampaignItem.campaign_id} \n${kpssCampaignItem.campaign_name}`,
  //                 title: [`№${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.campaign_name}`],
  //                 strategy: [`${kpssCampaignItem.bidding_strategy}`, `${kpssCampaignItem.daily_budget}`, `${kpssCampaignItem.daily_budget_period}`],
  //                 bidding: kpssCampaignItem.recommendation,
  //                 biddingId: [`${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.recommendation}`]
  //               }
  //             ])
  //
  //             setKpssCampaignReportRowSelect(kpssCampaignReportRowSelect => [
  //               ...kpssCampaignReportRowSelect,
  //               {
  //                 campaign_id: kpssCampaignItem.campaign_id,
  //                 daily_budget: kpssCampaignItem.daily_budget,
  //                 daily_budget_period: kpssCampaignItem.daily_budget_period,
  //                 campaign_name: kpssCampaignItem.campaign_name,
  //                 kpss_from_impressions: kpssCampaignItem.kpss_from_impressions,
  //                 kpss_from_clicks: kpssCampaignItem.kpss_from_clicks,
  //                 bidding_strategy: kpssCampaignItem.bidding_strategy,
  //                 ineffective_rate: kpssCampaignItem.ineffective_rate,
  //                 state: kpssCampaignItem.state,
  //                 impressions: kpssCampaignItem.impressions,
  //                 rate: kpssCampaignItem.rate,
  //                 clicks: kpssCampaignItem.clicks,
  //                 conversions: kpssCampaignItem.conversions,
  //                 revenue: kpssCampaignItem.revenue,
  //                 recommendation: kpssCampaignItem.recommendation,
  //                 value: `№${kpssCampaignItem.campaign_id}`,
  //                 label: `№${kpssCampaignItem.campaign_id} \n${kpssCampaignItem.campaign_name}`,
  //                 title: [`№${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.campaign_name}`],
  //                 strategy: [`${kpssCampaignItem.bidding_strategy}`, `${kpssCampaignItem.daily_budget}`, `${kpssCampaignItem.daily_budget_period}`],
  //                 bidding: kpssCampaignItem.recommendation,
  //                 biddingId: [`${kpssCampaignItem.campaign_id}`, `${kpssCampaignItem.recommendation}`]
  //               }
  //             ])
  //           });
  //           setLoadingData(false)
  //         });
  //     } else if (toogle == 2) {
  //       //fetch(`${API_URL}/kpss/get_report/?profile_id=${profileId}&agg=${agg}`, {
  //       //fetch(`${API_URL}/kpss/get_report/?profile_id=${profileId}&agg=${agg}&goal_ids=${[goal]}&attribution_ids=${[attribution]}&date_from=${startDate.toLocaleDateString('fr-CA')}&date_to=${endDate.toLocaleDateString('fr-CA')}&campaign_ids=${kpssCampaigns}&adgroup_ids=${kpssAdgroups}`, {
  //       fetch(`${API_URL}/kpss/get_report/?profile_id=${profileId}&agg=${agg}&goal_ids=${[goal]}&attribution_ids=${[attribution]}&date_from=${startDate.toLocaleDateString('fr-CA')}&date_to=${endDate.toLocaleDateString('fr-CA')}&campaign_ids=${kpssCampaigns}`, {
  //         method: 'GET',
  //         headers: headers
  //       })
  //         .then(response => response.json())
  //         //.then(data => setKpssReportRow(data.data))
  //         .then((data) => {
  //           setKpssAdgroupReportRow([])
  //           setKpssAdgroupReportRowSelect([])
  //           setPageCountKpssCampaign(data.total)
  //           data.results.map(kpssAdgroupItem => {
  //             setKpssAdgroupReportRow(kpssAdgroupReportRow => [
  //               ...kpssAdgroupReportRow,
  //               {
  //                 adgroup_id: kpssAdgroupItem.adgroup_id,
  //                 campaign_name: kpssAdgroupItem.campaign_name,
  //                 campaign_id: kpssAdgroupItem.campaign_id,
  //                 group_name: kpssAdgroupItem.group_name,
  //                 kpss_from_impressions: kpssAdgroupItem.kpss_from_impressions,
  //                 kpss_from_clicks: kpssAdgroupItem.kpss_from_clicks,
  //                 ineffective_rate: kpssAdgroupItem.ineffective_rate,
  //                 impressions: kpssAdgroupItem.impressions,
  //                 rate: kpssAdgroupItem.rate,
  //                 clicks: kpssAdgroupItem.clicks,
  //                 conversions: kpssAdgroupItem.conversions,
  //                 revenue: kpssAdgroupItem.revenue,
  //                 recommendation: kpssAdgroupItem.recommendation,
  //                 value: `№${kpssAdgroupItem.adgroup_id}`,
  //                 label: `№${kpssAdgroupItem.adgroup_id} \n${kpssAdgroupItem.group_name}`,
  //                 campaign_group: [`№${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.group_name}`],
  //                 campaign: [`№${kpssAdgroupItem.campaign_id}`, `${kpssAdgroupItem.campaign_name}`],
  //                 biddingId: [`${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.recommendation}`]
  //               }
  //             ])
  //
  //             setKpssAdgroupReportRowSelect(kpssAdgroupReportRowSelect => [
  //               ...kpssAdgroupReportRowSelect,
  //               {
  //                 adgroup_id: kpssAdgroupItem.adgroup_id,
  //                 campaign_name: kpssAdgroupItem.campaign_name,
  //                 campaign_id: kpssAdgroupItem.campaign_id,
  //                 group_name: kpssAdgroupItem.group_name,
  //                 kpss_from_impressions: kpssAdgroupItem.kpss_from_impressions,
  //                 kpss_from_clicks: kpssAdgroupItem.kpss_from_clicks,
  //                 ineffective_rate: kpssAdgroupItem.ineffective_rate,
  //                 impressions: kpssAdgroupItem.impressions,
  //                 rate: kpssAdgroupItem.rate,
  //                 clicks: kpssAdgroupItem.clicks,
  //                 conversions: kpssAdgroupItem.conversions,
  //                 revenue: kpssAdgroupItem.revenue,
  //                 recommendation: kpssAdgroupItem.recommendation,
  //                 value: `№${kpssAdgroupItem.adgroup_id}`,
  //                 label: `№${kpssAdgroupItem.adgroup_id} \n${kpssAdgroupItem.group_name}`,
  //                 campaign_group: [`№${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.group_name}`],
  //                 campaign: [`№${kpssAdgroupItem.campaign_id}`, `${kpssAdgroupItem.campaign_name}`],
  //                 biddingId: [`${kpssAdgroupItem.adgroup_id}`, `${kpssAdgroupItem.recommendation}`]
  //               }
  //             ])
  //           });
  //           setLoadingData(false)
  //         });
  //     } else {
  //       //fetch(`${API_URL}/kpss/get_report/?profile_id=${profileId}&agg=${agg}`, {
  //       fetch(`${API_URL}/kpss/get_report/?profile_id=${profileId}&agg=${agg}&goal_ids=${[goal]}&attribution_ids=${[attribution]}&date_from=${startDate.toLocaleDateString('fr-CA')}&date_to=${endDate.toLocaleDateString('fr-CA')}&campaign_ids=${kpssCampaigns}&adgroup_ids=${kpssAdgroups}`, {
  //         method: 'GET',
  //         headers: headers
  //       })
  //         .then(response => response.json())
  //         //.then(data => setKpssReportRow(data.data))
  //         .then((data) => {
  //           setKpssPhrasesReportRow([])
  //           setKpssPhrasesReportRowSelect([])
  //           setPageCountKpssCampaign(data.total)
  //           data.results.map(kpssPhrasesItem => {
  //             setKpssPhrasesReportRow(kpssPhrasesReportRow => [
  //               ...kpssPhrasesReportRow,
  //               {
  //                 key: kpssPhrasesItem.key,
  //                 campaign_name: kpssPhrasesItem.campaign_name,
  //                 campaign_id: kpssPhrasesItem.campaign_id,
  //                 group_name: kpssPhrasesItem.group_name,
  //                 adgroup_id: kpssPhrasesItem.adgroup_id,
  //                 kpss: kpssPhrasesItem.kpss,
  //                 key_id: kpssPhrasesItem.key_id,
  //                 query: kpssPhrasesItem.query,
  //                 impressions: kpssPhrasesItem.impressions,
  //                 rate: kpssPhrasesItem.rate,
  //                 clicks: kpssPhrasesItem.clicks,
  //                 conversions: kpssPhrasesItem.conversions,
  //                 revenue: kpssPhrasesItem.revenue,
  //                 value: `№${kpssPhrasesItem.key_id}`,
  //                 label: `№${kpssPhrasesItem.key_id} \n${kpssPhrasesItem.key}`,
  //                 key_phrase: [`№${kpssPhrasesItem.key_id}`, `${kpssPhrasesItem.key}`],
  //                 ad_set: [`№${kpssPhrasesItem.adgroup_id}`, `${kpssPhrasesItem.group_name}`],
  //                 campaign: [`№${kpssPhrasesItem.campaign_id}`, `${kpssPhrasesItem.campaign_name}`]
  //               }
  //             ])
  //
  //             setKpssPhrasesReportRowSelect(kpssPhrasesReportRowSelect => [
  //               ...kpssPhrasesReportRowSelect,
  //               {
  //                 key: kpssPhrasesItem.key,
  //                 campaign_name: kpssPhrasesItem.campaign_name,
  //                 campaign_id: kpssPhrasesItem.campaign_id,
  //                 group_name: kpssPhrasesItem.group_name,
  //                 adgroup_id: kpssPhrasesItem.adgroup_id,
  //                 kpss: kpssPhrasesItem.kpss,
  //                 key_id: kpssPhrasesItem.key_id,
  //                 query: kpssPhrasesItem.query,
  //                 impressions: kpssPhrasesItem.impressions,
  //                 rate: kpssPhrasesItem.rate,
  //                 clicks: kpssPhrasesItem.clicks,
  //                 conversions: kpssPhrasesItem.conversions,
  //                 revenue: kpssPhrasesItem.revenue,
  //                 value: `№${kpssPhrasesItem.key_id}`,
  //                 label: `№${kpssPhrasesItem.key_id} \n${kpssPhrasesItem.key}`,
  //                 key_phrase: [`№${kpssPhrasesItem.key_id}`, `${kpssPhrasesItem.key}`],
  //                 ad_set: [`№${kpssPhrasesItem.adgroup_id}`, `${kpssPhrasesItem.group_name}`],
  //                 campaign: [`№${kpssPhrasesItem.campaign_id}`, `${kpssPhrasesItem.campaign_name}`]
  //               }
  //             ])
  //           });
  //           setLoadingData(false)
  //         });
  //     }
  //   }
  //
  //   // const getAttribution = async () => {
  //   //   await axios.get(`${API_URL}/get_attribution_models/`)
  //   //   .then(function (response) {
  //   //     //console.log(response.data.data)
  //   //     setAttributions(response.data)
  //   //   })
  //   //   .catch(error => {
  //   //       console.error('There was an error!', error)
  //   //   });
  //   // }
  //   //
  //   // getAttribution()
  //
  //   fetch(`${API_URL}/get_attribution_models/`, {
  //     method: 'GET',
  //     headers: headers
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       //console.log(json);
  //       setAttributions(json)
  //       // json.map((object) => {
  //       //   setAttributions((attributions) => [
  //       //     ...attributions,
  //       //     {
  //       //       value: object.id,
  //       //       label: object.short_name,
  //       //       isDisabled: false
  //       //     }
  //       //   ]);
  //       // });
  //     });
  //
  //   // const getGoals = async () => {
  //   //   await axios.get(`${API_URL}/get_goals/`)
  //   //   .then(function (response) {
  //   //     //console.log(response.data.data)
  //   //     setGoals(response.data)
  //   //   })
  //   //   .catch(error => {
  //   //       console.error('There was an error!', error)
  //   //   });
  //   // }
  //   //
  //   // getGoals()
  //
  //   fetch(`${API_URL}/get_goals/?profile_id=${profileId}`, {
  //     method: 'GET',
  //     headers: headers
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       //console.log(json);
  //       //setGoals(json)
  //       setGoals([])
  //       json.map(goalItem => {
  //         setGoals(goals => [
  //           ...goals,
  //           {
  //             name: goalItem.name,
  //             direct_id: goalItem.direct_id,
  //             id: goalItem.id,
  //             value: `${goalItem.value}`,
  //             label: `${goalItem.label} ${goalItem.direct_id}`
  //           }
  //         ])
  //       });
  //       // json.map((object) => {
  //       //   setGoals((goals) => [
  //       //     ...goals,
  //       //     {
  //       //       value: object.id,
  //       //       label: object.name,
  //       //       isDisabled: false
  //       //     }
  //       //   ]);
  //       // });
  //     });
  //
  //   fetch(`${API_URL}/get_dataset_period/?profile_id=${profileId}&section=kpss`, {
  //     method: 'GET',
  //     headers: headers
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       //console.log(json);
  //       if (json.status == "ERROR") {
  //         ///
  //       } else if (json.result.date_from == null || json.result.date_to == null) {
  //         ///
  //       } else if (localStorage.getItem('profileDateFrom') != "0000-00-00" && localStorage.getItem('profileDateTo') != "0000-00-00") {
  //         setStartDate(new Date(String(localStorage.getItem('profileDateFrom'))))
  //         setEndDate(new Date(String(localStorage.getItem('profileDateTo'))))
  //         const dateFromResult = new Date(json.result.date_from)
  //         const dateToResult = new Date(json.result.date_to)
  //         setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
  //         setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
  //       } else {
  //         const dateFromResult = new Date(json.result.date_from)
  //         const dateToResult = new Date(json.result.date_to)
  //         const lastDateFromResult = new Date(json.result.last_date_from)
  //         const lastDateToResult = new Date(json.result.last_date_to)
  //         setStartDate(new Date(lastDateFromResult.getFullYear(), lastDateFromResult.getMonth(), lastDateFromResult.getDate()))
  //         setEndDate(new Date(lastDateToResult.getFullYear(), lastDateToResult.getMonth(), lastDateToResult.getDate()))
  //         //setStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
  //         //setEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
  //         setAvailableStartDate(new Date(dateFromResult.getFullYear(), dateFromResult.getMonth(), dateFromResult.getDate()))
  //         setAvailableEndDate(new Date(dateToResult.getFullYear(), dateToResult.getMonth(), dateToResult.getDate()))
  //       }
  //       //setGoals(json)
  //       // json.map((object) => {
  //       //   setGoals((goals) => [
  //       //     ...goals,
  //       //     {
  //       //       value: object.id,
  //       //       label: object.name,
  //       //       isDisabled: false
  //       //     }
  //       //   ]);
  //       // });
  //
  //       // Рабочий вариант
  //       if (defaultValueGoal.value == "0") {
  //         setGoalForReport(false)
  //       } else {
  //         setGoalForReport(true)
  //       }
  //     });
  // }, [profileId, toogle])

  ///const lastKpssRowIndex = currentPage * kpssRowPerPage
  ///const firstKpssRowIndex = lastKpssRowIndex - kpssRowPerPage
  //const currentKpssRow = kpssReportRow.slice(firstKpssRowIndex, lastKpssRowIndex)
  //const filter = kpssReportRow.filter(item => item.campaign_id.includes(searchValue))

  // const currentKpssRow = filtering ? kpssReportRow.filter(item => item.campaign_id.includes(searchValue)) : kpssReportRow.slice(firstKpssRowIndex, lastKpssRowIndex)
  // const currentKpssRow = filtering ? kpssReportRow.filter(q => searchValue.every(k => q.campaign_id.includes(k))) : kpssReportRow.slice(firstKpssRowIndex, lastKpssRowIndex) //item.includes(searchValue)) : kpssReportRow.slice(firstKpssRowIndex, lastKpssRowIndex) //???

  //const myArrayFiltered = data.filter((el) => !secondData.includes(el.serviceId));

  const currentKpssCampaingRow = filteringCampaign ? kpssCampaignReportRow.filter((item) => searchValueCampaing.includes(String(item.campaign_id))) : kpssCampaignReportRow //.slice(firstKpssRowIndex, lastKpssRowIndex)
  const currentKpssAdgroupRow = filteringAdgroup ? kpssAdgroupReportRow.filter((item) => searchValueAdgroup.includes(String(item.adgroup_id))) : kpssAdgroupReportRow
  const currentKpssPhrasesRow = filteringPhrases ? kpssPhrasesReportRow.filter((item) => searchValuePhrases.includes(String(item.key_id))) : kpssPhrasesReportRow

  // const data = {
  //   nodes: list.filter((item) =>
  //     item.name.includes(search)
  //   ),
  // };

  function connectBidderCampaign(event, campaignId) {
    if (localStorage.getItem("chooseAdgroupId") !== null) {
      localStorage.removeItem("chooseAdgroupId")
    }

    localStorage.setItem('chooseCampaignId', `${campaignId}`)
  }

  function connectBidderAdgroup(event, campaignId) {
    if (localStorage.getItem("chooseCampaignId") !== null) {
      localStorage.removeItem("chooseCampaignId")
    }

    localStorage.setItem('chooseAdgroupId', `${campaignId}`)
  }

  const paginate = pageNumber => setCurrentPage(pageNumber)
  const nextPage = () => setCurrentPage( prev => prev + 1 )
  const prevPage = () => setCurrentPage( prev => prev - 1 )

  // if (loading) {
  //   return <h2>Загрузка...</h2>
  // }

  const pageNumbers: number[] = []

  for (let i = 1; i <= Math.ceil(kpssCampaignReportRow.length / kpssRowPerPage); i++) {
    pageNumbers.push(i)
  }

  // useEffect( () => {
  //   document.addEventListener('scroll', scrollHandler)
  //   return function() {
  //     document.removeEventListener('scroll', scrollHandler)
  //   };
  // }, [])

  // useEffect( () => {
  //   const getAttribution = async () => {
  //     await axios.get(`${API_URL}/get_attribution_models/`)
  //     .then(function (response) {
  //       //console.log(response.data.data)
  //       setAttributions(response.data)
  //     })
  //     .catch(error => {
  //         console.error('There was an error!', error)
  //     });
  //   }
  //
  //   getAttribution()
  // }, [])

  // useEffect( () => {
  //   const getGoals = async () => {
  //     await axios.get(`${API_URL}/get_goals/`)
  //     .then(function (response) {
  //       //console.log(response.data.data)
  //       setGoals(response.data)
  //     })
  //     .catch(error => {
  //         console.error('There was an error!', error)
  //     });
  //   }
  //
  //   getGoals()
  // }, [])

  const [selectedKpssCampaign, setSelectedKpssCampaign] = useState({})
  const [selectedKpssAdgroup, setSelectedKpssAdgroup] = useState({})

  const handleOnExport = () => {
    var wb = XLSX.utils.book_new()
    if (toogle == 1) {
      //console.log(campaignReportRow)
      const header = ['Кампания',
        'Среднев. через показы рел. заголовка',
        'Среднев. через клики рел. заголовка',
        'Среднев. через показы рел. текста',
        'Среднев. через клики рел. текста',
        'Показы',
        'Клики',
        'Расход',
        'Конверсии',
        'Доход'];
      // const campaignData = (campaignReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.campaigns
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(kpssCampaignReportRow) //, {header}
      XLSX.utils.book_append_sheet(wb, ws, "KPSSCampaign")
      XLSX.writeFile(wb, "KPSSCampaign.xlsx")
    } else if (toogle == 2) {
      //console.log(adgroupReportRow)
      const header = ['Группы',
        'Среднев. через показы рел. заголовка',
        'Среднев. через клики рел. заголовка',
        'Среднев. через показы рел. текста',
        'Среднев. через клики рел. текста',
        'Показы',
        'Клики',
        'Расход',
        'Конверсии',
        'Доход'];
      // const adgroupData = (adgroupReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.groups
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(kpssAdgroupReportRow)
      XLSX.utils.book_append_sheet(wb, ws, "KPSSAdgroup")
      XLSX.writeFile(wb, "KPSSAdgroup.xlsx")
    } else {
      //console.log(advertReportRow)
      const header = ['Заголовок объявления',
        'Текст объявления',
        'Поисковый запрос',
        'Релевантность заголовка запросу',
        'Релевантность текста запросу',
        'Показы',
        'Клики',
        'Расход',
        'Конверсии',
        'Доход'];
      // const advertData = (advertReportRow).map(row => {
      //   delete row.value
      //   delete row.label
      //   delete row.title_announcement
      //   return row
      // })

      var ws = XLSX.utils.json_to_sheet(kpssPhrasesReportRow)
      XLSX.utils.book_append_sheet(wb, ws, "KPSSPhrases")
      XLSX.writeFile(wb, "KPSSPhrases.xlsx")
    }

    sendMetricGoalVisitParametr('reachGoal', 'table_download_report-page', {
      [`${localStorage.getItem('profileName')}`]: {
        [`${window.location.pathname}`]: {
          "table_download_report-page": {}
        }
      }
    })
  }

  const handleResetFilters = () => {
    // setToggle(1)

    // setKpssCampaigns([])
    // setSelectedKpssCampaign({})
    // localStorage.removeItem("chooseKpssCampaignId")
    // setIsActiveGroups(true)
    // setIsActivePhrases(true)
    // setKpssAdgroups([]) // Убираем выбранные группы при выборе кампаний
    // setSelectedKpssAdgroup({}) // Убираем выбранные группы при выборе кампаний

    setSelectedOptionSearchCampaign([])
    setSelectedOptionSearchAdgroup([])
    setSelectedOptionSearchPhrases([])

    setFilteringCampaign(false)
    setFilteringAdgroup(false)
    setFilteringPhrases(false)

    //setStartDate(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
    //setEndDate(new Date(nowDate.getFullYear(), nowDate.getMonth(), 0))
    //setMinDate(new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1))
    //setMaxDate(nowDate)
    //setAvailableStartDate(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 7))
    //setAvailableEndDate(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1))
    localStorage.setItem('profileDateFrom', "0000-00-00")
    localStorage.setItem('profileDateTo', "0000-00-00")
    setAttribution(8)
    localStorage.setItem('profileAttributionValue', "8")
    localStorage.setItem('profileAttributionLabel', "Автоматическая атрибуция")
    setSelectedOptionAttribution(defaultValueAttribution)
    setGoal(0)
    localStorage.setItem('profileGoalValue', "0")
    localStorage.setItem('profileGoalLabel', "Выберите данные по целям")
    setSelectedOptionGoal(defaultValueGoal)
    setAutotarget("keywords")
    localStorage.setItem('profileAutotargetValue', "keywords")
    localStorage.setItem('profileAutotargetLabel', "Выберите условие показа")
    setSelectedOptionAutotarget(defaultValueAutotarget)

    setButtonResetFilters(true)

    fetchDate()
  }

  const checkboxColumn = {
    renderCheckbox: (checkboxProps, cellProps) => {
      const { onChange, checked } = checkboxProps
      //console.log(cellProps.data)
      //console.log(checkboxProps)

      if (checkboxProps.checked == true) {
        return (
          <CheckBox
            checked={true}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }

      if (checkboxProps.checked == false && kpssCampaigns.length < 1) { // || kpssCampaigns.length > 1
        return (
          <CheckBox
            checked={false}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }

      if (checkboxProps.checked == false && kpssCampaigns.length > 1) {
        return (
          <CheckBox
            checked={false}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }

      if (checkboxProps.checked == null && kpssCampaigns.length > 1) {
        return (
          <CheckBox
            checked={true}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }

      if (checkboxProps.checked == true && kpssCampaigns.length < 1) {
        return (
          <CheckBox
            //checked={false}
            disabled={false}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      } else {
        return (
          <CheckBox
            disabled={true}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          >
          </CheckBox>
        );
      }
    }
  }

  const formatOptionLabelGoal = ({ value, label }) => (
    <div style={{ display: "flex" }}>
      <div>{label}</div>
      <div style={{ marginLeft: "10px", color: "#ccc" }}>
        {value != 0 ? value : ''}
      </div>
    </div>
  )

  const editedKpssPhrasesReportRowSelect = Array.from(new Map(kpssPhrasesReportRowSelect.map(item => [item.key_id, item])).values())

  const [defaultFilterValueCampaign, setDefaultFilterValueCampaign] = useState([
    { name: 'kpss_from_clicks', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'kpss_from_impressions', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null }
  ])

  const onFilterValueChangeCampaign = useCallback((filterValue) => {
    setDefaultFilterValueCampaign(filterValue)
  }, [])

  const [defaultFilterValueAdgroup, setDefaultFilterValueAdgroup] = useState([
    { name: 'kpss_from_clicks', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null },
    { name: 'kpss_from_impressions', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null }
  ])

  const onFilterValueChangeAdgroup = useCallback((filterValue) => {
    setDefaultFilterValueAdgroup(filterValue)
  }, [])

  const [defaultFilterValuePhrases, setDefaultFilterValuePhrases] = useState([
    { name: 'kpss', type: 'number', operator: 'inrange', value: {start: null, end: null}, emptyValue: null }
  ])

  const onFilterValueChangePhrases = useCallback((filterValue) => {
    setDefaultFilterValuePhrases(filterValue)
  }, [])

  return (
    <div>
      {/* <div>{JSON.stringify(kpssReportRow)}</div> */}
      {/* <div>{JSON.stringify(searchValue)}</div>
      <div>===================================</div>
      <div>{JSON.stringify(kpssReportRow)}</div> */}
      <div className='card-container'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex flex-column'>
          <ol className="breadcrumb breadcrumb-dot text-muted fs-7 fw-bold mb-5">
            <li className="breadcrumb-item pe-3">

              <a href="#" className="pe-1">
                <i className="fa-solid fa-house text-muted fs-4" />
              </a>
            </li>
            <li className="breadcrumb-item px-0 text-muted">Отчет о КПСС </li>
          </ol>
          {/*<h3 className='card-title align-items-start flex-column'>*/}
          {/*  {<span className='card-label fw-bold fs-3 mb-1'>Отчет о КПСС <span className='text-muted fs-5'>({pageCountKpssCampaign})</span></span>}*/}
          {/*  /!* <span className='text-muted mt-1 fw-semibold fs-7'>Выберете рекламные кампании для оптимизации КПСС</span> *!/*/}
          {/*</h3>*/}

          <div className='row d-flex justify-content-between'>
            <div className='col'>
              <h2 className='card-title align-items-start flex-column mb-3'>
                <span className='card-label fw-bold fs-3 mb-1'>Отчет о КПСС <span className='text-muted fs-5'>({pageCountKpssCampaign})</span></span>
              </h2>
              <h5 className='mb-2 text-muted'>&nbsp;</h5>
            </div>
            <div className='col d-flex justify-content-end'>
              <button className='btn btn-light-primary fw-semibold' onClick={handleOnExport}>Выгрузить в XLSX
                <KTIcon iconName='arrow-down-right' className='fs-4  ms-1' />
              </button>

              <button
                className={`btn fw-semibold btn-light-primary ${buttonResetFilters ? "btn-reset-disabled" : "btn-reset"}`}
                onClick={handleResetFilters}
                disabled={buttonResetFilters}
              >
                Сбросить фильтры
              </button>
            </div>
          </div>

          <ul className="nav nav-tabs nav-line-tabs mt-5 mb-8 fs-6 border-bottom-0">
            <li className="nav-item">
              <a
                className={toogle === 1 ? "nav-link fw-bold active" : "nav-link fw-bold"}
                //data-bs-toggle="tab"
                //href="#kt_tab_pane_1"
                href="#"
                onClick={()=>updateToggle(1)}
              >
                Кампании
              </a>
            </li>
            <li className="nav-item">
              <a
                className={toogle === 2 ? `nav-link fw-bold active` : `nav-link fw-bold ${isActiveGroups ? "disabled" : ""}`}
                //data-bs-toggle="tab"
                //href="#kt_tab_pane_2"
                href="#"
                onClick={()=>updateToggle(2)}
              >
                Группы
              </a>
            </li>
            <li className="nav-item">
              <a
                className={toogle === 3 ? `nav-link fw-bold active` : `nav-link fw-bold ${isActivePhrases ? "disabled" : ""}`}
                //data-bs-toggle="tab"
                //href="#kt_tab_pane_3"
                href="#"
                onClick={()=>updateToggle(3)}
              >
                Фразы + Поисковые запросы
              </a>
            </li>
          </ul>

        </div>
        {/* end::Header */}


        <div className="tab-content" id="myTabContent">
          <div className={toogle === 1 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_1" role="tabpanel">
            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-7 mb-md-5 mb-xl-6'>
                </div>
                <div className='col-5 mb-md-5 mb-xl-6 text-period'>
                  Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""} | {autotargetDateFrom.toLocaleDateString() == new Date(0).toLocaleDateString() ? "Автотаргетинг в НГРАМ отсутствует" : `Автотаргетинг с ${autotargetDateFrom.toLocaleDateString()}`}
                </div>
              </div>
              <div className='row'>
                <div className='col-7 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                  <i className="bi bi-search fs-6 fw-bold" />
                </span>
                    {/* <Select options={kpssReportRowSelect} className='react-select-container form-control border-start-0 p-0' classNamePrefix="react-select" placeholder="Поиск по названию или номеру РК" defaultValue={{value: "0", label: "Поиск по названию или номеру РК"}} onChange={handleSearch} isClearable /> */}
                    <Select
                      className='react-select-container form-control border-start-0 p-0 my-search'
                      classNamePrefix="react-select"
                      options={kpssCampaignReportRowSelect}
                      placeholder="Поиск по названию или номеру РК"
                      defaultValue={selectedOptionSearchCampaign}
                      value={selectedOptionSearchCampaign}
                      onChange={handleSearchCampaign}
                      isLoading={loadingData}
                      isMulti
                      onFocus={() => setSelectFocus(2)}
                      onBlur={onBlur}
                    />
                  </div>
                </div>
                <div className='col-5 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                  <i className="bi bi-calendar4-event fs-6 fw-bold" />
                </span>
                    {/* <select className="form-select border-start-0 ps-0 text-muted" aria-label="Select example">
                  <option>9 янв 2023 - 12 апр 2023</option>
                  <option value="1">9 янв 2023 - 12 апр 2023</option>
                  <option value="2">9 янв 2023 - 12 апр 2023</option>
                  <option value="3">9 янв 2023 - 12 апр 2023</option>
                </select> */}
                    <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                      //locale={locale}
                      locale="ru"
                      selected={startDate}
                      onChange={handleChangeDate}
                      minDate={minDate}
                      maxDate={maxDate}
                      selectsRange
                      startDate={startDate}
                      endDate={endDate}
                      //dateFormat="yyyy-MM-dd"
                      dateFormat="d MMMM yyyy"
                    />

                  </div>


                </div>
                {/* <div className='col-3 mb-md-5 mb-xl-6'>
            <a href="#" className="btn btn-light-primary w-100"><i className="bi bi-funnel fs-6 me-1"></i>Расширенный фильтр</a>
            </div> */}
              </div>
              <div className='row mb-5'>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Атрибуция
                    <Tooltip text="По умолчанию используется Автоматическая атрибуция">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Данные по целям
                    <Tooltip text="По умолчанию для выбора доступны цели из настроек РК">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon" placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Условия показа
                    <Tooltip text="По умолчанию используется 'Ключевые фразы'">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={autotargets} className="form-select react-select-container p-0 my-select" classNamePrefix="no-icon" placeholder="Выберите условие показа" value={selectedOptionAutotarget} defaultValue={defaultValueAutotarget} onChange={handleAutotarget} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
              {/*  <div className='col-3 mb-md-3 mb-xl-6'>*/}
              {/*    /!* <a href="#" className='btn btn-primary text-light fw-bold w-auto px-3 py-2' onClick={getKpssReport}>*/}
              {/*  Сформировать отчет*/}
              {/*</a> *!/*/}

              {/*    <button*/}
              {/*      onClick={getKpssReport}*/}
              {/*      className='btn btn-lg btn-primary w-100 mb-5 rounded-2'*/}
              {/*      disabled={buttonSendReport}*/}
              {/*    >*/}
              {/*      {!loading && <span className='indicator-label'>Сформировать отчет</span>}*/}
              {/*      {loading && (*/}
              {/*        <span className='indicator-progress' style={{display: 'block'}}>*/}
              {/*      Пожалуйста подождите...{' '}*/}
              {/*          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>*/}
              {/*    </span>*/}
              {/*      )}*/}
              {/*    </button>*/}
              {/*  </div>*/}
              </div>

              <div className='row mb-10'>
                {/*{checkUser && (*/}
                <div className='col-7'>
                  <div className='bg-light'>
                    <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                      {/* begin::Table head */}
                      <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>Доля по типу трафика</span>
                          </div>
                        </th>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>%Показов</span>
                          </div>
                        </th>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>%Кликов</span>
                          </div>
                        </th>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>%Расходов</span>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                      {doeReportRow.map((value, key) => {
                        return (
                      <tr key={key} data-key={key}>
                        <td>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*Поиск: Автотаргетинг*/}
                              {/*{value.account_allocation}*/}
                              {(() => {
                                if (value.account_allocation == 'AD_NETWORK') {
                                  return (
                                    "Сеть"
                                  )
                                } else if (value.account_allocation == 'AUTOTARGETING') {
                                  return (
                                    "Поиск: Автотаргетинг"
                                  )
                                } else if (value.account_allocation == 'FEED_FILTER') {
                                  return (
                                    "Поиск: Фид"
                                  )
                                } else if (value.account_allocation == 'RETARGETING') {
                                  return (
                                    "Поиск: Аудитория"
                                  )
                                } else if (value.account_allocation == 'WEBPAGE_FILTER') {
                                  return (
                                    "Поиск: Содержание URL"
                                  )
                                } else if (value.account_allocation == 'OFFER_RETARGETING') {
                                  return (
                                    "Поиск: Офферный ретаргетинг"
                                  )
                                } else if (value.account_allocation == 'KEYWORD') {
                                  return (
                                    "Поиск: Ключевые фразы"
                                  )
                                } else {
                                  return (
                                    ""
                                  )
                                }
                              })()}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center justify-content-end'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*1,16%*/}
                              {value.impressions_percent}%
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center justify-content-end'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*1,16%*/}
                              {value.clicks_percent}%
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center justify-content-end'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*1,16%*/}
                              {value.rate_percent}%
                            </div>
                          </div>
                        </td>
                      </tr>)
                            })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                </div>
                {/*)}*/}
                <div className='col-5'>
                  <div className='bg-light'>
                    <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                      {/* begin::Table head */}
                      <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>Средневзвешенное значение КПСС по аккаунту</span>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              КПСС через клики
                            </div>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*44,08%*/}
                              {summaryKpssFromClicks}%
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              КПСС через показы
                            </div>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*55,92%*/}
                              {summaryKpssFromImpressions}%
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                </div>
              </div>

              {/* begin::Table container */}
              <ReactDataGrid
                  ///checkboxColumn={true}
                  checkboxColumn={checkboxColumn} // Object for disabled checkbox
                  checkboxOnlyRowSelect={true}
                  className='table__data-grid'
                  idProperty="campaign_id"
                  showZebraRows={false}
                  style={gridStyle}
                  activateRowOnFocus={activateRowOnFocus}
                  columns={columnsKpssCampaingn}
                  dataSource={currentKpssCampaingRow}
                  pagination={true}
                  //limit={10}
                  defaultLimit={10}
                  rowHeight={null}
                  minRowHeight={63}
                  onSelectionChange={onSelectionKpssCampaign}
                  selected={selectedKpssCampaign}
                  editable={true}
                  loading={loadingData}
                  loadingText={pleaseWait}
                  enableFiltering={true}
                  defaultFilterValue={defaultFilterValueCampaign}
                  onFilterValueChange={onFilterValueChangeCampaign}
                  i18n={translateRDG}
                  enableColumnAutosize={false}
              />

              <div className='table-responsive'>
                {/* begin::Table */}
                <ScrollContainer>
                  <table className='table table-bordered border align-middle mb-0 gx-3 gy-3' style={{ display: 'none' }}>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-300px text-nowrap'>
                        <div className='d-flex flex-row align-items-center'>
                          <div className='form-check form-check-ms form-check-custom form-check'>
                            <input
                              className='form-check-input  '
                              type='hidden'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-9-check'
                            />
                          </div>
                          <div className='ms-3 text-muted fw-semibold fs-7'>
                            Название
                          </div>

                        </div>

                      </th>
                      <th className='min-w-175px text-nowrap text-muted fw-semibold fs-7'>КПСС через клики </th>
                      <th className='min-w-175px text-nowrap text-muted fw-semibold fs-7'>КПСС через показы </th>
                      <th className='min-w-175px text-nowrap text-muted fw-semibold fs-7'>Бюджет и стратегия</th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold fs-7'>Показы</th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold fs-7'>Клики</th>
                      <th className='min-w-175px text-nowrap text-muted fw-semibold fs-7'>Расход с НДС</th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold fs-7'>Конверсии</th>
                      <th className='min-w-125px text-nowrap text-muted fw-semibold fs-7'>Доход</th>
                      <th className='min-w-200px text-nowrap text-muted fw-semibold fs-7'>Неэффективный расход</th>
                      <th className='min-w-450px text-nowrap text-muted fw-semibold fs-7'>Рекомендации</th>
                      <th className='min-w-175px text-nowrap text-muted fw-semibold fs-7'>Биддинг</th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>


                    {currentKpssCampaingRow.map((value, key) => {
                      return (

                        <tr key={key} data-key={key}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='form-check form-check-ms form-check-custom form-check '>
                                <input className='form-check-input widget-9-check' type='hidden' value='1' />
                              </div>
                              <div className='d-flex justify-content-start flex-column ms-3'>
                              <span className='text-muted fw-semibold text-muted d-block fs-7 blur'>
                                {/* №3222123 */}
                                №{value.campaign_id}
                              </span>
                                <div className='text-dark fw-bold fs-6 blur'>
                                  {/* Поздравление Деда Мороза — МСК */}
                                  {value.campaign_name}
                                </div>
                                <div>
                                  {/* <button className='btn btn-light text-muted fw-bold w-auto px-3 py-2'><i className="bi bi-play-fill text-muted fs-2"></i> Запустить</button> */}
                                  {/*value.campaign_state == "ON" ?
                                    <button className='btn btn-light-success fw-bold  w-auto px-3 py-2' onClick={(event) => {campaignState(event, value.campaign_id, "suspend")}}><i className="bi bi-pause-fill fs-2"></i> Остановить</button> :
                                    <button className='btn btn-light text-muted fw-bold w-auto px-3 py-2' onClick={(event) => {campaignState(event, value.campaign_id, "resume")}}><i className="bi bi-play-fill text-muted fs-2"></i> Запустить</button>
                                  */}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='text-end'>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                {/* <span className='text-muted me-2 fs-7 fw-semibold'>49%</span> */}
                                <span className='text-muted me-2 fs-7 fw-semibold'>{value.kpss_from_clicks}%</span>
                              </div>
                              <div className='progress h-6px w-100'>
                                {Number(value.kpss_from_clicks) <= 75 ? <div
                                  className='progress-bar bg-danger'
                                  role='progressbar'
                                  style={{width: `${value.kpss_from_clicks}%`}}
                                /> : <div
                                  className='progress-bar bg-success'
                                  role='progressbar'
                                  style={{width: `${value.kpss_from_clicks}%`}}
                                /> }

                                {/* <div
                          className='progress-bar bg-danger'
                          role='progressbar'
                          style={{width: '49%'}}
                        ></div> */}
                              </div>
                            </div>
                          </td>
                          <td className='text-end'>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                {/* <span className='text-muted me-2 fs-7 fw-semibold'>49%</span> */}
                                <span className='text-muted me-2 fs-7 fw-semibold'>{value.kpss_from_impressions}%</span>
                              </div>
                              <div className='progress h-6px w-100'>
                                {Number(value.kpss_from_impressions) <= 75 ? <div
                                  className='progress-bar bg-danger'
                                  role='progressbar'
                                  style={{width: `${value.kpss_from_impressions}%`}}
                                /> : <div
                                  className='progress-bar bg-success'
                                  role='progressbar'
                                  style={{width: `${value.kpss_from_impressions}%`}}
                                /> }

                                {/* <div
                          className='progress-bar bg-danger'
                          role='progressbar'
                          style={{width: '49%'}}
                        ></div> */}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-column'>
                              <span className='text-dark fw-bold text-muted lh-1 fs-7'>
                                {/* Оптимизация конверсий */}
                                {value.bidding_strategy}
                              </span>
                              {/* <span className='text-dark fw-bold  fs-6'>
                        8 000 000 ₽/нед
                      </span> */}
                              <span className='text-dark fw-bold  fs-6'>
                                {value.daily_budget} {value.daily_budget_period}
                              </span>
                            </div>

                          </td>
                          <td>
                    <span className='text-dark fw-bold  fs-6'>
                      {/* 420 */}
                      {value.impressions}
                    </span>
                          </td>
                          <td>
                    <span className='text-dark fw-bold  fs-6'>
                      {/* 420 */}
                      {value.clicks}
                    </span>
                          </td>
                          <td>
                    <span className='text-dark fw-bold  fs-6'>
                      {/* 420 */}
                      {value.rate}
                    </span>
                          </td>
                          <td>
                    <span className='text-dark fw-bold  fs-6'>
                      {/* 420 */}
                      {value.conversions}
                    </span>
                          </td>
                          <td>
                    <span className='text-dark fw-bold  fs-6'>
                      {/* 420 */}
                      {value.revenue}
                    </span>
                          </td>
                          <td>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                {/* <span className='text-muted me-2 fs-7 fw-semibold'>51%</span> */}
                                <span className='text-muted me-2 fs-7 fw-semibold'>{value.ineffective_rate}%</span>
                              </div>
                              <div className='progress h-6px w-100'>
                                {Number(value.ineffective_rate) <= 25 ? <div
                                  className='progress-bar bg-success'
                                  role='progressbar'
                                  style={{width: `${value.ineffective_rate}%`}}
                                /> : <div
                                  className='progress-bar bg-danger'
                                  role='progressbar'
                                  style={{width: `${value.ineffective_rate}%`}}
                                /> }

                                {/* <div
                          className='progress-bar bg-warning'
                          role='progressbar'
                          style={{width: '51%'}}
                        ></div> */}
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className='text-dark fw-bold text-muted lh-sm fs-7'>
                              {/* Поисковые запросы не релевантны ключевым словам. <a className='text-dark fw-bold text-muted fs-7 text-decoration-underline' href="#">Оптимизируйте КПСС</a> для биддинга ставок  */}
                              {value.recommendation}
                            </p>
                          </td>
                          <td>
                            <div className='d-flex'>
                              {value.recommendation == "Хороший показатель КПСС, подключите биддер для управления ставками" ? <span><Link to='/bidder-page ' className='btn btn-light text-dark fw-bold w-auto px-3 py-2'><i className="bi bi-play-fill text-dark fs-2"></i> Подключить</Link></span> :
                                <span className='bg-light-danger text-danger fw-bold d-flex align-items-center fw-bold w-auto px-3 py-2 rounded-1'>
                        <KTSVG path="/media/icons/duotune/general/gen045.svg" className="text-danger svg-icon-muted svg-icon-2 me-3" />
                        Недоступен
                      </span>
                              }
                            </div>
                          </td>
                        </tr>)
                    })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </ScrollContainer>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
              <div className='d-flex align-items-center justify-content-between mb-5 mt-7'>
                <div style={{ display: 'none' }}>
                  <Select options={options4} className='form-select form-select-solid react-select-container p-0' classNamePrefix="no-icon"  placeholder="10"/>
                </div>
                <div style={{ display: 'none' }}>
                  <ul className="pagination">
                    {/* <li className="page-item previous disabled">
                  <a href="#" className="page-link">
                    <i className="previous"></i>
                  </a>
                </li> */}

                    {
                      pageNumbers.map(number => (
                        filteringCampaign ? <li key={number} /> :
                          <li className="page-item" key={number}>
                            <a href="#" className={`page-link fw-bold text-muted ${currentPage === number ? "active" : ""}`} onClick={() => paginate(number)}>
                              {number}
                            </a>
                          </li>
                      ))
                    }

                    {/* <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a href="#" className="page-link">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    4
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    5
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link fw-bold text-muted">
                    6
                  </a>
                </li> */}

                    {/* <li className="page-item next">
                  <a href="#" className="page-link fw-bold text-muted">
                    <i className="next text-muted"></i>
                  </a>
                </li> */}
                  </ul>
                </div>
              </div>

            </div>


            {/* begin::Body */}

          </div>

          <div className={toogle === 2 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_2" role="tabpanel">
            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-7 mb-md-5 mb-xl-6'>
                </div>
                <div className='col-5 mb-md-5 mb-xl-6 text-period'>
                  Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""} | {autotargetDateFrom.toLocaleDateString() == new Date(0).toLocaleDateString() ? "Автотаргетинг в НГРАМ отсутствует" : `Автотаргетинг с ${autotargetDateFrom.toLocaleDateString()}`}
                </div>
              </div>
              <div className='row'>
                <div className='col-7 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                  <i className="bi bi-search fs-6 fw-bold" />
                </span>
                    <Select
                      className='react-select-container form-control border-start-0 p-0 my-search'
                      classNamePrefix="react-select"
                      options={kpssAdgroupReportRowSelect}
                      placeholder="Поиск по группам"
                      defaultValue={selectedOptionSearchAdgroup}
                      value={selectedOptionSearchAdgroup}
                      onChange={handleSearchAdgroup}
                      isLoading={loadingData}
                      isMulti
                      onFocus={() => setSelectFocus(2)}
                      onBlur={onBlur}
                    />
                  </div>
                </div>
                <div className='col-5 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                  <i className="bi bi-calendar4-event fs-6 fw-bold" />
                </span>
                    {/* <select className="form-select border-start-0 ps-0 text-muted" aria-label="Select example">
                  <option>9 янв 2023 - 12 апр 2023</option>
                  <option value="1">9 янв 2023 - 12 апр 2023</option>
                  <option value="2">9 янв 2023 - 12 апр 2023</option>
                  <option value="3">9 янв 2023 - 12 апр 2023</option>
                </select> */}
                    <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                      //locale={locale}
                                locale="ru"
                                selected={startDate}
                                onChange={handleChangeDate}
                                minDate={minDate}
                                maxDate={maxDate}
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                      //dateFormat="yyyy-MM-dd"
                                dateFormat="d MMMM yyyy"
                    />

                  </div>
                </div>
                {/* <div className='col-3 mb-md-5 mb-xl-6'>
            <a href="#" className="btn btn-light-primary w-100"><i className="bi bi-funnel fs-6 me-1 fw-bold"></i>Расширенный фильтр</a>
            </div> */}
              </div>
              <div className='row mb-5'>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Атрибуция
                    <Tooltip text="По умолчанию используется Автоматическая атрибуция">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Данные по целям
                    <Tooltip text="По умолчанию для выбора доступны цели из настроек РК">
                      <i className="las la-info-circle ms-2 fs-2"></i>
                    </Tooltip>
                  </h6>
                  <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon" placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Условия показа
                    <Tooltip text="По умолчанию используется 'Ключевые фразы'">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={autotargets} className="form-select react-select-container p-0 my-select" classNamePrefix="no-icon" placeholder="Выберите условие показа" value={selectedOptionAutotarget} defaultValue={defaultValueAutotarget} onChange={handleAutotarget} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
              </div>

              <div className='row mb-10'>
                {/*{checkUser && (*/}
                <div className='col-7'>
                  <div className='bg-light'>
                    <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                      {/* begin::Table head */}
                      <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>Доля по типу трафика</span>
                          </div>
                        </th>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>%Показов</span>
                          </div>
                        </th>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>%Кликов</span>
                          </div>
                        </th>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>%Расходов</span>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                      {doeReportRow.map((value, key) => {
                        return (
                          <tr key={key} data-key={key}>
                            <td>
                              <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                  {/*Поиск: Автотаргетинг*/}
                                  {/*{value.account_allocation}*/}
                                  {(() => {
                                    if (value.account_allocation == 'AD_NETWORK') {
                                      return (
                                        "Сеть"
                                      )
                                    } else if (value.account_allocation == 'AUTOTARGETING') {
                                      return (
                                        "Поиск: Автотаргетинг"
                                      )
                                    } else if (value.account_allocation == 'FEED_FILTER') {
                                      return (
                                        "Поиск: Фид"
                                      )
                                    } else if (value.account_allocation == 'RETARGETING') {
                                      return (
                                        "Поиск: Аудитория"
                                      )
                                    } else if (value.account_allocation == 'WEBPAGE_FILTER') {
                                      return (
                                        "Поиск: Содержание URL"
                                      )
                                    } else if (value.account_allocation == 'OFFER_RETARGETING') {
                                      return (
                                        "Поиск: Офферный ретаргетинг"
                                      )
                                    } else if (value.account_allocation == 'KEYWORD') {
                                      return (
                                        "Поиск: Ключевые фразы"
                                      )
                                    } else {
                                      return (
                                        ""
                                      )
                                    }
                                  })()}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-end'>
                                <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                  {/*1,16%*/}
                                  {value.impressions_percent}%
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-end'>
                                <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                  {/*1,16%*/}
                                  {value.clicks_percent}%
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-end'>
                                <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                  {/*1,16%*/}
                                  {value.rate_percent}%
                                </div>
                              </div>
                            </td>
                          </tr>)
                      })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                </div>
                {/*)}*/}
                <div className='col-5'>
                  <div className='bg-light'>
                    <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                      {/* begin::Table head */}
                      <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>Средневзвешенное значение КПСС по аккаунту</span>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              КПСС через клики
                            </div>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*44,08%*/}
                              {summaryKpssFromClicks}%
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              КПСС через показы
                            </div>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*55,92%*/}
                              {summaryKpssFromImpressions}%
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                </div>
              </div>

              {/* begin::Table container */}
              <ReactDataGrid
                checkboxColumn={true}
                checkboxOnlyRowSelect={true}
                className='table__data-grid'
                idProperty="adgroup_id"
                showZebraRows={false}
                style={gridStyle}
                activateRowOnFocus={activateRowOnFocus}
                columns={columnsKpssAdgroup}
                dataSource={currentKpssAdgroupRow}
                pagination={true}
                //limit={10}
                defaultLimit={10}
                rowHeight={null}
                minRowHeight={63}
                onSelectionChange={onSelectionKpssAdgroup}
                selected={selectedKpssAdgroup}
                editable={true}
                loading={loadingData}
                loadingText={pleaseWait}
                enableFiltering={true}
                defaultFilterValue={defaultFilterValueAdgroup}
                onFilterValueChange={onFilterValueChangeAdgroup}
                i18n={translateRDG}
                enableColumnAutosize={false}
              />

              <div className='table-responsive'>
                {/* begin::Table */}
                <ScrollContainer>
                  <table className='table table-bordered border align-middle mb-0 gx-5 gy-5'  style={{ display: 'none' }}>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-400px text-nowrap'>
                        <div className='d-flex flex-row align-items-center'>
                          <div className='form-check form-check-ms form-check-custom form-check'>
                            <input
                              className='form-check-input'
                              type='hidden'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-9-check'
                            />
                          </div>
                          <div className='ms-3 text-muted'>
                            Название
                          </div>

                        </div>

                      </th>
                      <th className='min-w-175px text-nowrap text-muted'>КПСС через клики </th>
                      <th className='min-w-175px text-nowrap text-muted'>КПСС через показы </th>
                      <th className='min-w-175px text-nowrap text-muted'>Бюджет и стратегия</th>
                      <th className='min-w-125px text-nowrap text-muted'>Показы</th>
                      <th className='min-w-125px text-nowrap text-muted'>Клики</th>
                      <th className='min-w-175px text-nowrap text-muted'>Расход с НДС</th>
                      <th className='min-w-125px text-nowrap text-muted'>Конверсии</th>
                      <th className='min-w-125px text-nowrap text-muted'>Доход</th>
                      <th className='min-w-200px text-nowrap text-muted'>Неэффективный расход</th>
                      <th className='min-w-450px text-nowrap text-muted'>Рекомендации</th>
                      <th className='min-w-175px text-nowrap text-muted'>Биддинг</th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                    <tr>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-check-ms form-check-custom form-check '>
                            <input className='form-check-input widget-9-check' type='hidden' value='1' />
                          </div>
                          <div className='d-flex justify-content-start flex-column ms-3'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          №3222123
                        </span>
                            <div className='text-dark fw-bold fs-6'>
                              Поздравление Деда Мороза — МСК
                            </div>
                            <div>
                              <button className='btn btn-light text-muted fw-bold w-auto px-3 py-2'><i className="bi bi-play-fill text-muted fs-2" /> Запустить</button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>49%</span>
                          </div>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-danger'
                              role='progressbar'
                              style={{width: '49%'}}
                            />
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>49%</span>
                          </div>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-danger'
                              role='progressbar'
                              style={{width: '49%'}}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex flex-column'>
                      <span className='text-dark fw-bold text-muted lh-1 fs-7'>
                        Оптимизация конверсий
                      </span>
                          <span className='text-dark fw-bold  fs-6'>
                        8 000 000 ₽/нед
                      </span>
                        </div>

                      </td>
                      <td>
                    <span className='text-dark fw-bold  fs-6'>
                      420
                    </span>
                      </td>
                      <td>
                    <span className='text-dark fw-bold  fs-6'>
                      420
                    </span>
                      </td>
                      <td>
                    <span className='text-dark fw-bold  fs-6'>
                      420
                    </span>
                      </td>
                      <td>
                    <span className='text-dark fw-bold  fs-6'>
                      420
                    </span>
                      </td>
                      <td>
                    <span className='text-dark fw-bold  fs-6'>
                      420
                    </span>
                      </td>
                      <td>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>51%</span>
                          </div>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-warning'
                              role='progressbar'
                              style={{width: '51%'}}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className='text-dark fw-bold text-muted lh-sm fs-7'>
                          Поисковые запросы не релевантны ключевым словам. <a className='text-dark fw-bold text-muted fs-7 text-decoration-underline' href="#">Оптимизируйте КПСС</a> для биддинга ставок
                        </p>
                      </td>
                      <td>
                        <div className='d-flex'>
                      <span className='bg-light-danger text-danger fw-bold d-flex align-items-center fw-bold w-auto px-3 py-2 rounded-1'>
                        <KTSVG path="/media/icons/duotune/general/gen045.svg" className="text-danger svg-icon-muted svg-icon-2 me-3" />
                        Недоступен
                      </span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </ScrollContainer>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
              <div className='d-flex align-items-center justify-content-between mb-5 mt-7'>
                <div style={{ display: 'none' }}>
                  <Select options={options4} className='form-select form-select-solid react-select-container p-0' classNamePrefix="no-icon"  placeholder="10"/>
                </div>
                <div style={{ display: 'none' }}>
                  <ul className="pagination">
                    <li className="page-item previous disabled">
                      <a href="#" className="page-link">
                        <i className="previous" />
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link fw-bold text-muted">
                        1
                      </a>
                    </li>
                    <li className="page-item active">
                      <a href="#" className="page-link">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link fw-bold text-muted">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link fw-bold text-muted">
                        4
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link fw-bold text-muted">
                        5
                      </a>
                    </li>
                    <li className="page-item">
                      <a href="#" className="page-link fw-bold text-muted">
                        6
                      </a>
                    </li>
                    <li className="page-item next">
                      <a href="#" className="page-link fw-bold text-muted">
                        <i className="next text-muted" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            {/* begin::Body */}
          </div>

          <div className={toogle === 3 ? "tab-pane fade active show" : "tab-pane fade"} id="kt_tab_pane_2" role="tabpanel">
            {/* begin::Body */}
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-7 mb-md-5 mb-xl-6'>
                </div>
                <div className='col-5 mb-md-5 mb-xl-6 text-period'>
                  Доступный период: {availableStartDate.toLocaleDateString() != null ? availableStartDate.toLocaleDateString() : ""} - {availableEndDate.toLocaleDateString() != null ? availableEndDate.toLocaleDateString() : ""} | {autotargetDateFrom.toLocaleDateString() == new Date(0).toLocaleDateString() ? "Автотаргетинг в НГРАМ отсутствует" : `Автотаргетинг с ${autotargetDateFrom.toLocaleDateString()}`}
                </div>
              </div>
              <div className='row'>
                <div className='col-7 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                <span className="input-group-text bg-white border-end-0" id="basic-addon1">
                  <i className="bi bi-search fs-6 fw-bold" />
                </span>
                    <Select
                      className='react-select-container form-control border-start-0 p-0 my-search'
                      classNamePrefix="react-select"
                      options={editedKpssPhrasesReportRowSelect}
                      placeholder="Поиск по фразам"
                      defaultValue={selectedOptionSearchPhrases}
                      value={selectedOptionSearchPhrases}
                      onChange={handleSearchPhrases}
                      isLoading={loadingData}
                      isMulti
                      onFocus={() => setSelectFocus(2)}
                      onBlur={onBlur}
                    />
                  </div>
                </div>
                <div className='col-5 mb-md-5 mb-xl-6'>
                  <div className="input-group mb-3">
                <span className="input-group-text bg-white border-end-0" id="basic-addon2">
                  <i className="bi bi-calendar4-event fs-6 fw-bold" />
                </span>
                    <DatePicker className="form-select d-flex border-start-0 ps-0 rounded-start-0 text-muted"
                      //locale={locale}
                                locale="ru"
                                selected={startDate}
                                onChange={handleChangeDate}
                                minDate={minDate}
                                maxDate={maxDate}
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                      //dateFormat="yyyy-MM-dd"
                                dateFormat="d MMMM yyyy"
                    />

                  </div>
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Атрибуция
                    <Tooltip text="По умолчанию используется Автоматическая атрибуция">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={attributions} className='form-select react-select-container p-0 my-select' classNamePrefix="no-icon"  placeholder="Установите атрибуцию" value={selectedOptionAttribution} defaultValue={defaultValueAttribution} onChange={handleAttribution} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Данные по целям
                    <Tooltip text="По умолчанию для выбора доступны цели из настроек РК">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={goals} formatOptionLabel={formatOptionLabelGoal} className={goalForReport ? "form-select react-select-container p-0 my-select" : "form-select react-select-container p-0 my-select danger"} classNamePrefix="no-icon" placeholder="Выберите данные по целям" value={selectedOptionGoal} defaultValue={defaultValueGoal} onChange={handleGoal} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
                <div className='col-4 mb-md-5 mb-xl-6'>
                  <h6>Условия показа
                    <Tooltip text="По умолчанию используется 'Ключевые фразы'">
                      <i className="las la-info-circle ms-2 fs-2" />
                    </Tooltip>
                  </h6>
                  <Select options={autotargets} className="form-select react-select-container p-0 my-select" classNamePrefix="no-icon" placeholder="Выберите условие показа" value={selectedOptionAutotarget} defaultValue={defaultValueAutotarget} onChange={handleAutotarget} onFocus={() => setSelectFocus(2)} onBlur={onBlur} />
                </div>
              </div>

              <div className='row mb-10'>
                {/*{checkUser && (*/}
                <div className='col-7'>
                  <div className='bg-light'>
                    <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                      {/* begin::Table head */}
                      <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>Доля по типу трафика</span>
                          </div>
                        </th>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>%Показов</span>
                          </div>
                        </th>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>%Кликов</span>
                          </div>
                        </th>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>%Расходов</span>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                      {doeReportRow.map((value, key) => {
                        return (
                          <tr key={key} data-key={key}>
                            <td>
                              <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                  {/*Поиск: Автотаргетинг*/}
                                  {/*{value.account_allocation}*/}
                                  {(() => {
                                    if (value.account_allocation == 'AD_NETWORK') {
                                      return (
                                        "Сеть"
                                      )
                                    } else if (value.account_allocation == 'AUTOTARGETING') {
                                      return (
                                        "Поиск: Автотаргетинг"
                                      )
                                    } else if (value.account_allocation == 'FEED_FILTER') {
                                      return (
                                        "Поиск: Фид"
                                      )
                                    } else if (value.account_allocation == 'RETARGETING') {
                                      return (
                                        "Поиск: Аудитория"
                                      )
                                    } else if (value.account_allocation == 'WEBPAGE_FILTER') {
                                      return (
                                        "Поиск: Содержание URL"
                                      )
                                    } else if (value.account_allocation == 'OFFER_RETARGETING') {
                                      return (
                                        "Поиск: Офферный ретаргетинг"
                                      )
                                    } else if (value.account_allocation == 'KEYWORD') {
                                      return (
                                        "Поиск: Ключевые фразы"
                                      )
                                    } else {
                                      return (
                                        ""
                                      )
                                    }
                                  })()}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-end'>
                                <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                  {/*1,16%*/}
                                  {value.impressions_percent}%
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-end'>
                                <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                  {/*1,16%*/}
                                  {value.clicks_percent}%
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center justify-content-end'>
                                <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                                  {/*1,16%*/}
                                  {value.rate_percent}%
                                </div>
                              </div>
                            </td>
                          </tr>)
                      })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                </div>
                {/*)}*/}
                <div className='col-5'>
                  <div className='bg-light'>
                    <table className='table table-bordered border align-middle mb-0 gx-3 gy-3'>
                      {/* begin::Table head */}
                      <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-160px text-nowrap text-muted fw-semibold'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='text-nowrap text-muted fw-semibold fs-7'>Средневзвешенное значение КПСС по аккаунту</span>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              КПСС через клики
                            </div>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*44,08%*/}
                              {summaryKpssFromClicks}%
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              КПСС через показы
                            </div>
                            <div className='d-flex align-items-center text-dark fw-bold fs-7'>
                              {/*55,92%*/}
                              {summaryKpssFromImpressions}%
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                </div>
              </div>

              {/* begin::Table container */}
              <ReactDataGrid
                idProperty="id"
                showZebraRows={false}
                style={gridStyle}
                activateRowOnFocus={activateRowOnFocus}
                columns={columnsKpssPhrases}
                dataSource={currentKpssPhrasesRow}
                pagination={true}
                //limit={10}
                defaultLimit={10}
                rowHeight={null}
                minRowHeight={63}
                editable={true}
                loading={loadingData}
                loadingText={pleaseWait}
                enableFiltering={true}
                defaultFilterValue={defaultFilterValuePhrases}
                onFilterValueChange={onFilterValueChangePhrases}
                i18n={translateRDG}
                enableColumnAutosize={false}
              />
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>

        </div>


      </div>


      <div className='card mt-8 mb-5 d-none'>
        <div className='card-header border-0 pt-5 d-flex flex-column'>
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <h3 className='card-title card-label fw-bold fs-3 mb-3'>
              Рекламных кампаний выбрано
              <span className='text-muted ms-2'>(0)</span>
            </h3>
            <button className='fw-bold fs-3 btn btn-white text-danger'>Удалить все</button>
          </div>
          <div className='d-none'>
            <ul className='list-unstyled d-flex flex-row flex-wrap'>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3">Поздравление Деда Мороза — МСК <i className="bi bi-x fs-2x" /></a>
              </li>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3">Платье в гороше — WB <i className="bi bi-x fs-2x" /></a>
              </li>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3 ">Зимняя резина — МСК <i className="bi bi-x fs-2x" /></a>
              </li>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3">Самокаты с плохой зарядкой <i className="bi bi-x fs-2x" /></a>
              </li>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3">Продажа картошки <i className="bi bi-x fs-2x" /></a>
              </li>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3">Турецкие палочки <i className="bi bi-x fs-2x" /></a>
              </li>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3">Эхолот с крутой маржой <i className="bi bi-x fs-2x" /></a>
              </li>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3">Запчасти для Весты <i className="bi bi-x fs-2x" /></a>
              </li>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3">Карты таро — реально предсказывают судьбу <i className="bi bi-x fs-2x" /></a>
              </li>
              <li>
                <a href="#" className="btn btn-light-primary text-primary text-hover-light text-nowrap mb-3 me-3">Курс цыгана <i className="bi bi-x fs-2x" /></a>
              </li>
            </ul>

          </div>
          <Link to='/optimization-page' className='btn btn-light fw-bold text-muted mb-5 '>
            Семантический анализ для оптимизации КПСС
          </Link>
        </div>
      </div>
    </div>
  )
}

export {TablesWidget9}
