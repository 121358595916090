export const sendMetricVisitParametr = (type, value) => {
  window.ym(97343596, type, value)
}

export const sendMetricGoalVisitParametr = (type, idGoal, value) => {
  window.ym(97343596, type, idGoal, value)
}

// export const sendMetricVisitorOptions = (type, value) => {
//   window.ym(97343596, type, value)
// }

export const addToDataLayer = (value) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(value)
  }
}
