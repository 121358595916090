import {FC} from 'react'
// import ChartsWidget3 from "./_metronic/partials/widgets/charts/ChartsWidget3";
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {PageTitle} from '../../_metronic/layout/core'
import {
  TablesWidget19,
} from '../../_metronic/partials/widgets'


const SummaryReport: FC = () => {
  return (
    <div >
      <TablesWidget19 className=''/>
    </div>
  )
}

export {SummaryReport}
